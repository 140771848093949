import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponentType.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelComponentTypeState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { v4 as uuid } from 'uuid' ;
import ModelSubComponentTypeListTable from './ModelSubComponentTypeListTable';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../utils/';

const ModelComponentType = ({
  userProfileWidgets,
  modelComponentType,
  saveModelComponentType,
  describeModelComponentType,
  changeModelComponentType,
  resetModelComponent,
  intl
}) => {
  let history = useNavigate();
  let { modelComponentTypeId } = useParams();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let IsEdit = false;

  let FirmwareType = modelComponentType.selectedModelComponentType.FirmwareType ? true : false;

  const [localformErrors, setFormErrors] = useState({});

  const [hasFirmware, setHasFirmware] = useState(FirmwareType);

  const backUrl = '/admin-controls/model-component';
  let widgetCode = '';
  if (modelComponentTypeId) {
    IsEdit = true;
    widgetCode = 'MODELCOMPONENTTYPE_EDIT';
  }

  useEffect(() => {
    if (modelComponentTypeId) {
      describeModelComponentType(modelComponentTypeId);
    }
  }, [describeModelComponentType, modelComponentTypeId]);

  useEffect(() => {
    if (FirmwareType) {
      setHasFirmware(FirmwareType);
    }
  }, [FirmwareType]);

  //clean up
  useEffect(() => {
    return () => {
      resetModelComponent({
        ...initialModelComponentTypeState,
        modelComponentType: {
          ...initialModelComponentTypeState.modelComponentType,
          selectedModelComponentType: {
            ...initialModelComponentTypeState.modelComponentType.selectedModelComponentType,
            modelSubComponentTypes: []
          }
        }
      });
    };
  }, []);

  // Update redux store
  const setLocalModelComponentType = currentState => {
    changeModelComponentType(currentState);
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = modelComponentType.selectedModelComponentType;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.ModelComponentTypeId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'MODELCOMPONENTTYPE_ADDNEW';
    }

    saveModelComponentType(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelComponentType ||
      !modelComponentType.selectedModelComponentType ||
      !modelComponentType.selectedModelComponentType.Name ||
      isRequired(modelComponentType.selectedModelComponentType.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'modelComponentTypes.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalModelComponentType({
      ...modelComponentType,
      selectedModelComponentType: { ...modelComponentType.SelectedModelComponentType, [e.target.name]: e.target.value }
    });
  };

  const onHasFirmwareChange = e => {
    setHasFirmware(!hasFirmware);
  };

  const onDropdownChange = e => {
    setLocalModelComponentType({
      ...modelComponentType,
      selectedModelComponentType: { ...modelComponentType.SelectedModelComponentType, FirmwareType: e.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  const onModelSubComponentTypeAddOrEdit = modelSubComponentTypes => {
    setLocalModelComponentType({
      ...modelComponentType,
      selectedModelComponentType: {
        ...modelComponentType.SelectedModelComponentType,
        modelSubComponentTypes: modelSubComponentTypes
      }
    });
  };

  const firmwareOptions = () => {
    let firmwareOptions = [
      {
        label: 'None',
        value: ''
      },
      {
        label: 'PCBA',
        value: 'PCBA'
      },
      {
        label: 'SBC',
        value: 'SBC'
      }
    ];

    return firmwareOptions.sort(compareValues('label'));
  };

  let messageId = (modelComponentType && modelComponentType.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelCompTypeName = (modelComponentType.selectedModelComponentType ? modelComponentType.selectedModelComponentType.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'modelComponentTypes.edit', defaultMessage: 'Edit' }) + ' ' + modelCompTypeName;
  } else {
    heading = intl.formatMessage({ id: 'modelComponentTypes.addNewComponentType', defaultMessage: 'Add new component type' });
  }

  return (
    <div className={s.modelComponentType}>
      {modelComponentType.isLoading && <LoadingSpinner />}
      {modelComponentType.isRedirect ? <Navigate to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={modelComponentType.showBanner}
        status={modelComponentType.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageComponents" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelComponentTypeHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="modelComponentTypes.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.modelComponentTypeContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formModelComponentTypeName">
                      <Form.Label>
                        <FormattedMessage id="modelComponentTypes.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={modelComponentType.selectedModelComponentType.Name}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'modelComponentTypes.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formModelComponentTypeDescription">
                      <Form.Label>
                        <FormattedMessage id="modelComponentTypes.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'modelComponentTypes.description',
                          defaultMessage: 'Description'
                        })}
                        value={modelComponentType.selectedModelComponentType.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formActiveModel">
                      <Checkbox
                        key={uuidv4()}
                        dataArray={[
                          {
                            SKEY: 'hasfirmware',
                            target: { type: 'checkbox' },
                            label: intl.formatMessage({
                              id: 'modelComponentTypes.hasFirmware',
                              defaultMessage: 'Has Firmware'
                            }),
                            isChecked: hasFirmware
                          }
                        ]}
                        onSelectionChange={onHasFirmwareChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                {hasFirmware && (
                  <Row>
                    <Col lg={3}>
                      <Form.Group controlId="firmwareType">
                        <Form.Label>
                          <FormattedMessage id="modelComponentTypes.firmwareType" defaultMessage="Firmware Type" />
                        </Form.Label>

                        <Dropdown
                          id="ddlFirmwareOption"
                          dataArray={firmwareOptions()}
                          controlData={{
                            placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                          }}
                          onDropdownChange={onDropdownChange}
                          selectedOption={firmwareOptions().filter(
                            option => option.value === modelComponentType.selectedModelComponentType.FirmwareType
                          )}
                          data-unittest="ddlFirmwareOption"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}
              </div>
            </Form>
          </div>
          <div>
            <Form>
              <Row>
                <Col>
                  <ModelSubComponentTypeListTable
                    intl={intl}
                    onModelSubComponentTypeAddOrEdit={onModelSubComponentTypeAddOrEdit}
                    modelSubComponentTypes={modelComponentType.selectedModelComponentType.modelSubComponentTypes}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button
                    variant="primary"
                    className={classNames(s.margin5, s.btnSaveChanges)}
                    onClick={submitForm}
                    noValidate
                    data-unittest="saveData"
                  >
                    <FormattedMessage id="modelComponentTypes.save" defaultMessage="SAVE" />
                  </Button>

                  <Button
                    variant="outline-secondary"
                    className={classNames(s.btnCancel)}
                    onClick={cancelHandler}
                    noValidate
                    data-unittest="saveCancel"
                  >
                    <FormattedMessage id="modelComponentTypes.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelComponentType.defaultProps = {
  modelComponentType: {
    ...initialModelComponentTypeState
  }
};

ModelComponentType.propTypes = {
  saveModelComponentType: PropTypes.func.isRequired,
  changeModelComponentType: PropTypes.func.isRequired,
  modelComponentType: PropTypes.object.isRequired
};

export default injectIntl(ModelComponentType);
