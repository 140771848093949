import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './UnitConfigurationParts.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialUnitConfigurationState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';
import { getApprovedComponent } from '../../actions/approvedComponents';
import { getSpareComponentPart } from '../../actions/unitConfiguration';

const UnitConfigurationPart = ({
  OldComponentPartId,
  saveComponentPart,
  modelComponentPositions,
  changeComponentPart,
  cancelHandler,
  UnitId,
  currentEditId,
  intl
}) => {
  const dispatch = useDispatch();
  const unitConfigurationData = useSelector(state => state.unitConfiguration);
  const approvedComponentsData = useSelector(state => state.approvedComponent);
  const unitManagerData = useSelector(state => state.unitManager);
  const unitConfiguration = unitConfigurationData && unitConfigurationData.unitConfiguration;
  const approvedComponents = approvedComponentsData && approvedComponentsData.approvedComponent;
  const unitManager = unitManagerData && unitManagerData.unitManager;

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let filter = unitConfiguration.filter || '';
  let offset = unitConfiguration.offset || 0;
  let limit = unitConfiguration.limit || 1000;
  let IsEdit = false;
  let selectedComponentTypeId = 0;

  const isConsumable = unitConfiguration.componentParts.selectedComponentPart.IsConsumable || false;
  const selectedModelComponentPositionId = unitConfiguration.componentParts.selectedComponentPart.ModelComponentPositionId || 0;

  useEffect(() => {
    dispatch(getSpareComponentPart({ offset, limit, filter }, 'UNIT_PARTS'));
  }, [getSpareComponentPart, currentEditId]);

  useEffect(() => {
    dispatch(getApprovedComponent({ offset, limit, filter }, 'UNIT_PARTS_REPLACE'));
  }, [getApprovedComponent, offset, limit, filter]);

  let selectedComponentPosition = modelComponentPositions.filter(x => x.ModelComponentPositionId === selectedModelComponentPositionId);
  if (selectedComponentPosition.length > 0) {
    selectedComponentTypeId = selectedComponentPosition[0].ModelComponentTypeId;
  }

  const [localformErrors, setFormErrors] = useState({});

  if (OldComponentPartId) {
    IsEdit = true;
  }

  // Update redux store
  const setLocaleComponentPart = currentState => {
    changeComponentPart(currentState);
  };

  let consumableApprovedComponents =
    (approvedComponents &&
      approvedComponents.approvedComponentList &&
      approvedComponents.approvedComponentList.filter(x => x.IsConsumable)) ||
    [];

  const modelComponentPositionOptions = () => {
    let modelComponentPositionData = [];
    modelComponentPositions.forEach(element => {
      if (
        unitConfiguration &&
        unitConfiguration.componentParts &&
        unitConfiguration.componentParts.componentPartsList.filter(x => x.ModelComponentPositionId === element.ModelComponentPositionId)
          .length === 1
      ) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      modelComponentPositionData.push({
        ...element,
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return modelComponentPositionData.sort(compareValues('label'));
  };

  const approvedComponentOptions = () => {
    let approvedComponentsData = [];

    if (selectedComponentTypeId > 0) {
      consumableApprovedComponents = consumableApprovedComponents.filter(x => x.ModelComponentTypeId === selectedComponentTypeId);
    }

    consumableApprovedComponents.forEach(element => {
      approvedComponentsData.push({
        ...element,
        label: `${element.Brand} - ${element.Model}`,
        value: element.ApprovedComponentId
      });
    });

    approvedComponentsData.push({
      label: <FormattedMessage id="componentParts.selectApprovedComponent" defaultMessage="Select Approved Component" />,
      value: ''
    });

    return approvedComponentsData.sort(compareValues('label'));
  };

  const partsOptions = () => {
    let partsData = [];
    let spareParts =
      (unitConfiguration &&
        unitConfiguration.componentParts &&
        unitConfiguration.componentParts.spareComponentPartsList &&
        unitConfiguration.componentParts.spareComponentPartsList) ||
      [];
    if (selectedComponentTypeId > 0) {
      spareParts = spareParts.filter(x => x.ModelComponentTypeId === selectedComponentTypeId);
    }

    spareParts.forEach(element => {
      partsData.push({
        ...element,
        label: ` ${element.SerialNumber} - ${element.Brand} - ${element.Model}`,
        value: element.ComponentPartId
      });
    });
    return partsData.sort(compareValues('label'));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = unitConfiguration && unitConfiguration.componentParts.selectedComponentPart;
    saveData.AddUnitComponentPart = !IsEdit;
    saveData.ReplaceComponentPart = IsEdit;
    saveData.UnitId = UnitId;
    saveData.UnitSerialNumber = unitManager.selectedUnit.UnitSerialNumber;
    saveData.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    //in case user has not changed anything in Approved Component dropdown and clicked replace
    if (saveData.IsConsumable && !saveData.UpdateSerialNumber && !saveData.AllocateComponentPart) {
      var d = new Date();
      let newSerialNumber = `${saveData.UnitSerialNumber}/${saveData.ModelComponentPositionName}/${moment(d).unix()}`;
      saveData.SerialNumber = newSerialNumber;
      saveData.UpdateSerialNumber = true;
      saveData.AllocateComponentPart = false;
    }

    saveComponentPart(saveData);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !isConsumable &&
      (!unitConfiguration ||
        !unitConfiguration.componentParts.selectedComponentPart ||
        !unitConfiguration.componentParts.selectedComponentPart.ComponentPartId ||
        isRequired(unitConfiguration.componentParts.selectedComponentPart.ComponentPartId, 1))
    ) {
      formErrors.ComponentPartId = intl.formatMessage({
        id: 'unitConfigurationComponentParts.componentPartMandatory',
        defaultMessage: 'Component Part is mandatory'
      });
      isValid = false;
    }

    if (
      isConsumable &&
      (!unitConfiguration ||
        !unitConfiguration.componentParts.selectedComponentPart ||
        !unitConfiguration.componentParts.selectedComponentPart.ApprovedComponentId ||
        isRequired(unitConfiguration.componentParts.selectedComponentPart.ApprovedComponentId, 1))
    ) {
      formErrors.ApprovedComponentId = intl.formatMessage({
        id: 'componentParts.approvedComponentMandatory',
        defaultMessage: 'Approved Component is mandatory'
      });
      isValid = false;
    }

    if (
      !unitConfiguration ||
      !unitConfiguration.componentParts.selectedComponentPart ||
      !unitConfiguration.componentParts.selectedComponentPart.ModelComponentPositionId ||
      isRequired(unitConfiguration.componentParts.selectedComponentPart.ModelComponentPositionId, 1)
    ) {
      formErrors.ModelComponentPositionId = intl.formatMessage({
        id: 'unitConfigurationComponentParts.modelComponentPositionMandatory',
        defaultMessage: 'Model Component Position is mandatory'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onComponentPartDropdownChange = e => {
    setLocaleComponentPart({
      selectedComponentPart: {
        ...unitConfiguration.componentParts.selectedComponentPart,
        ComponentPartId: e.value,
        ComponentTypeName: e.ComponentTypeName,
        SerialNumber: e.SerialNumber
      }
    });
  };

  const onPositionDropdownChange = e => {
    setLocaleComponentPart({
      selectedComponentPart: { ...unitConfiguration.componentParts.selectedComponentPart, ModelComponentPositionId: e.value }
    });
  };

  const onApprovedComponentDropdownChange = e => {
    var d = new Date();
    const newSerialNumber = `${unitConfiguration.componentParts.selectedComponentPart.UnitSerialNumber}/${
      unitConfiguration.componentParts.selectedComponentPart.ModelComponentPositionName
    }/${moment(d).unix()}`;

    //if approved component is same as existing
    if (unitConfiguration.componentParts.selectedComponentPart.ApprovedComponentId === e.value) {
      setLocaleComponentPart({
        selectedComponentPart: {
          ...unitConfiguration.componentParts.selectedComponentPart,
          SerialNumber: newSerialNumber,
          UpdateSerialNumber: true,
          AllocateComponentPart: false
        }
      });
    } else {
      //check if the component part already exists in spare parts for the selected approved component
      let spareParts = unitConfiguration.componentParts.spareComponentPartsList.filter(
        x => x.ApprovedComponentId === e.value && x.ModelComponentTypeId === selectedComponentTypeId
      );
      if (spareParts.length > 0) {
        let existingPart = spareParts[0];
        setLocaleComponentPart({
          selectedComponentPart: {
            ...unitConfiguration.componentParts.selectedComponentPart,
            ComponentPartId: existingPart.ComponentPartId,
            SerialNumber: newSerialNumber,
            ApprovedComponentId: e.value,
            UpdateSerialNumber: true,
            AllocateComponentPart: true
          }
        });
      } else {
        //create new component part and add to the unit
        setLocaleComponentPart({
          selectedComponentPart: {
            ...unitConfiguration.componentParts.selectedComponentPart,
            SerialNumber: newSerialNumber,
            Location: '',
            ApprovedComponentId: e.value,
            IsFaulty: false,
            IsActive: true,
            IsDeleted: false,
            GuidIdentifier: uuidv4(),
            IsConsumable: true,
            UpdateSerialNumber: false,
            AllocateComponentPart: true
          }
        });
      }
    }
  };

  let ddlComponentPartClass = localformErrors && localformErrors.ComponentPartId ? s.ddlError : '';
  let ddlPositionClass = localformErrors && localformErrors.ModelComponentPositionId ? s.ddlError : '';
  let ddlApprovedComponentClass = localformErrors && localformErrors.ApprovedComponentId ? s.ddlError : '';

  return (
    <div className={s.componentPart}>
      <div className={s.componentPartContent}>
        <Form>
          <div className={s.topRow}>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="formPositionName">
                  <Form.Label>
                    <FormattedMessage id="componentParts.selectModleComponentPosition" defaultMessage="Select Model Component Position" />
                  </Form.Label>
                  <Dropdown
                    id="formPositionName"
                    dataArray={modelComponentPositionOptions()}
                    controlData={{
                      placeholderText: (
                        <FormattedMessage
                          id="componentParts.selectModleComponentPosition"
                          defaultMessage="Select Model Component Position"
                        />
                      ),
                      customClassName: ddlPositionClass
                    }}
                    onDropdownChange={onPositionDropdownChange}
                    selectedOption={modelComponentPositionOptions().filter(
                      option => option.value === unitConfiguration.componentParts.selectedComponentPart.ModelComponentPositionId
                    )}
                    disabled={IsEdit}
                    data-unittest="formModelComponentPosition"
                  />
                  {localformErrors && localformErrors.ModelComponentPositionId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.ModelComponentPositionId}
                    </p>
                  )}
                </Form.Group>
              </Col>
              {!isConsumable ? (
                <Col lg={4}>
                  <Form.Group controlId="formComponentPart">
                    <Form.Label>
                      <FormattedMessage id="unitConfigurationComponentParts.selectPart" defaultMessage="Select Part" />
                    </Form.Label>
                    <Dropdown
                      id="formComponentPart"
                      dataArray={partsOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="unitConfigurationComponentParts.selectPart" defaultMessage="Select Part" />,
                        customClassName: ddlComponentPartClass
                      }}
                      onDropdownChange={onComponentPartDropdownChange}
                      selectedOption={partsOptions().filter(
                        option => option.value === unitConfiguration.componentParts.selectedComponentPart.ComponentPartId
                      )}
                      data-unittest="formComponentPart"
                    />
                    {localformErrors && localformErrors.ComponentPartId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.ComponentPartId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              ) : (
                <Col lg={4}>
                  <Form.Group controlId="formApprovedComponent">
                    <Form.Label>
                      <FormattedMessage id="componentParts.approvedComponent" defaultMessage="Approved Component" />
                    </Form.Label>
                    <Dropdown
                      id="formApprovedComponent"
                      dataArray={approvedComponentOptions()}
                      controlData={{
                        placeholderText: (
                          <FormattedMessage id="componentParts.selectApprovedComponent" defaultMessage="Select Approved Component" />
                        ),
                        customClassName: ddlApprovedComponentClass
                      }}
                      onDropdownChange={onApprovedComponentDropdownChange}
                      selectedOption={approvedComponentOptions().filter(
                        option => option.value === unitConfiguration.componentParts.selectedComponentPart.ApprovedComponentId
                      )}
                      data-unittest="formApprovedComponent"
                    />
                    {localformErrors && localformErrors.ApprovedComponentId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.ApprovedComponentId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col lg={OldComponentPartId ? 5 : 4} xs={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  {IsEdit ? (
                    <FormattedMessage id="componentParts.replace" defaultMessage="Replace" />
                  ) : (
                    <FormattedMessage id="componentParts.save" defaultMessage="SAVE" />
                  )}
                </Button>
                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                >
                  <FormattedMessage id="componentParts.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
              <Col lg={OldComponentPartId ? 7 : 5}></Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

UnitConfigurationPart.defaultProps = {
  unitConfiguration: {
    ...initialUnitConfigurationState.unitConfiguration
  }
};

UnitConfigurationPart.propTypes = {
  saveComponentPart: PropTypes.func.isRequired,
  changeComponentPart: PropTypes.func.isRequired,
  cancelHandler: PropTypes.func.isRequired,
  UnitId: PropTypes.number.isRequired,
  modelComponentPositions: PropTypes.array.isRequired
};

export default injectIntl(UnitConfigurationPart);
