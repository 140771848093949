import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './Login.module.scss';
import bs from '../../styles/bootstrap-overrides.scss';
import { checkAndRedirect, isValidEmail, isValidPassword } from '../../utils/';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import logoBlack from '../../assets/logo-black.svg';
import alert from '../../assets/alert.svg';
import greentick from '../../assets/greentick.svg';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Alert from 'react-bootstrap/Alert';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Cookies from 'js-cookie';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';

const Login = ({ isAuthenticated, isLoading, loginError, loginErrorMessage, isForgotPassword, submitLogin, redirect }) => {
  const [form, setForm] = useState({ username: '', password: '' });
  const [formErrors, setFormErrors] = useState({ username: false, password: false });
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    checkAndRedirect(!isAuthenticated, redirect);
  }, [isAuthenticated, redirect]);

  const handleInput = e => {
    const { name, value } = e.target;

    setForm({
      ...form,
      [name]: value
    });
  };

  const handleSubmit = e => {
    e.preventDefault();
    const usernameError = !isValidEmail(form.username);
    const passwordError = !isValidPassword(form.password);

    setFormErrors({ username: usernameError, password: passwordError });

    if (!usernameError && !passwordError) {
      //Removing cookies
      if (form.username !== Cookies.get(`username-${envName}`)) {
        Cookies.remove(`selectedprofile-${envName}`, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
        Cookies.remove(`selectedprofileid-${envName}`, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
        Cookies.remove(`selectedorganisationid-${envName}`, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
        Cookies.remove(`username-${envName}`, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
        Cookies.remove(`userid-${envName}`, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
      }

      submitLogin(form);
    }
  };

  const handleContactUs = () => {
    window.open('https://watersourceaustralia.com.au/contact', '_blank');
  };

  return (
    <div className={s.wrapper}>
      <img src={logoBlack} alt="icon" className={classNames(s.hideDesktop, s.logo)} />
      <div className={s.contactSection}>
        <span>
          <FormattedMessage id="general.needHelp" defaultMessage="Need help?" />
        </span>
        <Button variant="outline-secondary" onClick={handleContactUs}>
          <FormattedMessage id="general.contactUsNow" defaultMessage="CONTACT US NOW" />
        </Button>
      </div>

      <div className={s.login}>
        <h1>
          <FormattedMessage id="login.login" defaultMessage="Log In" />
        </h1>

        <Form onSubmit={handleSubmit}>
          <Row>
            <Col lg={9} sm={12}>
              {loginError && (
                <Alert variant="light" className={s.alertError}>
                  <img src={alert} alt="icon" />{' '}
                  <div>
                    {loginErrorMessage ? (
                      <FormattedMessage id={loginErrorMessage} defaultMessage="Error logging in" />
                    ) : (
                      <FormattedMessage id="login.error" defaultMessage="Error logging in" />
                    )}
                  </div>
                </Alert>
              )}
              {isForgotPassword && (
                <Alert variant="light" className={s.alertSuccess}>
                  <img src={greentick} alt="icon" />{' '}
                  <div>
                    <FormattedMessage
                      id="login.forgotPasswordSuccess"
                      defaultMessage="Your password has been successfully reset. You can now use your new password to log in to your account."
                    />
                  </div>
                </Alert>
              )}
            </Col>
          </Row>

          <Row>
            <Col lg={9} sm={12}>
              <Form.Label>
                <FormattedMessage id="login.emailAddress" defaultMessage="Email address" />
              </Form.Label>
              <Form.Control
                type="input"
                name="username"
                value={form.username}
                onChange={handleInput}
                className={formErrors.username ? s.formControlError : ''}
              />

              {formErrors.username && (
                <p role="alert" className={s.error}>
                  <FormattedMessage id="login.enterValidEmail" defaultMessage="Please enter a valid email" />
                </p>
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={9} md={12}>
              <Form.Label>
                {' '}
                <FormattedMessage id="login.password" defaultMessage="Password" />
              </Form.Label>
              <Form.Control
                type="password"
                name="password"
                value={form.password}
                onChange={handleInput}
                className={formErrors.password ? s.formControlError : ''}
              />

              {formErrors.password && (
                <p role="alert" className={s.error}>
                  <FormattedMessage id="login.enterValidPassword" defaultMessage="Please enter a valid password" />
                </p>
              )}
            </Col>
          </Row>

          <Row>
            <Col lg={3} sm={12}>
              <Button disabled={isLoading} type="submit" className={s.loginButton}>
                <FormattedMessage id="login.logInButton" defaultMessage="LOG IN" />
              </Button>
            </Col>
            <Col lg={6} md={12}>
              <Link to={`/forgot-password`}>
                <span>
                  <FormattedMessage id="forgotpassword.iForgotMyPassword" defaultMessage="I FORGOT MY PASSWORD" />
                </span>
              </Link>
            </Col>
          </Row>
        </Form>

        {isLoading && <LoadingSpinner />}
      </div>
    </div>
  );
};

Login.defaultProps = {
  isAuthenticated: false
};

Login.propTypes = {
  isAuthenticated: PropTypes.bool,
  isLoading: PropTypes.bool.isRequired,
  loginError: PropTypes.bool.isRequired,
  redirect: PropTypes.func.isRequired,
  submitLogin: PropTypes.func.isRequired
};

export default Login;
