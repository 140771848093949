import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelTelemetryLink.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelTelemetryLinkState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { Navigate, Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import classNames from 'classnames';
import { isRequired, logEntry, compareValues } from '../../utils';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import { describeTelemetryLink, setTelemetryLinkChange, postTelemetryLink, getTelemetryLinks } from '../../actions/telemetryLinks';
import { getComponentPositions } from '../../actions/model';
import { getModelTelemetryLinks, getUserProfileWidget, getComponentPositionsModel } from '../../selectors/index';
import { TELEMETRY_LINKS_MANAGEMENT, CREATE_TELEMETRY_LINK, UPDATE_TELEMETRY_LINK } from '../../constants/logs';
import { TELEMETRY_TYPES } from '../../constants/index';
import { useRef } from 'react';

const ModelTelemetryLink = ({ intl }) => {
  const dispatch = useDispatch();
  let { ModelId, TelemetryLinkId } = useParams();
  const telemetryLinkManager = useSelector(state => getModelTelemetryLinks(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const componentPositionsManager = useSelector(state => getComponentPositionsModel(state));

  const [localformErrors, setFormErrors] = useState({});
  const [localSourcePositions, setLocalSourcePositions] = useState([]);
  const [localTelemetryTypes, setLocalTelemetryTypes] = useState([]);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let IsEdit = false;
  let heading = '';
  let telemetryType = telemetryLinkManager.selectedTelemetryLink.TelemetryType;
  let targetModelComponentPositionLinkId = telemetryLinkManager.selectedTelemetryLink.TargetModelComponentPositionLinkId;
  let widgetCode = TelemetryLinkId && TelemetryLinkId > 0 ? 'MODEL_TELEMETRY_LINKS_EDIT' : 'MODEL_TELEMETRY_LINKS_ADD';

  useEffect(() => {
    if (TelemetryLinkId && TelemetryLinkId > 0) {
      dispatch(describeTelemetryLink(TelemetryLinkId, widgetCode));
    }
  }, [describeTelemetryLink, TelemetryLinkId, widgetCode]);

  useEffect(() => {
    dispatch(getComponentPositions(ModelId, true, true, 'MODELCOMPONENTPOSITIONLIST'));
  }, [getComponentPositions, ModelId]);

  useEffect(() => {
    dispatch(getTelemetryLinks(ModelId, 'MODEL_TELEMETRY_LINKS'));
  }, [getTelemetryLinks, ModelId]);

  useEffect(() => {
    if (IsEdit) {
      setSourcComponentPositions(telemetryType);
    }
  }, [telemetryType]);

  useEffect(() => {
    if (IsEdit) {
      setTelemetryTypes(targetModelComponentPositionLinkId);
    }
  }, []);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(
        setTelemetryLinkChange({
          ...telemetryLinkManager,
          selectedTelemetryLink: { ...initialModelTelemetryLinkState.TelemetryLink.selectedTelemetryLink }
        })
      );
    };
  }, []);

  // Update redux store
  const setLocalTelemetryLink = currentState => {
    dispatch(setTelemetryLinkChange(currentState));
  };
  //Saving
  let saveBtn = useRef();
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    if (saveBtn.current) {
      saveBtn.current.setAttribute('disabled', 'disabled');
    }

    let saveData = telemetryLinkManager.selectedTelemetryLink;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.GuidIdentifier = uuidv4();
    }

    //saving Model Version
    let actionName = IsEdit ? UPDATE_TELEMETRY_LINK : CREATE_TELEMETRY_LINK;
    let log = logEntry(TELEMETRY_LINKS_MANAGEMENT, actionName, saveData);
    dispatch(postTelemetryLink(saveData, log, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !telemetryLinkManager ||
      !telemetryLinkManager.selectedTelemetryLink ||
      !telemetryLinkManager.selectedTelemetryLink.TargetModelComponentPositionLinkId
    ) {
      formErrors.Position = intl.formatMessage({
        id: 'model.telemetryLinks.positionMandatory',
        defaultMessage: 'Position is mandatory field'
      });
      isValid = false;
    }

    if (
      !telemetryLinkManager ||
      !telemetryLinkManager.selectedTelemetryLink ||
      parseInt(telemetryLinkManager.selectedTelemetryLink.TelemetryType) < 0
    ) {
      formErrors.TelemetryType = intl.formatMessage({
        id: 'model.telemetryLinks.telemetryTypeMandatory',
        defaultMessage: 'Telemetry Type  is mandatory field'
      });
      isValid = false;
    }

    if (
      !telemetryLinkManager ||
      !telemetryLinkManager.selectedTelemetryLink ||
      !telemetryLinkManager.selectedTelemetryLink.SourceModelComponentPositionLinkId
    ) {
      formErrors.SourcePosition = intl.formatMessage({
        id: 'model.telemetryLinks.sourcePositionMandatory',
        defaultMessage: 'Source Position is mandatory field'
      });
      isValid = false;
    }

    if (
      telemetryLinkManager.selectedTelemetryLink.SourceModelComponentPositionLinkId &&
      telemetryLinkManager.selectedTelemetryLink.TargetModelComponentPositionLinkId &&
      telemetryLinkManager.selectedTelemetryLink.SourceModelComponentPositionLinkId ===
        telemetryLinkManager.selectedTelemetryLink.TargetModelComponentPositionLinkId
    ) {
      formErrors.sourceTargetSame = intl.formatMessage({
        id: 'model.telemetryLinks.sourceTargetSame',
        defaultMessage: 'Position and Source Position can not be same'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const positionOptions = () => {
    let positionsData = [];
    componentPositionsManager &&
      componentPositionsManager.componentPositionModels.forEach(element => {
        let isDisabled = false;
        if (!IsEdit) {
          const telementryLink =
            (telemetryLinkManager.TelemetryLinks &&
              telemetryLinkManager.TelemetryLinks.filter(
                x => x.TargetModelComponentPositionLinkId === element.ModelComponentPositionLinkId
              )) ||
            [];

          if (telementryLink.length === Object.keys(TELEMETRY_TYPES).length) {
            isDisabled = true;
          }
        }

        positionsData.push({
          isDisabled: isDisabled,
          label: element.Name,
          value: element.ModelComponentPositionLinkId
        });
      });

    positionsData.push({
      label: <FormattedMessage id="model.telemetryLinks.selectOne" defaultMessage="Please select one" />,
      value: ''
    });

    return positionsData.sort(compareValues('label'));
  };

  const sourcePositionOptions = () => {
    let positionsData = [];

    if (localSourcePositions.length > 0) {
      localSourcePositions.forEach(element => {
        positionsData.push({
          label: element.Name,
          value: element.ModelComponentPositionLinkId
        });
      });

      positionsData.push({
        label: <FormattedMessage id="model.telemetryLinks.selectOne" defaultMessage="Please select one" />,
        value: ''
      });
    }

    return positionsData.sort(compareValues('label'));
  };

  const telemetryTypeOptions = () => {
    let telemetryTypeData = [];

    if (localTelemetryTypes.length > 0) {
      localTelemetryTypes.forEach(element => {
        telemetryTypeData.push({
          isDisabled: element.isDisabled,
          label: element.label,
          value: element.value
        });
      });

      telemetryTypeData.push({
        label: <FormattedMessage id="model.telemetryLinks.selectOne" defaultMessage="Please select one" />,
        value: -1
      });
    }

    return telemetryTypeData.sort(compareValues('label'));
  };

  const setTelemetryTypes = targetModelComponentPositionLinkId => {
    let telemetryTypeData = [];

    Object.keys(TELEMETRY_TYPES).map(key => {
      let isDisabled = false;
      if (!IsEdit) {
        const telementryLink =
          (telemetryLinkManager.TelemetryLinks &&
            telemetryLinkManager.TelemetryLinks.filter(x => x.TargetModelComponentPositionLinkId === targetModelComponentPositionLinkId)) ||
          [];

        const telemetryTypes = telementryLink.map(x => x.TelemetryType);

        if (telemetryTypes.includes(parseInt(key))) {
          isDisabled = true;
        }
      }
      telemetryTypeData.push({
        isDisabled: isDisabled,
        label: TELEMETRY_TYPES[key].name,
        value: parseInt(key)
      });
    });

    setLocalTelemetryTypes(telemetryTypeData);
  };

  const setSourcComponentPositions = telemetryType => {
    let componentPositions = (componentPositionsManager && componentPositionsManager.componentPositionModels) || [];

    if (componentPositions.length > 0) {
      if (telemetryType === 0 || telemetryType === 1) {
        componentPositions = componentPositions.filter(x => x.NeedState);
      } else {
        componentPositions = componentPositions.filter(x => x.NeedFeedback);
      }
    }
    setLocalSourcePositions(componentPositions);
  };

  //on control value change
  const onChange = e => {
    setLocalTelemetryLink({
      ...telemetryLinkManager,
      selectedTelemetryLink: { ...telemetryLinkManager.selectedTelemetryLink, [e.target.name]: e.target.value }
    });
  };

  const onPositionChange = e => {
    setLocalTelemetryLink({
      ...telemetryLinkManager,
      selectedTelemetryLink: { ...telemetryLinkManager.selectedTelemetryLink, TargetModelComponentPositionLinkId: e.value }
    });

    setTelemetryTypes(e.value);
  };

  const onSourcePositionChange = e => {
    setLocalTelemetryLink({
      ...telemetryLinkManager,
      selectedTelemetryLink: { ...telemetryLinkManager.selectedTelemetryLink, SourceModelComponentPositionLinkId: e.value }
    });
  };

  const onTelemetryTypeChange = e => {
    setLocalTelemetryLink({
      ...telemetryLinkManager,
      selectedTelemetryLink: { ...telemetryLinkManager.selectedTelemetryLink, TelemetryType: e.value }
    });

    //filter and set source positions
    setSourcComponentPositions(e.value);
  };

  if (TelemetryLinkId && TelemetryLinkId > 0) {
    IsEdit = true;
    heading = intl.formatMessage({ id: 'modelVersion.editModelVersion', defaultMessage: 'Edit' });
  } else {
    heading = intl.formatMessage({ id: 'model.telemetryLinks.addNewTelemetryLink', defaultMessage: 'Add New Telemetry Link' });
  }

  let messageId = (telemetryLinkManager && telemetryLinkManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const ddlPositionError = localformErrors && localformErrors.Position ? s.ddlError : '';
  const ddlTelemetryTypeError = localformErrors && localformErrors.TelemetryType ? s.ddlError : '';
  const ddlSourcePositionError = localformErrors && localformErrors.SourcePosition ? s.ddlError : '';

  const returnUrl = `/admin-controls/model-configuration/${ModelId}/`;

  return (
    <div className={s.telemetryLink}>
      {telemetryLinkManager.isLoading || (telemetryLinkManager.isLoading && <LoadingSpinner />)}
      {telemetryLinkManager.isOpSuccessful && !telemetryLinkManager.showBanner ? <Navigate to={returnUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={telemetryLinkManager.showBanner}
        status={telemetryLinkManager.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.telemetryLinkHeader}>
            <Link className={s.backLink} to={returnUrl}>
              &lt; &nbsp;
              <FormattedMessage id="modelVersion.backToModelConfiguration" defaultMessage="BACK TO MODEL CONFIGURATION" />
            </Link>
            <h3 data-unittest="headingLabel">
              {heading} {IsEdit ? ' :' + telemetryLinkManager.selectedTelemetryLink.TargetComponentPositionName : ''}
            </h3>
          </div>
          <div className={s.telemetryLinkContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTargetPosition">
                    <Form.Label>
                      <FormattedMessage id="model.telemetryLinks.position" defaultMessage="Position" />
                    </Form.Label>

                    <Dropdown
                      id="formTargetPosition"
                      dataArray={positionOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="model.telemetryLinks.selectOne" defaultMessage="Please select one" />,
                        customClassName: ddlPositionError
                      }}
                      disabled={IsEdit}
                      onDropdownChange={onPositionChange}
                      selectedOption={positionOptions().filter(
                        option => option.value === telemetryLinkManager.selectedTelemetryLink.TargetModelComponentPositionLinkId
                      )}
                      data-unittest="formPosition"
                    />
                    {localformErrors && localformErrors.Position && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Position}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formDescription">
                    <Form.Label>
                      <FormattedMessage id="model.telemetryLinks.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'modelVersionManagement.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={telemetryLinkManager.selectedTelemetryLink.Description}
                      className={`${s.textArea}`}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formTelemetryType">
                    <Form.Label>
                      <FormattedMessage id="model.telemetryLinks.telemetryType" defaultMessage="Telemetry Type" />
                    </Form.Label>

                    <Dropdown
                      id="formTelemetryType"
                      dataArray={telemetryTypeOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="model.telemetryLinks.selectOne" defaultMessage="Please select one" />,
                        customClassName: ddlTelemetryTypeError
                      }}
                      onDropdownChange={onTelemetryTypeChange}
                      disabled={IsEdit > 0}
                      selectedOption={telemetryTypeOptions().filter(
                        option => option.value === telemetryLinkManager.selectedTelemetryLink.TelemetryType
                      )}
                      data-unittest="formTelemetryType"
                    />
                    {localformErrors && localformErrors.TelemetryType && (
                      <p role="alert" className={s.error}>
                        {localformErrors.TelemetryType}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formSourcePosition">
                    <Form.Label>
                      <FormattedMessage id="model.telemetryLinks.sourcePosition" defaultMessage="Source Position" />
                    </Form.Label>

                    <Dropdown
                      id="formSourcePosition"
                      dataArray={sourcePositionOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="model.telemetryLinks.selectOne" defaultMessage="Please select one" />,
                        customClassName: ddlSourcePositionError
                      }}
                      onDropdownChange={onSourcePositionChange}
                      selectedOption={sourcePositionOptions().filter(
                        option => option.value === telemetryLinkManager.selectedTelemetryLink.SourceModelComponentPositionLinkId
                      )}
                      data-unittest="formSourcePosition"
                    />
                    {localformErrors && localformErrors.SourcePosition && (
                      <p role="alert" className={s.error}>
                        {localformErrors.SourcePosition}
                      </p>
                    )}
                    {localformErrors && localformErrors.sourceTargetSame && (
                      <p role="alert" className={s.error}>
                        {localformErrors.sourceTargetSame}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <div>
                <Button
                  variant="primary"
                  ref={saveBtn}
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="model.telemetryLinks.save" defaultMessage="SAVE" />
                </Button>
                <Link to={returnUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="model.telemetryLinks.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelTelemetryLink.defaultProps = {
  telemetryLinkManager: {
    ...initialModelTelemetryLinkState.TelemetryLink
  }
};

export default injectIntl(ModelTelemetryLink);
