import React from 'react';
import PropTypes from 'prop-types';
import Alert from 'react-bootstrap/Alert';
import s from './DeviceTile.module.scss';
import bs from '../../styles/bootstrap-overrides.scss';
import pin from '../../assets/location.svg';
import deviceProcessing from '../../assets/device-processing.svg';
import modelImg from '../../assets/units-model.svg';
import alert from '../../assets/alert.svg';
import warning from '../../assets/warning.svg';
import pendingAlarm from '../../assets/alarm-outline-small.svg';
import online from '../../assets/online.svg';
import offline from '../../assets/offline.svg';
import { FormattedMessage, IntlProvider } from 'react-intl';
import moment from 'moment';
import { ALARM_STATUS_NEW, ALARM_STATUS_PENDING } from '../../constants';
import locked from '../../assets/blue-locked.svg';
import schedule from '../../assets/clock-blue.svg';
import Cookies from 'js-cookie';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import languageObject from '../../translations/messages';
import { getSequenceName } from '../../utils';

const DeviceTile = ({
  unitName,
  handleClick,
  location,
  country,
  model,
  modelVersion,
  unitSerialNumber,
  deviceSerialNumber,
  isTaggedOut,
  deviceSensorData,
  deviceEventData,
  deviceAlarmsData,
  flowSequences,
  tagOutMessage,
  isSchedulesCount,
  scheduleMessage
}) => {
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const timestamp = deviceSensorData && deviceSensorData.map(item => item.timestamp);
  const deviceOnline = timestamp && timestamp[0] && moment(timestamp[0]).isBetween(moment().subtract(1, 'minute'), moment());
  let currentSequence = 'Data is not available';
  let currentSequenceObj = deviceSensorData && deviceSensorData.map(item => item.current_state);
  if (currentSequenceObj && currentSequenceObj.length > 0) {
    let sequence = getSequenceName(flowSequences, currentSequenceObj[0]);
    currentSequence = sequence.Name != '' ? sequence.Name : 'Data is not available';
  }
  // const deviceEvent =
  //   deviceEventData && deviceEventData.length > 0 && deviceEventData[0].events && deviceEventData[0].events.filter(item => item.type === 0);
  // const flowSequence =
  //   deviceEvent &&
  //   deviceEvent[0] &&
  //   deviceEvent[0].code &&
  //   flowSequences &&
  //   flowSequences.filter(x => x.SequenceCode === parseInt(deviceEvent[0].code));
  // const currentSequence = flowSequence && flowSequence.length > 0 && flowSequence[0].Name;

  const warnings =
    deviceEventData && deviceEventData.length > 0 && deviceEventData[0].events && deviceEventData[0].events.filter(item => item.type === 1);

  const newAlarms =
    deviceAlarmsData &&
    deviceAlarmsData.filter(event => {
      return event.EventStatus === ALARM_STATUS_NEW;
    });

  const pendingAlarms =
    deviceAlarmsData &&
    deviceAlarmsData.filter(event => {
      return event.EventStatus === ALARM_STATUS_PENDING;
    });

  let warningCount = (warnings && warnings.length) || 0;
  let newAlarmsCount = (newAlarms && newAlarms.length) || 0;
  let pendingAlarmsCount = (pendingAlarms && pendingAlarms.length) || 0;

  const eventTypeClass = newAlarmsCount > 0 || pendingAlarmsCount > 0 ? s.borderRed : warningCount > 0 ? s.borderYellow : '';
  const locale = Cookies.get(`defaultLanguage-${envName}`) || 'en';
  return (
    <IntlProvider locale={locale} messages={languageObject[locale]}>
      <li key={unitSerialNumber} className={eventTypeClass}>
        <a
          onClick={() => {
            handleClick(unitSerialNumber);
          }}
          className={s.deviceTile}
        >
          <div className={s.contentWrapper}>
            <div className={s.rightIcons}>
              {isSchedulesCount ? (
                <div className={s.scheduleIcon}>
                  <img src={schedule} data-tip={scheduleMessage} />
                  <Tooltip  />
                </div>
              ) : (
                ''
              )}

              {isTaggedOut ? (
                <div className={s.taggedout}>
                  <img src={locked} data-tip={tagOutMessage} />
                  <Tooltip  />
                </div>
              ) : (
                ''
              )}
            </div>
            <div className={s.deviceInfo}>
              <h3 className={s.tileDeviceId}>{unitName}</h3>
              <h6 className={s.tileDeviceId}>
                {unitSerialNumber}, {deviceSerialNumber}
              </h6>
              {model && (
                <p className={s.content}>
                  <img src={modelImg} alt="-" className={s.iconsize} />
                  <span>{model ? model : '-'}</span>
                </p>
              )}
              <p className={s.content}>
                <img src={pin} alt="Location pin" />
                <span>{`${location}, ${country}`}</span>
              </p>
              <p className={s.content}>
                <img src={deviceProcessing} alt="Device Processing" />
                <span>{currentSequence ? currentSequence : 'Data not available'}</span>
              </p>

              <p className={s.content}>
                <img src={deviceOnline ? online : offline} alt={deviceOnline ? 'Online' : 'Offline'} />
                <span>
                  {deviceOnline ? (
                    <FormattedMessage id="deviceTile.online" defaultMessage="Online" />
                  ) : (
                    <FormattedMessage id="deviceTile.offline" defaultMessage="Offline" />
                  )}
                </span>
              </p>
            </div>

            {pendingAlarmsCount > 0 && (
              <p className={s.content}>
                <img src={pendingAlarm} alt="icon" />
                <span>
                  {pendingAlarms.length} <FormattedMessage id="deviceList.pendingAlarm" defaultMessage="pending alarm" />
                </span>
              </p>
            )}

            {(newAlarmsCount > 0 && (
              <Alert variant="light" className={`d-flex flex-row align-items-center ${warningCount > 0 ? s.alarmWarning : s.alarmOnly}`}>
                <img src={alert} alt="icon" />
                <span>
                  <FormattedMessage id="deviceTile.newAlarms" defaultMessage="New Alarms" /> ({newAlarms.length})
                </span>
              </Alert>
            )) || <span />}

            {(warningCount > 0 && (
              <Alert variant="warning" className={`d-flex flex-row align-items-center ${s.warning}`}>
                <img src={warning} alt="icon" />{' '}
                <span>
                  <FormattedMessage id="deviceTile.warnings" defaultMessage="Warnings in the last 24h" /> ({warnings.length})
                </span>
              </Alert>
            )) || <span />}
          </div>
        </a>
      </li>
    </IntlProvider>
  );
};

DeviceTile.defaultProps = {
  location: 'unknown',
  unitSerialNumber: ''
};

DeviceTile.propTypes = {
  unitSerialNumber: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  location: PropTypes.string,
  deviceAlarmsData: PropTypes.array,
  flowSequences: PropTypes.array.isRequired,
  isSchedulesCount: PropTypes.bool
};

export default DeviceTile;
