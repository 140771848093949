import React, { useEffect } from 'react';
import svgpanzoom from 'svg-pan-zoom';
import './Alpha2DeviceStyle.css';

import StringToReactComponent from 'string-to-react-component';

let schematicDiagram = null;
const Site = ({ siteDetails, gatewayAssociations, siteAssociations }) => {
  const svgElement = document.querySelector('#dv-schematics');
  var options = {
    zoomEnabled: true,
    mouseWheelZoomEnabled: siteDetails.enableMouseZoom,
    controlIconsEnabled: true,
    fit: true,
    center: true,
    contain: true
  };

  if (svgElement) {
    schematicDiagram = svgpanzoom(svgElement, options);

    if (siteDetails.enableMouseZoom) {
      schematicDiagram.enableMouseWheelZoom();
    } else {
      schematicDiagram.disableMouseWheelZoom();
    }
  }

  const onResize = () => {
    if (schematicDiagram) {
      // schematicDiagram.reset();
      schematicDiagram.resize();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
  }, [onResize]); // Only initialize listener on initial render

  const solarPanel = 'Panel';
  let siteDiagramSVG = (siteDetails && siteDetails.SiteDiagram) || '<svg />';

  const siteDiagram = `(props)=>{ return (${siteDiagramSVG})}`;

  // Constants for layout
  const VERTICAL_SPACING = 139; // Space between elements
  const START_Y = 50; // Starting Y position
  const CENTER_X = 927;
  const unitGenerator = (units, gateways) => {
    const boxItem = [];
    const yPosGateway = START_Y + (units.length - 1) * VERTICAL_SPACING + 150;
    units.map((item, index) =>
      boxItem.push(
        <a href={`/unit/process-flow/${item.UnitSerialNumber}`} key={item.UnitSerialNumber} title={`${item.UnitName}`}>
          <g transform={'matrix(.32 0 0 .30 ' + CENTER_X + ' ' + (START_Y + index * VERTICAL_SPACING) + ')'}>
            <rect
              x={24.165}
              y={50}
              width={355.955}
              height={400}
              fill="#e0e0e0"
              rx={17.798}
              style={{
                strokeWidth: 1.33408
              }}
            />
            <rect
              x={32.451}
              y={60}
              width={340.24}
              height={380}
              fill="#f5f5f5"
              rx={15.122}
              style={{
                strokeWidth: 1.37485
              }}
            />
            <rect x={46.933} y={70.062} width={314.141} height={107.149} fill="#f1f3f5" stroke="#dee2e6" strokeWidth={1.272} rx={4.833} />
            <text
              x={205}
              y={139}
              className="st6"
              style={{
                fontWeight: 600,
                fontSize: 36,
                fontFamily: 'Calibri',
                overflow: 'visible',
                fill: '#0a5487',
                fillRule: 'evenodd',
                strokeLinecap: 'square',
                strokeMiterlimit: 3,
                textAnchor: 'middle'
              }}
              id={'unitIdm' + index}
            >
              {item.UnitName}
            </text>
            <rect
              x={48.166}
              y={190.42}
              width={43.71}
              height={170.009}
              fill="#fff"
              rx={4.371}
              style={{
                strokeWidth: 1.11289
              }}
            />
            <rect
              x={54.452}
              y={197.991}
              width={31.14}
              height={154.01}
              fill="#2f92d0"
              rx={3.114}
              style={{
                strokeWidth: 1.12342
              }}
            />
            <rect x={99.318} y={193.003} width={259.359} height={165.998} fill="#fff" stroke="#bdbdbd" strokeWidth={2.142} rx={12.968} />
            <path d="M116.59 216.272H345.26m-228.671 20H345.26m-228.671 20H345.26" stroke="#e0e0e0" strokeWidth={3.381} />
            <path d="M150 380h100" stroke="#2196f3" strokeWidth={2} strokeDasharray="5, 5" />
            <circle cx={150} cy={380} r={3} fill="#2196f3" />
            <circle cx={250} cy={380} r={3} fill="#2196f3" />
            <path d="M180 450h40l-20 20z" fill="#2f92d0" />
            <circle cx={200} cy={420} r={15} fill="#4caf50" />
            <circle cx={200} cy={420} r={10} fill="#fff" />
            <path d="m195 475 5 10 5-10z" fill="#2196f3">
              <animate attributeName="opacity" values="1;0;1" dur="2s" repeatCount="indefinite" />
            </path>
            <circle cx={140} cy={420} r={5} fill="#4caf50" />
            <circle cx={260} cy={420} r={5} fill="#2196f3" />
          </g>
        </a>
      )
    );
    const GATEWAY_START_POS = -480;
    const GATEWAY_X_POS = 838;
    const GATEWAY_SPACING = 130;
    gateways.map((item, index) =>
      boxItem.push(
        <a
          xlinkHref={`/unit/process-flow/${item.GatewaySerialNumber}`}
          key={'gw' + item.GatewaySerialNumber}
          title={`${item.GatewayName}`}
          transform={
            'matrix(3.67713 0 0 3.19493  ' + GATEWAY_X_POS + ' ' + (GATEWAY_START_POS + yPosGateway + index * GATEWAY_SPACING) + ')'
          }
        >
          <g
            style={{
              fontSize: 12,
              overflow: 'visible',
              fill: 'none',
              fillRule: 'evenodd',
              strokeLinecap: 'square',
              strokeMiterlimit: 3
            }}
            transform="matrix(.2633 0 0 .28218 7.425 -64.396)"
          >
            <g id={'deviceid' + index} transform="matrix(1.2687 0 0 1.38668 -25.208 -311.231)">
              <g transform="translate(112.188 -4.505)">
                <path
                  d="M26.3 840.57v-21.12c0-.35-.16-.69-.43-.94s-.64-.38-1.03-.38H1.46c-.39 0-.76.13-1.03.38-.28.25-.43.59-.43.94v21.12c0 .35.15.69.43.93.27.25.64.39 1.03.39h23.38c.39 0 .76-.14 1.03-.39s.43-.58.43-.93zm-23.38-1.33v-3.62h9.99c.35.93 1.05 1.71 1.97 2.21a4.9 4.9 0 0 0 3.08.53c1.06-.16 2.03-.65 2.72-1.4.7-.74 1.08-1.69 1.08-2.66 0-.98-.39-1.92-1.08-2.67-.7-.74-1.66-1.24-2.72-1.4-1.07-.16-2.16.03-3.08.53-.92.5-1.62 1.29-1.97 2.21H2.92v-12.2h20.45v3.62H13.34c-.35-.93-1.05-1.71-1.97-2.21-.92-.5-2.01-.69-3.07-.53-1.07.16-2.03.65-2.73 1.4a3.9 3.9 0 0 0-1.07 2.66c0 .98.38 1.92 1.07 2.67.7.74 1.67 1.24 2.73 1.4 1.06.16 2.15-.03 3.07-.53.92-.5 1.62-1.29 1.97-2.21h10.03v12.2zm12.67-4.89c0-.29.09-.57.27-.81.18-.25.43-.43.73-.55.3-.11.62-.13.94-.08.31.06.6.2.83.4.22.21.38.47.44.76.06.28.03.58-.09.85s-.33.49-.6.66c-.27.16-.58.24-.9.24-.42-.01-.82-.17-1.12-.44a1.4 1.4 0 0 1-.46-1.03zm-4.97-8.69c0 .29-.09.58-.27.82-.18.24-.43.43-.73.54-.3.11-.62.14-.94.09-.31-.06-.6-.2-.83-.41-.23-.2-.38-.46-.44-.75-.07-.28-.03-.58.09-.85s.33-.5.6-.66c.27-.16.58-.25.9-.25.21 0 .43.03.63.1.21.07.39.18.54.32.16.14.28.3.37.48.08.18.12.38.12.57z"
                  className="st10"
                  style={{
                    fill: 'none',
                    stroke: '#0a5487',
                    strokeWidth: 1.5,
                    strokeLinecap: 'butt'
                  }}
                />
              </g>
              <g transform="translate(18.262 1.264)">
                <path
                  d="M90.348 805.595h4.38v-2.64h-4.38v-5.94c0-.26-.09-.51-.25-.73-.16-.22-.38-.39-.65-.49-.26-.1-.55-.12-.84-.07-.28.04-.54.17-.74.35l-14.39 13.02c-.27.26-.42.59-.43.94v33.21c0 .23.06.46.19.66.12.19.3.36.51.48l14.34 7.76c.22.12.47.18.73.18.25 0 .51-.06.73-.18.22-.12.4-.28.53-.49.13-.2.2-.43.2-.66v-5.7h4.38v-2.65h-4.38zm-2.92 43.09-11.47-6.13v-31.9l11.47-10.49z"
                  className="st10"
                  style={{
                    fill: 'none',
                    stroke: '#0a5487',
                    strokeWidth: 1.5,
                    strokeLinecap: 'butt'
                  }}
                />
              </g>
              <g transform="translate(137.827 11.74)">
                <path
                  d="M21.11 798.66 6.72 785.6a1.45 1.45 0 0 0-.75-.35c-.28-.05-.57-.03-.84.07-.26.1-.49.27-.65.49-.16.22-.24.47-.24.73v5.94H0v2.64h4.24v37.09H0v2.65h4.24v5.7c0 .23.06.46.19.66.13.21.31.37.54.49.22.12.47.18.73.18.25 0 .51-.06.73-.18l14.36-7.72c.22-.12.39-.29.52-.49.13-.19.19-.42.19-.65v-33.21c.01-.18-.02-.36-.08-.52a1.3 1.3 0 0 0-.31-.46zm-2.51 33.42-11.44 6.16v-48.55l11.47 10.42z"
                  className="st10"
                  style={{
                    fill: 'none',
                    stroke: '#0a5487',
                    strokeWidth: 1.5,
                    strokeLinecap: 'butt'
                  }}
                />
              </g>
              <g transform="translate(127.6 4.688)">
                <path
                  d="M5.84 839.24H0v2.65h5.84z"
                  className="st10"
                  style={{
                    fill: 'none',
                    stroke: '#0a5487',
                    strokeWidth: 1.5,
                    strokeLinecap: 'butt'
                  }}
                />
              </g>
              <g transform="translate(117.374 4.688)">
                <path
                  d="M5.84 839.24H0v2.65h5.84z"
                  className="st10"
                  style={{
                    fill: 'none',
                    stroke: '#0a5487',
                    strokeWidth: 1.5,
                    strokeLinecap: 'butt'
                  }}
                />
              </g>
              <g transform="translate(127.6 -35.025)">
                <path
                  d="M5.84 839.24H0v2.65h5.84z"
                  className="st10"
                  style={{
                    fill: 'none',
                    stroke: '#0a5487',
                    strokeWidth: 1.5,
                    strokeLinecap: 'butt'
                  }}
                />
              </g>
              <g transform="translate(117.374 -35.025)">
                <path
                  d="M5.84 839.24H0v2.65h5.84z"
                  className="st10"
                  style={{
                    fill: 'none',
                    stroke: '#0a5487',
                    strokeWidth: 1.5,
                    strokeLinecap: 'butt'
                  }}
                />
              </g>
            </g>
            <text
              id={'deviceTextId' + index}
              x={131.194}
              y={891.949}
              className="st6"
              style={{
                fontWeight: 700,
                fontSize: '13.9999px',
                fontFamily: 'Calibri',
                textAnchor: 'middle',
                fill: '#0a5487'
              }}
            >
              {item.GatewayName}
            </text>
            <text
              key={'deviceTextIdgw' + index}
              x={132.492}
              y={786.16}
              className="st6"
              style={{
                fontWeight: 700,
                fontSize: '13.9999px',
                fontFamily: 'Calibri',
                textAnchor: 'middle',
                overflow: 'visible',
                fill: '#0a5487',
                fillRule: 'evenodd',
                strokeLinecap: 'square',
                strokeMiterlimit: 3
              }}
            >
              {'IoT Gateway'}
            </text>
            <rect
              style={{
                fill: '#000',
                fillOpacity: 0.01,
                stroke: '#0a5487',
                strokeWidth: 0.545485
              }}
              id={'gatewayrectName' + index}
              width={134.795}
              height={132.276}
              x={65.304}
              y={770.965}
              rx={1.906}
            />
          </g>
        </a>
      )
    );
    return <g>{boxItem}</g>;
  };

  const unitsListGenerator = items => {
    const boxItem = [];
    const START_TEXT_Y = 740;
    const START_TEXT_X = 87;
    const VERTICAL_SPACE_TEXT_X = 25;
    items.map((item, index) =>
      boxItem.push(
        <a href={`/unit/process-flow/${item.UnitSerialNumber}`} key={`unitlisting-${index + 1}`} title={`${item.UnitName}`}>
          <text
            x={START_TEXT_X}
            y={START_TEXT_Y + index * VERTICAL_SPACE_TEXT_X}
            style={{
              fontWeight: 600,
              fontSize: '12px',
              fontFamily: 'Calibri',
              textAnchor: 'middle',
              overflow: 'visible',
              fill: '#0a5487',
              fillRule: 'evenodd',
              strokeLinecap: 'square',
              strokeMiterlimit: 3
            }}
          >
            {item.UnitName}
          </text>
        </a>
      )
    );

    return <g>{boxItem}</g>;
  };

  let units = unitGenerator(siteAssociations, gatewayAssociations);
  let unitsList = unitsListGenerator(siteAssociations);
  return <StringToReactComponent data={{ units, unitsList }}>{siteDiagram}</StringToReactComponent>;
};

export default Site;
