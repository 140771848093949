import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './SequenceVersionDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialSequenceVersionState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Link, useParams, useNavigate, Navigate } from 'react-router-dom';
import SequenceVersionFiles from './SequenceVersionFiles';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import { isRequired, compareValues, getQuerystring } from '../../utils';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import GoBack from '../WSAControls/GoBack/GoBack';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';

const SequenceVersionDetail = ({
  userProfileWidgets,
  currentTimezone,
  flowSequences,
  sequenceVersionManager,
  saveSequenceVersion,
  describeSequenceVersion,
  changeSequenceVersion,
  modelVersionManager,
  describeModelVersion,
  intl
}) => {
  let IsEdit = false;
  let IsView = false;
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let { modelId, modelVersionId, sequenceVersionId } = useParams();
  let IsNew = getQuerystring('new') || '0';
  const history = useNavigate();
  const backLink = `/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version`;

  const [formError, setFormError] = useState({});

  const [active, setActive] = useState(false);
  const [deleteSequenceVersionFileId, setDeleteSequenceVersionFileId] = useState(0);

  sequenceVersionId = parseInt(sequenceVersionId);

  useEffect(() => {
    if (!modelVersionManager || !modelVersionManager.selectedModelVersion || !modelVersionManager.selectedModelVersion.ModelVersionId)
      describeModelVersion(modelVersionId);
  }, [describeModelVersion, modelVersionId]);

  const dialogDelete = intl.formatMessage({
    id: 'sequenceVersionManagement.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this sequence file?'
  });

  let isPublished = (sequenceVersionManager.selectedSequenceVersion && sequenceVersionManager.selectedSequenceVersion.IsPublished) || false;
  IsEdit = sequenceVersionId !== 0 && !isPublished;
  IsView = sequenceVersionId !== 0 && isPublished;

  const changeAllowed = IsWidgetAccessible(userProfileWidgets, `SEQUENCEVERSIONUPDATE`);
  const deleteAllowed = IsWidgetAccessible(userProfileWidgets, `SEQUENCEFILESDELETE`);

  let messageId = (sequenceVersionManager && sequenceVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelName = (modelVersionManager.selectedModelVersion ? modelVersionManager.selectedModelVersion.ModelName : '') || '';
  const modelVersionName =
    (modelVersionManager && modelVersionManager.selectedModelVersion && modelVersionManager.selectedModelVersion.Name) || '';

  const sequenceVersionName =
    (sequenceVersionManager.selectedSequenceVersion ? sequenceVersionManager.selectedSequenceVersion.Name : '') || '';
  let headingData = `${modelName}:${modelVersionName}:${sequenceVersionName} `;
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'sequenceVersionManagement.edit', defaultMessage: 'Edit' }) + ': ' + headingData;
  } else if (IsView) {
    heading = headingData;
  } else {
    heading =
      intl.formatMessage({ id: 'sequenceVersionManagement.newSequenceVersion', defaultMessage: 'New Sequence Version' }) +
      ' ' +
      headingData;
  }

  //Checking permission and redirecting to unauth page if unauthorised
  if (IsEdit && !IsWidgetAccessible(userProfileWidgets, 'SEQUENCEVERSIONUPDATE')) {
    history.push('/unauth');
  }
  if (IsView && !IsWidgetAccessible(userProfileWidgets, 'SEQUENCEVERSIONUPDATE')) {
    history.push('/unauth');
  }
  if (sequenceVersionId === 0 && !IsWidgetAccessible(userProfileWidgets, 'SEQUENCEVERSIONADD')) {
    history.push('/unauth');
  }

  let widgetCode = '';

  if (sequenceVersionId) {
    widgetCode = 'SEQUENCEVERSIONUPDATE';
  } else {
    widgetCode = 'SEQUENCEVERSIONADD';
  }

  useEffect(() => {
    changeSequenceVersion(initialSequenceVersionState.sequenceVersionManager);
    if (sequenceVersionId) describeSequenceVersion(sequenceVersionId, widgetCode);
  }, [describeSequenceVersion, sequenceVersionId, changeSequenceVersion, widgetCode]);

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = sequenceVersionManager.selectedSequenceVersion;
    saveData.ModelVersionId = modelVersionId;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;

    if (!IsEdit) {
      saveData.SequenceVersionId = 0;
      saveData.GuidIdentifier = uuidv4();
      saveData.ModelVersionId = modelVersionId;
    }

    saveSequenceVersion(saveData, widgetCode);
  };

  const validateInput = () => {
    let formError = {};

    let isValid = true;

    if (
      !sequenceVersionManager ||
      !sequenceVersionManager.selectedSequenceVersion ||
      !sequenceVersionManager.selectedSequenceVersion.Name ||
      isRequired(sequenceVersionManager.selectedSequenceVersion.Name, 1)
    ) {
      formError.Name = intl.formatMessage({
        id: 'sequenceVersionManagement.nameMandatory',
        defaultMessage: 'Version name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !sequenceVersionManager ||
      !sequenceVersionManager.selectedSequenceVersion ||
      !sequenceVersionManager.selectedSequenceVersion.VersionNo ||
      isRequired(sequenceVersionManager.selectedSequenceVersion.VersionNo, 1)
    ) {
      formError.VersionNo = intl.formatMessage({
        id: 'sequenceVersionManagement.versionNoMandatory',
        defaultMessage: 'Version number is a mandatory field'
      });
      isValid = false;
    } else {
      if (!validateVersionNo(sequenceVersionManager.selectedSequenceVersion.VersionNo)) {
        formError.VersionNo = intl.formatMessage({
          id: 'sequenceVersionManagement.versionNoFomatError',
          defaultMessage: 'Version number format should be 1.0.0'
        });
        isValid = false;
      }
    }

    if (
      !sequenceVersionManager ||
      !sequenceVersionManager.selectedSequenceVersion ||
      !sequenceVersionManager.selectedSequenceVersion.VerifyErrorCode ||
      isRequired(sequenceVersionManager.selectedSequenceVersion.VerifyErrorCode, 1)
    ) {
      formError.VerifyErrorCode = intl.formatMessage({
        id: 'sequenceVersionManagement.verifyErrorCodeMandatory',
        defaultMessage: 'Error code is a mandatory field'
      });
      isValid = false;
    }

    if (
      !sequenceVersionManager ||
      !sequenceVersionManager.selectedSequenceVersion ||
      !sequenceVersionManager.selectedSequenceVersion.VerifyWarningCode ||
      isRequired(sequenceVersionManager.selectedSequenceVersion.VerifyWarningCode, 1)
    ) {
      formError.VerifyWarningCode = intl.formatMessage({
        id: 'sequenceVersionManagement.verifyErrorCodeMandatory',
        defaultMessage: 'Warning code is a mandatory field'
      });
      isValid = false;
    }
    if (
      !sequenceVersionManager ||
      !sequenceVersionManager.selectedSequenceVersion ||
      !sequenceVersionManager.selectedSequenceVersion.StartStateId
    ) {
      formError.StartStateId = intl.formatMessage({
        id: 'sequenceVersionManagement.startStateMandatory',
        defaultMessage: 'Sequence start state is a mandatory field'
      });
      isValid = false;
    }

    setFormError(formError);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      sequenceVersionManager.selectedSequenceVersion = {
        ...sequenceVersionManager.selectedSequenceVersion,
        [e.target.name]: e.target.checked
      };
    } else {
      sequenceVersionManager.selectedSequenceVersion = {
        ...sequenceVersionManager.selectedSequenceVersion,
        [e.target.name]: e.target.value
      };
    }
    changeSequenceVersion(sequenceVersionManager);
  };

  const onSequenceFileRemove = sequenceVersionFileId => {
    setDeleteSequenceVersionFileId(sequenceVersionFileId);
    handleCommand();
  };

  const validateVersionNo = val => {
    const regex = /^\d+(\.\d+)*$/;
    return regex.test(val);
  };
  const onVersionNoChange = e => {
    var regex = /^(([0-9.]?)*)+$/;
    if (e.target.value && regex.test(e.target.value)) {
      onChange(e);
    }
  };

  const addNewbtnClick = () => {
    let newUrl = `/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/${sequenceVersionId}/sequence-version-file/0`;
    history.push(newUrl);
  };

  const viewOrChangeBtnClick = sequenceVersionFileId => {
    let newUrl = `/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/${sequenceVersionId}/sequence-version-file/${sequenceVersionFileId}${
      IsView ? '?view=1' : ''
    }`;
    history.push(newUrl);
  };

  const sequenceGlobalSettingOptions = () => {
    let sequenceGlobalSettingData = [];
    flowSequences.forEach(element => {
      sequenceGlobalSettingData.push({
        ...element,
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });

    let sortedData = sequenceGlobalSettingData.sort(compareValues('label'));
    return sortedData;
  };

  const onDropdownChange = e => {
    sequenceVersionManager.selectedSequenceVersion = {
      ...sequenceVersionManager.selectedSequenceVersion,
      StartStateId: e.value
    };
    changeSequenceVersion(sequenceVersionManager);
  };

  const redirect = () => {
    if (sequenceVersionManager.isOpSuccessful && !sequenceVersionManager.showBanner) {
      if (
        sequenceVersionManager &&
        sequenceVersionManager.selectedSequenceVersion &&
        sequenceVersionManager.selectedSequenceVersion.SequenceVersionId
      ) {
        if (sequenceVersionManager.performDelete)
          return (
            <Navigate
              to={`/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/?id=${sequenceVersionId}`}
            />
          );
        return <Navigate to={backLink} />;
      } else if (sequenceVersionManager.sequenceVersionId && IsNew == '0') {
        return (
          <Navigate
            to={`/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/${sequenceVersionManager.sequenceVersionId}?new=1`}
          />
        );
      }
    }
  };

  const handleCommand = () => {
    setActive(true);
  };

  const onDeleteClick = () => {
    let saveData = sequenceVersionManager.selectedSequenceVersion;
    saveData.IsEdit = IsEdit;
    saveData.SequenceFile = {
      SequenceVersionFileId: deleteSequenceVersionFileId,
      IsPerformDelete: true,
      UpdatedBy: Cookies.get(`userid-${envName}`) || 1
    };
    sequenceVersionManager.performDelete = true;
    changeSequenceVersion(sequenceVersionManager);
    saveSequenceVersion(saveData, 'SEQUENCEFILESDELETE');
  };

  const handleConfirm = () => {
    onDeleteClick();
    handleClose();
  };

  const handleClose = () => {
    setActive(false);
    setDeleteSequenceVersionFileId(0);
  };

  const onPublishBtnClick = () => {
    if (!validatePublish()) {
      return;
    }
    let saveData = sequenceVersionManager.selectedSequenceVersion;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    saveData.ModelVersionId = modelVersionId;
    saveData.IsPublished = true;
    saveSequenceVersion(saveData, 'SEQUENCEVERSIONPUBLISH');
  };

  const validatePublish = () => {
    let seqVerFiles =
      (sequenceVersionManager.selectedSequenceVersion && sequenceVersionManager.selectedSequenceVersion.SequenceVersionFiles) || [];
    let predefinedSeqs = flowSequences.filter(item => item.IsPredefined);
    let isValid = true;
    let missingSequences = [];
    predefinedSeqs.forEach(item => {
      let verFile = seqVerFiles.find(file => file.SequenceStateId == item.GeneralGlobalSettingId);
      if (verFile == null) {
        isValid = false;
        missingSequences.push(item.Code);
      }
    });
    let formError = {};
    if (!isValid) {
      formError.PublishError =
        intl.formatMessage({
          id: 'sequenceVersionManagement.predefinedSequenceVersionFileMandatory',
          defaultMessage: 'Sequence version file missing for predefined states'
        }) + ` - ${missingSequences.join(',')}`;
    } else {
      formError.PublishError = null;
    }
    setFormError(formError);
    return isValid;
  };

  let ddlClass = formError && formError.StartStateId ? s.ddlError : '';
  return (
    <div className={s.sequenceVersionDetail}>
      {sequenceVersionManager.isLoading && <LoadingSpinner />}
      {redirect()}
      <ConfirmDialog title={dialogDelete} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />

      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={sequenceVersionManager.showBanner}
        status={sequenceVersionManager.isOpSuccessful}
        warning={sequenceVersionManager.warning}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.sequenceVersionManagementHeader}>
            <GoBack returnUrl={backLink}>
              &lt; &nbsp;
              <FormattedMessage id="sequenceVersionManagement.back" defaultMessage="BACK" />
            </GoBack>
            <Row>
              <Col xs={5}>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
              <Col>
                {!IsView && (
                  <Row className={s.colActionButton}>
                    {!sequenceVersionManager.selectedSequenceVersion.IsPublished && (
                      <Button
                        style={WidgetVisibility(userProfileWidgets, `SEQUENCEVERSIONPUBLISH`)}
                        variant="primary"
                        data-unittest="saveData"
                        className={`${s.margin5}, ${s.btnSaveChanges}`}
                        noValidate
                        onClick={() => {
                          onPublishBtnClick();
                        }}
                      >
                        <FormattedMessage id="sequenceVersionManagement.publish" defaultMessage="publish" />
                      </Button>
                    )}
                  </Row>
                )}
              </Col>
            </Row>
          </div>

          <div className={s.sequenceVersionDetailContent}>
            <Form className={s.viewRow}>
              {formError && formError.PublishError && (
                <p role="alert" className={s.error}>
                  {formError.PublishError}
                </p>
              )}

              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formSequenceVersionName">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionManagement.name" defaultMessage="Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={sequenceVersionManager.selectedSequenceVersion.Name}
                      className={`${s.formControl} ${formError && formError.Name ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'sequenceVersionManagement.name',
                        defaultMessage: 'Name'
                      })}
                      disabled={IsView}
                    />
                    {formError && formError.Name && (
                      <p role="alert" className={s.error}>
                        {formError.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formSequenceVersionNo">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionManagement.versionNo" defaultMessage="Version Number" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="VersionNo"
                      onChange={onVersionNoChange}
                      value={sequenceVersionManager.selectedSequenceVersion.VersionNo}
                      className={`${s.formControl} ${formError && formError.VersionNo ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'sequenceVersionManagement.versionNo',
                        defaultMessage: 'Version Number'
                      })}
                      disabled={IsView}
                    />
                    {formError && formError.VersionNo && (
                      <p role="alert" className={s.error}>
                        {formError.VersionNo}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formSequenceVersionDescription">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionManagement.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      className={s.txtArea}
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'sequenceVersionManagement.description',
                        defaultMessage: 'Description'
                      })}
                      value={sequenceVersionManager.selectedSequenceVersion.Description}
                      disabled={IsView}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formSequenceVersionVerifyErrorCode">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionManagement.verifyErrorCode" defaultMessage="Verify Error Code" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="VerifyErrorCode"
                      onChange={onChange}
                      value={sequenceVersionManager.selectedSequenceVersion.VerifyErrorCode}
                      className={`${s.formControl} ${formError && formError.VerifyErrorCode ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'sequenceVersionManagement.verifyErrorCode',
                        defaultMessage: 'Verify Error Code'
                      })}
                      disabled={IsView}
                    />
                    {formError && formError.VerifyErrorCode && (
                      <p role="alert" className={s.error}>
                        {formError.VerifyErrorCode}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formSequenceVersionVerifyWarningCode">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionManagement.verifyWarningCode" defaultMessage="Verify Warning Code" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="VerifyWarningCode"
                      onChange={onChange}
                      value={sequenceVersionManager.selectedSequenceVersion.VerifyWarningCode}
                      className={`${s.formControl} ${formError && formError.VerifyWarningCode ? s.formControlError : ''}`}
                      placeholder={intl.formatMessage({
                        id: 'sequenceVersionManagement.verifyWarningCode',
                        defaultMessage: 'Verify Warning Code'
                      })}
                      disabled={IsView}
                    />
                    {formError && formError.VerifyWarningCode && (
                      <p role="alert" className={s.error}>
                        {formError.VerifyWarningCode}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formSequenceVersionUnit">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionManagement.sequenceStartState" defaultMessage="Sequence Start State" />
                    </Form.Label>

                    <Dropdown
                      id="formSequenceVersionSequences"
                      dataArray={sequenceGlobalSettingOptions()}
                      controlData={{ placeholderText: 'Select start state', customClassName: ddlClass }}
                      onDropdownChange={onDropdownChange}
                      selectedOption={sequenceGlobalSettingOptions().filter(
                        option => option.value == sequenceVersionManager.selectedSequenceVersion.StartStateId
                      )}
                      disabled={IsView}
                      data-unittest="formSequenceVersionSequences"
                    />
                    {formError && formError.StartStateId && (
                      <p role="alert" className={s.error}>
                        {formError.StartStateId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            <SequenceVersionFiles
              onSequenceFileRemove={onSequenceFileRemove}
              addNewBtnClick={addNewbtnClick}
              viewOrChangeBtnClick={viewOrChangeBtnClick}
              selectedFiles={sequenceVersionManager.selectedSequenceVersion.SequenceVersionFiles}
              isView={IsView}
              isEdit={IsEdit}
              isPublished={isPublished}
              sequenceGlobalSettingsOptions={sequenceGlobalSettingOptions}
              currentTimezone={currentTimezone}
              deleteAllowed={deleteAllowed}
              changeAllowed={changeAllowed}
            />
          </div>
          {formError && formError.Task && (
            <p role="alert" className={s.error}>
              {formError.Task}
            </p>
          )}
          <div>
            <Form>
              {!IsView && (
                <Button
                  variant="primary"
                  data-unittest="saveData"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  disabled={IsView}
                >
                  <FormattedMessage id="sequenceVersionManagement.saveChanges" defaultMessage="Save Changes" />
                </Button>
              )}
              <Link to={backLink}>
                <Button variant="outline-secondary" className={s.btnCancel}>
                  <FormattedMessage id="sequenceVersionManagement.cancel" defaultMessage="Cancel" />
                </Button>
              </Link>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

SequenceVersionDetail.defaultProps = {
  sequenceVersionManager: {
    ...initialSequenceVersionState.sequenceVersionManager
  }
};

SequenceVersionDetail.propTypes = {
  saveSequenceVersion: PropTypes.func.isRequired,
  sequenceVersionManager: PropTypes.object.isRequired,
  describeSequenceVersion: PropTypes.func.isRequired,
  changeSequenceVersion: PropTypes.func.isRequired
};

export default injectIntl(SequenceVersionDetail);
