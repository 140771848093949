import React, { useState, useEffect } from 'react';
import s from './FAQCategory.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getFAQCategory } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialFAQCategoryState } from '../../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import { isRequired } from '../../../utils';
import Cookies from 'js-cookie';
import AdminControlsContentTemplate from '../../AdminControls/AdminControlsContentTemplate';
import FAQuestionList from '../FAQuestion/FAQuestionList';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import {
  describeFAQCategoryThunk,
  saveFAQCategoryThunk,
  setFAQCategoryChangeThunk,
  setFAQCategoryCleanUpThunk
} from '../../../actions/faqCategories';

import { v4 as uuid } from 'uuid' ;

const FAQCategory = ({ intl }) => {
  let history = useNavigate();
  let { faqCategoryId } = useParams();

  const dispatch = useDispatch();
  const faqCategory = useSelector(state => getFAQCategory(state));
  const userProfileWidgets = useSelector(state => state.userProfileWidgets);
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let IsEdit = false;

  const [localformErrors, setFormErrors] = useState({});

  const backUrl = '/admin-controls/faqs';
  let widgetCode = '';
  if (faqCategoryId) {
    IsEdit = true;
    widgetCode = 'FAQ_CATEGORY_EDIT';
  }

  useEffect(() => {
    if (faqCategoryId) {
      dispatch(describeFAQCategoryThunk(faqCategoryId, widgetCode));
    }
  }, [describeFAQCategoryThunk, dispatch, faqCategoryId]);

  //clean up
  useEffect(() => {
    return () => {
      dispatch(setFAQCategoryCleanUpThunk(initialFAQCategoryState.faqCategory.selectedFAQCategory));
    };
  }, []);

  // Update redux store
  const setLocalFAQCategory = currentState => {
    dispatch(setFAQCategoryChangeThunk(currentState));
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = faqCategory.selectedFAQCategory;
    saveData.PerformDelete = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.FAQCategoryId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'FAQ_CATEGORY_ADD';
    }

    dispatch(saveFAQCategoryThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !faqCategory ||
      !faqCategory.selectedFAQCategory ||
      !faqCategory.selectedFAQCategory.Name ||
      isRequired(faqCategory.selectedFAQCategory.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'common.nameRequired',
        defaultMessage: 'Name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    setLocalFAQCategory({
      ...faqCategory,
      selectedFAQCategory: { ...faqCategory.selectedFAQCategory, [e.target.name]: e.target.value }
    });
  };

  const cancelHandler = () => {
    history.push(backUrl);
  };

  let messageId = (faqCategory && faqCategory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelCompTypeName = (faqCategory.selectedFAQCategory ? faqCategory.selectedFAQCategory.Name : '') || '';
  let heading = '';
  if (IsEdit) {
    heading = intl.formatMessage({ id: 'common.edit', defaultMessage: 'Edit' }) + ' ' + modelCompTypeName;
  } else {
    heading = intl.formatMessage({ id: 'faqCategorys.addFAQCategory', defaultMessage: 'Add new FAQ Category' });
  }

  return (
    <div className={s.faqCategory}>
      {faqCategory.isLoading && <LoadingSpinner />}
      {faqCategory.isRedirect ? <Navigate to={backUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={faqCategory.showBanner}
        status={faqCategory.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageFAQ" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.faqCategoryHeader}>
            <GoBack className={s.backLink} returnUrl={backUrl}>
              &lt; &nbsp;
              <FormattedMessage id="common.back" defaultMessage="BACK" />
            </GoBack>
            <h3>{heading}</h3>
          </div>
          <div className={s.faqCategoryContent}>
            <Form>
              <div className={s.topRow}>
                <Row>
                  <Col lg={4}>
                    <Form.Group controlId="formFAQCategoryName">
                      <Form.Label>
                        <FormattedMessage id="common.name" defaultMessage="Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="Name"
                        onChange={onChange}
                        value={faqCategory.selectedFAQCategory.Name || ''}
                        className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                        placeholder={intl.formatMessage({
                          id: 'common.name',
                          defaultMessage: 'Name'
                        })}
                      />
                      {localformErrors && localformErrors.Name && (
                        <p role="alert" className={s.error}>
                          {localformErrors.Name}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group controlId="formFAQCategoryDescription">
                      <Form.Label>
                        <FormattedMessage id="common.description" defaultMessage="Description" />
                      </Form.Label>

                      <Form.Control
                        className={s.textArea}
                        as="textarea"
                        rows="3"
                        name="Description"
                        onChange={onChange}
                        placeholder={intl.formatMessage({
                          id: 'common.description',
                          defaultMessage: 'Description'
                        })}
                        value={faqCategory.selectedFAQCategory.Description}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Button
                      variant="primary"
                      className={classNames(s.margin5, s.btnSaveChanges)}
                      onClick={submitForm}
                      noValidate
                      data-unittest="saveData"
                    >
                      <FormattedMessage id="common.save" defaultMessage="SAVE" />
                    </Button>

                    <Button
                      variant="outline-secondary"
                      className={classNames(s.btnCancel)}
                      onClick={cancelHandler}
                      noValidate
                      data-unittest="saveCancel"
                    >
                      <FormattedMessage id="common.cancel" defaultMessage="CANCEL" />
                    </Button>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          {faqCategoryId && (
            <FAQuestionList userProfileWidgets={userProfileWidgets} faqCategorySpecificQuestions={true} faqCategoryId={faqCategoryId} />
          )}
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

FAQCategory.defaultProps = {
  faqCategory: {
    ...initialFAQCategoryState
  }
};

export default injectIntl(FAQCategory);
