import React, { useEffect, useState } from 'react';
import s from './SequenceVersionFileDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import { initialSequenceVersionState } from '../../reducers/initialState';
import Button from 'react-bootstrap/Button';
import Banner from '../Banner/Banner';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import Form from 'react-bootstrap/Form';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useNavigate, Link, useParams, Navigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { v4 as uuidv4 } from 'uuid';
import { templateJSONData } from './templateFile';
import { sampleJsonData } from './sampleFile';
import './external.css';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import { getQuerystring, compareValues } from '../../utils';
import GoBack from '../WSAControls/GoBack/GoBack';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { JsonEditor } from 'json-edit-react';

const SequenceVersionFileDetail = ({
  intl,
  sequenceVersionFileManager,
  sequenceVersionManager,
  userProfileWidgets,
  currentTimezone,
  modelVersionManager,
  flowSequences,
  describeSequenceVersionFile,
  saveSequenceVersionFile,
  changeSequenceVersionFile,
  describeSequenceVersion,
  describeModelVersion
}) => {
  let { sequenceVersionId, modelId, modelVersionId, sequenceVersionFileId } = useParams();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const backLink = `/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/${sequenceVersionId}`;
  const jsonEditorTheme = {
    displayName: 'Default',
    fragments: { edit: 'rgb(42, 161, 152)' },
    styles: {
      container: {
        fontFamily: '"Barlow", Helvetica, Arial, sans-serif',
        maxWidth: 'min(100%, 100vw)'
      }
    }
  };

  const [localformErrors, setFormErrors] = useState({});

  let IsView = getQuerystring('view') || '0';
  IsView = IsView === '1';
  let IsEdit = sequenceVersionFileId == 0 ? false : true;
  let widgetCode = IsEdit ? 'SEQUENCEVERSIONUPDATE' : 'SEQUENCEVERSIONADD';

  useEffect(() => {
    if (!modelVersionManager || !modelVersionManager.selectedModelVersion || !modelVersionManager.selectedModelVersion.ModelVersionId)
      describeModelVersion(modelVersionId, widgetCode);
  }, [describeModelVersion, modelVersionId, widgetCode]);

  useEffect(() => {
    if (
      !sequenceVersionManager ||
      !sequenceVersionManager.selectedSequenceVersion ||
      !sequenceVersionManager.selectedSequenceVersion.SequenceVersionId
    )
      describeSequenceVersion(sequenceVersionId, widgetCode);
  }, [describeSequenceVersion, sequenceVersionId, widgetCode]);

  useEffect(() => {
    if (sequenceVersionFileId != '0') {
      describeSequenceVersionFile(sequenceVersionId, sequenceVersionFileId, widgetCode);
    }
  }, [describeSequenceVersionFile, sequenceVersionId, sequenceVersionFileId, widgetCode]);

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !sequenceVersionFileManager ||
      !sequenceVersionFileManager.selectedSequenceVersionFile ||
      !sequenceVersionFileManager.selectedSequenceVersionFile.SequenceStateId
    ) {
      formErrors.SequenceStateId = intl.formatMessage({
        id: 'sequenceVersionFilesManagement.sequenceStateMandatory',
        defaultMessage: 'Sequence State is mandatory'
      });
      isValid = false;
    }

    if (
      sequenceVersionFileManager &&
      sequenceVersionFileManager.selectedSequenceVersionFile &&
      sequenceVersionFileManager.selectedSequenceVersionFile.HasSuperState &&
      !sequenceVersionFileManager.selectedSequenceVersionFile.SuperStateId
    ) {
      formErrors.SuperStateId = intl.formatMessage({
        id: 'sequenceVersionFilesManagement.superStateMandatory',
        defaultMessage: 'Super State is mandatory'
      });
      isValid = false;
    }

    if (
      !sequenceVersionFileManager ||
      !sequenceVersionFileManager.selectedSequenceVersionFile ||
      !sequenceVersionFileManager.selectedSequenceVersionFile.JsonText ||
      isRequired(sequenceVersionFileManager.selectedSequenceVersionFile.JsonText, 1)
    ) {
      formErrors.JsonText = intl.formatMessage({
        id: 'sequenceVersionFilesManagement.jsonTextMandatory',
        defaultMessage: 'Sequence JSON is mandatory'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = sequenceVersionManager.selectedSequenceVersion;
    saveData.SequenceFile = saveData.selectedSequenceVersionFile;
    saveData.IsEdit = IsEdit;
    if (!IsEdit) {
      saveData.SequenceFile.SequenceVersionFileId = 0;
      saveData.SequenceFile.UpdatedBy = Cookies.get(`userid-${envName}`) || 1;
    }
    saveSequenceVersionFile(saveData, widgetCode);
  };

  const setLocalSequenceVersionFile = currentState => {
    changeSequenceVersionFile(currentState);
  };

  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalSequenceVersionFile({
        ...sequenceVersionFileManager,
        selectedSequenceVersionFile: {
          ...sequenceVersionFileManager.selectedSequenceVersionFile,
          [e.target.name]: e.target.checked,
          SuperStateId: e.target.checked ? sequenceVersionFileManager.selectedSequenceVersionFile.SuperStateId : null
        }
      });
    } else {
      setLocalSequenceVersionFile({
        ...sequenceVersionFileManager,
        selectedSequenceVersionFile: { ...sequenceVersionFileManager.selectedSequenceVersionFile, [e.target.name]: e.target.value }
      });
    }
  };

  const redirect = () => {
    if (sequenceVersionFileManager.isOpSuccessful && !sequenceVersionFileManager.showBanner) {
      return <Navigate to={backLink} />;
    }
  };

  const getHeadingName = () => {
    const modelName = (modelVersionManager.selectedModelVersion ? modelVersionManager.selectedModelVersion.ModelName : '') || '';
    const modelVersionName =
      (modelVersionManager && modelVersionManager.selectedModelVersion && modelVersionManager.selectedModelVersion.Name) || '';

    const sequenceVersionName =
      (sequenceVersionManager.selectedSequenceVersion ? sequenceVersionManager.selectedSequenceVersion.Name : '') || '';

    let headingData = `${modelName}:${modelVersionName}:${sequenceVersionName} `;
    let heading = '';
    if (IsEdit) {
      heading =
        intl.formatMessage({ id: 'sequenceVersionFilesManagement.editSequenceVersionFile', defaultMessage: 'Edit Sequence Version File' }) +
        ': ' +
        headingData;
    } else if (IsView) {
      heading = headingData;
    } else {
      heading =
        intl.formatMessage({ id: 'sequenceVersionFilesManagement.newSequenceVersionFile', defaultMessage: 'New Sequence Version File' }) +
        ' ' +
        headingData;
    }
    return heading;
  };

  const disableSequenceGlobalSettingStateOptions = element => {
    if (
      sequenceVersionManager.selectedSequenceVersion.SequenceVersionFiles.find(
        item => item.SequenceStateId == element.GeneralGlobalSettingId
      ) != null
    ) {
      if (
        sequenceVersionManager.selectedSequenceVersion.SequenceVersionFiles.find(
          item => item.SequenceVersionFileId == sequenceVersionFileId && element.GeneralGlobalSettingId == item.SequenceStateId
        ) != null
      ) {
        return false;
      }
      return true;
    }
    return false;
  };

  const sequenceGlobalSettingStateOptions = () => {
    let sequenceGlobalSettingData = [];
    flowSequences.forEach(element => {
      sequenceGlobalSettingData.push({
        ...element,
        label: element.Name,
        value: element.GeneralGlobalSettingId,
        isDisabled: disableSequenceGlobalSettingStateOptions(element)
      });
    });

    let sortedData = sequenceGlobalSettingData.sort(compareValues('label'));
    return [{ value: 0, label: 'Please select' }, ...sortedData];
  };

  const disableSequenceGlobalSettingSuperStateOptions = element => {
    if (
      sequenceVersionManager.selectedSequenceVersion.SequenceVersionFiles.find(
        item => item.SequenceStateId == element.GeneralGlobalSettingId
      ) == null ||
      element.GeneralGlobalSettingId == sequenceVersionFileManager.selectedSequenceVersionFile.SequenceStateId
    ) {
      return true;
    }
    if (
      sequenceVersionManager.selectedSequenceVersion.SequenceVersionFiles.find(
        item => item.SequenceStateId == element.GeneralGlobalSettingId && item.SequenceVersionFileId == sequenceVersionFileId
      ) != null
    ) {
      return true;
    }

    if (
      sequenceVersionManager.selectedSequenceVersion.SequenceVersionFiles.find(
        item => item.SequenceStateId == element.GeneralGlobalSettingId && item.HasSuperState
      ) != null
    ) {
      return true;
    }

    return false;
  };

  const sequenceGlobalSettingSuperStateOptions = () => {
    let sequenceGlobalSettingData = [];
    flowSequences.forEach(element => {
      sequenceGlobalSettingData.push({
        ...element,
        label: element.Name,
        value: element.GeneralGlobalSettingId,
        isDisabled: disableSequenceGlobalSettingSuperStateOptions(element)
      });
    });

    let sortedData = sequenceGlobalSettingData.sort(compareValues('label'));
    return [{ value: 0, label: 'Please select' }, ...sortedData];
  };

  const onSequenceDropdownChange = e => {
    setLocalSequenceVersionFile({
      ...sequenceVersionFileManager,
      selectedSequenceVersionFile: {
        ...sequenceVersionFileManager.selectedSequenceVersionFile,
        SequenceStateId: e.value,
        SuperStateId: null
      }
    });
  };

  const onSuperStateDropdownChange = e => {
    setLocalSequenceVersionFile({
      ...sequenceVersionFileManager,
      selectedSequenceVersionFile: { ...sequenceVersionFileManager.selectedSequenceVersionFile, SuperStateId: e.value }
    });
  };

  const onJSONTextChange = data => {
    setLocalSequenceVersionFile({
      ...sequenceVersionFileManager,
      selectedSequenceVersionFile: {
        ...sequenceVersionFileManager.selectedSequenceVersionFile,
        JsonText: (data.newData && JSON.stringify(data.newData)) || null
      }
    });
  };

  let messageId = (sequenceVersionManager && sequenceVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let successMessageId = (sequenceVersionFileManager && sequenceVersionFileManager.displayMessageCode) || 'none';
  const successMessageText = intl.formatMessage({ id: successMessageId, defaultMessage: successMessageId });

  let backToMessage = intl.formatMessage({
    id: 'sequenceVersionFilesManagement.backtoSequenceVersion',
    defaultMessage: 'BACK TO SEQUENCE VERSION'
  });
  let ddlSequenceStateClass = localformErrors && localformErrors.SequenceStateId ? s.ddlError : '';
  let ddlSuperClass = localformErrors && localformErrors.SuperStateId ? s.ddlError : '';

  return (
    <div className={s.sequenceVersionFile}>
      {sequenceVersionManager.isLoading && <LoadingSpinner />}

      <Banner
        key={uuidv4()}
        failureText={messageText}
        showBanner={sequenceVersionManager.showBanner}
        status={sequenceVersionManager.isOpSuccessful}
        successText={messageText}
      />
      <Banner
        key={uuidv4()}
        failureText={successMessageText}
        showBanner={sequenceVersionFileManager.showBanner}
        status={sequenceVersionFileManager.isOpSuccessful}
        successText={successMessageText}
      />
      {redirect()}
      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.sequenceVersionFileHeader}>
            <GoBack returnUrl={backLink}>
              &lt; &nbsp;
              {backToMessage}
            </GoBack>
            <Row>
              <Col xs={5}>
                <h3 data-unittest="headingLabel">{getHeadingName()}</h3>
              </Col>
            </Row>
          </div>

          <div className={s.sequenceVersionFileContent}>
            <Form>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formSequenceVersionFileState">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionFilesManagement.sequenceState" defaultMessage="Sequence State" />
                    </Form.Label>

                    <Dropdown
                      id="formSequenceVersionFileState"
                      dataArray={sequenceGlobalSettingStateOptions(false)}
                      controlData={{ placeholderText: 'Select state', customClassName: ddlSequenceStateClass }}
                      onDropdownChange={onSequenceDropdownChange}
                      selectedOption={sequenceGlobalSettingStateOptions().filter(
                        option => option.value == sequenceVersionFileManager.selectedSequenceVersionFile.SequenceStateId
                      )}
                      disabled={IsView}
                      data-unittest="formSequenceVersionSequences"
                    />
                    {localformErrors && localformErrors.SequenceStateId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.SequenceStateId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formHasSuperState">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'HasSuperState',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'sequenceVersionFilesManagement.subState',
                            defaultMessage: 'Sub State'
                          }),
                          isChecked: sequenceVersionFileManager.selectedSequenceVersionFile.HasSuperState
                        }
                      ]}
                      onSelectionChange={onChange}
                      disable={IsView}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {sequenceVersionFileManager.selectedSequenceVersionFile.HasSuperState && (
                <Row>
                  <Col xs={12} lg={6}>
                    <Form.Group controlId="formSequenceVersionSuperState">
                      <Form.Label>
                        <FormattedMessage id="sequenceVersionFilesManagement.superState" defaultMessage="Super State" />
                      </Form.Label>

                      <Dropdown
                        id="formSequenceVersionSuperState"
                        dataArray={sequenceGlobalSettingSuperStateOptions(true)}
                        controlData={{ placeholderText: 'Select start state', customClassName: ddlSuperClass }}
                        onDropdownChange={onSuperStateDropdownChange}
                        selectedOption={sequenceGlobalSettingSuperStateOptions().filter(
                          option => option.value == sequenceVersionFileManager.selectedSequenceVersionFile.SuperStateId
                        )}
                        disabled={IsView}
                        data-unittest="formSequenceVersionSuperState"
                      />
                      {localformErrors && localformErrors.SuperStateId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.SuperStateId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}

              <Row>
                <Col xs={12} lg={6}>
                  <Form.Group controlId="formSequenceVersionWaitFor">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionFilesManagement.waitFor" defaultMessage="Wait For" />
                    </Form.Label>
                    <InputNumber
                      disabled={IsView}
                      name="WaitFor"
                      step="1"
                      onInputChange={onChange}
                      customClassName={s.formControl}
                      value={sequenceVersionFileManager.selectedSequenceVersionFile.WaitFor?.toString()}
                      id="WaitFor"
                      placeholder={intl.formatMessage({
                        id: 'sequenceVersionFilesManagement.waitFor',
                        defaultMessage: 'Wait For'
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={12}>
                  <Form.Group controlId="formSequenceVersionJSONText">
                    <Form.Label>
                      <FormattedMessage id="sequenceVersionFilesManagement.jsonText" defaultMessage="JSON Text" />
                    </Form.Label>
                    <Link className={s.sampleLink} to="/sampleFile.js" target="blank">
                      <FormattedMessage id="sequenceVersionFilesManagement.sampleSequenceFile" defaultMessage="Sample sequence file" />
                    </Link>

                    <JsonEditor
                      className={s.jsonEditor}
                      theme={jsonEditorTheme}
                      data={
                        (sequenceVersionFileManager.selectedSequenceVersionFile.JsonText &&
                          JSON.parse(sequenceVersionFileManager.selectedSequenceVersionFile.JsonText)) ||
                        templateJSONData
                      }
                      onUpdate={onJSONTextChange}
                    />

                    {localformErrors && localformErrors.JsonText && (
                      <p role="alert" className={s.error}>
                        {localformErrors.JsonText}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
          <div>
            <Form>
              {!IsView && (
                <Button
                  variant="primary"
                  data-unittest="saveData"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  disabled={IsView}
                >
                  <FormattedMessage id="sequenceVersionFilesManagement.saveChanges" defaultMessage="Save Changes" />
                </Button>
              )}
              <Link to={backLink}>
                <Button variant="outline-secondary" className={s.btnCancel}>
                  <FormattedMessage id="sequenceVersionFilesManagement.cancel" defaultMessage="Cancel" />
                </Button>
              </Link>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

SequenceVersionFileDetail.defaultProps = {
  sequenceVersionFileManager: {
    ...initialSequenceVersionState.sequenceVersionManager.selectedSequenceVersion
  }
};

export default injectIntl(SequenceVersionFileDetail);
