import React, { useEffect, useState } from 'react';
import s from './BuildVersion.module.scss';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getReleaseNoteViewThunk } from '../../../actions/releaseNotes';
import { getReleaseNote } from '../../../selectors/index';
import { CONST_PLATFORM_NAME_CONTROL_SYSTEM, CONST_PLATFORM_NAME_ELEIOT } from '../../../constants/index';
import { TypeH4 } from 'react-bootstrap-icons';

const BuildVersionViewer = () => {
  const dispatch = useDispatch();
  const widgetCode = 'GENERALACCESS';
  const releaseNote = useSelector(state => getReleaseNote(state));
  const releaseNoteViewData = releaseNote.viewReleaseNotes || [];
  const rnControlSystemViewData = releaseNoteViewData.filter(item => item.PlatformName == CONST_PLATFORM_NAME_CONTROL_SYSTEM);
  const rnEleiotViewData = releaseNoteViewData.filter(item => item.PlatformName == CONST_PLATFORM_NAME_ELEIOT);
  useEffect(() => {
    dispatch(getReleaseNoteViewThunk(widgetCode));
  }, [getReleaseNoteViewThunk, dispatch, widgetCode]);

  let eleiotReleaseNote = (rnEleiotViewData && rnEleiotViewData.length > 0 && rnEleiotViewData[0]) || {};
  return (
    <div className={s.contentWrapper}>
      <h2>
        <FormattedMessage id="buildVersion.eleiotBuildVersion" defaultMessage="Eleiot Build Version" /> : {process.env.REACT_APP_VERSION_NO}
      </h2>
      {process.env.REACT_APP_VERSION_NO != eleiotReleaseNote.VersionNo && (
        <span className={s.alert}>
          <FormattedMessage
            id="buildVersion.buildAndReleaseVersionDoesntMatch"
            defaultMessage="The Eleiot build version does not match the latest release version"
          />
        </span>
      )}
      {rnEleiotViewData && rnEleiotViewData.length > 0 && (
        <>
          <h2 className={s.releaseVersionHeading}>
            <FormattedMessage id="buildVersion.releaseVersion" defaultMessage="Release Version" />
          </h2>
          <ReleaseNoteItem item={rnEleiotViewData[0]} />
        </>
      )}
    </div>
  );
};

export default BuildVersionViewer;

const ReleaseNoteItem = ({ item }) => {
  return (
    <div className={s.buildVersionContent}>
      <Row>
        <Col>
          <h4>{item.Name}</h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <h4>
            <FormattedMessage id="buildVersion.versionNo" defaultMessage="Version No" /> : {item.VersionNo}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormattedMessage id="releaseNotes.dependencyVersionName" defaultMessage="Dependency Version Name" /> :{' '}
          {item.DependencyVersionName || ''}
        </Col>
      </Row>
      <Row>
        <Col>
          <FormattedMessage id="releaseNotes.dependencyVersionNo" defaultMessage="Dependency Version No" /> :{' '}
          {item.DependencyVersionNo || ''}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={s.notes} dangerouslySetInnerHTML={{ __html: item.Notes }} />
        </Col>
      </Row>
    </div>
  );
};
