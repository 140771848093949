import React from 'react';
import s from './BuildVersion.module.scss';
import BuildVersionViewer from './BuildVersionViewer';
import { injectIntl } from 'react-intl';
import HelpControlsContentTemplate from '../HelpControls/HelpControlsContentTemplate';

const BuildVersion = ({ intl }) => {
  return (
    <div className={s.buildVersionWrapper}>
      <HelpControlsContentTemplate selectedPage="version">
        <div className={s.contentWrapper}>
          <BuildVersionViewer />
        </div>
      </HelpControlsContentTemplate>
    </div>
  );
};

export default injectIntl(BuildVersion);
