import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './FAQCategory.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getFAQCategory } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { initialFAQCategoryState } from '../../../reducers/initialState';
import { getFAQCategoryThunk, saveFAQCategoryThunk } from '../../../actions/faqCategories';
import FAQCategoryListTable from './FAQCategoryListTable';
import collapseDown from '../../../assets/collapse-down.svg';
import collapseUp from '../../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import SearchBar from '../../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';

const FAQCategoryList = ({ userProfileWidgets, intl }) => {
  const history = useNavigate();

  let initialLimit = 6;

  const dispatch = useDispatch();
  const faqCategory = useSelector(state => getFAQCategory(state));

  let initialRecords = faqCategory.faqCategories.slice(0, initialLimit);
  const [faqCategoryOpen, setFAQCategoryOpen] = useState(false);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    dispatch(getFAQCategoryThunk('FAQ_CATEGORY_LIST'));
  }, [getFAQCategoryThunk, dispatch, faqCategory.isOpSuccessful]);

  const [localFAQCategories, setLocalFAQCategories] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [faqCategoryId, setFAQCategoryId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localFAQCategoryLength = localFAQCategories.length;
  const globalSettingLength = faqCategory.faqCategories.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalFAQCategories(faqCategory.faqCategories);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = faqCategory.faqCategories.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalFAQCategories(filteredList);
  };

  const filterData = () => {
    let filteredList = faqCategory.faqCategories;
    if (isFilter) {
      filteredList = faqCategory.faqCategories.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getFAQCategoryData = () => {
    let faqCategory = [];
    if (showAll) {
      faqCategory = filterData();
    } else {
      if (isFilter) {
        faqCategory = localFAQCategoryLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        faqCategory = initialRecords;
      }
    }
    return faqCategory.sort(compareValues('Name'));
  };

  const onRemoveFAQCategoryClick = faqCategoryId => {
    setActive(true);
    setFAQCategoryId(faqCategoryId);
  };

  const DeleteFAQCategory = () => {
    let saveData = faqCategory.selectedFAQCategory;
    saveData.PerformDelete = true;
    saveData.FAQCategoryId = faqCategoryId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveFAQCategoryThunk(saveData, 'FAQ_CATEGORY_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setFAQCategoryId(0);
  };

  const handleConfirm = () => {
    DeleteFAQCategory();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/admin-controls/faqs/category');
  };

  const onChangeFAQCategoryClick = faqCategoryId => {
    history.push(`/admin-controls/faqs/category/${faqCategoryId}`);
  };

  let messageId = (faqCategory && faqCategory.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'FAQ_CATEGORY_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'FAQ_CATEGORY_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'faqCategories.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this category?'
  });

  return (
    <div className={s.faqCategoryList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'FAQ_CATEGORY_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={faqCategoryOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setFAQCategoryOpen(!faqCategoryOpen)}
                aria-controls="model-component-type-section"
                aria-expanded={faqCategoryOpen}
              ></img>
              <h4>
                <FormattedMessage id="faqCategories.faqCategory" defaultMessage="FAQ Category" />
              </h4>
              <span className={s.circle}>{faqCategory.faqCategories.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={faqCategoryOpen}>
          <div className={s.innerComponent} id="model-component-type-section">
            <div className={s.component}>
              {faqCategory.isLoading && <LoadingSpinner />}
              {faqCategory.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={faqCategory.showBanner}
                  status={faqCategory.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={faqCategory.showAddEditScreen}
                      // initialText={filter}
                      placeHolderTextId="common.search"
                      data-unittest="searchFAQCategories"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'FAQ_CATEGORY_ADD')}
                      onClick={onAddNewClick}
                      disabled={faqCategory.showAddEditScreen}
                      data-unittest="addFAQCategoryButton"
                    >
                      + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <FAQCategoryListTable
                    RemoveFAQCategory={onRemoveFAQCategoryClick}
                    FAQCategoryData={getFAQCategoryData()}
                    ChangeFAQCategory={onChangeFAQCategoryClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localFAQCategoryLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localFAQCategoryLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

FAQCategoryList.defaultProps = {
  ...initialFAQCategoryState
};

export default injectIntl(FAQCategoryList);
