import React, { useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './ApprovedComponentView.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialApprovedComponentState } from '../../reducers/initialState';
import { useParams, useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import GoBack from '../WSAControls/GoBack/GoBack';
import classNames from 'classnames';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { v4 as uuid } from 'uuid' ;
import { compareValues } from '../../utils/';
import { WidgetVisibility } from '../../utils/widgetManager';
import Table from 'react-bootstrap/Table';

const ApprovedComponentView = ({ userProfileWidgets, approvedComponent, describeApprovedComponent, resetModelComponent, intl }) => {
  let history = useNavigate();
  let { approvedComponentId } = useParams();

  const backUrl = '/admin-controls/model-component?list=1';

  if (!approvedComponentId) {
    history.push(backUrl);
  }

  useEffect(() => {
    if (approvedComponentId) {
      describeApprovedComponent(approvedComponentId, 'APPROVEDCOMPONENT_VIEW');
    }
  }, [describeApprovedComponent, approvedComponentId]);

  //clean up
  useEffect(() => {
    return () => {
      resetModelComponent({
        ...initialApprovedComponentState
      });
    };
  }, []);

  const componentOptions = () => {
    let componentOptions = [];

    approvedComponent.modelComponentTypes.forEach(element => {
      componentOptions.push({
        label: element.Name,
        value: element.ModelComponentTypeId
      });
    });
    return componentOptions.sort(compareValues('label'));
  };

  const equationOptions = () => {
    let equationOptions = [];

    approvedComponent.calibrationEquations.forEach(element => {
      equationOptions.push({
        label: element.Name,
        value: element.CalibrationEquationId
      });
    });
    let sortedData = equationOptions.sort(compareValues('label'));
    return [{ value: 0, label: 'None' }, ...sortedData];
  };

  const getSelectedName = (fnName, value) => {
    let filteredVal = fnName().filter(option => option.value === value);
    if (filteredVal.length > 0) {
      return filteredVal[0].label;
    }
    return '-';
  };

  const onChangeClick = approvedComponentId => {
    history.push(`/admin-controls/model-component/approved-component/${approvedComponentId}`);
  };

  const lblYes = intl.formatMessage({ id: 'approvedComponents.yes', defaultMessage: 'Yes' });
  const lblNo = intl.formatMessage({ id: 'approvedComponents.no', defaultMessage: 'No' });
  let messageId = (approvedComponent && approvedComponent.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const approvedComponentName =
    (approvedComponent.selectedApprovedComponent
      ? `${approvedComponent.selectedApprovedComponent.Brand} ${approvedComponent.selectedApprovedComponent.Model}`
      : '') || '';

  let heading = '';

  heading = approvedComponentName;

  const approvedSubComponentList = approvedComponent.selectedApprovedComponent.ApprovedSubComponentsData || [];

  return (
    <div className={s.approvedComponent}>
      {approvedComponent.isLoading && <LoadingSpinner />}

      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={approvedComponent.showBanner}
        status={approvedComponent.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageComponents" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.approvedComponentHeader}>
            <GoBack returnUrl={backUrl} className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="approvedComponents.back" defaultMessage="BACK" />
            </GoBack>
            <Row>
              <Col xs={5}>
                <h3 data-unittest="headingLabel">{heading}</h3>
              </Col>
              <Col>
                <Row className={s.colActionButton}>
                  <Button
                    style={WidgetVisibility(userProfileWidgets, `APPROVEDCOMPONENT_EDIT`)}
                    variant="primary"
                    data-unittest="changeData"
                    noValidate
                    onClick={() => {
                      onChangeClick(approvedComponentId);
                    }}
                  >
                    <FormattedMessage id="approvedComponents.change" defaultMessage="CHANGE" />
                  </Button>
                </Row>
              </Col>
            </Row>
          </div>
          <div className={s.approvedComponentContent}>
            <div className={s.topRow}>
              <Form>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.brand" defaultMessage="Brand" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.Brand}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.model" defaultMessage="Model" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.Model}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.reference" defaultMessage="Approved Component Reference" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.ApprovedComponentRef}</Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.description" defaultMessage="Description" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.Description || ''}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.uOM" defaultMessage="Unit of measure" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.UnitOfMeasure || '-'}</Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.componentType" defaultMessage="Component type" />
                    </Form.Label>
                  </Col>
                  <Col>{getSelectedName(componentOptions, approvedComponent.selectedApprovedComponent.ModelComponentTypeId)}</Col>
                </Row>

                <Row>
                  <hr />
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.hasState" defaultMessage="Has state" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.HasState ? lblYes : lblNo}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.hasFeedback" defaultMessage="Has feedback" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.HasFeedback ? lblYes : lblNo}</Col>
                </Row>
                <Row>
                  <hr></hr>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.supplierName" defaultMessage="Supplier name" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.SupplierName || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.supplierAddress" defaultMessage="Supplier address" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.SupplierAddress || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.manufacturerName" defaultMessage="Manufacturer name" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.ManufacturerName || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.manufacturerAddress" defaultMessage="Manufacturer address" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.ManufacturerAddress || '-'}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.dataSheetURL" defaultMessage="Data sheet URL" />
                    </Form.Label>
                  </Col>
                  <Col>
                    <a href={approvedComponent.selectedApprovedComponent.DatasheetURL || '#'} target="_blank" className={s.dataSheetLInk}>
                      {approvedComponent.selectedApprovedComponent.DatasheetURL || '-'}
                    </a>
                  </Col>
                </Row>

                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.serviceable" defaultMessage="Serviceable" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.IsServiceable ? lblYes : lblNo}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.consumable" defaultMessage="Consumable" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.IsConsumable ? lblYes : lblNo}</Col>
                </Row>
                <Row className={s.viewRow}>
                  <Col xs={12} lg={3}>
                    <Form.Label>
                      <FormattedMessage id="approvedComponents.active" defaultMessage="Active" />
                    </Form.Label>
                  </Col>
                  <Col>{approvedComponent.selectedApprovedComponent.IsActive ? lblYes : lblNo}</Col>
                </Row>
                <Row>
                  <hr></hr>
                </Row>
                <Row>
                  <Col lg={6}>
                    <h4 className={s.subheading}>
                      <FormattedMessage id="approvedComponents.approvedSubComponents" defaultMessage="Approved Sub Components" />
                    </h4>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <Table variant className={s.innerTable}>
                      <thead>
                        <tr>
                          <th>
                            <FormattedMessage id="approvedComponents.subComponentType" defaultMessage="Sub Component Type" />
                          </th>
                          <th>
                            <FormattedMessage id="approvedComponents.calibrationRequired" defaultMessage="Calibration Required" />
                          </th>
                          <th>
                            <FormattedMessage id="approvedComponents.output" defaultMessage="Output" />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {approvedSubComponentList.map(n => {
                          return (
                            <Fragment key={n.GuidIdentifier}>
                              <tr key={n.ApprovedSubComponentId} data-unittest="ApprovedSubComponentsData">
                                <td>{n.ApprovedSubComponentName}</td>
                                <td>{n.CalibrationRequired ? 'Yes' : 'No'}</td>
                                <td>{n.Output ? 'Yes' : 'No'}</td>
                              </tr>
                            </Fragment>
                          );
                        })}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Form>
            </div>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ApprovedComponentView.defaultProps = {
  approvedComponent: {
    ...initialApprovedComponentState.approvedComponent
  }
};

ApprovedComponentView.propTypes = {
  approvedComponent: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  describeApprovedComponent: PropTypes.func.isRequired,
  resetModelComponent: PropTypes.func.isRequired
};

export default injectIntl(ApprovedComponentView);
