import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './UnitConfiguration.module.scss';
import { FormattedMessage } from 'react-intl';
import { initialUnitManagerState } from '../../reducers/initialState';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import LoadingSpinner from '..//LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { isRequired } from '../../utils/';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import moment from 'moment-timezone';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import InputNumber from '../WSAControls/InputNumber/InputNumber';

const UnitModel = ({
  unitManager,
  userProfileWidgets,
  onSave,
  intl,
  getSelectedUserProfileWidgets,
  currentTimezone,
  getOrganisationRegionByOrgId,
  getUnitManagerAndEngineers
}) => {
  let countries = countryList().getData();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const orgId = Cookies.get(`selectedorganisationid-${envName}`) || 0;

  const lblYes = intl.formatMessage({ id: 'common.yes', defaultMessage: 'Yes' });
  const lblNo = intl.formatMessage({ id: 'common.no', defaultMessage: 'No' });

  const regionList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    unitManager.selectedUnit.RegionList.forEach(element => {
      itemData.push({
        label: element.Name,
        value: element.OrganisationRegionId
      });
    });
    return itemData;
  };

  const userList = () => {
    let itemData = [];

    itemData.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    unitManager.selectedUnit.UserList.forEach(element => {
      itemData.push({
        label: element.FullName,
        value: element.UserId
      });
    });
    return itemData;
  };

  const [form, setForm] = useState({
    Name: '',
    CommissionGpsLatitude: '',
    CommissionGpsLongitude: '',
    WarrantyStartDate: '',
    WarrantyEndDate: '',
    InstallationStreetAddress: '',
    InstallationCity: '',
    InstallationRegion: '',
    InstallationPostCode: '',
    InstallationCountry: '',
    InstallationCountryCode: '',
    RegionId: '',
    UnitManagerId: 0,
    UnitTechnicianId: 0,
    Description: '',
    ManufactureId: null,
    ManufactureDate: null,
    InstallationStatus: false,
    InstallationDate: null,
    InstallationEngineer: 0,
    CommissionStatus: false,
    CommissionDate: null,
    CommissionPowerOnTest: false,
    CommissionWaterSample: 0.0,
    CommissionCallHome: '',
    isEdit: false
  });
  const [formErrors, setFormErrors] = useState({
    Name: false,
    InstallationDate: false,
    CommissionDate: false,
    Warranty: false
  });

  const handleEditOrCancelClick = isEdit => {
    setForm({
      Name: unitManager.selectedUnit.Name,
      CommissionGpsLatitude: unitManager.selectedUnit.CommissionGpsLatitude,
      CommissionGpsLongitude: unitManager.selectedUnit.CommissionGpsLongitude,
      WarrantyStartDate: unitManager.selectedUnit.WarrantyStartDate,
      WarrantyEndDate: unitManager.selectedUnit.WarrantyEndDate,
      InstallationStreetAddress: unitManager.selectedUnit.InstallationStreetAddress,
      InstallationCity: unitManager.selectedUnit.InstallationCity,
      InstallationRegion: unitManager.selectedUnit.InstallationRegion,
      UnitManagerId: unitManager.selectedUnit.UnitManagerId,
      UnitManagerName: unitManager.selectedUnit.UnitManagerName,
      InstallationEngineerName: unitManager.selectedUnit.InstallationEngineerName,
      UnitTechnicianId: unitManager.selectedUnit.UnitTechnicianId,
      UnitTechnicianName: unitManager.selectedUnit.UnitTechnicianName,
      InstallationPostCode: unitManager.selectedUnit.InstallationPostCode,
      InstallationCountry: unitManager.selectedUnit.InstallationCountry,
      InstallationCountryCode: {
        value: countryList().getValue(unitManager.selectedUnit.InstallationCountry),
        label: unitManager.selectedUnit.InstallationCountry
      },
      RegionId: unitManager.selectedUnit.RegionId,
      RegionName: unitManager.selectedUnit.RegionName,
      Description: unitManager.selectedUnit.Description,
      ManufactureId: unitManager.selectedUnit.ManufactureId,
      ManufactureDate: unitManager.selectedUnit.ManufactureDate,
      InstallationStatus: unitManager.selectedUnit.InstallationStatus,
      InstallationDate: unitManager.selectedUnit.InstallationDate,
      InstallationEngineer: unitManager.selectedUnit.InstallationEngineer,
      CommissionStatus: unitManager.selectedUnit.CommissionStatus,
      CommissionDate: unitManager.selectedUnit.CommissionDate,
      CommissionPowerOnTest: unitManager.selectedUnit.CommissionPowerOnTest,
      CommissionWaterSample: unitManager.selectedUnit.CommissionWaterSample,
      CommissionCallHome: unitManager.selectedUnit.CommissionCallHome,

      isEdit: isEdit
    });
    setFormErrors({
      Name: false,
      InstallationDate: false,
      CommissionDate: false,
      warranty: false
    });
  };

  const onGenericDropdownChange = (e, fieldName) => {
    setForm({
      ...form,
      [fieldName]: e.value
    });
  };

  const handleInput = (e, data) => {
    const { name } = e.target;
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setForm({
        ...form,
        [name]: e.target.checked
      });
    } else {
      const { name, value } = e.target;
      setForm({
        ...form,
        [name]: value
      });
    }
  };

  const onCountryChange = selectedCountry => {
    setForm({
      ...form,
      InstallationCountry: selectedCountry.label,
      InstallationCountryCode: selectedCountry
    });
  };

  const onRegionChange = e => {
    setForm({
      ...form,
      RegionId: e.value
    });
  };

  const onUnitManagerChange = e => {
    setForm({
      ...form,
      UnitManagerId: e.value
    });
  };

  const onUnitTechnicianChange = e => {
    setForm({
      ...form,
      UnitTechnicianId: e.value
    });
  };

  useEffect(() => {
    if (!window.google) {
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAP_API_KEY}&libraries=places`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    }
  }, []);

  useEffect(() => {
    getOrganisationRegionByOrgId(orgId);
  }, [getOrganisationRegionByOrgId, orgId]);

  useEffect(() => {
    getUnitManagerAndEngineers(orgId);
  }, [orgId]);

  const handleAddressChange = e => {
    e.preventDefault();

    const latlng = {
      lat: parseFloat(form.CommissionGpsLatitude),
      lng: parseFloat(form.CommissionGpsLongitude)
    };
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: latlng }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          let streetAddress = '';
          let city = '';
          let postCode = '';
          let region = '';
          let country = '';
          results[0].address_components.forEach(item => {
            if (item.types.includes('street_number')) {
              streetAddress = item.long_name;
            } else if (item.types.includes('route')) {
              streetAddress = `${streetAddress} ${item.long_name}`;
            } else if (item.types.includes('locality')) {
              city = item.long_name;
            } else if (item.types.includes('postal_code')) {
              postCode = item.long_name;
            } else if (item.types.includes('country')) {
              country = item.long_name;
            }
          });
          let regionFields = results[0].address_components.filter(
            x => x.types.includes('postal_town') || x.types.includes('administrative_area_level_1')
          );
          if (regionFields.length > 0) {
            region = regionFields[0].long_name;
          }
          setForm({
            ...form,
            InstallationStreetAddress: streetAddress,
            InstallationCity: city,
            InstallationRegion: region,
            InstallationPostCode: postCode,
            InstallationCountry: country,
            InstallationCountryCode: { value: countryList().getValue(country), label: country }
          });
        } else {
          console.log('No results found');
        }
      } else {
        console.log('Geocoder failed due to: ' + status);
      }
    });
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (!validateInput()) return;

    onSave({
      UnitId: unitManager.selectedUnit.UnitId,
      Name: form.Name,
      CommissionGpsLatitude: form.CommissionGpsLatitude,
      CommissionGpsLongitude: form.CommissionGpsLongitude,
      WarrantyStartDate: (form.WarrantyStartDate && form.WarrantyStartDate.toString()) || null,
      WarrantyEndDate: (form.WarrantyEndDate && form.WarrantyEndDate.toString()) || null,
      InstallationStreetAddress: form.InstallationStreetAddress,
      InstallationCity: form.InstallationCity,
      InstallationRegion: form.InstallationRegion,
      InstallationPostCode: form.InstallationPostCode,
      InstallationCountry: form.InstallationCountry,
      UnitManagerId: form.UnitManagerId,
      UnitTechnicianId: form.UnitTechnicianId,
      RegionId: form.RegionId,
      Description: form.Description,
      ManufactureId: form.ManufactureId,
      ManufactureDate: form.ManufactureDate,
      InstallationStatus: form.InstallationStatus,
      InstallationDate: form.InstallationDate,
      InstallationEngineer: form.InstallationEngineer,
      CommissionStatus: form.CommissionStatus,
      CommissionDate: form.CommissionDate,
      CommissionPowerOnTest: form.CommissionPowerOnTest,
      CommissionWaterSample: form.CommissionWaterSample,
      CommissionCallHome: form.CommissionCallHome
    });
  };

  const validateInput = () => {
    const nameError = isRequired(form.Name, 1);

    let warranty = form.WarrantyEndDate < form.WarrantyStartDate;
    if ((form.WarrantyEndDate && !form.WarrantyStartDate) || (!form.WarrantyEndDate && form.WarrantyStartDate)) {
      warranty = true;
    }

    let installationDate = false;
    if (form.ManufactureDate && form.InstallationDate && form.ManufactureDate > form.InstallationDate) {
      installationDate = true;
    }

    let commissionDate = false;
    if (
      (form.ManufactureDate && form.CommissionDate && form.ManufactureDate > form.CommissionDate) ||
      (form.InstallationDate && form.CommissionDate && form.InstallationDate > form.CommissionDate)
    ) {
      commissionDate = true;
    }

    setFormErrors({
      Name: nameError,
      InstallationDate: installationDate,
      CommissionDate: commissionDate,
      Warranty: warranty
    });

    if (nameError || installationDate || commissionDate || warranty) return false;
    return true;
  };

  const getFormattedate = date => {
    if (!date) return '';

    return moment.unix(date).tz(currentTimezone);
  };

  const getDateInDDMMYYYFormat = date => {
    if (!date) return '';
    return moment
      .unix(date)
      .tz(currentTimezone)
      .format('DD-MMM-YYYY');
  };

  let messageId = (unitManager && unitManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (unitManager.detailsSubmitted && form.isEdit) {
    setForm({ isEdit: false });

    setTimeout(() => {
      let selectedProfileid = Cookies.get(`selectedprofileid-${envName}`) || 0;
      let userName = Cookies.get(`username-${envName}`) || '';
      getSelectedUserProfileWidgets(selectedProfileid, userName);
    }, 5000);
  }

  if (unitManager.showBanner && form.isEdit && unitManager.isOpSuccessful) {
    setForm({ isEdit: false });
  }

  return (
    <div className={`${s.innerComponent} mt-2`} id="unit-model-section">
      {unitManager.isLoading && <LoadingSpinner />}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={unitManager.showBanner}
        status={unitManager.isOpSuccessful || unitManager.detailsSubmitted}
        successText={messageText}
      />
      {!form.isEdit && (
        <div className={s.unitModel}>
          {IsWidgetAccessible(userProfileWidgets, 'EDIT_UNIT_INFO') && (
            <Row>
              <Col>
                <Button
                  variant="outline-secondary"
                  className={s.buttonContainer}
                  onClick={() => {
                    handleEditOrCancelClick(true);
                  }}
                >
                  <FormattedMessage id="profileSettings.edit" defaultMessage="Edit" />
                </Button>
              </Col>
            </Row>
          )}

          <Row className={`"mt-2" ${s.marginTop25} `}>
            <Col>
              <h4 className={s.section}>
                <FormattedMessage id="unit.unitDetails" defaultMessage="Unit Details" />
              </h4>
              <hr></hr>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.unitName" defaultMessage="Unit Name" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.Name}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.unitSerialNumber" defaultMessage="Unit Serial Number" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.UnitSerialNumber}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.deviceSerialNumber" defaultMessage="Device Serial Number" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.DeviceSerialNumber}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="modelVersion.modelName" defaultMessage="Model" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.ModelName}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="modelVersion.version" defaultMessage="Version" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.ModelVersionName}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.description" defaultMessage="Description" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.Description || ''}</Col>
          </Row>

          <Row className={`"mt-2" ${s.marginTop25} `}>
            <Col>
              <h4 className={s.section}>
                <FormattedMessage id="unit.manufacture" defaultMessage="Manufacture Details" />
              </h4>
              <hr></hr>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.manufactureid" defaultMessage="Manufacture Id" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.ManufactureId || '-'}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.manufactureDate" defaultMessage="Manufacture Date" />
              </span>
            </Col>
            <Col lg={9}>{getDateInDDMMYYYFormat(unitManager.selectedUnit.ManufactureDate) || '-'}</Col>
          </Row>

          <Row className={`"mt-2" ${s.marginTop25} `}>
            <Col>
              <h4 className={s.section}>
                <FormattedMessage id="unit.installation" defaultMessage="Installation Details" />
              </h4>
              <hr></hr>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.installationStatus" defaultMessage="Installation Completed" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.InstallationStatus ? lblYes : lblNo}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.installationDate" defaultMessage="Installation Date" />
              </span>
            </Col>
            <Col lg={9}>{getDateInDDMMYYYFormat(unitManager.selectedUnit.InstallationDate) || '-'}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.installationEngineer" defaultMessage="Engineer" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.InstallationEngineerName || '-'}</Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.installationAddress" defaultMessage="Address" />
              </span>
            </Col>
            <Col
              lg={9}
            >{`${unitManager.selectedUnit.InstallationStreetAddress} ${unitManager.selectedUnit.InstallationCity} ${unitManager.selectedUnit.InstallationRegion}
            ${unitManager.selectedUnit.InstallationPostCode} ${unitManager.selectedUnit.InstallationCountry}`}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.region" defaultMessage="Region/Site" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.RegionName || '-'} </Col>
          </Row>

          <Row className={`"mt-2" ${s.marginTop25} `}>
            <Col>
              <h4 className={s.section}>
                <FormattedMessage id="unit.commission" defaultMessage="Commission Details" />
              </h4>
              <hr></hr>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.commissionStatus" defaultMessage="Commission Completed" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.CommissionStatus ? lblYes : lblNo} </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.commissionDate" defaultMessage="Commission Date" />
              </span>
            </Col>
            <Col lg={9}>{getDateInDDMMYYYFormat(unitManager.selectedUnit.CommissionDate) || '-'} </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.commissionPowerOnTest" defaultMessage="Power On Test" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.CommissionPowerOnTest ? lblYes : lblNo} </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.commissionWaterSample" defaultMessage="Water Sample" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.CommissionWaterSample || '-'} </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.commissionCallHome" defaultMessage="Call Home" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.CommissionCallHome || '-'} </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.unitSupervisingManager" defaultMessage="Unit Supervising Manager" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.UnitManagerName || '-'} </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.unitSupervisingTechnician" defaultMessage="Unit Supervising Technician" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.UnitTechnicianName || '-'} </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.warrantyStartDate" defaultMessage="Warranty start date" />
              </span>
            </Col>
            <Col lg={9}>{getDateInDDMMYYYFormat(unitManager.selectedUnit.WarrantyStartDate) || '-'}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.warrantyEndDate" defaultMessage="Warranty end date" />
              </span>
            </Col>
            <Col lg={9}>{getDateInDDMMYYYFormat(unitManager.selectedUnit.WarrantyEndDate) || '-'}</Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.latitude" defaultMessage="Latitude" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.CommissionGpsLatitude}</Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.longitude" defaultMessage="Longitude" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.CommissionGpsLongitude}</Col>
          </Row>

          <Row className={`"mt-2" ${s.marginTop25} `}>
            <Col>
              <h4 className={s.section}>
                <FormattedMessage id="unit.unitVersion" defaultMessage="Unit Version" />
              </h4>
              <hr></hr>
            </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.controlSystemVersion" defaultMessage="Control System Version" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.ControlSystemVersion || '-'} </Col>
          </Row>
          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.globalSettingsVersion" defaultMessage="Global Settings Version" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.GlobalSettingsVersion || '-'} </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.thresholdVersion" defaultMessage="Threshold Version" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.ThresholdVersion || '-'} </Col>
          </Row>

          <Row className="mt-2">
            <Col lg={3}>
              <span>
                <FormattedMessage id="unit.periodicSummarySettingsVersion" defaultMessage="Periodic Summary Settings Version" />
              </span>
            </Col>
            <Col lg={9}>{unitManager.selectedUnit.PeriodicSummarySettingsVersion || '-'} </Col>
          </Row>
        </div>
      )}
      {form.isEdit && (
        <div className={s.unitModel}>
          <Form>
            <Row>
              <Col>
                <div className={s.buttonContainer}>
                  <Button type="submit" disabled={unitManager.isLoading} onClick={handleSubmit} className={s.saveButton}>
                    <FormattedMessage id="unit.save" defaultMessage="Save" />
                  </Button>
                  <Button
                    variant="outline-secondary"
                    onClick={() => {
                      handleEditOrCancelClick(false);
                    }}
                  >
                    <FormattedMessage id="unit.cancel" defaultMessage="Cancel" />
                  </Button>
                </div>
              </Col>
            </Row>

            <Row className={`"mt-2" ${s.marginTop25} `}>
              <Col>
                <h4 className={s.section}>
                  <FormattedMessage id="unit.unitDetails" defaultMessage="Unit Details" />
                </h4>
                <hr></hr>
              </Col>
            </Row>

            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.unitSerialNumber" defaultMessage="Unit Serial Number" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="unitSerialNumber"
                  disabled={true}
                  defaultValue={unitManager.selectedUnit.UnitSerialNumber}
                />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.deviceSerialNumber" defaultMessage="Device Serial Number" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="deviceSerialNumber"
                  disabled={true}
                  defaultValue={unitManager.selectedUnit.DeviceSerialNumber}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="modelVersion.modelName" defaultMessage="Model" />
                </Form.Label>
                <Form.Control type="text" name="model" defaultValue={unitManager.selectedUnit.ModelName} disabled={true} />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="modelVersion.version" defaultMessage="Version" />
                </Form.Label>
                <Form.Control type="text" name="version" disabled={true} defaultValue={unitManager.selectedUnit.ModelVersionName} />
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={6}>
                <Form.Label>
                  <FormattedMessage id="unit.unitName" defaultMessage="Unit Name" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="Name"
                  value={form.Name}
                  onChange={handleInput}
                  className={`${formErrors.Name ? s.formControlError : ''}`}
                />

                {formErrors.Name && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.unitNameMandatory" defaultMessage="Unit name is mandatory field" />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={6}>
                <Form.Label>
                  <FormattedMessage id="unit.description" defaultMessage="Desciption" />
                </Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  name="Description"
                  value={form.Description || ''}
                  onChange={handleInput}
                  placeholder={intl.formatMessage({
                    id: 'unit.enterDescription',
                    defaultMessage: 'Enter description'
                  })}
                />
              </Col>
            </Row>

            <Row className={`"mt-2" ${s.marginTop25} `}>
              <Col>
                <h4 className={s.section}>
                  <FormattedMessage id="unit.manufacture" defaultMessage="Manufacture Details" />
                </h4>
                <hr></hr>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.manufactureid" defaultMessage="Manufacture Id" />
                </Form.Label>

                <InputNumber
                  name="ManufactureId"
                  step="1"
                  onInputChange={handleInput}
                  customClassName={s.formControl}
                  value={form.ManufactureId}
                  id="ManufactureId"
                  placeholder={intl.formatMessage({
                    id: 'unit.manufactureid',
                    defaultMessage: 'Manufacture Id'
                  })}
                />
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.manufactureDate" defaultMessage="Manufacture Date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.manufactureDate ? s.formControlError : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'ManufactureDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(form.ManufactureDate)}
                  defaultValue={getFormattedate(form.ManufactureDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
              </Col>
            </Row>

            <Row className={`"mt-2" ${s.marginTop25} `}>
              <Col>
                <h4 className={s.section}>
                  <FormattedMessage id="unit.installation" defaultMessage="Installation Details" />
                </h4>
                <hr></hr>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>{''}</Form.Label>
                <Form.Group controlId="formActiveModel">
                  <Checkbox
                    key="InstallationStatus"
                    dataArray={[
                      {
                        SKEY: 'InstallationStatus',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'unit.installationStatus',
                          defaultMessage: 'Installation Completed'
                        }),
                        isChecked: form.InstallationStatus
                      }
                    ]}
                    onSelectionChange={handleInput}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationDate" defaultMessage="Installation Date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.InstallationDate ? s.formControlError : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'InstallationDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(form.InstallationDate)}
                  defaultValue={getFormattedate(form.InstallationDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.InstallationDate && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage
                      id="unit.invalidInstallationDateError"
                      defaultMessage="Installation date should be grater than Manufacturing Date "
                    />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.installationEngineer" defaultMessage="Engineer" />
                </Form.Label>
                <Dropdown
                  id="drpUnitManagerId"
                  dataArray={userList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onGenericDropdownChange(e, 'InstallationEngineer')}
                  selectedOption={userList().filter(option => option.value == form.InstallationEngineer)}
                  disabled={false}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={6} lg={3}>
                <h4>
                  {' '}
                  <FormattedMessage id="unit.installationAddress" defaultMessage="Address" />
                </h4>
              </Col>
              <Col xs={6} lg={3}>
                <Button type="submit" disabled={unitManager.isLoading} onClick={handleAddressChange} className={s.updateButton}>
                  <FormattedMessage id="unit.addAddressFromGps" defaultMessage="Add Address From GPS" />
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.installationStreetAddress" defaultMessage="Street Address" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="InstallationStreetAddress"
                  value={form.InstallationStreetAddress}
                  onChange={handleInput}
                  className={``}
                />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.installationSuburbLocation" defaultMessage="Suburb/Location" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="InstallationRegion"
                  onChange={handleInput}
                  value={form.InstallationRegion}
                  className={` `}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.installationCity" defaultMessage="City" />
                </Form.Label>
                <Form.Control type="text" name="InstallationCity" value={form.InstallationCity} onChange={handleInput} className={``} />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.installationPostCode" defaultMessage="Post code" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="InstallationPostCode"
                  value={form.InstallationPostCode}
                  onChange={handleInput}
                  className={``}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.region" defaultMessage="Region/Site" />
                </Form.Label>
                <Dropdown
                  id="drpRegionId"
                  dataArray={regionList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onRegionChange(e)}
                  selectedOption={regionList().filter(option => option.value === form.RegionId)}
                  disabled={false}
                />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.installationCountry" defaultMessage="Country" />
                </Form.Label>
                <Select
                  isSearchable
                  name="InstallationCountry"
                  label="InstallationCountry"
                  placeholder="Select Country"
                  className={`${s.wsaDropdown}`}
                  options={countries}
                  onChange={onCountryChange}
                  value={form.InstallationCountryCode}
                  output={country => country && country.value}
                />
              </Col>
            </Row>

            <Row className={`"mt-2" ${s.marginTop25} `}>
              <Col>
                <h4 className={s.section}>
                  <FormattedMessage id="unit.commission" defaultMessage="Commission Details" />
                </h4>
                <hr></hr>
              </Col>
            </Row>

            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>{''}</Form.Label>
                <Form.Group controlId="formActiveModel">
                  <Checkbox
                    key="CommissionStatus"
                    dataArray={[
                      {
                        SKEY: 'CommissionStatus',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'unit.commissionStatus',
                          defaultMessage: 'Commission Completed'
                        }),
                        isChecked: form.CommissionStatus
                      }
                    ]}
                    onSelectionChange={handleInput}
                  />
                </Form.Group>
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.commissionDate" defaultMessage="Commission Date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.CommissionDate ? s.formControlError : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'CommissionDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(form.CommissionDate)}
                  defaultValue={getFormattedate(form.CommissionDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.CommissionDate && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage
                      id="unit.invalidCommissionDateError"
                      defaultMessage="Commission date cannot be before the installation date and Manufacture Date"
                    />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>{''}</Form.Label>
                <Form.Group controlId="formCommissionPowerOnTest">
                  <Checkbox
                    key="CommissionPowerOnTest"
                    dataArray={[
                      {
                        SKEY: 'CommissionPowerOnTest',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'unit.commissionPowerOnTest',
                          defaultMessage: 'Power On Test'
                        }),
                        isChecked: form.CommissionPowerOnTest
                      }
                    ]}
                    onSelectionChange={handleInput}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={12} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.commissionWaterSample" defaultMessage="Water Sample" />
                </Form.Label>

                <InputNumber
                  name="CommissionWaterSample"
                  step=".1"
                  onInputChange={handleInput}
                  customClassName={s.formControl}
                  value={form.CommissionWaterSample}
                  id="CommissionWaterSample"
                  placeholder={intl.formatMessage({
                    id: 'unit.commissionWaterSample',
                    defaultMessage: 'Water Sample'
                  })}
                />

                {/* <Form.Control
                    type="text"
                    name="CommissionWaterSample"
                    value={form.CommissionWaterSample}
                    onChange={handleInput}
                    className={`${formErrors.Name ? s.formControlError : ''}`}
                  /> */}
              </Col>
              <Col xs={6} lg={4}>
                <Form.Label>
                  <FormattedMessage id="unit.commissionCallHome" defaultMessage="Call Home" />
                </Form.Label>
                <Form.Control type="text" onChange={handleInput} name="CommissionCallHome" defaultValue={form.CommissionCallHome} />
              </Col>
            </Row>

            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.unitSupervisingManager" defaultMessage="Unit Supervising Manager" />
                </Form.Label>
                <Dropdown
                  id="drpUnitManagerId"
                  dataArray={userList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onUnitManagerChange(e)}
                  selectedOption={userList().filter(option => option.value === form.UnitManagerId)}
                  disabled={false}
                />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.unitSupervisingTechnician" defaultMessage="Unit Supervising Technician" />
                </Form.Label>
                <Dropdown
                  id="drpUnitTechnicianId"
                  dataArray={userList()}
                  controlData={{
                    placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
                    customClassName: ''
                  }}
                  onDropdownChange={e => onUnitTechnicianChange(e)}
                  selectedOption={userList().filter(option => option.value === form.UnitTechnicianId)}
                  disabled={false}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.warrantyStartDate" defaultMessage="Warranty start date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.Warranty ? s.formControlError : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'WarrantyStartDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(form.WarrantyStartDate)}
                  defaultValue={getFormattedate(form.WarrantyStartDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.warrantyEndDate" defaultMessage="Warranty end date" />
                </Form.Label>
                <DateTimePicker
                  className={`${formErrors.Warranty ? s.formControlError : s.frmCalendar}`}
                  onChange={m => {
                    handleInput({ target: { name: 'WarrantyEndDate', value: (m && m.unix && m.unix()) || null } });
                  }}
                  timeFormat={null}
                  dateFormat="DD-MMM-YYYY"
                  value={getFormattedate(form.WarrantyEndDate)}
                  defaultValue={getFormattedate(form.WarrantyEndDate)}
                  closeOnSelect={true}
                  showClear={false}
                />
                {formErrors.Warranty && (
                  <p role="alert" className={s.error}>
                    <FormattedMessage id="unit.warrantyError" defaultMessage="Warrany end date must be grater than start date" />
                  </p>
                )}
              </Col>
            </Row>
            <Row>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.latitude" defaultMessage="Latitude" />
                </Form.Label>
                <InputNumber
                  name="CommissionGpsLatitude"
                  step="1"
                  onInputChange={handleInput}
                  customClassName={s.formControl}
                  value={form.CommissionGpsLatitude}
                  id="CommissionGpsLatitude"
                  placeholder={intl.formatMessage({
                    id: 'unit.latitude',
                    defaultMessage: 'Latitude'
                  })}
                />
              </Col>
              <Col xs={6} lg={3}>
                <Form.Label>
                  <FormattedMessage id="unit.longitude" defaultMessage="Longitude" />
                </Form.Label>
                <InputNumber
                  name="CommissionGpsLongitude"
                  step="1"
                  onInputChange={handleInput}
                  customClassName={s.formControl}
                  value={form.CommissionGpsLongitude}
                  id="CommissionGpsLongitude"
                  placeholder={intl.formatMessage({
                    id: 'unit.longitude',
                    defaultMessage: 'Longitude'
                  })}
                />
              </Col>
            </Row>
          </Form>
        </div>
      )}
    </div>
  );
};

UnitModel.defaultProps = {
  unitManager: {
    ...initialUnitManagerState.unitManager
  }
};

UnitModel.propTypes = {
  unitManager: PropTypes.object.isRequired
};

export default UnitModel;
