import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';


import {
  EVENT_ACTION_SET,
  EVENT_ACTION_ERROR,
  EVENT_ACTION_IS_LOADING,
  EVENT_ACTIVITY_SAVE_ERROR,
  EVENT_ACTIVITY_SAVE,
  EVENT_ACTIVITY_IS_SAVING,
  EVENT_ACTIVITY_NEW_SET,
  ALARM_STATUS_NEW,
  ALARM_STATUS_PENDING,
  ALARM_STATUS_RESOLVED,
  EVENT_ACTION_SET_FOR_UNIT,
  EVENT_ACTION_ACTIVITY_TYPE_ASSIGN,
  EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS,
  EVENT_ACTION_ACTIVITY_TYPE_LINKALARM,
  EVENT_ACTION_ACTIVITY_TYPE_UNLINKALARM,
  EVENT_ACTION_UPDATE_CHART,
  EVENT_ACTION_UPDATE_CHART_FILTERS,
  EVENT_ACTION_CHART_IS_LOADING,
  EVENT_ACTION_UPDATE_METRIC_DATA,
  EVENT_ACTION_CHART_ERROR,
  EVENT_ACTION_CLEANUP,
  EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD,
  EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD_SUCCESS
} from '../constants/index';

import { apiAction, logEntry } from '../utils/index';
import moment from 'moment';
import { EVENT_ACTION_MANAGEMENT, EVENT_ACTION_ACTIVITY_UPDATE } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const fetchEventActionThunk = (eventActionId, widgetCode) => (dispatch, getState) => {
  const { unitSerialNumber } = getState().devices;
  const reqData = {
    EventActionId: eventActionId,
    UnitSerialNumber: unitSerialNumber,
    OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0
  };
  dispatch(fetchEventAction(reqData, widgetCode));
};

export const fetchEventAction = (data, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${data.UnitSerialNumber}/events/eventAction/describe?unitSerialNumber=${data.UnitSerialNumber}`,
    method: 'GET',
    onSuccess: resp => setEventAction(resp),
    onFailure: error => setEventActionErrorThunk(error),
    onLoad: e => setEventActionIsLoading(e),
    accessToken: Cookies.get(`access-${envName}`),
    data,
    widgetcode: widgetCode
  });

export const setEventActionErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setEventActionError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEventActionError(messageCode, false));
  }, 3500);
};

export const setEventActionError = (messageCode, status) => ({
  type: EVENT_ACTION_ERROR,
  payload: {
    eventAction: {
      isOpSuccessfull: false,
      showBanner: status,
      isLoading: false,
      messageCode: messageCode || ''
    }
  }
});

export const setEventAction = data => ({
  type: EVENT_ACTION_SET,
  payload: { ...data, isLoading: false }
});

export const setEventActionIsLoading = data => ({
  type: EVENT_ACTION_IS_LOADING,
  payload: { isLoading: data }
});

export const postEventActivitiyThunk = widgetCode => (dispatch, getState) => {
  const eventObj = getState().eventAction;
  const { unitSerialNumber } = getState().devices;

  let activities = [];

  let NewActivity = eventObj.eventAction.NewActivity;
  let EventAction = eventObj.eventAction.EventAction;
  let userId = Cookies.get(`userid-${envName}`) || 1;

  NewActivity.EventActionId = eventObj.eventAction.EventAction.EventActionId;
  NewActivity.GuidIdentifier = uuid();
  NewActivity.IsActive = true;
  NewActivity.CreatedByUserId = userId;

  activities.push(NewActivity);

  if (
    EventAction.EventStatus === ALARM_STATUS_NEW &&
    NewActivity.ActivityType !== EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS.statusType &&
    NewActivity.ActivityType !== EVENT_ACTION_ACTIVITY_TYPE_LINKALARM.statusType &&
    NewActivity.ActivityType !== EVENT_ACTION_ACTIVITY_TYPE_UNLINKALARM.statusType
  ) {
    let activity = {};

    activity.EventActionId = eventObj.eventAction.EventAction.EventActionId;
    activity.GuidIdentifier = uuid();
    activity.IsActive = true;
    activity.ActivityType = EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS.statusType;
    activity.EventStatus = ALARM_STATUS_PENDING;
    activity.CreatedByUserId = userId;

    EventAction.EventStatus = ALARM_STATUS_PENDING;
    EventAction.ModifiedByUserId = userId;

    activities.push(activity);
  }

  if (NewActivity.ActivityType === EVENT_ACTION_ACTIVITY_TYPE_UPDATED_STATUS.statusType) {
    EventAction.EventStatus = NewActivity.EventStatus;
    EventAction.ModifiedByUserId = userId;

    if (NewActivity.EventStatus === ALARM_STATUS_RESOLVED) {
      EventAction.ResolvedDateTime = moment().unix();
    }
  } else if (NewActivity.ActivityType === EVENT_ACTION_ACTIVITY_TYPE_ASSIGN.statusType) {
    EventAction.EventActionAssignedToUserId = NewActivity.AssignedUserId;
    EventAction.ModifiedByUserId = userId;

    if (activities.length > 0) {
      EventAction.EventStatus = ALARM_STATUS_PENDING;
    }
  }

  EventAction.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 0;

  let log = logEntry(EVENT_ACTION_MANAGEMENT, EVENT_ACTION_ACTIVITY_UPDATE, {
    eventAction: { eventAction: EventAction, eventActivities: activities }
  });
  dispatch(
    postEventActivitiy(
      { UnitSerialNumber: unitSerialNumber, eventAction: { eventAction: EventAction, eventActivities: activities }, log: log },
      widgetCode
    )
  );
};

export const postEventActivitiy = (data, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${data.UnitSerialNumber}/events/eventAction/eventActivity/save?unitSerialNumber=${data.UnitSerialNumber}`,
    method: 'POST',
    onSuccess: resp => setEventActivitiy(resp),
    onFailure: error => setEventActivitiyErrorThunk(error),
    onLoad: e => setEventActivitiyIsLoading(e),
    accessToken: Cookies.get(`access-${envName}`),
    data,
    widgetcode: widgetCode
  });

export const setEventActivitiyErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setEventActivitiyError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEventActivitiyError(messageCode, false));
  }, 3500);
};

export const setEventActivitiyError = (messageCode, status) => ({
  type: EVENT_ACTIVITY_SAVE_ERROR,
  payload: {
    eventAction: {
      isOpSuccessfull: false,
      showBanner: status,
      isLoading: false,
      messageCode: messageCode || ''
    }
  }
});

export const setEventActivitiy = data => ({
  type: EVENT_ACTIVITY_SAVE,
  payload: { ...data, isLoading: false }
});

export const setEventActivitiyIsLoading = data => ({
  type: EVENT_ACTIVITY_IS_SAVING,
  payload: { isLoading: data }
});

export const onActivityChangeThunk = newActivity => (dispatch, getState) => {
  let { NewActivity } = getState().eventAction;
  NewActivity = newActivity;

  dispatch(setActivityChange(NewActivity));
};

export const setActivityChange = NewActivity => ({
  type: EVENT_ACTIVITY_NEW_SET,
  payload: { NewActivity }
});

export const fetchUnitEventActionThunk = (unitId, status, widgetCode) => dispatch => {
  const reqData = {
    UnitId: unitId || 0,
    Status: status,
    OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0
  };
  dispatch(fetchUnitEventAction(reqData, widgetCode));
};

export const fetchUnitEventAction = (data, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/events/eventAction`,
    method: 'GET',
    onSuccess: resp => setUnitEventAction(resp),
    onFailure: error => setEventActionErrorThunk(error),
    onLoad: e => setEventActionIsLoading(e),
    accessToken: Cookies.get(`access-${envName}`),
    data,
    widgetcode: widgetCode
  });

export const setUnitEventAction = data => ({
  type: EVENT_ACTION_SET_FOR_UNIT,
  payload: { ...data, isLoading: false }
});

export const updateChartValues = (field, value) => ({
  type: EVENT_ACTION_UPDATE_CHART,
  payload: { field, value }
});

export const updateChartFilterValues = data => ({
  type: EVENT_ACTION_UPDATE_CHART_FILTERS,
  payload: { data }
});

export const fetchMetricData = (unitSerialNumber, from, to, sensor, limit, resultType, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/telemetry?metric=${sensor}`,
    method: 'GET',
    onSuccess: updateChartMetric,
    onFailure: err => chartError(err),
    accessToken: Cookies.get(`access-${envName}`),
    onLoad: e => setIsLoading(e),
    data: {
      from,
      to,
      limit,
      resultType: resultType
    },
    widgetcode: widgetCode
  });

export const setIsLoading = status => ({
  type: EVENT_ACTION_CHART_IS_LOADING,
  payload: { status }
});

export const updateChartMetric = data => ({
  type: EVENT_ACTION_UPDATE_METRIC_DATA,
  payload: { data }
});

export const chartError = err => ({
  type: EVENT_ACTION_CHART_ERROR,
  payload: { err }
});

export const eventActionCleanUpThunk = eventAction => dispatch => {
  dispatch(eventActionCleanUp(eventAction));
};

export const eventActionCleanUp = eventAction => ({
  type: EVENT_ACTION_CLEANUP,
  payload: { eventAction }
});

export const addTelemetryDownload = (downloadUrl, EventActionId, UnitSerialNumber) => dispatch => {
  dispatch(addTelemetryDownloadLink(downloadUrl));

  let saveData = {
    eventAction: {
      EventActionId: EventActionId
    },
    UnitSerialNumber: UnitSerialNumber,
    eventTelemetryDownloads: [downloadUrl]
  };
  dispatch(saveTelemetryDownloadLink(saveData, 'ALARMMANAGE_TELEMETRY'));
};

export const addTelemetryDownloadLink = downloadUrl => ({
  type: EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD,
  payload: downloadUrl
});

export const saveTelemetryDownloadLink = (data, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${data.UnitSerialNumber}/events/eventAction/telemetryDownload/save`,
    method: 'POST',
    accessToken: Cookies.get(`access-${envName}`),
    onLoad: e => setEventActionIsLoading(e),
    onSuccess: resp => setEventActionDownloadReportSuccess(resp),
    onFailure: error => setEventActionErrorThunk(error),
    data,
    widgetcode: widgetCode
  });

export const setEventActionDownloadReportSuccess = messageCode => ({
  type: EVENT_ACTION_ADD_TELEMETRY_DOWNLOAD_SUCCESS,
  payload: {
    eventAction: {
      isOpSuccessfull: false,
      showBanner: false,
      isLoading: false,
      messageCode: ''
    }
  }
});
