import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './TermsAndConditions.module.scss';
import { injectIntl, FormattedMessage } from 'react-intl';
import { postUserThunk } from '../../../actions/userManager';
import { setAcceptedLicenseAgreement } from '../../../actions/UserProfileWidgets';
import { logout } from '../../../actions/user';
import { getUserProfileWidget, getUserState } from '../../../selectors/index';
import Button from 'react-bootstrap/Button';
import Cookies from 'js-cookie';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

/* 
 How to update the latest licensing agreements?
  Upload latest licensing agreements in static hosted web site's s3 folder called TermsAndConditions. For example, location for dev 
  will be s3://dev.eleiot.watersourceaustralia.com.au/TermsAndConditions/software_license_agreement.pdf. The file name should be 
  "software_license_agreement.pdf". Upload the file in the source code folder unbder public/TermsAndConditions/software_license_agreement.pdf 
  so that the file will be same even after the next deployment.  Also invalidate the cache in cloudfront in respective environment. 
  Insert new entry in table called LicenseAgreements and make it as an active entry and disable all other entries by updating IsActive=0 in db.
  URL for license agreements will be https://s3.ap-southeast-2.amazonaws.com/dev.eleiot.watersourceaustralia.com.au/TermsAndConditions/software_license_agreement.pdf
*/

const PDFModal = ({ intl, pdfUrl }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => getUserState(state));
  const userProfileWidget = useSelector(state => getUserProfileWidget(state));
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const saveTermsAndConditions = () => {
    const userId = Cookies.get(`userid-${envName}`) || 0;
    dispatch(postUserThunk({ LicenseAgreementId: userProfileWidget.activeLicenseAgreementId, UserId: userId }, 'GENERALACCESS', true));
    dispatch(setAcceptedLicenseAgreement(userProfileWidget.activeLicenseAgreementId));
  };

  const [isOpen, setIsOpen] = useState(true);

  const handleAccept = () => {
    Cookies.set(`termsandconditions-${envName}`, true);
    saveTermsAndConditions();
    setIsOpen(false);
  };

  const handleCancel = () => {
    setIsOpen(false);
    dispatch(logout());
  };

  if (!isOpen) return null;

  if (!userProfileWidget || !userProfileWidget.activeLicenseAgreementId) return null;

  userProfileWidget.acceptedLicenseAgreementId = userProfileWidget.acceptedLicenseAgreementId
    ? userProfileWidget.acceptedLicenseAgreementId
    : null;
  if (
    userProfileWidget.activeLicenseAgreementId &&
    userProfileWidget.activeLicenseAgreementId == userProfileWidget.acceptedLicenseAgreementId
  )
    return null;

  pdfUrl = `${process.env.PUBLIC_URL}/TermsAndConditions/software_license_agreement.pdf?#toolbar=0`;
  return (
    <div className={s.termsAndConditionWrapper}>
      <div className={s.contentWrapper}>
        <div className={s.content}>
          <object data={pdfUrl} type="application/pdf" className={s.embedObject} title="PDF Viewer">
            <p>
              <FormattedMessage id="termsAndConditions.pdfSupport" defaultMessage="Your browser does not support PDFs" />
              &nbsp;
              <a href={pdfUrl} target="_blank" rel="noopener noreferrer">
                <FormattedMessage id="termsAndConditions.downloadPdf" defaultMessage="Download the PDF" />
              </a>{' '}
            </p>
          </object>
        </div>
        <Row>
          <Col>
            <div className={s.agreeMessage}>
              <FormattedMessage
                id="termsAndConditions.agree"
                defaultMessage="By clicking 'Accept' you agree to these software license terms and conditions"
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={s.buttonHolder}>
              <Button onClick={handleAccept} className={s.button}>
                <FormattedMessage id="common.accept" defaultMessage="Accept" />
              </Button>
              <Button onClick={handleCancel} className={s.declineBtn}>
                <FormattedMessage id="common.decline" defaultMessage="Decline" />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default injectIntl(PDFModal);
