import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import s from './HelpControlsTabs.module.scss';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../../utils/widgetManager';

const Tab = ({ selectedPage, pageComponent, url, title }) => (
  <Fragment>
    <Link className={classNames({ [s.active]: selectedPage === pageComponent })} to={url}>
      {title}
      {selectedPage === pageComponent && <span />}
    </Link>
  </Fragment>
);

const HelpControlsTabs = ({ selectedPage, userProfileWidgets }) => (
  <ul className={s.tabNav}>
    <li>
      <Tab url={`/help`} title={<FormattedMessage id="help.faq" defaultMessage="FAQ" />} selectedPage={selectedPage} pageComponent="faq" />
    </li>
    {IsWidgetAccessible(userProfileWidgets, 'NOTIFICATIONS_HISTORY_TAB') && (
      <li>
        <Tab
          url={`/help/notifications`}
          title={<FormattedMessage id="help.notifications" defaultMessage="Notifications" />}
          selectedPage={selectedPage}
          pageComponent="notifications"
        />
      </li>
    )}
    <li>
      <Tab
        url={`/terms-and-conditions`}
        title={<FormattedMessage id="help.termsAndConditions" defaultMessage="Terms and conditions" />}
        selectedPage={selectedPage}
        pageComponent="tac"
      />
    </li>
    <li>
      <Tab
        url={`/build-version`}
        title={<FormattedMessage id="help.buildVersion" defaultMessage="Build Version" />}
        selectedPage={selectedPage}
        pageComponent="version"
      />
    </li>
  </ul>
);

HelpControlsTabs.propTypes = {
  selectedPage: PropTypes.string.isRequired
};

export default HelpControlsTabs;
