import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './PeriodicSummarySettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';

const PeriodicSummarySettings = ({
  PeriodicSummarySettingId,
  ModelVersionId,
  savePeriodicSummarySetting,
  periodicSummarySetting,
  changePeriodicSummarySetting,
  cancelHandler,
  setEditHandler,
  intl
}) => {
  const regexDefaultValue = /^[a-zA-Z0-9  .]+$/;
  const regexCode = /^[a-zA-Z0-9_]+$/;
  let IsEdit = false;
  const [localformErrors, setFormErrors] = useState({});

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  if (PeriodicSummarySettingId) {
    IsEdit = true;
  }

  // Update redux store
  const setLocalPeriodicSummarySetting = currentState => {
    changePeriodicSummarySetting(currentState);
  };

  let widgetCode = IsEdit ? 'PERIODICSUMMARYSETTINGSEDIT' : 'PERIODICSUMMARYSETTINGSADD';

  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = periodicSummarySetting.SelectedPeriodicSummarySetting;
    saveData.MarkAsDeleted = false;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.PeriodicSummarySettingId = 0;
      saveData.ModelVersionId = ModelVersionId;
      saveData.GuidIdentifier = uuidv4();
    }

    savePeriodicSummarySetting(saveData, widgetCode, false);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;
    if (
      !periodicSummarySetting ||
      !periodicSummarySetting.SelectedPeriodicSummarySetting ||
      isRequired(periodicSummarySetting.SelectedPeriodicSummarySetting.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'periodicSummarySettings.nameRequired',
        defaultMessage: 'Name is required'
      });
      isValid = false;
    }
    if (
      !periodicSummarySetting ||
      !periodicSummarySetting.SelectedPeriodicSummarySetting ||
      isRequired(periodicSummarySetting.SelectedPeriodicSummarySetting.Code, 1)
    ) {
      formErrors.Code = intl.formatMessage({
        id: 'periodicSummarySettings.codeRequired',
        defaultMessage: 'Code is required'
      });
      isValid = false;
    }

    if (
      !periodicSummarySetting ||
      !periodicSummarySetting.SelectedPeriodicSummarySetting ||
      isRequired(periodicSummarySetting.SelectedPeriodicSummarySetting.Expression, 1)
    ) {
      formErrors.Expression = intl.formatMessage({
        id: 'periodicSummarySettings.expressionRequired',
        defaultMessage: 'Expression value is required'
      });
      isValid = false;
    }

    setFormErrors(formErrors);
    return isValid;
  };

  //clean up
  useEffect(() => {
    return () => {
      setEditHandler(-1);
    };
  }, []);

  //on control value change
  const onChange = e => {
    if (e.target.name == 'Code' && e.target.value != '') {
      if (!regexCode.test(e.target.value)) return;
    }

    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalPeriodicSummarySetting({
        ...periodicSummarySetting,
        SelectedPeriodicSummarySetting: {
          ...periodicSummarySetting.SelectedPeriodicSummarySetting,
          [e.target.name]: e.target.checked
        }
      });
    } else {
      setLocalPeriodicSummarySetting({
        ...periodicSummarySetting,
        SelectedPeriodicSummarySetting: {
          ...periodicSummarySetting.SelectedPeriodicSummarySetting,
          [e.target.name]: e.target.value
        }
      });
    }
  };

  return (
    <div className={s.periodicSummarySettings}>
      <div className={s.periodicSummarySettingsContent}>
        <Form>
          <div className={s.topRow}>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="formPeriodicSummarySettingName">
                  <Form.Label>
                    <FormattedMessage id="periodicSummarySettings.name" defaultMessage="Name" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="Name"
                    onChange={onChange}
                    placeholder={intl.formatMessage({
                      id: 'periodicSummarySettings.name',
                      defaultMessage: 'Name'
                    })}
                    value={periodicSummarySetting.SelectedPeriodicSummarySetting.Name}
                    className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Name && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Name}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="formGlobalSettingsCode">
                  <Form.Label>
                    <FormattedMessage id="globalSettingsManagement.code" defaultMessage="Code" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="Code"
                    onChange={onChange}
                    value={periodicSummarySetting.SelectedPeriodicSummarySetting.Code}
                    className={`${s.formControl} ${localformErrors && localformErrors.Code ? s.formControlError : ''}`}
                  />
                  {localformErrors && localformErrors.Code && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Code}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col lg={4}>
                <Form.Group controlId="formGlobalSettingsExpression">
                  <Form.Label>
                    <FormattedMessage id="globalSettingsManagement.expression" defaultMessage="Expression Value" />
                  </Form.Label>

                  <Form.Control
                    type="text"
                    name="Expression"
                    onChange={onChange}
                    value={periodicSummarySetting.SelectedPeriodicSummarySetting.Expression}
                    className={`${s.formControl} ${localformErrors && localformErrors.Expression ? s.formControlError : ''}`}
                    placeholder="eg: {t_yourtelemetrycomponent} * {s_yourglobalsettingkey} "
                  />
                  {localformErrors && localformErrors.Expression && (
                    <p role="alert" className={s.error}>
                      {localformErrors.Expression}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Form.Group controlId="formPeriodicSummarySettingsDescription">
                  <Form.Label>
                    <FormattedMessage id="periodicSummarySettings.description" defaultMessage="Description" />
                  </Form.Label>

                  <Form.Control
                    className={s.txtArea}
                    as="textarea"
                    rows="3"
                    name="Description"
                    onChange={onChange}
                    placeholder={intl.formatMessage({
                      id: 'periodicSummarySettings.description',
                      defaultMessage: 'Description'
                    })}
                    value={periodicSummarySetting.SelectedPeriodicSummarySetting.Description}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={4}>
                <Form.Group controlId="formActiveModel">
                  <Checkbox
                    key={uuidv4()}
                    dataArray={[
                      {
                        SKEY: 'IsActive',
                        target: { type: 'checkbox' },
                        label: intl.formatMessage({
                          id: 'periodicSummarySettings.active',
                          defaultMessage: 'Active'
                        }),
                        isChecked: periodicSummarySetting.SelectedPeriodicSummarySetting.IsActive
                      }
                    ]}
                    onSelectionChange={onChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg={PeriodicSummarySettingId ? 8 : 4} xs={12}>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="periodicSummarySettings.save" defaultMessage="SAVE" />
                </Button>
                <Button
                  variant="outline-secondary"
                  className={classNames(s.btnCancel)}
                  onClick={cancelHandler}
                  noValidate
                  data-unittest="saveCancel"
                >
                  <FormattedMessage id="periodicSummarySettings.cancel" defaultMessage="CANCEL" />
                </Button>
              </Col>
              <Col lg={PeriodicSummarySettingId ? 7 : 5}></Col>
            </Row>
          </div>
        </Form>
      </div>
    </div>
  );
};

PeriodicSummarySettings.propTypes = {
  savePeriodicSummarySetting: PropTypes.func.isRequired,
  changePeriodicSummarySetting: PropTypes.func.isRequired,
  periodicSummarySetting: PropTypes.object.isRequired,
  cancelHandler: PropTypes.func.isRequired
};

export default injectIntl(PeriodicSummarySettings);
