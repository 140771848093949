import React from 'react';
import PropTypes from 'prop-types';
import s from './User.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import { initialUserManagerState, initialUnitManagerState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Navigate, Link } from 'react-router-dom';
import bs from '../../styles/bootstrap-overrides.scss';
import UserProfiles from './UserProfiles';
import UserProfileUnits from './UserProfileUnits';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import { NOTIFICATION_DELIVERY_TYPES } from '../../constants/index';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import equal from 'fast-deep-equal';
import classNames from 'classnames';
import { isValidEmail, isRequired } from '../../utils/';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';

class User extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userManager: {
        ...initialUserManagerState.userManager,
        selectedUser: {
          ...initialUserManagerState.userManager.selectedUser,
          selectedProfiles: []
        },
        profiles: [],
        units: []
      },
      highlightedProfile: {},
      formErrors: {}
    };
    this.IsEdit = false;
    this.Redirect = false;
  }

  envName = process.env.REACT_APP_ENV_NAME_SHORT;

  LoggedInUserId = Cookies.get(`userid-${this.envName}`) || 1;
  OrganisationId = Cookies.get(`selectedorganisationid-${this.envName}`) || 1;

  componentDidMount() {
    this.setupComponent();
  }

  //Initial setup
  setupComponent() {
    this.IsEdit = false;
    this.Redirect = false;
    this.filter = this.props.unitManager.filter || '';
    this.limit = this.props.unitManager.limit || 1000;
    let widgetCode = this.props.match.params.userId ? 'USERUPDATE' : 'USERINSERT';
    this.props.getUnits(this.limit, this.filter, this.OrganisationId, widgetCode);

    //Checking whether it is edit or add new
    if (this.props.match.params.userId) {
      const { userId } = this.props.match.params;

      this.IsEdit = true;
      //redircting to list if selected user is empty and userID is not blank
      if (!userId) {
        this.Redirect = true;
      } else {
        this.props.describeUser(userId, widgetCode);
      }
    } else {
      this.props.describeUser(null, widgetCode);
    }
  }

  componentDidUpdate(prevProps) {
    this.loadPropsToState(prevProps);
  }

  componentWillUnmount() {
    this.props.setSelectedUser({
      ...initialUserManagerState.userManager.selectedUser,
      selectedProfiles: []
    });
  }

  //Saving
  submitForm = e => {
    e.preventDefault();

    if (!this.validateInput()) {
      return;
    }

    let saveData = { ...this.state.userManager.selectedUser };
    saveData.IsEdit = this.IsEdit;
    let widgetCode = 'USERINSERT';
    if (!this.IsEdit) {
      saveData.OrganisationId = this.OrganisationId;
      saveData.GuidIdentifier = uuidv4();
      saveData.CreatedByUserId = this.LoggedInUserId;
    } else {
      saveData.OrganisationId = this.OrganisationId;
      widgetCode = 'USERUPDATE';
      saveData.ModifiedByUserId = this.LoggedInUserId;
    }
    //saving User
    this.props.saveUser(saveData, widgetCode);
  };

  validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !this.state.userManager ||
      !this.state.userManager.selectedUser ||
      !this.state.userManager.selectedUser.FirstName ||
      isRequired(this.state.userManager.selectedUser.FirstName, 1)
    ) {
      formErrors.FirstName = this.props.intl.formatMessage({
        id: 'usermanagment.firstnamemandatory',
        defaultMessage: 'First name is mandatory field'
      });
      isValid = false;
    }

    if (
      !this.state.userManager ||
      !this.state.userManager.selectedUser ||
      !this.state.userManager.selectedUser.Email ||
      !isValidEmail(this.state.userManager.selectedUser.Email)
    ) {
      formErrors.Email = this.props.intl.formatMessage({ id: 'usermanagment.entervalidemail', defaultMessage: 'Please enter valid email' });
      isValid = false;
    }

    if (
      !this.state.userManager ||
      !this.state.userManager.selectedUser ||
      !this.state.userManager.selectedUser.PhoneNumber ||
      isRequired(this.state.userManager.selectedUser.PhoneNumber.replace(/ /g, ''), 8)
    ) {
      formErrors.PhoneNumber = this.props.intl.formatMessage({
        id: 'usermanagment.entervalidphoneno',
        defaultMessage: 'Please enter valid phone number (minimum 7 digits including country code)'
      });
      isValid = false;
    }

    if (
      !this.state.userManager ||
      !this.state.userManager.selectedUser ||
      !this.state.userManager.selectedUser.selectedProfiles ||
      this.state.userManager.selectedUser.selectedProfiles.length === 0
    ) {
      formErrors.Profiles = this.props.intl.formatMessage({
        id: 'usermanagment.addatleaseoneprofile',
        defaultMessage: 'Please add at least one profile'
      });
      isValid = false;
    }

    if (
      !this.state.userManager ||
      !this.state.userManager.selectedUser ||
      !this.state.userManager.selectedUser.DeliveryType ||
      isRequired(this.state.userManager.selectedUser.DeliveryType, 1)
    ) {
      formErrors.DeliveryType = this.props.intl.formatMessage({
        id: 'usermanagment.contactNameMandatory',
        defaultMessage: 'Preferred Contact Method is mandatory field'
      });
      isValid = false;
    }

    this.setState({
      ...this.state,
      formErrors: {
        ...formErrors
      }
    });

    return isValid;
  };

  //on control value change
  onChange = (e, data) => {
    //Converting phone-value to the required type object
    if (data && data.countryCode) {
      e = { target: { type: 'text', name: 'PhoneNumber', value: e } };
    }

    if (e.target && e.target.type && e.target.type === 'checkbox') {
      this.setState({
        userManager: {
          ...this.state.userManager,
          selectedUser: { ...this.state.userManager.selectedUser, [e.target.name]: e.target.checked }
        }
      });
    } else {
      this.setState({
        userManager: {
          ...this.state.userManager,
          selectedUser: { ...this.state.userManager.selectedUser, [e.target.name]: e.target.value }
        }
      });
    }
  };

  //executed when a profile is selected from list of selected profiles
  onSelectedProfileClick = profile => {
    this.setState({ highlightedProfile: profile });
  };

  //Invoked when list of selected profiles got updated
  onSelectedProfileListUpdated = profiles => {
    this.setState({
      userManager: { ...this.state.userManager, selectedUser: { ...this.state.userManager.selectedUser, selectedProfiles: profiles } }
    });
  };

  //Invoked on unit selection updated
  onUnitSelectionChange = selectedProfile => {
    let updatedProfiles = this.state.userManager.selectedUser.selectedProfiles.map(selProf => {
      if (selProf.ProfileId === selectedProfile.ProfileId) {
        return selectedProfile;
      } else {
        return selProf;
      }
    });

    this.setState({
      userManager: {
        ...this.state.userManager,
        selectedUser: {
          ...this.state.userManager.selectedUser,
          selectedProfiles: updatedProfiles
        }
      }
    });
  };

  //handler methods for userprofileunit component
  onUnitSearch = filter => {
    this.filter = filter;
    let widgetCode = this.props.match.params.userId ? 'USERUPDATE' : 'USERINSERT';
    this.props.getUnits(this.limit, filter, this.OrganisationId, widgetCode);
  };

  loadPropsToState(prevProps) {
    if (!equal(this.props.userManager.selectedUser, prevProps.userManager.selectedUser)) {
      this.setState({
        userManager: {
          ...this.state.userManager,
          selectedUser: { ...this.props.userManager.selectedUser },
          profiles: this.props.userManager.profiles,
          units: this.props.userManager.units
        }
      });
    }
  }

  deliveryTypes = () => {
    let deliveryTypes = [];
    deliveryTypes = Object.keys(NOTIFICATION_DELIVERY_TYPES).map(key => {
      return {
        SKEY: NOTIFICATION_DELIVERY_TYPES[key].value,
        label: NOTIFICATION_DELIVERY_TYPES[key].name,
        value: NOTIFICATION_DELIVERY_TYPES[key].value,
        isChecked:
          this.state.userManager.selectedUser.DeliveryType &&
          this.state.userManager.selectedUser.DeliveryType.split(',').find(e => e === NOTIFICATION_DELIVERY_TYPES[key].name)
            ? true
            : false
      };
    });
    return deliveryTypes;
  };

  onCheckboxSelectionChange = (e, data) => {
    let deliveryType = this.state.userManager.selectedUser.DeliveryType;
    if (data.isChecked) {
      deliveryType = (deliveryType && `${deliveryType},${data.label}`) || data.label;
    } else {
      if (deliveryType) {
        deliveryType = deliveryType
          .split(',')
          .filter(elem => elem !== data.label)
          .join(',');
      }
    }

    this.setState({
      userManager: {
        ...this.state.userManager,
        selectedUser: {
          ...this.state.userManager.selectedUser,
          DeliveryType: deliveryType
        }
      }
    });
  };

  render() {
    if (this.Redirect) {
      return <Navigate to="/admin-controls/user-list" />;
    }

    let isEdit = this.IsEdit || false;

    let messageId = (this.props.userManager && this.props.userManager.displayMessageCode) || 'none';
    const messageText = this.props.intl.formatMessage({ id: messageId, defaultMessage: messageId });

    const placeHolderEmail = this.props.intl.formatMessage({ id: 'usermanagment.enteremail', defaultMessage: 'Enter Email' });

    let allProfiles = (this.props.userManager && this.props.userManager.profiles) || [];

    let userName = (this.state.userManager.selectedUser && this.state.userManager.selectedUser.Email) || null;
    return (
      <div className={s.user}>
        {this.props.userManager.isLoading && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={this.props.userManager.showBanner}
          status={this.props.userManager.isOpSuccessfull}
          successText={messageText}
        />
        {this.props.userManager.isOpSuccessfull && !this.props.userManager.showBanner ? <Navigate to="/admin-controls/user-list" /> : ''}

        <AdminControlsContentTemplate selectedPage="manageUsers" userProfileWidgets={this.props.userProfileWidgets}>
          <div className={s.contentWrapper}>
            <div className={s.userHeader}>
              <Link className={s.backLink} to={'/admin-controls/user-list'}>
                &lt; &nbsp;
                <FormattedMessage id="usermanagement.backtousers" defaultMessage="BACK TO USERS" />
              </Link>
              <h3>
                {isEdit ? (
                  <FormattedMessage id="usermanagement.edituserdetails" defaultMessage="Edit user details" />
                ) : (
                  <FormattedMessage id="usermanagement.linkaddnewuser" defaultMessage="Add new user" />
                )}
              </h3>
            </div>
            <div className={s.userContent}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formFirstName">
                      <Form.Label>
                        <FormattedMessage id="usermanagement.firstname" defaultMessage="First Name" />
                      </Form.Label>

                      <Form.Control
                        type="text"
                        name="FirstName"
                        onChange={this.onChange}
                        value={this.state.userManager.selectedUser.FirstName}
                        className={`${s.formControl} ${this.state.formErrors && this.state.formErrors.FirstName ? s.formControlError : ''}`}
                      />
                      {this.state.formErrors && this.state.formErrors.FirstName && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.FirstName}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formLastName">
                      <Form.Label>
                        <FormattedMessage id="usermanagement.lastname" defaultMessage="Last Name" />
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="LastName"
                        onChange={this.onChange}
                        value={this.state.userManager.selectedUser.LastName}
                        className={s.formControl}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formUserEmail">
                      <Form.Label>
                        <FormattedMessage id="usermanagement.email" defaultMessage="Email" />
                      </Form.Label>

                      <Form.Control
                        type="email"
                        name="Email"
                        disabled={isEdit}
                        onChange={this.onChange}
                        placeholder={placeHolderEmail}
                        value={this.state.userManager.selectedUser.Email}
                        className={`${s.formControl} ${this.state.formErrors && this.state.formErrors.Email ? s.formControlError : ''}`}
                      />
                      {this.state.formErrors && this.state.formErrors.Email && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.Email}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formUserEmail">
                      <Form.Label>
                        <FormattedMessage id="usermanagement.contactno" defaultMessage="Contact number" />
                      </Form.Label>
                      <PhoneInput
                        country={'au'}
                        inputStyle={{ width: '100%' }}
                        value={this.state.userManager.selectedUser.PhoneNumber}
                        onChange={this.onChange}
                        inputProps={{ name: 'phone' }}
                        inputClass={`${s.formControl} ${
                          this.state.formErrors && this.state.formErrors.PhoneNumber ? s.formControlError : ''
                        }`}
                        buttonClass={this.state.formErrors && this.state.formErrors.PhoneNumber ? s.formControlError : ''}
                      />
                      {this.state.formErrors && this.state.formErrors.PhoneNumber && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.PhoneNumber}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formPreferredContactMethod">
                      <Form.Label>
                        <FormattedMessage id="usermanagement.preferredContactMethod" defaultMessage="Preferred Contact Method" />
                      </Form.Label>
                      <Checkbox dataArray={this.deliveryTypes()} onSelectionChange={this.onCheckboxSelectionChange} />
                      {this.state.formErrors && this.state.formErrors.DeliveryType && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.DeliveryType}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group controlId="formUserIsEnabled">
                      <Checkbox
                        key={uuidv4()}
                        dataArray={[
                          {
                            SKEY: 'IsActive',
                            target: { type: 'checkbox' },
                            label: 'Enable user',
                            isChecked: this.state.userManager.selectedUser.IsActive
                          }
                        ]}
                        onSelectionChange={this.onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Form>

              <Form className={s.userProfileUnitsContainer}>
                {/* User Profiles START */}
                <Form.Group controlId="formUserProfiles">
                  <Form.Label className={s.subHeading}>
                    <h4>
                      <FormattedMessage id="usermanagement.userprofiles" defaultMessage="Profiles" />
                    </h4>
                  </Form.Label>
                </Form.Group>
                <Container className={s.userProfileAndUnitsContainer} fluid={true}>
                  <Row>
                    <Col lg={6} className="pl-0">
                      <Form.Label className={s.subHeading2}>
                        <FormattedMessage id="usermanagement.selectprofilethisuser" defaultMessage="1. Select a profile(s) for this user" />
                      </Form.Label>
                    </Col>
                    <Col lg={6} className="d-none d-lg-table-cell pl-0">
                      <Form.Label className={s.subHeading2}>
                        <FormattedMessage id="usermanagement.selectunits" defaultMessage="2. Select a unit(s) for " />
                        {this.state.highlightedProfile && this.state.highlightedProfile.Name}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row className={s.outerRow}>
                    <Col lg={6} className={s.outerLeftCol}>
                      <UserProfiles
                        profiles={allProfiles}
                        selectedUserName={userName}
                        selectedProfiles={this.state.userManager.selectedUser.selectedProfiles}
                        onSelectedProfileClick={this.onSelectedProfileClick}
                        onSelectedProfileListUpdated={this.onSelectedProfileListUpdated}
                      />
                    </Col>
                    <Col lg={6} className="d-lg-none pl-0">
                      {this.state.formErrors && this.state.formErrors.Profiles && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.Profiles}
                        </p>
                      )}
                    </Col>
                    <Col lg={6} className="d-lg-none pl-0">
                      <Form.Label className={s.subHeading2}>
                        <FormattedMessage id="usermanagement.selectunits" defaultMessage="2. Select a unit(s) for " />
                        {this.state.highlightedProfile && this.state.highlightedProfile.Name}
                      </Form.Label>
                    </Col>
                    <Col lg={6} className={s.outerRightCol}>
                      <UserProfileUnits
                        unitManager={this.props.unitManager}
                        onUnitSearch={this.onUnitSearch}
                        highlightedProfile={this.state.highlightedProfile}
                        onUnitSelectionChange={this.onUnitSelectionChange}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} className="d-none d-lg-table-cell pl-0">
                      {this.state.formErrors && this.state.formErrors.Profiles && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.Profiles}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Container>

                {/* User Profiles END */}
                <div>
                  <Button variant="primary" className={classNames(s.margin5, s.btnSaveChanges)} onClick={this.submitForm} noValidate>
                    <FormattedMessage id="profile.profileSave" defaultMessage="Save Changes" />
                  </Button>
                  <Link to="/admin-controls/user-list">
                    <Button variant="outline-secondary" className={s.btnCancel}>
                      <FormattedMessage id="profile.profileCancel" defaultMessage="Cancel" />
                    </Button>
                  </Link>
                </div>
              </Form>
            </div>
          </div>
        </AdminControlsContentTemplate>
      </div>
    );
  }
}

User.defaultProps = {
  userManager: {
    ...initialUserManagerState.userManager,
    units: [],
    profiles: []
  },
  highlightedProfile: {},
  formErrors: {},
  unitManager: {
    ...initialUnitManagerState.unitManager
  }
};

User.propTypes = {
  saveUser: PropTypes.func.isRequired,

  userManager: PropTypes.object.isRequired,
  unitManager: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  getUnits: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired
};

export default injectIntl(User);
