import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import s from './GoBack.module.scss';

const GoBack = props => {
  let history = useNavigate();

  const onClick = () => {
    if (history.length > 2) {
      history.goBack();
      return;
    }
    if (props.defaultLink) {
      history.push(props.defaultLink);
    } else {
      history.push('/dashboard');
    }
  };

  let returnContent = (
    <button
      className={`${s.backLink} ${props.customClassName}`}
      type="button"
      onClick={() => {
        onClick();
      }}
    >
      {props.children}
    </button>
  );

  if (props.returnUrl && props.returnUrl.length > 0) {
    returnContent = (
      <Link className={`${s.backLink} ${props.customClassName}`} to={props.returnUrl}>
        {props.children}
      </Link>
    );
  }
  return <div className={s.GoBackWrapper}>{returnContent}</div>;
};

export default GoBack;
