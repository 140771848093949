import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './NotificationTypeList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { initialNotificationsState } from '../../../reducers/initialState';
import NotificationTypeListTable from './NotificationTypeListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import SearchBar from '../../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils';
import { getNotificationTypesThunk } from '../../../actions/notifications';
import { getUserProfileWidget, getNotifications } from '../../../selectors/index';
import AdminControlsContentTemplate from '../../AdminControls/AdminControlsContentTemplate';

const NotificationTypeList = ({ intl }) => {
  const history = useNavigate();

  const dispatch = useDispatch();
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const notifications = useSelector(state => getNotifications(state));

  let initialLimit = 6;
  let initialRecords = notifications.notificationTypes.slice(0, initialLimit);

  useEffect(() => {
    dispatch(getNotificationTypesThunk('NOTIFICATION_TYPES_LIST'));
  }, [getNotificationTypesThunk, notifications.isOpSuccessful]);

  const [localNotificationTypes, setLocalNotificationTypes] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);

  const [searchText, setSearchText] = useState('');

  const localNotificationTypesLength = localNotificationTypes.length;
  const notificationTypesLength = notifications.notificationTypes.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalNotificationTypes(notifications.notificationTypes);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = notifications.notificationTypes.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalNotificationTypes(filteredList);
  };

  const filterData = () => {
    let filteredList = notifications.notificationTypes;
    if (isFilter) {
      filteredList = notifications.notificationTypes.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getNotificationTypesData = () => {
    let notificationTypes = [];
    if (showAll) {
      notificationTypes = filterData();
    } else {
      if (isFilter) {
        notificationTypes = localNotificationTypesLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        notificationTypes = initialRecords;
      }
    }
    return notificationTypes.sort(compareValues('Name'));
  };

  const onChangeNotificationTypeClick = notificationTypeId => {
    history.push(`/admin-controls/notifications/notification-type-users/${notificationTypeId}`);
  };

  let messageId = (notifications && notifications.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'NOTIFICATION_USERS_EDIT');

  return (
    <div className={s.notifications}>
      {notifications.isLoading && <LoadingSpinner />}
      <Banner
        failureText={messageText}
        showBanner={notifications.showBanner}
        status={notifications.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageNotifications" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper} style={WidgetVisibility(userProfileWidgets, 'NOTIFICATION_TYPES_LIST')}>
          <Row>
            <Col xs={12} md={6}>
              <div className={s.notificationsHeader}>
                <div className={s.subHeading}>
                  <FormattedMessage id="notifications.notificationTypes" defaultMessage="Notification Types" />
                  <span data-unittest="notificationTypeDataCount" className={s.circle}>
                    {notifications.notificationTypes.length}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col sm={4} lg={4}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                disabled={notifications.showAddEditScreen}
                // initialText={filter}
                placeHolderTextId="common.search"
                data-unittest="searchNotificationTypes"
              />
            </Col>
          </Row>
          <div className={s.innerComponent} id="notifications-type-section">
            <div>
              <NotificationTypeListTable
                NotificationTypesData={getNotificationTypesData()}
                ChangeNotificationType={onChangeNotificationTypeClick}
                UpdateAllowed={isUpdateAllowed}
              />
            </div>
            {((!isFilter && notificationTypesLength > initialLimit) || (isFilter && localNotificationTypesLength > initialLimit)) && (
              <Row className={s.topRow}>
                <Col>
                  <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                    {!showAll
                      ? `${showAllText} (${!showAll && !isFilter ? notificationTypesLength : localNotificationTypesLength})`
                      : showLessText}
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

NotificationTypeList.defaultProps = {
  ...initialNotificationsState
};

export default injectIntl(NotificationTypeList);
