import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './EventCode.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialEventCodeState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Navigate, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import { IsWidgetAccessible, WidgetVisibility } from '../../utils/widgetManager';
import 'react-datetime/css/react-datetime.css';
import InputNumber from '../WSAControls/InputNumber/InputNumber';
import GoBack from '../WSAControls/GoBack/GoBack';
import { EVENT_TYPES, EVENT_CATEGORY } from '../../constants/device';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const EventCode = ({
  eventCodeManager,
  describeEventCode,
  saveEventCode,
  setEventCodeChange,
  deleteEventCode,
  eventCodeCleanUp,
  modelComponentPositions,
  flowSequences,
  userProfileWidgets,
  currentTimezone,
  intl
}) => {
  let IsRedirect = false;
  let IsEdit = false;
  const [localformErrors, setFormErrors] = useState({});
  const [active, setActive] = useState(false);
  const [commandId, setCommandId] = useState(0);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let { eventCodeId } = useParams();
  let widgetCode = '';
  if (eventCodeId && parseInt(eventCodeId) > 0) {
    IsEdit = true;
    widgetCode = 'EVENTCODEUPDATE';
  }

  useEffect(() => {
    if (eventCodeId) {
      describeEventCode(eventCodeId);
    }
  }, [describeEventCode, eventCodeId]);

  //clean up
  useEffect(() => {
    return () => {
      eventCodeCleanUp({
        ...initialEventCodeState.eventCode
      });
    };
  }, []);

  const flowSequenceOptions = () => {
    let flowSequenceData = [];

    flowSequences.forEach(element => {
      flowSequenceData.push({
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });
    return flowSequenceData.sort(compareValues('label'));
  };

  const eventCategoryOptions = () => {
    let eventCategoryData = [];

    Object.keys(EVENT_CATEGORY).map(key =>
      eventCategoryData.push({
        label: EVENT_CATEGORY[key].name,
        value: EVENT_CATEGORY[key].value
      })
    );
    return eventCategoryData.sort(compareValues('label'));
  };

  const modelComponentsOptions = () => {
    let modelComponentsData = [];

    modelComponentPositions.forEach(element => {
      modelComponentsData.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return modelComponentsData.sort(compareValues('label'));
  };

  const eventCodeTypeOptions = () => {
    let eventCodeTypeData = [];

    Object.keys(EVENT_TYPES).map(key =>
      eventCodeTypeData.push({
        label: EVENT_TYPES[key].name,
        value: parseInt(key)
      })
    );
    return eventCodeTypeData;
  };

  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = eventCodeManager.selectedEventCode;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.EventCodeId = 0;
      saveData.GuidIdentifier = uuidv4();
      widgetCode = 'EVENTCODEADDNEW';
    }
    //saving Model Version
    saveEventCode(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !eventCodeManager ||
      !eventCodeManager.selectedEventCode ||
      !eventCodeManager.selectedEventCode.Code ||
      isRequired(eventCodeManager.selectedEventCode.Code, 1) ||
      eventCodeManager.selectedEventCode.Code.length !== 4
    ) {
      formErrors.Code = intl.formatMessage({
        id: 'eventCodes.codeInvalidCode',
        defaultMessage: 'Code has to be of a 4 digit code'
      });
      isValid = false;
    }

    if (
      !eventCodeManager ||
      !eventCodeManager.selectedEventCode ||
      !eventCodeManager.selectedEventCode.EventCategory ||
      isRequired(eventCodeManager.selectedEventCode.EventCategory, 1)
    ) {
      formErrors.EventCategory = intl.formatMessage({
        id: 'eventCodes.eventCategoryMandatory',
        defaultMessage: 'Event category is a mandatory field'
      });
      isValid = false;
    }

    if (
      !eventCodeManager ||
      !eventCodeManager.selectedEventCode ||
      eventCodeManager.selectedEventCode.Severity === '' ||
      isRequired(eventCodeManager.selectedEventCode.Severity, 1)
    ) {
      formErrors.Severity = intl.formatMessage({
        id: 'eventCodes.eventCodeType',
        defaultMessage: 'Event type is a mandatory field'
      });
      isValid = false;
    }

    if (
      !eventCodeManager ||
      !eventCodeManager.selectedEventCode ||
      !eventCodeManager.selectedEventCode.Name ||
      isRequired(eventCodeManager.selectedEventCode.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'eventCodes.nameMandatory',
        defaultMessage: 'Version name is a mandatory field'
      });
      isValid = false;
    }

    if (!eventCodeManager || !eventCodeManager.selectedEventCode || !eventCodeManager.selectedEventCode.GeneralGlobalSettingId) {
      formErrors.GeneralGlobalSettingId = intl.formatMessage({
        id: 'eventCodes.flowsequenceMandatory',
        defaultMessage: 'Flow Sequence is a mandatory field'
      });
      isValid = false;
    }

    if (!eventCodeManager || !eventCodeManager.selectedEventCode || !eventCodeManager.selectedEventCode.ModelComponentPositionId) {
      formErrors.ModelComponentPositionId = intl.formatMessage({
        id: 'eventCodes.sensorMandatory',
        defaultMessage: 'Sensor is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const handleConfirm = () => {
    deleteEventCodeRecord();
    handleClose();
  };

  const deleteEventCodeRecord = () => {
    if (eventCodeId) {
      deleteEventCode(eventCodeId);
    }
  };

  const handleClose = () => setActive(false);

  const deleteEventCodeHandler = commandId => {
    setActive(true);
    setCommandId(commandId);
  };

  const dialogTitle = intl.formatMessage({
    id: 'eventCodes.delete.confirmation',
    defaultMessage: 'Are you sure you want to delete this event code?'
  });

  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setEventCodeChange({
        ...eventCodeManager,
        selectedEventCode: { ...eventCodeManager.selectedEventCode, [e.target.name]: e.target.checked }
      });
    } else {
      setEventCodeChange({
        ...eventCodeManager,
        selectedEventCode: { ...eventCodeManager.selectedEventCode, [e.target.name]: e.target.value }
      });
    }
  };

  const onDropdownFlowSequenceChange = e => {
    setEventCodeChange({
      ...eventCodeManager,
      selectedEventCode: { ...eventCodeManager.selectedEventCode, GeneralGlobalSettingId: e.value }
    });
  };

  const onDropdownEventCategoryChange = e => {
    setEventCodeChange({
      ...eventCodeManager,
      selectedEventCode: { ...eventCodeManager.selectedEventCode, EventCategory: e.value }
    });
  };

  const onDropdownSensorChange = e => {
    setEventCodeChange({
      ...eventCodeManager,
      selectedEventCode: { ...eventCodeManager.selectedEventCode, ModelComponentPositionId: e.value }
    });
  };

  const onDropdownEventCodeTypeChange = e => {
    setEventCodeChange({
      ...eventCodeManager,
      selectedEventCode: { ...eventCodeManager.selectedEventCode, Severity: e.value }
    });
  };

  let ddlClassFlowSequence = localformErrors && localformErrors.GeneralGlobalSettingId ? s.ddlError : '';
  let ddlClassSensor = localformErrors && localformErrors.ModelComponentPositionId ? s.ddlError : '';
  let ddlClassEventCodeType = localformErrors && localformErrors.Severity ? s.ddlError : '';
  let ddlClassEventCategory = localformErrors && localformErrors.EventCategory ? s.ddlError : '';

  let messageId = (eventCodeManager && eventCodeManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const isSaveAllowed = eventCodeId
    ? IsWidgetAccessible(userProfileWidgets, 'EVENTCODEUPDATE')
    : IsWidgetAccessible(userProfileWidgets, 'EVENTCODEADDNEW');

  return (
    <div className={s.eventCode}>
      {eventCodeManager.isLoading && <LoadingSpinner />}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      {IsRedirect || eventCodeManager.isRedirect ? <Navigate to="/admin-controls/event-code-list" /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={eventCodeManager.showBanner}
        status={eventCodeManager.isOpSuccessful}
        successText={messageText}
      />
      <AdminControlsContentTemplate selectedPage="manageEventCodes" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.eventCodeHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="eventCodes.back" defaultMessage="BACK" />
            </GoBack>
            <h3>
              <FormattedMessage id="eventCodes.eventCode" defaultMessage="Event Code" />
            </h3>
          </div>
          <div className={s.eventCodeContent}>
            <Form>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formEventCodeCode">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.eventCodes" defaultMessage="Code" />
                    </Form.Label>

                    <InputNumber
                      name="Code"
                      step="1"
                      onInputChange={onChange}
                      customClassName={`${s.formControl} ${localformErrors && localformErrors.Code ? s.formControlError : ''}`}
                      value={eventCodeManager.selectedEventCode.Code}
                      id="formEventCodeCodeId"
                    />
                    {localformErrors && localformErrors.Code && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Code}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEventCodeFlowSequence">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.flowSequence" defaultMessage="Flow Sequence" />
                    </Form.Label>
                    <Dropdown
                      id="formEventCodeFlowSequenceId"
                      dataArray={flowSequenceOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="eventCodes.selectFlowSequence" defaultMessage="Flow Sequence" />,
                        customClassName: ddlClassFlowSequence
                      }}
                      onDropdownChange={onDropdownFlowSequenceChange}
                      selectedOption={flowSequenceOptions().filter(
                        option => option.value === eventCodeManager.selectedEventCode.GeneralGlobalSettingId
                      )}
                      data-unittest="formEventCodeFlowSequence"
                    />
                    {localformErrors && localformErrors.GeneralGlobalSettingId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.GeneralGlobalSettingId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEventCodeSensor">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.sensor" defaultMessage="Sensor" />
                    </Form.Label>
                    <Dropdown
                      id="formEventCodeSensorId"
                      dataArray={modelComponentsOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="eventCodes.selectSensor" defaultMessage="Select sensor" />,
                        customClassName: ddlClassSensor
                      }}
                      onDropdownChange={e => onDropdownSensorChange(e)}
                      selectedOption={modelComponentsOptions().filter(
                        option => option.value === eventCodeManager.selectedEventCode.ModelComponentPositionId
                      )}
                      data-unittest="formEventCodeSensor"
                    />
                    {localformErrors && localformErrors.ModelComponentPositionId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.ModelComponentPositionId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEventCodeEventCodeType">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.eventCodeType" defaultMessage="Event Code Type" />
                    </Form.Label>
                    <Dropdown
                      id="formEventCodeEventCodeTypeId"
                      dataArray={eventCodeTypeOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="eventCodes.selectEventCodeType" defaultMessage="Select event code type" />,
                        customClassName: ddlClassEventCodeType
                      }}
                      onDropdownChange={onDropdownEventCodeTypeChange}
                      selectedOption={eventCodeTypeOptions().filter(option => option.value === eventCodeManager.selectedEventCode.Severity)}
                      data-unittest="formEventCodeEventCodeType"
                    />
                    {localformErrors && localformErrors.Severity && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Severity}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEventCodeName">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.Name" defaultMessage="Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={eventCodeManager.selectedEventCode.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formEventCodeCategory">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.eventCategory" defaultMessage="Event Category" />
                    </Form.Label>

                    <Dropdown
                      id="formEventCategory"
                      dataArray={eventCategoryOptions()}
                      controlData={{
                        placeholderText: <FormattedMessage id="eventCodes.selectEventCategory" defaultMessage="Select event category" />,
                        customClassName: ddlClassEventCategory
                      }}
                      onDropdownChange={onDropdownEventCategoryChange}
                      selectedOption={eventCategoryOptions().filter(
                        option => option.value === eventCodeManager.selectedEventCode.EventCategory
                      )}
                      data-unittest="formEventCategory"
                    />
                    {localformErrors && localformErrors.EventCategory && (
                      <p role="alert" className={s.error}>
                        {localformErrors.EventCategory}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEventCodeDescription">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="5"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'eventCodes.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={eventCodeManager.selectedEventCode.Description}
                      className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formEventCodeNextAction">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.nextActionDetails" defaultMessage="Next Action Details" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="5"
                      name="NextActionDetails"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'eventCodes.enterNextActionDetail',
                        defaultMessage: 'Enter Next Action'
                      })}
                      value={eventCodeManager.selectedEventCode.NextActionDetails}
                      className={`${s.textArea} ${localformErrors && localformErrors.NextActionDetails ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formEventCodeThreshold">
                    <Form.Label>
                      <FormattedMessage id="eventCodes.threshold" defaultMessage="Threshold" />
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="Threshold"
                      onChange={onChange}
                      value={eventCodeManager.selectedEventCode.Threshold}
                      className={`${s.formControl} ${localformErrors && localformErrors.Threshold ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formActiveModelVersion">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsActive',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'eventCodes.eventCodeActive',
                            defaultMessage: 'Event code is active'
                          }),
                          isChecked: eventCodeManager.selectedEventCode.IsActive
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  disabled={!isSaveAllowed}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="eventCodes.save" defaultMessage="SAVE CHANGES" />
                </Button>
                <Link to="/admin-controls/event-code-list">
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="eventCodes.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
                {eventCodeId && (
                  <Button
                    variant="outline-secondary"
                    className={classNames(s.margin5, s.btnDelete)}
                    onClick={deleteEventCodeHandler}
                    noValidate
                    data-unittest="deleteData"
                    style={WidgetVisibility(userProfileWidgets, `EVENTCODEDELETE`)}
                  >
                    <FormattedMessage id="eventCodes.delete" defaultMessage="DELETE" />
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

EventCode.defaultProps = {
  eventCodeManager: {
    ...initialEventCodeState.eventCode
  },
  flowSequences: [],
  modelComponents: []
};

EventCode.propTypes = {
  saveEventCode: PropTypes.func.isRequired,
  eventCodeManager: PropTypes.object.isRequired,
  flowSequences: PropTypes.array.isRequired,
  modelComponentPositions: PropTypes.array.isRequired,
  describeEventCode: PropTypes.func.isRequired,
  deleteEventCode: PropTypes.func.isRequired,
  eventCodeCleanUp: PropTypes.func.isRequired,
  setEventCodeChange: PropTypes.func.isRequired
};

export default injectIntl(EventCode);
