import Cookies from 'js-cookie';
import {
  GET_ALL_USER_PROFILE_WIDGET_DETAILS,
  GET_ALL_USER_PROFILE_WIDGET_ERROR,
  IS_LOADING,
  SET_USER_PROFILE_WIDGET_DETAILS,
  GET_ALL_USER_PROFILE_UNITS,
  SET_DEVICES,
  SET_USER_PROFILE_WIDGET_DETAILS_LOADING,
  DEVICE_LOADING,
  SET_LICENSE_AGREEMENT
} from '../constants/index';
import { DOMAIN_NAME, IS_LOCALHOST } from '../constants/index';
import { apiAction } from '../utils/index';
import { IsWidgetPresent } from '../utils/widgetManager';
import { setOrganisationData } from './user';
const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setUserProfileWidgetError = value => ({
  type: GET_ALL_USER_PROFILE_WIDGET_ERROR,
  payload: { value }
});

export const getSelectedUserProfileWidgetsThunk = (profileId, userName, widgetCode) => dispatch => {
  dispatch(getSelectedUserProfileWidgets(profileId, userName, widgetCode));
};

export const getSelectedUserProfileWidgets = (profileId, userName, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/profile/profilewidgets`,
    data: {
      Id: profileId,
      userName: userName,
      userId: Cookies.get(`userid-${envName}`) || null,
      orgId: Cookies.get(`selectedorganisationid-${envName}`) || 0
    },
    method: 'GET',
    onLoad: status => setUserProfileWidgetLoadingStatusThunk(status),
    onSuccess: setSelectedUserProfileWidgetsThunk,
    onFailure: error => setUserProfileWidgetError(error),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setSelectedUserProfileWidgetsThunk = ({ profile }) => dispatch => {
  if (profile.profile && Object.keys(profile.userProfileData).length > 0) {
    let profileInfo = profile.profile;
    setSelectedProfile(profileInfo.ProfileId, profileInfo.Name, profile.userProfileData);
  }
  if (profile.organisations && profile.organisations.length > 0) {
    setOrganisationData(profile.organisations);
  }
  processUserWidgets(profile);
  dispatch(setSelectedUserProfileWidgets(profile));
  dispatch(setSelectedUserProfileUnits(profile));
  dispatch(setDevices(profile));
};

export const setSelectedUserProfileWidgets = profile => ({
  type: GET_ALL_USER_PROFILE_WIDGET_DETAILS,
  payload: { profile }
});

export const setSelectedUserProfileUnits = profile => ({
  type: GET_ALL_USER_PROFILE_UNITS,
  payload: { profile }
});

export const setDevices = ({ profile }) => ({
  type: SET_DEVICES,
  payload: profile
});

export const setAcceptedLicenseAgreement = licenseAgreementId => ({
  type: SET_LICENSE_AGREEMENT,
  payload: { acceptedLicenseAgreementId: licenseAgreementId }
});

export const setUserProfileWidgetLoadingStatusThunk = status => dispatch => {
  dispatch(setUserProfileWidgetLoadingStatus(status));
  dispatch(setDeviceLoading(status));
};

export const setUserProfileWidgetLoadingStatus = status => ({
  type: SET_USER_PROFILE_WIDGET_DETAILS_LOADING,
  payload: { status }
});

export const setDeviceLoading = status => ({
  type: DEVICE_LOADING,
  payload: { status }
});

export const setSelectedUserProfileDetails = profileWidgets => ({
  type: SET_USER_PROFILE_WIDGET_DETAILS,
  payload: { profileWidgets }
});

//this function is to set IsViewable for widgets which have switch between 2 views/widgets functionality
export const processUserWidgets = profile => {
  // Update the IsViewable to false for REALTIMESENSORS if user profile has access to both DEVICESCHEMATICDIAGRAM and REALTIMESENSORS
  if (
    IsWidgetPresent(profile.profile.ProfileWigets, 'DEVICESCHEMATICDIAGRAM') &&
    IsWidgetPresent(profile.profile.ProfileWigets, 'REALTIMESENSORS')
  ) {
    profile.profile.ProfileWigets = profile.profile.ProfileWigets.map(obj => {
      if (obj.Code === 'DEVICESCHEMATICDIAGRAM') {
        obj.IsViewable = true;
      }
      if (obj.Code === 'REALTIMESENSORS') {
        obj.IsViewable = false;
      }
      return obj;
    });
  }
};

const setSelectedProfile = (profileId, profileName, userProfilesData) => {
  if (userProfilesData && userProfilesData.UserProfiles) {
    let userProfiles = userProfilesData.UserProfiles;

    let selectedProfId = profileId || 0;
    let selectedProfName = profileName || '';

    //Cookies.set(`profiles-${envName}`, userProfiles, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    localStorage.setItem(`profiles-${envName}`, JSON.stringify(userProfiles));

    if (selectedProfId && selectedProfName) {
      Cookies.set(`selectedprofileid-${envName}`, selectedProfId, {
        expires: 7,
        domain: DOMAIN_NAME,
        secure: !IS_LOCALHOST
      });
      Cookies.set(`selectedprofile-${envName}`, selectedProfName, {
        expires: 7,
        domain: DOMAIN_NAME,
        secure: !IS_LOCALHOST
      });
    }
  }
};
