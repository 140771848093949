import React from 'react';
import s from './OrganisationList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { v4 as uuidv4 } from 'uuid';

const OrganisationListTable = ({ paginatedData, currentPage, UpdateAllowed, configureAllowed, isMoveUnitsAllowed }) => {
  const getMoreOptions = (organisationId, parentOrganisationId) => {
    let options = [];
    if (UpdateAllowed) {
      options.push({
        Url: `/admin-controls/organisation/${organisationId}/${parentOrganisationId}`,
        Name: <FormattedMessage id="common.edit" defaultMessage="Edit" />
      });
    }
    if (configureAllowed) {
      options.push({
        Url: `/admin-controls/organisation-list/${organisationId}/configure`,
        Name: <FormattedMessage id="common.configure" defaultMessage="Configure" />
      });
    }
    if (isMoveUnitsAllowed) {
      options.push({
        Url: `/admin-controls/organisation-list/${organisationId}/move-units`,
        Name: <FormattedMessage id="organisationManagement.moveUnits" defaultMessage="Move Units" />
      });
    }
    return options;
  };

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th className="d-none d-sm-table-cell">
              <FormattedMessage id="organisation.organisationName" defaultMessage="Organisation Name" />
            </th>
            <th>
              <FormattedMessage id="usermanagment.location" defaultMessage="Location" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="usermanagment.users" defaultMessage="Users" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="usermanagment.units" defaultMessage="Units" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="usermanagment.active" defaultMessage="Active" />
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.OrganisationId} data-unittest="organisationsData">
                <td className="d-none d-sm-table-cell">{n.Name || ''}</td>
                <td>{`${n.Town || ''} ${n.Region || ''} ${n.Country || ''} ${n.PostCode || ''}`}</td>
                <td className="d-none d-md-table-cell">{n.NumberOfUsers || 0}</td>
                <td className="d-none d-md-table-cell">{n.NumberOfUnits || 0}</td>
                <td className="d-none d-md-table-cell">{n.IsActive ? 'Yes' : 'No'}</td>
                <td>
                  <MoreOption key={uuidv4()} dataArray={getMoreOptions(n.OrganisationId, n.ParentOrganisationId)} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

OrganisationListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

export default injectIntl(OrganisationListTable);
