import React, { useState, useEffect, useRef } from 'react';
import { useParams, Navigate, useNavigate } from 'react-router-dom';
import bs from '../../styles/bootstrap-overrides.scss';
import s from './UnitStatistics.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import moment from 'moment-timezone';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import blueDownArrow from '../../assets/blue-down-arrow.svg';
import { useSelector, useDispatch } from 'react-redux';
import { getUnitStatistics, getCurrentTimezone } from '../../selectors/index';
import {
  getUnitStatisticsSettingsThunk,
  setUnitStatisticsSettingsThunk,
  getUnitStatisticsThunk,
  cleanUpThunk
} from '../../actions/unitStatistics';
import { initialUnitStatisticsState } from '../../reducers/initialState';
import { SELECTED_STAT_PERIOD_DASHBOARD, SELECTED_STAT_PERIOD_UNIT, DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
import Cookies from 'js-cookie';
import UnitStatisticsTile from '../UnitStatisticsTile/UnitStatisticsTile';
import { v4 as uuidv4 } from 'uuid';
import Banner from '../Banner/Banner';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const UnitStatistics = ({ intl, fromDashboard, devices }) => {
  const dispatch = useDispatch();
  const { unitSerialNumber } = useParams();
  const [periodSelectionOpen, setPeriodSelection] = useState(false);
  const widget = fromDashboard ? 'DASHBOARD_STATISTICS_VIEW' : 'UNIT_STATISTICS_VIEW';
  const SELECTED_STAT_PERIOD = fromDashboard ? SELECTED_STAT_PERIOD_DASHBOARD : SELECTED_STAT_PERIOD_UNIT;

  let selectedDatePeriod = Cookies.get(SELECTED_STAT_PERIOD) || 1;
  selectedDatePeriod = parseInt(selectedDatePeriod);

  const [selectedPeriod, setSelectedPeriod] = useState(selectedDatePeriod);
  const [tileEdit, setTileEdit] = useState(false);
  const unitStatistics = useSelector(state => getUnitStatistics(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const [lastUpdated, setLastUpdated] = useState(
    moment()
      .tz(currentTimezone)
      .format('HH:mm z')
  );

  const statisticsOpen = fromDashboard ? unitStatistics.isDashboardTabOpen : unitStatistics.isTabOpen;

  let messageId = (unitStatistics && unitStatistics.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  useEffect(() => {
    dispatch(getUnitStatisticsSettingsThunk(widget));
  }, [getUnitStatisticsSettingsThunk, dispatch]);

  const setStatisticsOpen = () => {
    if (fromDashboard) {
      dispatch(setUnitStatisticsSettingsThunk({ UnitStatistics: { isDashboardTabOpen: !statisticsOpen } }));
    } else {
      dispatch(setUnitStatisticsSettingsThunk({ UnitStatistics: { isTabOpen: !statisticsOpen } }));
    }
  };

  const onStatPeriodChange = e => {
    Cookies.set(SELECTED_STAT_PERIOD, e.PeriodId, { domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    setSelectedPeriod(e.PeriodId);
    setPeriodSelection(false);
  };

  const getStatPeriodName = statPeriodId => {
    let statPeriod = unitStatistics.statisticPeriods.find(ele => ele.PeriodId === statPeriodId);
    if (statPeriod) {
      return <FormattedMessage id={statPeriod.NameTranslationCode} defaultMessage={statPeriod.Name} />;
    }
    return '';
  };

  const getStatPeriodDates = statPeriodId => {
    let statPeriod = unitStatistics.statisticPeriods.find(ele => ele.PeriodId === statPeriodId);
    if (statPeriod) {
      return { fromDate: statPeriod.fromDate(), toDate: statPeriod.toDate() };
    }
    return {
      fromDate: moment()
        .subtract(24, 'hours')
        .valueOf(),
      toDate: moment().valueOf()
    };
  };

  const getStatisticSettings = tileOrder => {
    const statSettings = unitStatistics.statisticSettings.find(
      stat => stat.TileOrder === tileOrder && stat.FromDashboard === fromDashboard
    );
    if (!statSettings) {
      let filteredStatTypes = unitStatistics.statisticTypes.filter(stat => {
        return (
          unitStatistics.statisticSettings.filter(statSett => {
            return statSett.StatisticTypeId === stat.StatisticTypeId && statSett.FromDashboard === fromDashboard;
          }).length === 0
        );
      });
      if (filteredStatTypes.length > 0) {
        if (filteredStatTypes.length >= tileOrder) {
          return {
            ...filteredStatTypes[tileOrder - 1],
            FromDashboard: fromDashboard,
            TileOrder: tileOrder,
            UnitStatisticSettingId: 0,
            GuidIdentifier: uuidv4(),
            Value: 0.0
          };
        } else {
          return {
            ...filteredStatTypes[0],
            FromDashboard: fromDashboard,
            UnitStatisticSettingId: 0,
            TileOrder: tileOrder,
            GuidIdentifier: uuidv4(),
            Value: 0.0
          };
        }
      }
      return {
        TileOrder: tileOrder,
        StatisticTypeId: 1,
        FromDashboard: fromDashboard,
        UnitStatisticSettingId: 0,
        GuidIdentifier: uuidv4(),
        Value: 0.0
      };
    } else {
      statSettings.Value = 0.0;
      return statSettings;
    }
  };

  let statSettings1 = getStatisticSettings(1);
  let statSettings2 = getStatisticSettings(2);
  let statSettings3 = getStatisticSettings(3);

  const getStatisticRequestPayload = () => {
    let statTypes = [];
    statTypes.push({
      StatisticTypeId: statSettings1.StatisticTypeId,
      TileOrder: statSettings1.TileOrder
    });
    statTypes.push({
      StatisticTypeId: statSettings2.StatisticTypeId,
      TileOrder: statSettings2.TileOrder
    });
    statTypes.push({
      StatisticTypeId: statSettings3.StatisticTypeId,
      TileOrder: statSettings3.TileOrder
    });
    return statTypes;
  };

  const getStatisticPeriods = () => {
    if (fromDashboard) {
      return unitStatistics.statisticPeriods.filter(period => period.DashboardView);
    } else {
      return unitStatistics.statisticPeriods.filter(period => period.UnitView);
    }
  };

  let unitSerialNumbers = (devices && devices.map(device => device.unitSerialNumber)) || [];

  const reqPayload = getStatisticRequestPayload();
  const reqPeriod = getStatPeriodDates(selectedPeriod);

  useEffect(() => {
    if (!tileEdit && reqPeriod.fromDate && reqPeriod.toDate && reqPayload.length > 0 && unitStatistics.statisticTypes.length) {
      setLastUpdated(
        moment()
          .tz(currentTimezone)
          .format('HH:mm z')
      );
      let payload = {
        fromDashboard: fromDashboard,
        statisticTypes: reqPayload
      };

      if (fromDashboard) {
        payload.unitSerialNumbers = unitSerialNumbers;
      } else {
        payload.unitSerialNumbers = [unitSerialNumber];
      }

      if (payload.unitSerialNumbers.length > 0) {
        dispatch(getUnitStatisticsThunk(widget, payload, reqPeriod.fromDate, reqPeriod.toDate));
      }
    }
  }, [getUnitStatisticsThunk, dispatch, selectedPeriod, unitStatistics.statisticTypes, tileEdit, devices, unitSerialNumber]);

  //Cleaning up
  useEffect(() => {
    return () => {
      dispatch(cleanUpThunk(initialUnitStatisticsState));
    };
  }, []);

  return (
    <div className={`${s.unitStat} ${fromDashboard ? s.unitStatDashboard : ''}`}>
      <Banner
        key={uuidv4()}
        failureText={messageText}
        showBanner={unitStatistics.showBanner}
        status={unitStatistics.isOpSuccessful}
        successText={messageText}
      />
      {unitStatistics.isLoading && !tileEdit && <LoadingSpinner centeredLoading={false} />}
      <Row className={s.container}>
        <Col>
          <Row>
            <img
              src={statisticsOpen ? collapseDown : collapseUp}
              alt="Collapse section"
              onClick={() => setStatisticsOpen()}
              aria-controls="statistics-section"
              aria-expanded={statisticsOpen}
            ></img>
            {fromDashboard ? (
              <h3 className={s.subHeading} onClick={() => setPeriodSelection(!periodSelectionOpen)}>
                {getStatPeriodName(selectedPeriod)}
              </h3>
            ) : (
              <h4 className={s.subHeading} onClick={() => setPeriodSelection(!periodSelectionOpen)}>
                {getStatPeriodName(selectedPeriod)}
              </h4>
            )}
            <img src={blueDownArrow} onClick={() => setPeriodSelection(!periodSelectionOpen)}></img>
          </Row>

          {periodSelectionOpen && (
            <Row>
              <Col>
                <StatPeriodOptions
                  onStatPeriodChange={onStatPeriodChange}
                  intl={intl}
                  setPeriodSelection={setPeriodSelection}
                  getStatPeriodName={getStatPeriodName}
                  statPeriods={getStatisticPeriods()}
                />
              </Col>
            </Row>
          )}
        </Col>
      </Row>

      <Collapse in={statisticsOpen}>
        <div>
          <div className={s.updateDateTime}>
            <FormattedMessage id="unitStatistics.lastUpdated" defaultMessage="Last Updated" />
            {` ${lastUpdated}`}
          </div>

          <Row className={s.tiles}>
            <Col xs={12} md={12} lg={4}>
              <UnitStatisticsTile tileEdit={tileEdit} setTileEdit={setTileEdit} statistics={statSettings1} fromDashboard={fromDashboard} />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <UnitStatisticsTile tileEdit={tileEdit} setTileEdit={setTileEdit} statistics={statSettings2} fromDashboard={fromDashboard} />
            </Col>
            <Col xs={12} md={6} lg={4}>
              <UnitStatisticsTile tileEdit={tileEdit} setTileEdit={setTileEdit} statistics={statSettings3} fromDashboard={fromDashboard} />
            </Col>
          </Row>
        </div>
      </Collapse>
    </div>
  );
};

const StatPeriodOptions = ({ intl, statPeriods, getStatPeriodName, onStatPeriodChange, setPeriodSelection }) => {
  const clickRef = useRef();

  const handleClickOutside = e => {
    if (!clickRef.current.contains(e.target)) {
      setPeriodSelection(false);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  });
  return (
    <div className={s.statPeriodOptions} ref={clickRef}>
      {statPeriods.map((elem, index) => {
        return (
          <Row
            onClick={() => {
              onStatPeriodChange(elem);
            }}
            key={index}
            className={[s.statPeriodRows, index === 0 ? s.noBorder : '']}
          >
            <Col>{getStatPeriodName(elem.PeriodId)}</Col>
          </Row>
        );
      })}
    </div>
  );
};

export default injectIntl(UnitStatistics);
