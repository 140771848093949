import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import { REFRESH_TIME, REFRESH_TIME_OTHER } from '../../constants/index';
import s from './DeviceHeader.module.scss';
import pin from '../../assets/location.svg';
import clock from '../../assets/clock.svg';
import { FormattedMessage, injectIntl } from 'react-intl';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import moment from 'moment-timezone';
import Row from 'react-bootstrap/Row';
import onlineIcon from '../../assets/online.svg';
import offlineIcon from '../../assets/offline.svg';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const Item = ({ image, title, data, className }) => (
  <div className={className}>
    <img src={image} alt="icon" />
    {title !== '' ? (
      <span>
        {title}: {data}
      </span>
    ) : (
      <span>{data}</span>
    )}
  </div>
);

const Status = ({ online }) => (
  <div className={s.deviceStatus}>
    <img src={online ? onlineIcon : offlineIcon} alt="online" />
    {online ? (
      <FormattedMessage id="deviceHeader.online" defaultMessage="Online" />
    ) : (
      <FormattedMessage id="deviceHeader.offline" defaultMessage="Offline" />
    )}
  </div>
);

const DeviceHeader = ({
  backLink,
  unitSerialNumber,
  Name,
  deviceStatus,
  country,
  currentPath,
  installDate,
  fetchSensorReadings,
  location,
  changeTimezone,
  deviceTimezone,
  currentTimezone,
  intl
}) => {
  const refreshTime = currentPath.includes('/process-flow') ? REFRESH_TIME : REFRESH_TIME_OTHER;
  const currentlocation = useLocation();
  const showTimezone = currentlocation.pathname ? !currentlocation.pathname.includes('unit/reports') : true;
  const labelChecked = intl.formatMessage({ id: 'deviceHeader.switchLocalTimezone', defaultMessage: 'Switch to local time zone' });
  const labelUnchecked = intl.formatMessage({ id: 'deviceHeader.switchUnitTimezone', defaultMessage: 'Switch to unit time zone' });
  const labelNotAvailable = intl.formatMessage({ id: 'deviceHeader.notAvailable', defaultMessage: 'Not Available' });
  const isLocalTimeZone = currentTimezone === deviceTimezone ? false : true;
  const [showLocalTimeZone, setShowUnitTimeZone] = useState(isLocalTimeZone);
  const labelTime = isLocalTimeZone ? (
    <FormattedMessage id="deviceHeader.localTime" defaultMessage="Local time" />
  ) : (
    <FormattedMessage id="deviceHeader.unitTime" defaultMessage="Unit time" />
  );
  const tooltip = !showLocalTimeZone ? labelChecked : labelUnchecked;

  const handleClick = () => {
    setShowUnitTimeZone(!showLocalTimeZone);
    deviceTimezone !== '' && changeTimezone(showLocalTimeZone, deviceTimezone);
  };

  useEffect(() => {
    if (unitSerialNumber && unitSerialNumber.length > 0) {
      fetchSensorReadings(unitSerialNumber);
      const interval = setInterval(() => {
        fetchSensorReadings(unitSerialNumber);
      }, refreshTime);
      return () => clearInterval(interval);
    }
  }, [unitSerialNumber, fetchSensorReadings, refreshTime]);

  return (
    <div className={s.deviceHeader}>
      <Link className={s.backLink} to={backLink}>
        <FormattedMessage id="deviceHeader.back" defaultMessage="BACK TO UNITS" />
      </Link>
      <h1>{Name}</h1>

      <Row className={s.details}>
        <Status online={deviceStatus} />
        {location && <Item image={pin} title="Location" data={`${location}, ${country}`} className={s.location} />}

        {showTimezone && (
          <div className={`${s.localTime} pr-0`}>
            <img src={clock} alt="icon" />
            <span>
              {labelTime}:{' '}
              {showLocalTimeZone || (!showLocalTimeZone && deviceTimezone !== '')
                ? moment()
                    .tz(currentTimezone)
                    .format('HH:mm:ss z')
                : labelNotAvailable}
            </span>
            <ToggleSwitch
              handleClick={handleClick}
              classname={s.switch}
              checked={!showLocalTimeZone}
              labelChecked=""
              labelUnchecked=""
              tooltip={tooltip}
            />
          </div>
        )}

        {installDate && (
          <Item
            image={clock}
            title="Date installed"
            data={moment(installDate)
              .tz(currentTimezone)
              .format('HH:mm:ss z')}
            className={s.installedDate}
          />
        )}
      </Row>
      <Tooltip  />
    </div>
  );
};

DeviceHeader.defaultProps = {
  deviceTimezone: '',
  currentTimezone: moment.tz.guess()
};

DeviceHeader.propTypes = {
  backLink: PropTypes.string.isRequired,
  unitSerialNumber: PropTypes.string,
  deviceStatus: PropTypes.bool.isRequired,
  country: PropTypes.string,
  currentPath: PropTypes.string.isRequired,
  fetchSensorReadings: PropTypes.func.isRequired,
  installDate: PropTypes.number,
  location: PropTypes.string,
  changeTimezone: PropTypes.func.isRequired,
  deviceTimezone: PropTypes.string.isRequired,
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(DeviceHeader);
