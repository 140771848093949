import Cookies from 'js-cookie';
import {
  SET_DEVICE_SEQUENCE,
  DEVICE_ERROR,
  SET_BUTTON_STATE,
  UPDATE_COMMAND_STATUS,
  SET_DEVICES,
  SET_SELECTED_DEVICE,
  SET_SELECTED_VIEW,
  SET_DEVICE_TIMEZONE,
  GET_DEVICES_STATUS,
  GET_DEVICES_ALARMS_DETAILS,
  GET_UNIT_COMPONENT_PARTS,
  GET_UNIT_COMPONENT_PARTS_ERROR,
  DEVICE_LOADING,
  COMMAND_MODE_MANUAL
} from '../constants/index';
import { DEVICE_MANAGEMENT, SEND_DEVICE_COMMAND } from '../constants/logs';
import { apiAction, logEntry } from '../utils/index';
import tzLookup from 'tz-lookup';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setDeviceErrorThunk = (err, status) => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setCommandButtonStatus(status));
  dispatch(setDeviceError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setDeviceError(messageCode, false));
  }, 5000);
};

export const setDeviceError = (messageCode, status) => {
  return {
    type: DEVICE_ERROR,
    payload: {
      error: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode
      }
    }
  };
};

export const setDeviceLoading = status => ({
  type: DEVICE_LOADING,
  payload: { status }
});

export const setDevices = ({ devices }) => ({
  type: SET_DEVICES,
  payload: { devices }
});

export const setSelectedUnit = unitSerialNumber => ({
  type: SET_SELECTED_DEVICE,
  payload: { unitSerialNumber }
});

export const setSelectedView = viewName => ({
  type: SET_SELECTED_VIEW,
  payload: { viewName }
});

export const setCommandButtonStatus = (disabled, command) => ({
  type: SET_BUTTON_STATE,
  payload: { disabled, command }
});

export const sendCommand = (command, widgetCode, showBanner = true, ctype = COMMAND_MODE_MANUAL, value = null) => (dispatch, getState) => {
  const { unitSerialNumber } = getState().devices;
  dispatch(setCommandButtonStatus(true, command));
  let log = logEntry(
    DEVICE_MANAGEMENT,
    SEND_DEVICE_COMMAND,
    {
      command: command,
      comamndtype: ctype,
      unitSerialNumber: unitSerialNumber,
      commandValue: value
    },
    unitSerialNumber
  );
  dispatch(postCommand(command, unitSerialNumber, ctype, log, widgetCode, showBanner, value));
};

export const postCommand = (command, unitSerialNumber, ctype, log, widgetCode, showBanner, commandValue = null) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/command`,
    method: 'POST',
    onSuccess: retData => setCommandStatusThunk(retData, true, showBanner),
    onLoad: status => setDeviceLoading(status),
    onFailure: err => setDeviceErrorThunk(err, false),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      command,
      ctype,
      log,
      commandValue
    },
    widgetcode: widgetCode
  });

export const setCommandStatusThunk = (retData, status, showBanner) => dispatch => {
  const message = retData.message;
  dispatch(setCommandStatus(status, showBanner, message));

  setTimeout(() => {
    //Hide the banner and make the buttons active
    dispatch(setCommandButtonStatus(false));
    dispatch(setCommandStatus(null, false, ''));
  }, 5000);
};

export const setCommandStatus = (status, showBanner, message) => ({
  type: UPDATE_COMMAND_STATUS,
  payload: { status, showBanner, message }
});

export const setActiveDeviceSequence = (unitSerialNumber, sequence) => ({
  type: SET_DEVICE_SEQUENCE,
  payload: { unitSerialNumber, sequence }
});

export const setDeviceTimezone = (lat, long) => dispatch => {
  try {
    const deviceTimezone = tzLookup(lat, long);
    if (deviceTimezone) {
      dispatch(setActiveDeviceTimezone(deviceTimezone));
    }
  } catch (ex) {
    console.log(ex.message);
  }
};

export const setActiveDeviceTimezone = deviceTimezone => ({
  type: SET_DEVICE_TIMEZONE,
  payload: { deviceTimezone }
});

export const fetchDevicesStatus = (unitSerialNumbers, fromDate, toDate, widgetCode) => {
  return apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/unitsstatus`,
    method: 'POST',
    widgetcode: widgetCode,
    onSuccess: response => devicesStatusSuccessThunk(response),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      unitSerialNumbers: unitSerialNumbers,
      fromDate: fromDate,
      toDate: toDate
    }
  });
};
export const devicesStatusSuccessThunk = response => dispatch => {
  // setDevicesStatus(response);
  dispatch(setDevicesStatus(response));
};

export const setDevicesStatus = response => ({
  type: GET_DEVICES_STATUS,
  payload: response
});

export const devicesStatusFailedThunk = message => {
  devicesStatusSuccessThunk(message);
};

export const getDeviceAlarmsThunk = (unitSerialNumbers, status, widgetCode) => dispatch => {
  dispatch(fetchDevicesAlarms(unitSerialNumbers, status, widgetCode));
};

export const fetchDevicesAlarms = (unitSerialNumbers, status, widgetCode) => {
  return apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/operation/alarms`,
    data: {
      OrganisationId: Cookies.get(`selectedorganisationid-${envName}`) || 0,
      ProfileId: Cookies.get(`selectedprofileid-${envName}`) || 0,
      UserId: Cookies.get(`userid-${envName}`) || 0,
      Status: status
    },
    widgetcode: widgetCode,
    method: 'GET',
    onSuccess: retData => setAllAlarmListThunk(unitSerialNumbers, retData),
    onFailure: err => setAlarmErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`)
  });
};

export const setAllAlarmListThunk = (unitSerialNumbers, retData) => dispatch => {
  let messageCode = retData && retData.message;
  let data = (retData && retData.eventActions) || [];
  let eventActions = [];

  if (data.length > 0) {
    unitSerialNumbers.forEach(element => {
      let filteredList = data.filter(x => x.UnitSerialNumber == element);
      if (filteredList && filteredList.length > 0) {
        Array.prototype.push.apply(eventActions, filteredList);
      }
    });
  }

  dispatch(setAllAlarmList(messageCode, eventActions));
};

export const setAllAlarmList = (messageCode, eventActions) => ({
  type: GET_DEVICES_ALARMS_DETAILS,
  payload: {
    data: eventActions
  }
});

export const setAlarmErrorThunk = message => dispatch => {
  dispatch(setAllAlarmListThunk(message));
};

//Unit Components

export const getUnitComponentPartsThunk = (unitSerialNumber, unitId, widgetCode) => dispatch => {
  dispatch(fetchUnitComponentParts(unitSerialNumber, unitId, widgetCode));
};

export const fetchUnitComponentParts = (unitSerialNumber, unitId, widgetCode) => {
  return apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/unitComponents`,
    data: {
      UnitId: unitId || 0
    },
    method: 'GET',
    onSuccess: retData => setAllUnitComponentPartThunk(retData),
    onFailure: err => setUnitComponentPartError(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });
};

export const setAllUnitComponentPartThunk = ({ retData }) => dispatch => {
  let messageCode = retData && retData.message;
  let UnitComponentParts = (retData && retData.unitComponentParts) || [];
  let UnitSubComponentDetails = (retData && retData.unitSubComponentDetails) || [];
  dispatch(setAllUnitComponentPartList(messageCode, UnitComponentParts, UnitSubComponentDetails));
};

export const setAllUnitComponentPartList = (messageCode, UnitComponentParts, UnitSubComponentDetails) => ({
  type: GET_UNIT_COMPONENT_PARTS,
  payload: {
    UnitComponentParts: UnitComponentParts,
    UnitSubComponentDetails: UnitSubComponentDetails
  }
});

export const setUnitComponentPartError = value => ({
  type: GET_UNIT_COMPONENT_PARTS_ERROR,
  payload: {
    data: value
  }
});
