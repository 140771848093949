import React, { useEffect, useState } from 'react';
import { useParams, Link, Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';
import s from './SequenceVersion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { initialSequenceVersionState } from '../../reducers/initialState';
import SequenceVersionTable from './SequenceVersionTable';
import Pagination from '../Pagination/Pagination';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import { DEFAULT_PAGE_SIZE } from '../../constants';
import { getQuerystring } from '../../utils';
import Banner from '../Banner/Banner';
import { v4 as uuid } from 'uuid' ;

const SequenceVersion = ({
  getSequenceVersions,
  setCurrentPage,
  setFilteredData,
  sequenceVersionManager,
  userProfileWidgets,
  redirect,
  currentTimezone,
  describeModelVersion,
  modelVersionManager,
  sequenceVersionCleanUp,
  saveSequenceVersion,
  changeSequenceVersion,
  intl
}) => {
  const SEARCH_FILTER = 'SEARCH_FILTER';
  let { modelVersionId, modelId } = useParams();
  const [searchFilter, setSearchFilter] = useState('');

  const changeAllowed = IsWidgetAccessible(userProfileWidgets, `SEQUENCEVERSIONUPDATE`);
  let id = getQuerystring('id') || '0';

  useEffect(() => {
    if (!modelVersionManager || !modelVersionManager.selectedModelVersion || !modelVersionManager.selectedModelVersion.ModelVersionId)
      describeModelVersion(modelVersionId);
  }, [describeModelVersion, modelVersionId]);

  useEffect(() => {
    getSequenceVersions(modelVersionId);
  }, [getSequenceVersions, modelVersionId]);

  const onPageChangeHandler = currentPage => {
    setCurrentPage(currentPage);
  };

  const searchHandler = value => {
    setSearchFilter(value);
    filteredResults(SEARCH_FILTER, value);
    setCurrentPage(1);
  };

  const duplicateSequenceVersion = id => {
    let saveData = {};
    saveData.SequenceVersionId = id;
    saveData.ModelVersionId = modelVersionId;
    saveData.IsCopy = true;

    sequenceVersionManager.duplicateSequence = true;
    changeSequenceVersion(sequenceVersionManager);
    saveSequenceVersion(saveData, 'SEQUENCEVERSIONUPDATE');
  };

  useEffect(() => {
    return () => {
      sequenceVersionCleanUp(initialSequenceVersionState.sequenceVersionManager);
    };
  }, []);

  let sequenceVersionObj = Object.values(sequenceVersionManager.filteredResults);

  const filteredResults = (filterType, value) => {
    let filteredList = sequenceVersionManager.sequenceVersions;

    let apifilterObj = {
      filter: null
    };

    if (filterType === SEARCH_FILTER) {
      if (value !== '') {
        apifilterObj.filter = value;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(value.toLowerCase());
        });
      }
    } else {
      if (searchFilter !== '') {
        apifilterObj.filter = searchFilter;
        filteredList = filteredList.filter(function(item) {
          return item.Name.toLowerCase().includes(searchFilter.toLowerCase());
        });
      }
    }
    setFilteredData({ filteredResults: { filteredResults: filteredList } });
  };
  const returnTo = () => {
    if (id != '0') {
      return (
        <Navigate
          to={`/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/${id}`}
        />
      );
    }
  };
  let returnUrl = `/admin-controls/model-configuration/${modelId}`;
  let heading = intl.formatMessage({ id: 'sequenceVersionManagement.sequenceVersionManager', defaultMessage: 'Sequence Versions' });
  const modelName = (modelVersionManager.selectedModelVersion ? modelVersionManager.selectedModelVersion.ModelName : '') || '';
  const modelVersionName =
    (modelVersionManager && modelVersionManager.selectedModelVersion && modelVersionManager.selectedModelVersion.Name) || '';

  let messageId = (sequenceVersionManager && sequenceVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  // if(sequenceVersionManager.showBanner && sequenceVersionManager.duplicateSequence) {
  //   getSequenceVersions(modelVersionId);
  // }
  //To reaload the page after copy
  useEffect(() => {
    if (sequenceVersionManager.isOpSuccessful && sequenceVersionManager.showBanner && sequenceVersionManager.duplicateSequence) {
      getSequenceVersions(modelVersionId);
    }
  }, [
    getSequenceVersions,
    modelVersionId,
    sequenceVersionManager.isOpSuccessful,
    sequenceVersionManager.showBanner,
    sequenceVersionManager.duplicateSequence
  ]);

  return (
    <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
      {returnTo()}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={(sequenceVersionManager.showBanner && sequenceVersionManager.duplicateSequence) || false}
        status={sequenceVersionManager.isOpSuccessful}
        warning={sequenceVersionManager.warning}
        successText={messageText}
      />
      <div style={WidgetVisibility(userProfileWidgets, `SEQUENCEVERSIONLIST`)}>
        <div className={s.pageContent}>
          <div className={s.sequenceVersions}>
            {sequenceVersionManager.isLoading && <LoadingSpinner />}
            <Row>
              <Col sm={6} lg={8}>
                <Link className={s.backLink} to={returnUrl}>
                  &lt; &nbsp;
                  <FormattedMessage id="modelFirmwareVersion.backToModelConfiguration" defaultMessage="BACK TO MODEL CONFIGURATION" />
                </Link>
                <h3>
                  {heading}: {modelName} : {modelVersionName}
                </h3>
              </Col>
            </Row>
            <div className={s.contentWrapper}>
              <Row>
                <Col sm={6} lg={4}>
                  <div>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={true}
                      initialText={searchFilter}
                      placeHolderTextId="sequenceVersionManagement.search"
                    />
                  </div>
                </Col>
                <Col sm={6} lg={8} style={WidgetVisibility(userProfileWidgets, `SEQUENCEVERSIONADD`)}>
                  <Link
                    to={`/admin-controls/model-configuration/${modelId}/model-version-configuration/${modelVersionId}/sequence-version/0`}
                  >
                    <Button className={s.addbutton}>
                      + <FormattedMessage id="sequenceVersionManagement.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Link>
                </Col>
              </Row>
              <div className="">
                <Pagination
                  data={sequenceVersionObj}
                  pageSize={DEFAULT_PAGE_SIZE}
                  onPageChange={onPageChangeHandler}
                  startingPage={sequenceVersionManager.currentPage}
                >
                  <SequenceVersionTable
                    redirect={redirect}
                    currentPage={sequenceVersionManager.currentPage}
                    currentTimezone={currentTimezone}
                    modelId={modelId}
                    modelVersionId={modelVersionId}
                    changeAllowed={changeAllowed}
                    duplicateSequenceVersion={duplicateSequenceVersion}
                  />
                </Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AdminControlsContentTemplate>
  );
};

SequenceVersion.defaultProps = {
  sequenceVersionManager: initialSequenceVersionState.sequenceVersionManager
};

SequenceVersion.propTypes = {
  setPageFilter: PropTypes.func.isRequired,
  getSequenceVersion: PropTypes.func.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setPageFilter: PropTypes.func.isRequired,
  currentTimezone: PropTypes.string.isRequired,
  sequenceVersionManager: PropTypes.object.isRequired
};

export default injectIntl(SequenceVersion);
