import { Provider } from 'react-redux';
import React from 'react';
import { createRoot } from 'react-dom/client'; // Updated import for React 18
import App from './App';
import configureStore, { history, loadState, saveState, filterOfflineChartData } from './configureStore';
import throttle from 'lodash.throttle';
import './styles/reset.css';
import './styles/global.scss';
import 'bootstrap/dist/css/bootstrap.min.css';

// Make sure process is available globally to fix webpack 5 issues
window.process = window.process || {};
window.process.env = window.process.env || {};

const persistedState = loadState();
const store = configureStore(persistedState);

store.subscribe(
  throttle(() => {
    const { charts, devices } = store.getState();
    if (Object.keys(charts).length !== 0) {
      saveState({
        charts: filterOfflineChartData(charts),
        devices
      });
    }
  }, 2000)
);

// React 18 uses createRoot instead of ReactDOM.render
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <Provider store={store}>
    <App history={history} />
  </Provider>
);
 