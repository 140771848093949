import React from 'react';
import PropTypes from 'prop-types';
import s from './GeneralGlobalSettingsList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import MoreOption from '../WSAControls/MoreOption/MoreOption';
import { v4 as uuidv4 } from 'uuid';
import { FLOWSEQUENCE_TYPE, SERVICE_TYPE, GLOBAL_SETTINGS_TYPE } from '../../constants';

const GeneralGlobalSettingsListTable = ({
  paginatedData,
  currentPage,
  changeAllowed,
  deleteAllowed,
  duplicateAllowed,
  currentTimezone,
  onDeleteClick
}) => {
  const getMoreOptions = generalGlobalSettingId => {
    let options = [];
    if (changeAllowed) {
      options.push({
        Url: `/admin-controls/general-global-settings/${generalGlobalSettingId}`,
        Name: <FormattedMessage id="globalSettingsManagement.change" defaultMessage="Change" />
      });
    }

    if (duplicateAllowed) {
      options.push({
        Url: `/admin-controls/general-global-settings/${generalGlobalSettingId}?duplicate=1`,
        Name: <FormattedMessage id="globalSettingsManagement.duplicate" defaultMessage="Duplicate" />
      });
    }

    if (deleteAllowed) {
      options.push({
        Name: <FormattedMessage id="globalSettingsManagement.remove" defaultMessage="Remove" />,
        Click: () => {
          onDeleteClick(generalGlobalSettingId);
        }
      });
    }

    return options;
  };

  const getGlobalSettingsType = type => {
    let returnType = <FormattedMessage id="globalSettingsManagement.flowSequence" defaultMessage="Flow Sequence" />;
    switch (type) {
      case FLOWSEQUENCE_TYPE:
        returnType = <FormattedMessage id="globalSettingsManagement.flowSequence" defaultMessage="Flow Sequence" />;
        break;
      case GLOBAL_SETTINGS_TYPE:
        returnType = <FormattedMessage id="globalSettingsManagement.globalSetting" defaultMessage="Global Setting" />;
        break;
      case SERVICE_TYPE:
        returnType = <FormattedMessage id="globalSettingsManagement.serviceType" defaultMessage="Service Type" />;
        break;
      default:
        break;
    }

    return returnType;
  };

  return (
    <div className="">
      <Table variant className={s.table}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="globalSettingsManagement.name" defaultMessage="Name" />
            </th>
            <th>
              <FormattedMessage id="globalSettingsManagement.type" defaultMessage="type" />
            </th>
            <th>
              <FormattedMessage id="globalSettingsManagement.code" defaultMessage="Code" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="globalSettingsManagement.description" defaultMessage="Description" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="globalSettingsManagement.defaultValue" defaultMessage="Default Value" />
            </th>
            <th className="d-none d-md-table-cell">
              <FormattedMessage id="globalSettingsManagement.unitOfMeasure" defaultMessage="Unit of Measure" />
            </th>
            {(changeAllowed || deleteAllowed || duplicateAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {paginatedData.map(n => {
            return (
              <tr key={n.GeneralGlobalSettingId} data-unittest="globalSettingsData">
                <td>{n.Name || ''}</td>
                <td>{getGlobalSettingsType(n.Type)}</td>
                <td>{n.Code || ''}</td>
                <td className="d-none d-md-table-cell">{n.Description || ''}</td>
                <td className="d-none d-md-table-cell">{n.DefaultValue || ''}</td>
                <td className="d-none d-md-table-cell">{n.UnitOfMeasure || ''}</td>
                <td>
                  <div className={s.moreOtions}>
                    {(changeAllowed || deleteAllowed || duplicateAllowed) && (
                      <MoreOption key={uuidv4()} dataArray={getMoreOptions(n.GeneralGlobalSettingId)} />
                    )}
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

GeneralGlobalSettingsListTable.defaultProps = {
  paginatedData: [],
  currentPage: 1
};

GeneralGlobalSettingsListTable.propTypes = {
  currentTimezone: PropTypes.string.isRequired
};

export default injectIntl(GeneralGlobalSettingsListTable);
