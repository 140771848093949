import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './MoveUnits.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialOrganisationManagerState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Navigate, Link, useParams } from 'react-router-dom';
import OrganisationUnits from './OrganisationUnits';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import 'react-phone-input-2/lib/style.css';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import { getOrganisationManager, getUserProfileWidget } from '../../selectors/index';
import {
  postOrganisationThunk,
  describeOrganisationThunk,
  updateSelectedOrganisationThunk,
  setOrganisationUnitsThunk
} from '../../actions/organisationManager';

const MoveUnits = ({ intl }) => {
  const dispatch = useDispatch();
  const organisationManager = useSelector(state => getOrganisationManager(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));

  let IsRedirect = false;
  let IsUnitTransfer = true;
  let { organisationId } = useParams();
  let orgUnitKey = '';
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  const [formErrors, setFormErrors] = useState({});
  const [unitFilter, setUnitFilter] = useState('');
  const [modelVersionsByOrgId, setModelVersionsByOrgId] = useState([]);
  const [selectedModelVersion, setSelectedModelVersion] = useState({});
  const [selectedModelId, setSelectedModelId] = useState(0);

  let messageId = (organisationManager && organisationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  if (organisationId) {
    //redirecting to list if selected organisation is empty and OrganisationId is not blank
    if (!organisationId) {
      IsRedirect = true;
    }
  }

  let widgetCode = 'ORGANISATIONMOVEUNITS';

  useEffect(() => {
    if (organisationId) {
      dispatch(describeOrganisationThunk(organisationId, widgetCode, IsUnitTransfer));
    } else {
      setLocalSelectedOrganisation({
        ...organisationManager,
        selectedOrganisation: { ...initialOrganisationManagerState.organisationManager.selectedOrganisation },
        toFilterUnits: [],
        units: []
      });
    }
  }, [describeOrganisationThunk, organisationId, widgetCode, IsUnitTransfer]);

  // Update redux store
  const setLocalSelectedOrganisation = currentState => {
    dispatch(updateSelectedOrganisationThunk(currentState));
  };

  const setOrganisationUnits = currentState => {
    dispatch(setOrganisationUnitsThunk(currentState));
  };

  const scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  useEffect(() => {
    return () => {
      setLocalSelectedOrganisation({
        ...organisationManager,
        selectedOrganisation: {
          ...initialOrganisationManagerState.organisationManager.selectedOrganisation,
          Users: [],
          Units: []
        },
        parentOrganisationData: { ...initialOrganisationManagerState.organisationManager.parentOrganisationData },
        toFilterUsers: [],
        toFilterUnits: [],
        units: [],
        users: []
      });
    };
  }, []);

  //Saving
  const submitForm = e => {
    e.preventDefault();
    scrollToTop();
    if (!validateInput()) {
      return;
    }

    let saveData = {
      OrganisationId: organisationManager.selectedOrganisation.OrganisationId,
      TransferOrganisationId: organisationManager.selectedOrganisation.TransferOrganisationId,
      Units: organisationManager.selectedOrganisation.TransferUnits
    };
    saveData.IsUnitTransfer = IsUnitTransfer;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    //saving Organisation
    dispatch(postOrganisationThunk(saveData, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !organisationManager ||
      !organisationManager.selectedOrganisation ||
      !organisationManager.selectedOrganisation.TransferUnits ||
      !organisationManager.selectedOrganisation.TransferUnits.length > 0
    ) {
      formErrors.TransferUnits = intl.formatMessage({
        id: 'organisationManagement.transferUnitsMandatory',
        defaultMessage: 'Please move at least one unit'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const onOrganisationChange = e => {
    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, TransferOrganisationId: e.value }
    });
    let modelVersions = modelVersionOptions(e.value, selectedModelId);
    setModelVersionsByOrgId(modelVersions);
    if (modelVersions.length > 0) {
      setSelectedModelVersion(modelVersions[0]);
    }
  };

  //handler methods for unit component
  const onUnitSearch = filter => {
    setUnitFilter(filter);
    if (filter === '') {
      let initialOrganisationUnits = organisationManager.units;

      setOrganisationUnits({
        ...organisationManager,
        toFilterUnits: initialOrganisationUnits
      });
    }

    let orgUnits =
      organisationManager.units &&
      organisationManager.units.filter(function(item) {
        return (
          item.UnitName.toLowerCase().includes(filter.toLowerCase()) || item.ModelVersionName.toLowerCase().includes(filter.toLowerCase())
        );
      });

    setOrganisationUnits({
      ...organisationManager,
      toFilterUnits: orgUnits
    });
  };

  const onUnitsMove = selectedUnits => {
    const currentTransferUnits = organisationManager.selectedOrganisation.TransferUnits || [];
    currentTransferUnits.push(...selectedUnits);
    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, TransferUnits: currentTransferUnits, SelectedTransferUnits: [] }
    });

    let selectedUnitIds = selectedUnits.map(a => a.UnitId);

    let orgUnits = organisationManager.units && organisationManager.units.filter(x => !selectedUnitIds.includes(x.UnitId));

    setOrganisationUnits({
      ...organisationManager,
      toFilterUnits: orgUnits
    });
  };

  const onSelectedUnitsChange = selectedUnits => {
    if (Object.keys(formErrors).length > 0) {
      setFormErrors({});
    }

    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, SelectedTransferUnits: selectedUnits }
    });
  };

  const onUnitsRemove = unitId => {
    let transferUnits =
      organisationManager.selectedOrganisation.TransferUnits &&
      organisationManager.selectedOrganisation.TransferUnits.filter(x => x.UnitId !== unitId);

    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, TransferUnits: transferUnits }
    });

    let selectedUnitIds = transferUnits.map(a => a.UnitId);

    let orgUnits = organisationManager.units && organisationManager.units.filter(x => !selectedUnitIds.includes(x.UnitId));

    setOrganisationUnits({
      ...organisationManager,
      toFilterUnits: orgUnits
    });
  };

  const organisationOptions = () => {
    let organisations = [];
    //add curren organisation
    organisations.push({
      label: organisationManager.selectedOrganisation.Name,
      value: organisationManager.selectedOrganisation.OrganisationId
    });

    //Add parent organisation
    if (organisationManager.selectedOrganisation.OrganisationId !== organisationManager.selectedOrganisation.ParentOrganisationId) {
      organisations.push({
        label: organisationManager.selectedOrganisation.ParentOrganisationName,
        value: organisationManager.selectedOrganisation.ParentOrganisationId
      });
    }

    //Add sub organisations
    organisationManager.selectedOrganisation.SubOrganisations.forEach(element => {
      if (organisationManager.selectedOrganisation.OrganisationId !== element.OrganisationId) {
        organisations.push({
          label: element.Name,
          value: element.OrganisationId
        });
      }
    });
    return organisations;
  };

  const modelOptions = () => {
    let opData = [];

    const modelsData = organisationManager.orgModels;

    //Add sub organisations
    modelsData.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.ModelId
      });
    });
    return opData;
  };

  const modelVersionOptions = (organisationId, val) => {
    let opData = [];

    const modelVesionByOrgId =
      organisationManager.orgModelVersions &&
      organisationManager.orgModelVersions.filter(x => x.OrganisationId === organisationId && x.ModelId === val);

    //Add sub organisations
    modelVesionByOrgId.forEach(element => {
      opData.push({
        label: element.Name,
        value: element.ModelVersionId
      });
    });
    return opData;
  };

  const onModelChange = val => {
    let modelVersions = modelVersionOptions(organisationManager.selectedOrganisation.TransferOrganisationId, val);
    setModelVersionsByOrgId(modelVersions);
    setSelectedModelId(val);
    if (modelVersions.length > 0) {
      setSelectedModelVersion(modelVersions[0]);
    }

    setLocalSelectedOrganisation({
      ...organisationManager,
      selectedOrganisation: { ...organisationManager.selectedOrganisation, SelectedTransferUnits: [] }
    });
  };

  return (
    <div className={s.organisation}>
      {IsRedirect ? <Navigate to="/admin-controls/organisation-list" /> : ''}
      {organisationManager.isLoading && <LoadingSpinner />}

      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={organisationManager.showBanner}
        status={organisationManager.isOpSuccessful}
        successText={messageText}
      />
      {organisationManager.isOpSuccessful && !organisationManager.showBanner ? <Navigate to="/admin-controls/organisation-list" /> : ''}

      <AdminControlsContentTemplate selectedPage="manageOrganisations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.organisationHeader}>
            <Link className={s.backLink} to={'/admin-controls/organisation-list'}>
              &lt; &nbsp;
              <FormattedMessage id="organisationManagement.backToOrganisations" defaultMessage="BACK TO ORGANISATIONS" />
            </Link>
            <h3>
              <FormattedMessage id="organisationManagement.moveUnits" defaultMessage="Move Units" />
            </h3>
          </div>
          <div className={s.organisationContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formOrganisationName">
                    <Form.Label>
                      <FormattedMessage id="organisationManagement.organisationName" defaultMessage="Organisation Name" />
                    </Form.Label>

                    <span>: {organisationManager.selectedOrganisation.Name}</span>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h4>
                    <FormattedMessage id="organisationManagement.units" defaultMessage="Units" />
                  </h4>
                  <p>
                    <FormattedMessage
                      id="organisationManagement.moveUnitsToOrganisation"
                      defaultMessage="Move Units to organisation model versions"
                    />
                  </p>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <OrganisationUnits
                    key={orgUnitKey}
                    organisationManager={organisationManager}
                    onUnitSearch={onUnitSearch}
                    initialText={unitFilter}
                    organisationOptions={organisationOptions}
                    modelOptions={modelOptions}
                    onOrganisationChange={onOrganisationChange}
                    modelVersionsByOrgId={modelVersionsByOrgId}
                    selectedModelVersion={selectedModelVersion}
                    setSelectedModelVersion={setSelectedModelVersion}
                    selectedModelId={selectedModelId}
                    setSelectedModel={onModelChange}
                    onUnitsMove={onUnitsMove}
                    onUnitsRemove={onUnitsRemove}
                    onSelectedUnitsChange={onSelectedUnitsChange}
                  />
                </Col>
              </Row>
              {formErrors && formErrors.TransferUnits && (
                <p role="alert" className={s.error}>
                  {formErrors.TransferUnits}
                </p>
              )}
              <div>
                <Button
                  variant="primary"
                  data-unittest="saveData"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                >
                  <FormattedMessage id="common.save" defaultMessage="Save" />
                </Button>
                <Link to="/admin-controls/organisation-list">
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="profile.profileCancel" defaultMessage="Cancel" />
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

MoveUnits.defaultProps = {
  organisationManager: {
    ...initialOrganisationManagerState.organisationManager,
    units: [],
    toFilterUnits: []
  }
};

export default injectIntl(MoveUnits);
