import React, { Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersionGlobalSettings.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import help from '../../assets/help-grey.svg';
import ModelVersionGlobalSetting from './ModelVersionGlobalSetting';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

const ModelVersionGlobalSettingsListTable = ({
  setEditHandler,
  ModelVersionId,
  selectedModelVersionGlobalSettingId,
  saveModelVersionGlobalSetting,
  modelVersionGlobalSettings,
  describeModelVersionGlobalSetting,
  changeModelVersionGlobalSetting,
  unitGlobalSettings,
  globalSettingsData,
  UpdateAllowed,
  DeleteAllowed,
  ReadOnly,
  disableMode,
  disableModeHandler,
  addNewRef,
  intl,
  removeGlobalSetting
}) => {
  const fetchDataHandler = modelVersionGlobalSettingId => {
    setEditHandler(modelVersionGlobalSettingId);
    disableModeHandler(true);
    describeModelVersionGlobalSetting(modelVersionGlobalSettingId);
  };

  const cancelHandler = () => {
    setEditHandler(-1);
    disableModeHandler(false);
  };

  const editModelVersionContent = (
    <ModelVersionGlobalSetting
      ModelVersionGlobalSettingId={selectedModelVersionGlobalSettingId}
      ModelVersionId={ModelVersionId}
      saveModelVersionGlobalSetting={saveModelVersionGlobalSetting}
      describeModelVersionGlobalSetting={describeModelVersionGlobalSetting}
      modelVersionGlobalSetting={modelVersionGlobalSettings}
      changeModelVersionGlobalSetting={changeModelVersionGlobalSetting}
      unitGlobalSettings={unitGlobalSettings}
      cancelHandler={cancelHandler}
    />
  );

  useEffect(() => {
    Tooltip.rebuild();
  });

  let editorMain = (
    <tr>
      <td colSpan="10">{editModelVersionContent}</td>
    </tr>
  );

  let newRowClass = modelVersionGlobalSettings.showAddEditScreen && selectedModelVersionGlobalSettingId === 0 ? s.newRow : '';

  return (
    <div className="">
      <Table variant className={s.innerTable}>
        <thead>
          <tr>
            <th>
              <FormattedMessage id="modelVersion.settings" defaultMessage="Settings" />
            </th>
            <th>
              <FormattedMessage id="modelVersion.value" defaultMessage="Value" />
            </th>
            {!ReadOnly && (UpdateAllowed || DeleteAllowed) && <th></th>}
          </tr>
        </thead>
        <tbody>
          {globalSettingsData.map(n => {
            let tooltip = intl.formatMessage({ id: n.TranslationCode, defaultMessage: n.Description || n.Name });
            return (
              <Fragment key={n.ModelVersionGlobalSettingId}>
                <tr key={n.ModelVersionGlobalSettingId} data-unittest="globalSettingsData">
                  <td>
                    {n.Name} <img src={help} alt="icon" className={s.icon} data-tip={tooltip}></img>
                  </td>
                  <td>
                    {n.Value} {n.UnitOfMeasure}
                  </td>
                  {!ReadOnly && (UpdateAllowed || DeleteAllowed) && (
                    <td className={s.btnCol}>
                      {UpdateAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.changeButton}
                          href="#"
                          onClick={fetchDataHandler.bind(this, n.ModelVersionGlobalSettingId)}
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="modelVersion.change" defaultMessage="Change" />
                        </Button>
                      )}
                      {DeleteAllowed && (
                        <Button
                          variant="outline-secondary"
                          className={s.removeButton}
                          href="#"
                          onClick={() => removeGlobalSetting(n.ModelVersionGlobalSettingId)}
                          disabled={disableMode}
                          data-unittest="changeData"
                        >
                          <FormattedMessage id="modelVersion.remove" defaultMessage="Remove" />
                        </Button>
                      )}
                    </td>
                  )}
                </tr>
                {modelVersionGlobalSettings.showAddEditScreen &&
                  selectedModelVersionGlobalSettingId === n.ModelVersionGlobalSettingId &&
                  editorMain}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      <div className={newRowClass} ref={addNewRef}>
        {modelVersionGlobalSettings.showAddEditScreen && selectedModelVersionGlobalSettingId === 0 && editModelVersionContent}
      </div>
      <Tooltip  />
    </div>
  );
};

ModelVersionGlobalSettingsListTable.defaultProps = {
  globalSettingsData: []
};

ModelVersionGlobalSettingsListTable.propTypes = {
  setEditHandler: PropTypes.func.isRequired,
  selectedModelVersionGlobalSettingId: PropTypes.number,
  saveModelVersionGlobalSetting: PropTypes.func.isRequired,
  modelVersionGlobalSettings: PropTypes.object.isRequired,
  describeModelVersionGlobalSetting: PropTypes.func.isRequired,
  changeModelVersionGlobalSetting: PropTypes.func.isRequired,
  unitGlobalSettings: PropTypes.array.isRequired,
  globalSettingsData: PropTypes.array,
  UpdateAllowed: PropTypes.bool,
  disableMode: PropTypes.bool,
  disableModeHandler: PropTypes.func.isRequired
};

export default injectIntl(ModelVersionGlobalSettingsListTable);
