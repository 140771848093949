import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid';

import {
  ADD_CHART,
  CHART_ERROR,
  CHART_IS_LOADING,
  UPDATE_CHART,
  UPDATE_METRIC_DATA,
  RESTORE_CHART_CONFIG,
  REMOVE_CHART,
  UPDATE_CHART_FILTERS
} from '../constants/index';
import { apiAction } from '../utils/index';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const addChart = unitSerialNumber => {
  const id = uuid();
  return {
    type: ADD_CHART,
    payload: { id, unitSerialNumber }
  };
};

export const updateChartValues = (id, unitSerialNumber, field, value) => ({
  type: UPDATE_CHART,
  payload: { id, unitSerialNumber, field, value }
});

export const updateChartFilterValues = (id, unitSerialNumber, data) => ({
  type: UPDATE_CHART_FILTERS,
  payload: { id, unitSerialNumber, data }
});

export const fetchMetricData = (id, unitSerialNumber, from, to, sensor, limit, resultType, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_UNITS}/device/${unitSerialNumber}/telemetry?metric=${sensor.toString()}`,
    method: 'GET',
    onSuccess: updateChartMetric,
    onFailure: err => chartError(err, id, unitSerialNumber),
    accessToken: Cookies.get(`access-${envName}`),
    onLoad: e => setIsLoading(e, id, unitSerialNumber),
    data: {
      unitSerialNumber,
      id,
      from,
      to,
      limit,
      resultType: resultType
    },
    widgetcode: widgetCode
  });

export const setIsLoading = (status, id, unitSerialNumber) => ({
  type: CHART_IS_LOADING,
  payload: { status, unitSerialNumber, id }
});

export const updateChartMetric = (data, { unitSerialNumber, id }) => ({
  type: UPDATE_METRIC_DATA,
  payload: { data, unitSerialNumber, id }
});

export const chartError = (err, id, unitSerialNumber) => ({
  type: CHART_ERROR,
  payload: { id, unitSerialNumber, err }
});

export const removeChart = (id, unitSerialNumber) => ({
  type: REMOVE_CHART,
  payload: { id, unitSerialNumber }
});

export const restoreChartConfig = () => ({
  type: RESTORE_CHART_CONFIG
});
