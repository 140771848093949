import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelFirmwareVersion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelFirmwareVersionState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Navigate, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import moment from 'moment-timezone';
import { compareValues } from '../../utils/';
import { WidgetVisibility } from '../../utils/widgetManager';
import ModelUnitsList from './ModelUnitsList';
import FileUploader from '../WSAControls/FileUploader/FileUploader';
import FileList from '../WSAControls/FileList/FileList';

const ModelFirmwareVersion = ({
  saveModelFirmwareVersion,
  describeModel,
  modelManager,
  describeModelFirmwareVersion,
  getComponentPositions,
  modelFirmwareVersionManager,
  changeModelFirmwareVersion,
  getModelUnitsFilteredData,
  userProfileWidgets,
  currentTimezone,
  setModelUnitsCurrentPage,
  setModelUnitsPageFilter,
  intl
}) => {
  let IsEdit = false;
  let IsRedirect = false;
  let heading = intl.formatMessage({ id: 'modelFirmwareVersion.modelFirmwareVersion', defaultMessage: 'Model Firmware Version' });
  const [localformErrors, setFormErrors] = useState({});
  const [fileUploadStarted, setFileUploadStarted] = useState(false);
  const [saveGuid, setSaveGuid] = useState(uuidv4());
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let { ModelFirmwareVersionId, ModelId } = useParams();

  if (ModelFirmwareVersionId) {
    IsEdit = true;
    heading = intl.formatMessage({ id: 'modelFirmwareVersion.editModelFirmwareVersion', defaultMessage: 'Edit' });
  }

  let widgetCode = ModelFirmwareVersionId ? 'MODELFIRMWAREVERSIONUPDATE' : 'MODELFIRMWAREVERSIONADD';

  useEffect(() => {
    if (ModelId && !ModelFirmwareVersionId) {
      describeModel(ModelId, widgetCode);
    }
  }, [describeModel, ModelId, ModelFirmwareVersionId, widgetCode]);

  useEffect(() => {
    if (ModelId) {
      getComponentPositions(ModelId, widgetCode);
    }
  }, [getComponentPositions, ModelId, widgetCode]);

  useEffect(() => {
    if (ModelId && ModelFirmwareVersionId) {
      describeModelFirmwareVersion(ModelFirmwareVersionId, ModelId, widgetCode);
    }
  }, [describeModelFirmwareVersion, ModelFirmwareVersionId, ModelId, widgetCode]);

  useEffect(() => {
    if (ModelId && ModelFirmwareVersionId && modelFirmwareVersionManager.selectedModelFirmwareVersion.GuidIdentifier) {
      setSaveGuid(modelFirmwareVersionManager.selectedModelFirmwareVersion.GuidIdentifier);
    }
  }, [ModelFirmwareVersionId, ModelId, modelFirmwareVersionManager.selectedModelFirmwareVersion.GuidIdentifier]);

  //clean up
  useEffect(() => {
    return () => {
      changeModelFirmwareVersion({
        ...modelFirmwareVersionManager,
        selectedModelFirmwareVersion: { ...initialModelFirmwareVersionState.modelFirmwareVersionManager.selectedModelFirmwareVersion }
      });
    };
  }, []);

  // Update redux store
  const setLocalModelFirmwareVersion = currentState => {
    changeModelFirmwareVersion(currentState);
  };
  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = modelFirmwareVersionManager.selectedModelFirmwareVersion;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.OrganisationId = Cookies.get(`selectedorganisationid-${envName}`) || 1;

    if (!IsEdit) {
      saveData.ModelId = ModelId;
      saveData.ModelFirmwareVersionId = 0;
      saveData.GuidIdentifier = saveGuid;
    }

    //saving Model Version
    saveModelFirmwareVersion(saveData, widgetCode);
  };

  const onPublishClick = () => {
    if (IsEdit) {
      let saveData = modelFirmwareVersionManager.selectedModelFirmwareVersion;
      saveData.IsCurrentVersion = true;
      saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
      saveModelFirmwareVersion(saveData, 'MODELFIRMWAREVERSIONPUBLISH');
    }
  };

  const isValidVersion = value => {
    const versionRegEx = /[^a-zA-Z\d\.]/;
    return versionRegEx.test(value);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelFirmwareVersionManager ||
      !modelFirmwareVersionManager.selectedModelFirmwareVersion ||
      !modelFirmwareVersionManager.selectedModelFirmwareVersion.Name ||
      isRequired(modelFirmwareVersionManager.selectedModelFirmwareVersion.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'modelFirmwareVersion.nameMandatory',
        defaultMessage: 'Version name is a mandatory field'
      });
      isValid = false;
    }

    if (
      !modelFirmwareVersionManager ||
      !modelFirmwareVersionManager.selectedModelFirmwareVersion ||
      !modelFirmwareVersionManager.selectedModelFirmwareVersion.Name ||
      isRequired(modelFirmwareVersionManager.selectedModelFirmwareVersion.ModelComponentPositionId, 1)
    ) {
      formErrors.ModelComponentPositionId = intl.formatMessage({
        id: 'modelFirmwareVersion.componentPositionMandatory',
        defaultMessage: 'Component is a mandatory field'
      });
      isValid = false;
    }
    if (
      !modelFirmwareVersionManager ||
      !modelFirmwareVersionManager.selectedModelFirmwareVersion ||
      !modelFirmwareVersionManager.selectedModelFirmwareVersion.Version ||
      isValidVersion(modelFirmwareVersionManager.selectedModelFirmwareVersion.Version)
    ) {
      formErrors.Version = intl.formatMessage({
        id: 'modelFirmwareVersion.versionInvalid',
        defaultMessage: 'Invalid Version Number! No special characters except .'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalModelFirmwareVersion({
        ...modelFirmwareVersionManager,
        selectedModelFirmwareVersion: { ...modelFirmwareVersionManager.selectedModelFirmwareVersion, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalModelFirmwareVersion({
        ...modelFirmwareVersionManager,
        selectedModelFirmwareVersion: { ...modelFirmwareVersionManager.selectedModelFirmwareVersion, [e.target.name]: e.target.value }
      });
    }
  };

  const componentOptions = () => {
    let componentOptions = [];

    modelFirmwareVersionManager.ModelComponentPositions.forEach(element => {
      componentOptions.push({
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return componentOptions.sort(compareValues('label'));
  };

  const onComponentPositionChange = e => {
    setLocalModelFirmwareVersion({
      ...modelFirmwareVersionManager,
      selectedModelFirmwareVersion: { ...modelFirmwareVersionManager.selectedModelFirmwareVersion, ModelComponentPositionId: e.value }
    });
  };

  let messageId = (modelFirmwareVersionManager && modelFirmwareVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelName = (modelManager.selectedModel ? modelManager.selectedModel.Name : '') || '';

  const returnUrl = `/admin-controls/model-configuration/${ModelId}/`;

  //FILE UPLOAD
  const onUploadStart = files => {
    setFileUploadStarted(true);
  };

  const onUploadComplete = data => {
    setFileUploadStarted(false);
    if (data && data.length > 0) {
      let newFiles = data.map(item => {
        return {
          ModelFirmwareVersionId: 0,
          newFile: true,
          S3URL: item.S3URL,
          FileId: item.fileGuid,
          Name: item.name
        };
      });
      let allFiles = [...modelFirmwareVersionManager.selectedModelFirmwareVersion.Files, ...newFiles];

      setLocalModelFirmwareVersion({
        ...modelFirmwareVersionManager,
        selectedModelFirmwareVersion: {
          ...modelFirmwareVersionManager.selectedModelFirmwareVersion,
          Files: allFiles
        }
      });
    }
  };

  const removeFile = fileid => {
    if (!modelFirmwareVersionManager.selectedModelFirmwareVersion.IsCurrentVersion) {
      let files = modelFirmwareVersionManager.selectedModelFirmwareVersion.Files.filter(file => file.FileId !== fileid);
      let deletedFileIds = [...modelFirmwareVersionManager.selectedModelFirmwareVersion.deletedFileIds];
      deletedFileIds.push(fileid);
      setLocalModelFirmwareVersion({
        selectedModelFirmwareVersion: {
          ...modelFirmwareVersionManager.selectedModelFirmwareVersion,
          Files: files,
          deletedFileIds: deletedFileIds
        }
      });
    }
  };
  //END FILE UPLOAD

  return (
    <div className={s.modelfirmwareversion}>
      {modelManager.isLoading || (modelFirmwareVersionManager.isLoading && <LoadingSpinner />)}
      {modelFirmwareVersionManager.isOpSuccessful && !modelFirmwareVersionManager.showBanner ? <Navigate to={returnUrl} /> : ''}
      {IsRedirect ? <Navigate to={returnUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={modelFirmwareVersionManager.showBanner}
        status={modelFirmwareVersionManager.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelfirmwareversionHeader}>
            <Row>
              <Col xs={12} md={6}>
                <Link className={s.backLink} to={returnUrl}>
                  &lt; &nbsp;
                  <FormattedMessage id="modelFirmwareVersion.backToModelConfiguration" defaultMessage="BACK TO MODEL CONFIGURATION" />
                </Link>
                <h3 data-unittest="headingLabel">
                  {heading} {modelName} : {modelFirmwareVersionManager.selectedModelFirmwareVersion.Name}
                </h3>
              </Col>
              <Col xs={12} md={6} className={s.alignRight} style={WidgetVisibility(userProfileWidgets, 'MODELFIRMWAREVERSIONPUBLISH')}>
                {IsEdit && !modelFirmwareVersionManager.selectedModelFirmwareVersion.PublishedDateTime && (
                  <Button
                    variant="outline-secondary"
                    className={s.btnPublishVersion}
                    onClick={onPublishClick}
                    noValidate
                    data-unittest="saveDefault"
                  >
                    <FormattedMessage id="modelFirmwareVersion.publishFirmwareVersion" defaultMessage="PUBLISH VERSION" />
                  </Button>
                )}
              </Col>
            </Row>
          </div>
          <div className={s.modelfirmwareversionContent}>
            <Form>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formModelFirmwareVersionName">
                    <Form.Label>
                      <FormattedMessage id="modelFirmwareVersion.name" defaultMessage="Version Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={modelFirmwareVersionManager.selectedModelFirmwareVersion.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formModelFirmwareVersionComponentPosition">
                    <Form.Label>
                      <FormattedMessage id="modelFirmwareVersion.modelComponentPosition" defaultMessage="Component" />
                    </Form.Label>
                    <Dropdown
                      id="ddlComponentPosition"
                      dataArray={componentOptions()}
                      disabled={false}
                      controlData={{
                        placeholderText: <FormattedMessage id="modelFirmwareVersion.pleaseSelectOne" defaultMessage="Please select one" />
                      }}
                      onDropdownChange={onComponentPositionChange}
                      selectedOption={componentOptions().filter(
                        option => option.value === modelFirmwareVersionManager.selectedModelFirmwareVersion.ModelComponentPositionId
                      )}
                      data-unittest="ddlComponentPosition"
                    />
                    {localformErrors && localformErrors.ModelComponentPositionId && (
                      <p role="alert" className={s.error}>
                        {localformErrors.ModelComponentPositionId}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={3}>
                  <Form.Group controlId="formModelFirmwareVersionNo">
                    <Form.Label>
                      <FormattedMessage id="modelFirmwareVersion.version" defaultMessage="Version " />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Version"
                      onChange={onChange}
                      value={modelFirmwareVersionManager.selectedModelFirmwareVersion.Version}
                      className={`${s.formControl} ${localformErrors && localformErrors.Version ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Version && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Version}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formModelFirmwareVersionDescription">
                    <Form.Label>
                      <FormattedMessage id="modelFirmwareVersion.description" defaultMessage="Version Description" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="6"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'modelFirmwareVersion.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={modelFirmwareVersionManager.selectedModelFirmwareVersion.Description}
                      className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className={s.breakrow}>
                <Col sm={2}>
                  <Form.Label>
                    <FormattedMessage id="modelFirmwareVersion.publishedBy" defaultMessage="Published By" />
                  </Form.Label>
                </Col>
                <Col sm={10}>{modelFirmwareVersionManager.selectedModelFirmwareVersion.PublishedByUser || '-'}</Col>
              </Row>
              <Row className={s.breakrow}>
                <Col sm={2}>
                  <Form.Label>
                    <FormattedMessage id="modelFirmwareVersion.publishedDate" defaultMessage="publishedDate" />
                  </Form.Label>
                </Col>
                <Col sm={10}>
                  {modelFirmwareVersionManager.selectedModelFirmwareVersion.PublishedDateTime
                    ? moment
                        .unix(modelFirmwareVersionManager.selectedModelFirmwareVersion.PublishedDateTime)
                        .tz(currentTimezone)
                        .format('DD-MMM-YYYY HH:mm:ss z')
                    : '-'}
                </Col>
              </Row>
              {!modelFirmwareVersionManager.selectedModelFirmwareVersion.PublishedDateTime && (
                <Row>
                  <Col>
                    <Form.Label>
                      <FormattedMessage id="common.attachFile" defaultMessage="Attach File" />
                    </Form.Label>
                    <FileUploader
                      widgetCode={widgetCode}
                      allowedFileTypes={[
                        ['application/octet-stream', 'bin'],
                        ['application/pdf', 'pdf'],
                        ['application/msword', 'doc']
                      ]}
                      location={`config/${ModelId}/firmware/${saveGuid}`}
                      enableMultipleUpload={true}
                      maxFiles={5}
                      onUploadComplete={onUploadComplete}
                      onUploadStart={onUploadStart}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <div className={s.titleRows}>
                    <h4>
                      <FormattedMessage id="modelFirmwareVersion.firmwareFiles" defaultMessage="Firmware Files" />
                    </h4>

                    <FileList
                      files={modelFirmwareVersionManager.selectedModelFirmwareVersion.Files}
                      onRemoveFile={removeFile}
                      showDelete={!modelFirmwareVersionManager.selectedModelFirmwareVersion.IsCurrentVersion}
                    />
                  </div>
                  <br />
                </Col>
              </Row>
              <div>
                {!modelFirmwareVersionManager.selectedModelFirmwareVersion.IsCurrentVersion && (
                  <Button
                    variant="primary"
                    className={classNames(s.margin5, s.btnSaveChanges)}
                    disabled={modelFirmwareVersionManager.selectedModelFirmwareVersion.IsCurrentVersion}
                    onClick={submitForm}
                    noValidate
                    data-unittest="saveData"
                  >
                    <FormattedMessage id="modelFirmwareVersionManagement.saveModelFirmwareVersion" defaultMessage="SAVE CHANGES" />
                  </Button>
                )}
                <Link to={returnUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="modelFirmwareVersionManagement.cancelModelFirmwareVersion" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
          <div className={s.contentWrapper}>
            <ModelUnitsList
              setModelUnitsCurrentPage={setModelUnitsCurrentPage}
              setModelUnitsPageFilter={setModelUnitsPageFilter}
              selectedModelFirmwareVersion={modelFirmwareVersionManager.selectedModelFirmwareVersion}
              getModelUnitsFilteredData={getModelUnitsFilteredData}
              ModelId={ModelId}
              widgetCode={widgetCode}
              intl={intl}
              userProfileWidgets
              currentTimezone={currentTimezone}
            />
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelFirmwareVersion.defaultProps = {
  modelFirmwareVersionManager: {
    ...initialModelFirmwareVersionState.modelFirmwareVersionManager
  }
};

ModelFirmwareVersion.propTypes = {
  saveModelFirmwareVersion: PropTypes.func.isRequired,
  modelFirmwareVersionManager: PropTypes.object.isRequired,
  describeModelFirmwareVersion: PropTypes.func.isRequired,
  changeModelFirmwareVersion: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(ModelFirmwareVersion);
