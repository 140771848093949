import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelComponentPositionList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialModelComponentPositionsState } from '../../reducers/initialState';
import ModelComponentPositionListTable from './ModelComponentPositionListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';

const ModelComponentPositionList = ({
  modelComponentPositionManager,
  userProfileWidgets,
  intl,
  getModelComponentPosition,
  saveModelComponentPosition
}) => {
  const history = useNavigate();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  let initialRecords = modelComponentPositionManager.modelComponentPositions.slice(0, initialLimit);
  const [modelComponentPositionOpen, setModelComponentPositionOpen] = useState(false);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    getModelComponentPosition(offset, limit, filter);
  }, [getModelComponentPosition, offset, limit, filter, modelComponentPositionManager.isOpSuccessful]);

  const [localModelComponentPositions, setLocalModelComponentPositions] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [modelComponentPositionId, setModelComponentPositionId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localModelComponentPositionLength = localModelComponentPositions.length;
  const globalSettingLength = modelComponentPositionManager.modelComponentPositions.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalModelComponentPositions(modelComponentPositionManager.modelComponentPositions);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = modelComponentPositionManager.modelComponentPositions.filter(function(item) {
      return item.Name.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalModelComponentPositions(filteredList);
  };

  const filterData = () => {
    let filteredList = modelComponentPositionManager.modelComponentPositions;
    if (isFilter) {
      filteredList = modelComponentPositionManager.modelComponentPositions.filter(function(item) {
        return item.Name.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getModelComponentPositionData = () => {
    let modelComponentPosition = [];
    if (showAll) {
      modelComponentPosition = filterData();
    } else {
      if (isFilter) {
        modelComponentPosition = localModelComponentPositionLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        modelComponentPosition = initialRecords;
      }
    }
    return modelComponentPosition.sort(compareValues('Name'));
  };

  const onRemoveModelComponentPositionClick = modelComponentPositionId => {
    setActive(true);
    setModelComponentPositionId(modelComponentPositionId);
  };

  const DeleteModelComponentPosition = () => {
    let saveData = modelComponentPositionManager.selectedModelComponentPosition;
    saveData.PerformDelete = true;
    saveData.ModelComponentPositionId = modelComponentPositionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveModelComponentPosition(saveData, 'MODELCOMPONENTPOSITION_DELETE');
  };

  const handleClose = () => {
    setActive(false);
    setModelComponentPositionId(0);
  };

  const handleConfirm = () => {
    DeleteModelComponentPosition();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/admin-controls/model-component/model-component-position/0');
  };

  const onChangeModelComponentPositionClick = (modelComponentPositionId, parentComponentId = null) => {
    if (parentComponentId) {
      history.push(`/admin-controls/model-component/model-component-position/${modelComponentPositionId}/${parentComponentId}`);
    } else {
      history.push(`/admin-controls/model-component/model-component-position/${modelComponentPositionId}`);
    }
  };

  let messageId = (modelComponentPositionManager && modelComponentPositionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'modelComponentPositions.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelComponentPositions.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELCOMPONENTPOSITION_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'MODELCOMPONENTPOSITION_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'modelComponentPositions.confirmDelete',
    defaultMessage: 'Are you sure you want to delete model component position?'
  });

  return (
    <div className={s.modelComponentPosition}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'MODELCOMPONENTPOSITION_LIST')}>
        {
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <img
                  src={modelComponentPositionOpen ? collapseDown : collapseUp}
                  alt="Collapse section"
                  onClick={() => setModelComponentPositionOpen(!modelComponentPositionOpen)}
                  aria-controls="model-component-position-section"
                  aria-expanded={modelComponentPositionOpen}
                ></img>
                <h4>
                  <FormattedMessage id="modelComponentPositions.componentPositions" defaultMessage="Component positions" />
                </h4>
                <span className={s.circle}>{modelComponentPositionManager.modelComponentPositions.length}</span>
              </Row>
            </Col>
          </Row>
        }
        <Collapse in={modelComponentPositionOpen}>
          <div className={s.innerComponent} id="model-component-position-section">
            <div className={s.component}>
              {modelComponentPositionManager.isLoading && <LoadingSpinner />}
              {modelComponentPositionManager.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={modelComponentPositionManager.showBanner}
                  status={modelComponentPositionManager.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={modelComponentPositionManager.showAddEditScreen}
                      placeHolderTextId="modelComponentPositions.search"
                      data-unittest="searchModelComponentPositions"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'MODELCOMPONENTPOSITION_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={modelComponentPositionManager.showAddEditScreen}
                      data-unittest="addModelComponentPositionButton"
                    >
                      + <FormattedMessage id="modelComponentPositions.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <ModelComponentPositionListTable
                    RemoveModelComponentPosition={onRemoveModelComponentPositionClick}
                    ModelComponentPositionData={getModelComponentPositionData()}
                    ChangeModelComponentPosition={onChangeModelComponentPositionClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localModelComponentPositionLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localModelComponentPositionLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

ModelComponentPositionList.defaultProps = {
  modelComponentPositionManager: {
    ...initialModelComponentPositionsState.modelComponentPositionManager
  }
};

ModelComponentPositionList.propTypes = {
  modelComponentPositionManager: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  getModelComponentPosition: PropTypes.func.isRequired,
  saveModelComponentPosition: PropTypes.func.isRequired,
  setModelComponentPositionChange: PropTypes.func.isRequired
};

export default injectIntl(ModelComponentPositionList);
