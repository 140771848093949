import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ApprovedComponentList.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { initialApprovedComponentState } from '../../reducers/initialState';
import ApprovedComponentListTable from './ApprovedComponentListTable';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../utils/';
import Cookies from 'js-cookie';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';

const ApprovedComponentList = ({
  approvedComponent,
  userProfileWidgets,
  intl,
  getApprovedComponent,
  saveApprovedComponent,
  setApprovedComponentChange
}) => {
  const history = useNavigate();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;
  let initialRecords = approvedComponent.approvedComponentList.slice(0, initialLimit);
  const [approvedComponentOpen, setApprovedComponentOpen] = useState(false);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    getApprovedComponent(offset, limit, filter);
  }, [getApprovedComponent, offset, limit, filter, approvedComponent.isOpSuccessful]);

  const [localApprovedComponents, setLocalApprovedComponents] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [approvedComponentId, setApprovedComponentId] = useState(0);

  const [searchText, setSearchText] = useState('');

  const localApprovedComponentLength = localApprovedComponents.length;
  const globalSettingLength = approvedComponent.approvedComponentList.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalApprovedComponents(approvedComponent.approvedComponentList);
    }
    setShowAll(!showAll);
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = approvedComponent.approvedComponentList.filter(function(item) {
      return (
        item.ModelComponentType.toLowerCase().includes(value.toLowerCase()) ||
        item.Model.toLowerCase().includes(searchText.toLowerCase()) ||
        item.Brand.toLowerCase().includes(searchText.toLowerCase())
      );
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalApprovedComponents(filteredList);
  };

  const filterData = () => {
    let filteredList = approvedComponent.approvedComponentList;
    if (isFilter) {
      filteredList = approvedComponent.approvedComponentList.filter(function(item) {
        return (
          item.ModelComponentType.toLowerCase().includes(searchText.toLowerCase()) ||
          item.Model.toLowerCase().includes(searchText.toLowerCase()) ||
          item.Brand.toLowerCase().includes(searchText.toLowerCase())
        );
      });
    }

    return filteredList;
  };

  const getApprovedComponentData = () => {
    let approvedComponent = [];
    if (showAll) {
      approvedComponent = filterData();
    } else {
      if (isFilter) {
        approvedComponent = localApprovedComponentLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        approvedComponent = initialRecords;
      }
    }
    return approvedComponent.sort(compareValues('Name'));
  };

  const onRemoveApprovedComponentClick = approvedComponentId => {
    setActive(true);
    setApprovedComponentId(approvedComponentId);
  };

  const DeleteApprovedComponent = () => {
    let saveData = approvedComponent.selectedApprovedComponent;
    saveData.PerformDelete = true;
    saveData.ApprovedComponentId = approvedComponentId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveApprovedComponent(saveData, 'APPROVEDCOMPONENT_DELETE');
  };

  const handleClose = () => {
    setActive(false);
    setApprovedComponentId(0);
  };

  const handleConfirm = () => {
    DeleteApprovedComponent();
    handleClose();
  };

  const onAddNewClick = () => {
    history.push('/admin-controls/model-component/approved-component/0');
  };

  const onViewApprovedComponentClick = approvedComponentId => {
    history.push(`/admin-controls/model-component/approved-component-view/${approvedComponentId}`);
  };

  let messageId = (approvedComponent && approvedComponent.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'approvedComponents.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'approvedComponents.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'APPROVEDCOMPONENT_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'APPROVEDCOMPONENT_DELETE');
  const isViewAllowed = IsWidgetAccessible(userProfileWidgets, 'APPROVEDCOMPONENT_VIEW');

  const dialogTitle = intl.formatMessage({
    id: 'approvedComponents.confirmDelete',
    defaultMessage: 'Are you sure you want to delete approved component?'
  });

  return (
    <div className={s.approvedComponent}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'APPROVEDCOMPONENT_LIST')}>
        <Row>
          <Col xs={12} md={6}>
            <Row>
              <img
                src={approvedComponentOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setApprovedComponentOpen(!approvedComponentOpen)}
                aria-controls="approved-component-section"
                aria-expanded={approvedComponentOpen}
              ></img>
              <h4>
                <FormattedMessage id="approvedComponents.approvedComponents" defaultMessage="Approved components" />
              </h4>
              <span className={s.circle}>{approvedComponent.approvedComponentList.length}</span>
            </Row>
          </Col>
        </Row>
        <Collapse in={approvedComponentOpen}>
          <div className={s.innerComponent} id="approved-component-section">
            <div className={s.component}>
              {approvedComponent.isLoading && <LoadingSpinner />}
              {approvedComponent.showBanner && (
                <Banner
                  failureText={messageText}
                  showBanner={approvedComponent.showBanner}
                  status={approvedComponent.isOpSuccessful}
                  successText={messageText}
                />
              )}
              <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
              <div className={s.contentWrapper}>
                <Row className={s.topRow}>
                  <Col sm={6} lg={4}>
                    <SearchBar
                      searchHandler={searchHandler}
                      clearSearchInVisible={false}
                      disabled={approvedComponent.showAddEditScreen}
                      // initialText={filter}
                      placeHolderTextId="approvedComponent.search"
                      data-unittest="searchApprovedComponents"
                    />
                  </Col>
                  <Col sm={6} lg={8}>
                    <Button
                      className={s.addbutton}
                      style={WidgetVisibility(userProfileWidgets, 'APPROVEDCOMPONENT_ADDNEW')}
                      onClick={onAddNewClick}
                      disabled={approvedComponent.showAddEditScreen}
                      data-unittest="addApprovedComponentButton"
                    >
                      + <FormattedMessage id="approvedComponent.addNew" defaultMessage="ADD NEW" />
                    </Button>
                  </Col>
                </Row>

                <div>
                  <ApprovedComponentListTable
                    RemoveApprovedComponent={onRemoveApprovedComponentClick}
                    ApprovedComponentData={getApprovedComponentData()}
                    ViewApprovedComponent={onViewApprovedComponentClick}
                    UpdateAllowed={isUpdateAllowed}
                    DeleteAllowed={isDeleteAllowed}
                    ViewAllowed={isViewAllowed}
                  />
                </div>
                {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localApprovedComponentLength > initialLimit)) && (
                  <Row className={s.topRow}>
                    <Col>
                      <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                        {!showAll
                          ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localApprovedComponentLength})`
                          : showLessText}
                      </Button>
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </Collapse>
      </div>
    </div>
  );
};

ApprovedComponentList.defaultProps = {
  ...initialApprovedComponentState
};

ApprovedComponentList.propTypes = {
  approvedComponent: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object.isRequired,
  getApprovedComponent: PropTypes.func.isRequired,
  saveApprovedComponent: PropTypes.func.isRequired,
  setApprovedComponentChange: PropTypes.func.isRequired
};

export default injectIntl(ApprovedComponentList);
