import Cookies from 'js-cookie';
import { apiAction, logEntry } from '../utils/index';
import { v4 as uuidv4 } from 'uuid';

import {
  EVENTCODE_ERROR,
  GET_ALL_EVENTCODE_DETAILS,
  EVENTCODE_IS_LOADING,
  SET_EVENTCODE_CURRENT_PAGE,
  SET_EVENTCODE_PAGE_FILTER,
  SET_EVENTCODE_CHANGE,
  SET_EVENTCODE,
  UPDATE_EVENTCODE_SAVE_STATUS,
  SET_EVENT_CODE_FILTERED_RESULTS,
  EVENTCODE_CLEANUP
} from '../constants/index';

import { EVENTCODE_MANAGEMENT, CREATE_EVENTCODE, UPDATE_EVENTCODE, DELETE_EVENTCODE } from '../constants/logs';

const envName = process.env.REACT_APP_ENV_NAME_SHORT;

export const setEventCodeErrorThunk = err => dispatch => {
  let messageCode = err && err.response && err.response.data && err.response.data.errorMessage;
  messageCode = messageCode && messageCode.replace('[400]=>', '');
  dispatch(setEventCodeError(messageCode, true));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEventCodeError(messageCode, false));
  }, 2500);
};

export const setEventCodeError = (messageCode, status) => {
  return {
    type: EVENTCODE_ERROR,
    payload: {
      data: {
        isOpSuccessful: false,
        showBanner: status,
        isLoading: false,
        displayMessageCode: messageCode || ''
      }
    }
  };
};

export const setLoadingStatus = isLoading => ({
  type: EVENTCODE_IS_LOADING,
  payload: { isLoading: isLoading }
});

export const getEventCodeThunk = widgetCode => dispatch => {
  dispatch(getEventCode(widgetCode));
};

export const getEventCode = widgetCode =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/eventcodes`,
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setAllEventCodeListThunk(retData),
    onFailure: err => setEventCodeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setAllEventCodeListThunk = retData => dispatch => {
  let messageCode = retData && retData.message;
  let eventCodes = (retData && retData.eventCodes) || [];
  dispatch(setAllEventCodeList(messageCode, eventCodes));
};

export const setAllEventCodeList = (messageCode, eventCodes) => ({
  type: GET_ALL_EVENTCODE_DETAILS,
  payload: {
    data: {
      eventCodeList: eventCodes,
      filteredResults: eventCodes,
      isLoading: false,
      displayMessageCode: messageCode,
      showBanner: false,
      isOpSuccessful: false
    }
  }
});

export const setCurrentPage = currentPage => ({
  type: SET_EVENTCODE_CURRENT_PAGE,
  payload: { currentPage }
});

export const setPageFilter = filter => ({
  type: SET_EVENTCODE_PAGE_FILTER,
  payload: { filter }
});

export const describeEventCodeThunk = (EventCodeId, widgetCode) => dispatch => {
  dispatch(describeEventCode(EventCodeId, widgetCode));
};

export const describeEventCode = (EventCodeId, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}/management/eventcodes`,
    data: {
      EventCodeId: EventCodeId || undefined
    },
    method: 'GET',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEventCodeThunk(retData),
    onFailure: err => setEventCodeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    widgetcode: widgetCode
  });

export const setEventCodeThunk = retData => dispatch => {
  const describeEventCode = retData.eventCode || {};
  dispatch(setEventCode(describeEventCode));
};

export const setEventCode = data => ({
  type: SET_EVENTCODE,
  payload: {
    selectedEventCode: data,
    isOpSuccessful: false,
    showBanner: false
  }
});

export const saveEventCodeThunk = (EventCode, widgetCode) => dispatch => {
  let url = `/management/eventcodes/save`;
  let actionName = EventCode.EventCodeId === 0 ? CREATE_EVENTCODE : UPDATE_EVENTCODE;
  let log = logEntry(EVENTCODE_MANAGEMENT, actionName, EventCode);
  dispatch(saveEventCode(EventCode, url, log, widgetCode));
};

export const saveEventCode = (EventCode, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEventCodeStatusThunk(retData),
    onFailure: err => setEventCodeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      EventCode: EventCode,
      log: log
    },
    widgetcode: widgetCode
  });

export const setEventCodeStatusThunk = retData => dispatch => {
  let messageCode = retData;
  dispatch(setEventCodeStatus(messageCode, true, true, false));
  setTimeout(() => {
    //Hide the banner
    dispatch(setEventCodeStatus('', false, false, true));
  }, 2500);
};

export const setEventCodeStatus = (messageCode, status, isOpSuccessful, isRedirect) => ({
  type: UPDATE_EVENTCODE_SAVE_STATUS,
  payload: {
    data: {
      isOpSuccessful: isOpSuccessful,
      showBanner: status,
      isLoading: false,
      displayMessageCode: messageCode || '',
      isRedirect: isRedirect
    }
  }
});

export const setEventCodeChangeThunk = retData => dispatch => {
  dispatch(setEventCodeChange(retData));
};

export const setEventCodeChange = retData => ({
  type: SET_EVENTCODE_CHANGE,
  payload: retData
});

export const deleteEventCodeThunk = (EventCodeId, widgetCode) => dispatch => {
  let url = `/management/eventcodes/delete`;
  let actionName = DELETE_EVENTCODE;
  let log = logEntry(EVENTCODE_MANAGEMENT, actionName, EventCodeId);
  dispatch(deleteEventCode(EventCodeId, url, log, widgetCode));
};

export const deleteEventCode = (EventCodeId, url, log, widgetCode) =>
  apiAction({
    url: `${process.env.REACT_APP_API_ENDPOINT_ADMIN}${url}`,
    method: 'POST',
    onLoad: status => setLoadingStatus(status),
    onSuccess: retData => setEventCodeStatusThunk(retData),
    onFailure: err => setEventCodeErrorThunk(err),
    accessToken: Cookies.get(`access-${envName}`),
    data: {
      EventCodes: {
        EventCodeId: EventCodeId
      },
      log: log
    },
    widgetcode: widgetCode
  });

export const eventCodeCleanUpThunk = eventCode => dispatch => {
  dispatch(eventCodeCleanUp(eventCode));
};

export const eventCodeCleanUp = eventCode => ({
  type: EVENTCODE_CLEANUP,
  payload: {
    data: eventCode
  }
});

export const setFilteredDataThunk = retData => dispatch => {
  dispatch(setFilteredData(retData));
};

export const setFilteredData = retData => ({
  type: SET_EVENT_CODE_FILTERED_RESULTS,
  payload: retData
});
