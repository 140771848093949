import React from 'react';
import PropTypes from 'prop-types';
import s from './UnitReports.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialUnitReportsState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import bs from '../../styles/bootstrap-overrides.scss';
import { WidgetVisibility } from '../../utils/widgetManager';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import 'react-phone-input-2/lib/style.css';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import arrowDown from '../../assets/arrow-down.svg';
import Cookies from 'js-cookie';
import { v4 as uuid } from 'uuid' ;
import { UNIT_MANAGEMENT, UNITS_REPORT } from '../../constants/logs';
import { APP_VERSION } from '../../constants';
import help from '../../assets/help-grey.svg';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import { compareValues, downloadCsvFile, flattenObject } from '../../utils/';
import { getCompPositionLabel } from '../../utils/helperMethods';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';

class UnitReports extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      ...initialUnitReportsState,
      formError: {}
    };

    this.startTimestamp = moment()
      .tz(this.props.currentTimezone)
      .startOf('day')
      .subtract(1, 'days')
      .valueOf();
    this.endTimestamp = moment()
      .tz(this.props.currentTimezone)
      .startOf('day')
      .valueOf();

    this.startTimestampUtc = moment(this.startTimestamp)
      .utc()
      .format('DD-MMM-YYYY HH:mm:ss z');
    this.endTimestampUtc = moment(this.endTimestamp)
      .utc()
      .format('DD-MMM-YYYY HH:mm:ss z');

    this.isPopExecuted = false;

    this.state = {
      ...initialUnitReportsState,
      downloadLink: '',
      downloadLinkinEmail: 0,
      showUTCDateTime: true,
      search: {
        ...this.state.search,
        fromDate: this.startTimestamp,
        fromDateUtc: this.startTimestampUtc,
        toDate: this.endTimestamp,
        toDateUtc: this.endTimestampUtc,
        showBanner: false
      }
    };
  }

  envName = process.env.REACT_APP_ENV_NAME_SHORT;

  // json to csv converter
  jsonToCsv = items => {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(flattenObject(items[0]));

    // Makes the timestamp data the first element
    var first = 'timestamp';
    header.sort(function(x, y) {
      return x == first ? -1 : y == first ? 1 : 0;
    });
    let csv = items.map(row => {
      let flattenedRow = flattenObject(row);
      return header.map(fieldName => JSON.stringify(flattenedRow[fieldName], replacer)).join(',');
    });
    csv.unshift(header.join(','));
    return csv.join('\r\n');
  };

  submitForm = e => {
    e.preventDefault();

    if (!this.validateInput()) {
      return;
    }

    let searchData = { ...this.state.search };
    searchData.unitSerialNumber = this.props.match.params.unitSerialNumber;

    if (searchData.showDataPoints) {
      this.downloadReport(searchData);
    } else {
      this.exportReport(searchData);
    }
  };

  validateInput = () => {
    let formErrorData = {};
    let isValid = true;

    if (!isValid) {
      this.scrollToTop();
    }

    if (this.state.search.showDataPoints) {
      if (!this.state.search || !this.state.search.components || this.state.search.components.length === 0) {
        formErrorData.dataPoint = this.props.intl.formatMessage({
          id: 'unitreport.selectdatapoint',
          defaultMessage: 'Please select at least one data point'
        });
        if (isValid) this.scrollToBottom();
        isValid = false;
      } else {
        formErrorData.dataPoint = null;
      }
    } else {
      if (!this.state.search || !this.state.search.reportTypes || this.state.search.reportTypes.length === 0) {
        formErrorData.reportTypes = this.props.intl.formatMessage({
          id: 'unitreport.selectReportType',
          defaultMessage: 'Please select at least one report type'
        });
        if (isValid) this.scrollToBottom();
        isValid = false;
      } else {
        formErrorData.reportTypes = null;
      }
    }

    this.setState({ formErrors: formErrorData });

    return isValid;
  };

  scrollToTop = () => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  };

  scrollToBottom = () => {
    this.el.scrollIntoView({ behavior: 'smooth' });
  };

  getCurrentOrgIdentifier = () => {
    let currentOrgId = Cookies.get(`selectedorganisationid-${this.envName}`) || 0;
    //const organisations = JSON.parse(Cookies.get(`organisations-${this.envName}`)) || [];
    const organisations =
      (localStorage.getItem(`organisations-${this.envName}`) && JSON.parse(localStorage.getItem(`organisations-${this.envName}`))) || [];
    const selectedOrg = organisations.find(x => x.OrganisationId === parseInt(currentOrgId));
    if (selectedOrg) {
      return selectedOrg.OrgUuid;
    } else {
      return currentOrgId;
    }
  };

  exportReport = async queryData => {
    this.scrollToTop();
    //setting Loading status to true
    this.setState({
      ...this.state,
      downloadLink: '',
      downloadLinkinEmail: 0,
      search: {
        ...this.state.search,
        isLoading: true
      }
    });

    queryData.components = queryData.components.map(comp => {
      return { Name: comp.NeedState ? `state.${comp.Name}` : comp.Name };
    });

    let log = {
      Timestamp: moment().unix(),
      Username: Cookies.get(`username-${this.envName}`) || '',
      SessionId: '',
      UserId: Cookies.get(`userid-${this.envName}`) || '',
      OrganisationId: Cookies.get(`selectedorganisationid-${this.envName}`) || '',
      UnitSerialNumber: this.props.match.params.unitSerialNumber || 0,
      Function: UNIT_MANAGEMENT,
      Action: UNITS_REPORT,
      Payload: JSON.stringify(queryData)
    };

    try {
      let searchResults = null;
      let LastEvaluatedKey = null;
      let csvData = '';
      let messageCode = '';
      do {
        //API call
        queryData.LastEvaluatedKey = LastEvaluatedKey;

        let respData = await fetch(`${process.env.REACT_APP_API_ENDPOINT_UNITS}/reports/unit`, {
          method: 'post',
          body: JSON.stringify({ query: queryData, log: log }),
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + Cookies.get(`access-${this.envName}`),
            widgetcode: 'UNITREPORT',
            accessid: Cookies.get(`selectedprofileid-${this.envName}`) || ''
          }
        });

        //Reading from stream and converting to JSON
        let retData = await respData.json();

        if (!respData.ok) {
          //fetch do not throw exception for http error 400, so manually throwing exception
          throw retData;
        }

        messageCode = retData && retData.message;
        searchResults = (retData && retData.unitReport) || { items: [] };
        LastEvaluatedKey = searchResults.LastEvaluatedKey;

        //processing telemetry
        if (searchResults.Items && searchResults.Items.length > 0) {
          let telemetryData = searchResults.Items.map(item => {
            return {
              ...item,
              timestamp: item.timestamp,
              eventDateTime: moment(item.timestamp)
                .tz(this.props.currentTimezone)
                .format('DD-MMM-YYYY HH:mm:ss')
            };
          });
          csvData += this.jsonToCsv(telemetryData);
        }
      } while (LastEvaluatedKey);

      //Header for CSV
      let infoData =
        ' From:' +
        moment(searchResults.fromDate)
          .tz(this.state.showUTCDateTime ? 'UTC' : this.props.currentTimezone)
          .format('DD-MMM-YYYY HH:mm:ss z');

      infoData +=
        ' To: ' +
        moment(searchResults.toDate)
          .tz(this.state.showUTCDateTime ? 'UTC' : this.props.currentTimezone)
          .format('DD-MMM-YYYY HH:mm:ss z') +
        '\r\n';
      // csvData = 'Telemetry ' + infoData + csvData;

      //processing Sequence
      if (searchResults.sequences && searchResults.sequences.length > 0) {
        let sequenceItem = searchResults.sequences.map(log => {
          return {
            ...log,
            timestamp: log.timestamp,
            eventDateTime: moment(log.timestamp)
              .tz(this.state.showUTCDateTime ? 'UTC' : this.props.currentTimezone)
              .format('DD-MM-YYYY HH:mm:ss'),
            code:
              (this.props.flowSequences &&
                this.props.flowSequences.filter(x => x.SequenceCode === parseInt(log.code)).length > 0 &&
                this.props.flowSequences.filter(x => x.SequenceCode === parseInt(log.code))[0].Name) ||
              log.code,
            message: log.message || ''
          };
        });
        csvData += '\r\n\r\n Sequence ' + infoData + this.jsonToCsv(sequenceItem);
      }

      //processing infoEvents
      if (searchResults.infoEvents && searchResults.infoEvents.length > 0) {
        let infoEvent = searchResults.infoEvents.map(log => {
          return {
            ...log,
            timestamp: log.timestamp,
            eventDateTime: moment(log.timestamp)
              .tz(this.state.showUTCDateTime ? 'UTC' : this.props.currentTimezone)
              .format('DD-MM-YYYY HH:mm:ss'),
            code:
              (this.props.infoEvents &&
                this.props.infoEvents.filter(x => x.Code === log.code.toString()).length > 0 &&
                this.props.infoEvents.filter(x => x.Code === log.code.toString())[0].Name) ||
              log.code,
            message: log.message || ''
          };
        });
        csvData += '\r\n\r\n Event Info ' + infoData + this.jsonToCsv(infoEvent);
      }

      //processing warnings
      if (searchResults.warnings && searchResults.warnings.length > 0) {
        let warnings = searchResults.warnings.map(warning => {
          return {
            ...warning,
            timestamp: warning.timestamp,
            eventDateTime: moment(warning.timestamp)
              .tz(this.state.showUTCDateTime ? 'UTC' : this.props.currentTimezone)
              .format('DD-MM-YYYY HH:mm:ss'),
            code:
              (this.props.warnings &&
                this.props.warnings.filter(x => x.Code === warning.code?.toString()).length > 0 &&
                this.props.warnings.filter(x => x.Code === warning.code?.toString())[0].Name) ||
              warning.code,
            message: warning.message || ''
          };
        });

        csvData += '\r\n\r\n Warnings ' + infoData + this.jsonToCsv(warnings);
      }

      //processing alarms
      if (searchResults.alarms && searchResults.alarms.length > 0) {
        let alarms = searchResults.alarms.map(alarm => {
          return {
            ...alarm,
            timestamp: alarm.timestamp,
            eventDateTime: moment(alarm.timestamp)
              .tz(this.state.showUTCDateTime ? 'UTC' : this.props.currentTimezone)
              .format('DD-MM-YYYY HH:mm:ss'),
            code:
              (this.props.alarms &&
                this.props.alarms.filter(x => x.Code === alarm.code.toString()).length > 0 &&
                this.props.alarms.filter(x => x.Code === alarm.code.toString())[0].Name) ||
              alarm.code,
            message: alarm.message || ''
          };
        });

        csvData += '\r\n\r\n Alarms ' + infoData + this.jsonToCsv(alarms);
      }

      if (!csvData) {
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            isLoading: false,
            showBanner: true,
            isOpSuccessful: true,
            displayMessageCode: 'unitreport.returnedemptydata'
          },
          formErrors: {}
        });

        setTimeout(() => {
          this.setState({
            ...this.state,
            search: {
              ...this.state.search,
              showBanner: false
            }
          });
        }, 5000);

        return;
      } else {
        downloadCsvFile(csvData, `${this.props.match.params.unitSerialNumber}.csv`);
      }

      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          isLoading: false,
          showBanner: false,
          isOpSuccessful: true,
          displayMessageCode: messageCode
        },
        formErrors: {}
      });
    } catch (err) {
      let messageCode = (err && err.errorMessage) || null;
      messageCode = messageCode && messageCode.replace('[400]=>', '');

      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          isLoading: false,
          showBanner: true,
          isOpSuccessful: false,
          displayMessageCode: messageCode
        },
        formErrors: {}
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            showBanner: false
          }
        });
      }, 5000);
    }
  };

  downloadReport = async queryData => {
    this.scrollToTop();
    //setting Loading status to true
    this.setState({
      ...this.state,
      downloadLink: '',
      downloadLinkinEmail: 0,
      search: {
        ...this.state.search,
        isLoading: true
      }
    });

    queryData.components = queryData.components.map(comp => {
      return comp.Name.toLowerCase();
    });
    //Including FlowSequenceName
    queryData.components.push('FlowSequence');
    //Setting up async invocation if date range is more than 48 hours
    let difference = queryData.toDate - queryData.fromDate;
    let isAsync = 0;
    if (difference > 172800000) {
      //More than 48hours
      isAsync = 1;
    }

    try {
      let respData = await fetch(`${process.env.REACT_APP_API_ENDPOINT_UNITS}/reports/unitreportdownload`, {
        method: 'post',
        body: JSON.stringify({
          unitSerialNumber: queryData.unitSerialNumber,
          from: queryData.fromDate,
          to: queryData.toDate,
          organisationId: this.getCurrentOrgIdentifier(),
          userId: Cookies.get(`userid-${this.envName}`) || '',
          deliveryName: Cookies.get(`name-${this.envName}`) || '',
          deliveryTo: Cookies.get(`username-${this.envName}`) || '',
          timezone: this.props.currentTimezone,
          components: queryData.components,
          modelId: this.props.unitManager.selectedUnit.ModelId,
          isAsync: isAsync
        }),
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + Cookies.get(`access-${this.envName}`),
          widgetcode: 'UNITREPORT',
          accessid: Cookies.get(`selectedprofileid-${this.envName}`) || ''
        }
      });
      //Reading from stream and converting to JSON
      let retData = await respData.json();

      if (retData.statusCode === 200 && retData.downloadurl) {
        this.setState({
          ...this.state,
          downloadLink: retData.downloadurl,
          downloadLinkinEmail: 0,
          search: {
            ...this.state.search,
            isLoading: false,
            showBanner: false,
            isOpSuccessful: true,
            displayMessageCode: ''
          },
          formErrors: {}
        });
      } else if (retData.statusCode === 200 && !retData.downloadurl) {
        this.setState({
          ...this.state,
          downloadLink: '',
          downloadLinkinEmail: 1,
          search: {
            ...this.state.search,
            isLoading: false,
            showBanner: false,
            isOpSuccessful: true,
            displayMessageCode: ''
          },
          formErrors: {}
        });
      } else {
        throw retData;
      }
    } catch (err) {
      let messageCode = (err && err.errorMessage) || null;
      messageCode = messageCode && messageCode.replace('[400]=>', '');
      this.setState({
        ...this.state,
        search: {
          ...this.state.search,
          isLoading: false,
          showBanner: true,
          isOpSuccessful: false,
          displayMessageCode: messageCode
        },
        formErrors: {}
      });

      setTimeout(() => {
        this.setState({
          ...this.state,
          search: {
            ...this.state.search,
            showBanner: false
          }
        });
      }, 5000);
    }
  };

  //on control value change
  onChange = data => {
    this.setState({
      search: {
        ...this.state.search,
        ...data
      }
    });
  };

  //handling onblur
  adjustStringDate = type => {
    let mDate = this.state.search.fromDate;
    if (type === 'toDate') {
      mDate = this.state.search.toDate;
    }
    //handling string data input by user on datetimefield
    if (mDate && typeof mDate === 'string') {
      let mConvDate = moment(mDate);
      let stateDate = {};
      if (mConvDate && mConvDate._isValid && mConvDate.tz) {
        mConvDate = mConvDate.valueOf();
        if (type === 'toDate') {
          stateDate.toDate = mConvDate;
        } else {
          stateDate.fromDate = mConvDate;
        }
        this.setState({
          search: {
            ...this.state.search,
            ...stateDate
          }
        });
      }
    }
  };

  formatDate = m => {
    if (m && typeof m === 'number') {
      return moment(m).tz(this.props.currentTimezone);
    }
    return m;
  };

  onSelectAllChange = (e, data) => {
    let selectAllChecked = (e && e.target && e.target.checked) || false;

    let updatedunitComponentParts = this.props.unitManager.selectedUnit.unitComponentPositions
      .filter(comp => {
        if (comp.NeedFeedback || comp.NeedState) {
          return comp;
        }
      })
      .map(comp => {
        comp.isChecked = selectAllChecked;
        return comp;
      });

    this.setState({
      search: {
        ...this.state.search,
        components: updatedunitComponentParts.filter(comp => comp.isChecked),
        selectAll: selectAllChecked
      }
    });
  };

  componentDidUpdate(prevProps, prevState) {}

  //Fired on unit component selection change
  onunitComponentPartselectionChange = (e, component) => {
    let isChecked = (e && e.target && e.target.checked) || false;

    let updatedComponents = this.props.unitManager.selectedUnit.unitComponentPositions.map(unit => {
      if (unit.ModelComponentPositionId === component.ModelComponentPositionId) unit.isChecked = isChecked;
      return unit;
    });

    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        components: updatedComponents.filter(comp => comp.isChecked),
        selectAll: updatedComponents.filter(comp => comp.isChecked).length === updatedComponents.length
      }
    });
  };

  handleClick = () => {
    this.setState({
      ...this.state,
      showUTCDateTime: !this.state.showUTCDateTime
    });
  };

  onReportTypeSelectionChange = (e, component) => {
    let isChecked = (e && e.target && e.target.checked) || false;

    let defaultReportType = this.props.reportTypes.filter(f => f.DefaultChecked);

    let updatedComponents = defaultReportType;

    let showDataPoints = isChecked && component.ShowDataPoints;

    if (defaultReportType[0].ModelComponentPositionId !== component.ModelComponentPositionId) {
      // if the last item is unchecked for non telemetry report types
      if (this.state.search.reportTypes.length === 1 && !isChecked) {
        showDataPoints = true;
      }

      updatedComponents = this.props.reportTypes.map(rtype => {
        if (rtype.ModelComponentPositionId === component.ModelComponentPositionId) rtype.isChecked = isChecked;

        return rtype;
      });
    } else {
      updatedComponents.isChecked = true;
      showDataPoints = true;
    }

    this.setState({
      ...this.state,
      search: {
        ...this.state.search,
        reportTypes: updatedComponents.filter(comp => comp.isChecked),
        showDataPoints: showDataPoints
      }
    });
    this.setState({ formErrors: { dataPoint: '' } });
  };

  getEmailId = () => {
    const envName = process.env.REACT_APP_ENV_NAME_SHORT;
    return Cookies.get(`username-${envName}`);
  };

  render() {
    let messageId = (this.props.unitManager && this.props.unitManager.displayMessageCode) || 'unitreport.datafetchfailed';
    let reportMessageId = (this.state.search && this.state.search.displayMessageCode) || 'unitreport.datafetchfailed';

    const messageText = this.props.intl.formatMessage({ id: messageId, defaultMessage: messageId });
    const reportMessageText = this.props.intl.formatMessage({ id: reportMessageId, defaultMessage: reportMessageId });

    let selectedUnit = (this.props.unitManager && this.props.unitManager.selectedUnit) || {};
    let currentTimestamp = moment().tz(this.props.currentTimezone);

    let unitComponentPositions = selectedUnit.unitComponentPositions.filter(comp => comp.IsParent === 0);
    unitComponentPositions = unitComponentPositions
      .filter(comp => {
        if (comp.NeedState || comp.NeedFeedback) {
          return comp;
        }
      })
      .map(comp => {
        return {
          ...comp,
          value: comp.ModelComponentPositionId,
          label: getCompPositionLabel(comp),
          SKEY: comp.ModelComponentPositionId,
          isChecked:
            this.state.search.components.filter(selComp => selComp.ModelComponentPositionId === comp.ModelComponentPositionId).length === 1
        };
      });

    unitComponentPositions = unitComponentPositions.sort(compareValues('label'));

    let reportTypes = this.props.reportTypes.map(comp => {
      return {
        ...comp,
        value: comp.ModelComponentPositionId,
        label: comp.Name,
        SKEY: comp.ModelComponentPositionId,
        isChecked:
          this.state.search.reportTypes.length > 0
            ? this.state.search.reportTypes.filter(selComp => selComp.ModelComponentPositionId === comp.ModelComponentPositionId).length ===
              1
            : comp.DefaultChecked
      };
    });
    reportTypes = reportTypes.sort(compareValues('label'));

    const disableFutureDates = current => current.isBefore(currentTimestamp);

    const labelChecked = <FormattedMessage id="unitreports.downloadUTCDateTime" defaultMessage="Download in UTC date time" />;
    const labelUnchecked = <FormattedMessage id="unitreports.downloadLocalDateTime" defaultMessage="Download in Local date time" />;

    const disablePastDates = current =>
      current.isAfter(
        moment(this.state.search.fromDate)
          .tz(this.props.currentTimezone)
          .subtract(1, 'days')
      ) && current.isBefore(currentTimestamp);

    let showUnitBanner = this.props.unitManager.displayMessageCode ? true : false;

    return (
      <ContentTemplate
        selectedPage="unitreports"
        userProfileWidgets={this.props.userProfileWidgets}
        widgetCode={'UNITREPORT'}
        tagoutRequired={false}
      >
        <div className={s.unitReports}>
          {(this.props.unitManager.isLoading || this.state.search.isLoading) && <LoadingSpinner />}

          <Banner
            key={uuid()}
            failureText={messageText}
            showBanner={this.props.unitManager.showBanner && showUnitBanner}
            status={this.props.unitManager.isOpSuccessful}
            successText={messageText}
          />

          <Banner
            key={uuid()}
            failureText={reportMessageText}
            showBanner={this.state.search.showBanner}
            status={this.state.search.isOpSuccessful}
            successText={reportMessageText}
          />

          <div className={s.contentWrapper}>
            <div className={s.unitReportsContent}>
              <Form>
                <Row>
                  <Col>
                    <div className={s.unitsubHeading}>
                      <FormattedMessage id="unitreports.selectedateandtimerange" defaultMessage="Select date and time range" />
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={s.divWarning}>
                      <div>
                        {' '}
                        <img src={help} alt="icon" className={s.icon}></img>
                        <FormattedMessage
                          id="unitreports.messageDownloadSize"
                          defaultMessage="Reports with data more two days (48 hours) may take londer due to its size limitation"
                        />
                        {this.state.downloadLink.length > 0 ? (
                          <div className={s.downloadInfo}>
                            <FormattedMessage id="unitreports.reportReady" defaultMessage="Your report is now ready to be downloaded" />{' '}
                            <a href={this.state.downloadLink}>
                              <FormattedMessage id="unitreports.downloadreport" defaultMessage="click here to download" />
                            </a>{' '}
                          </div>
                        ) : (
                          ''
                        )}
                        {this.state.downloadLinkinEmail ? (
                          <div className={s.downloadInfo}>
                            {this.props.intl
                              .formatMessage({
                                id: 'unitreports.reportinEmail',
                                defaultMessage: 'Your report will be sent to your registered email id ({}) when it is ready.'
                              })
                              .replace('{}', this.getEmailId())}
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <Row>
                      <Col>
                        <div className={s.subHeading2}>
                          <FormattedMessage id="unitreports.fromdatetime" defaultMessage="From date/time" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group has-icon">
                          <span className="form-control-icon">
                            <img src={arrowDown} alt="icon"></img>
                          </span>
                          <DateTimePicker
                            className={s.datetimeInput}
                            isValidDate={disableFutureDates}
                            onChange={m => {
                              this.onChange({
                                fromDate: m.valueOf(),
                                fromDateUtc: moment(m.valueOf())
                                  .utc()
                                  .format('DD-MMM-YYYY HH:mm:ss z')
                              });
                            }}
                            timeFormat="HH:mm"
                            dateFormat="DD-MMM-YYYY"
                            value={this.formatDate(this.state.search.fromDate)}
                            defaultValue={this.formatDate(this.state.search.fromDate)}
                            closeOnSelect={true}
                            showClear={false}
                            //timeConstraints={{ minutes: { step: 15 } }}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={s.timeutc}>
                          <span>{this.state.search.fromDateUtc}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg={6}>
                    <Row>
                      <Col>
                        <div className={s.subHeading2}>
                          <FormattedMessage id="unitreports.todatetime" defaultMessage="To date/time" />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <div className="form-group has-icon">
                          <span className="form-control-icon">
                            <img src={arrowDown} alt="icon"></img>
                          </span>
                          <DateTimePicker
                            className={s.datetimeInput}
                            isValidDate={disablePastDates}
                            onChange={m =>
                              this.onChange({
                                toDate: m.valueOf(),
                                toDateUtc: moment(m.valueOf())
                                  .utc()
                                  .format('DD-MMM-YYYY HH:mm:ss z')
                              })
                            }
                            timeFormat="HH:mm"
                            dateFormat="DD-MMM-YYYY"
                            value={this.formatDate(this.state.search.toDate)}
                            defaultValue={this.formatDate(this.state.search.toDate)}
                            closeOnSelect={true}
                            showClear={false}
                            //timeConstraints={{ minutes: { step: 15 } }}
                          />
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className={s.timeutc}>
                          <span>{this.state.search.toDateUtc}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div>
                      {this.state.formErrors && this.state.formErrors.sizeWarning && (
                        <p role="alert" className={s.error}>
                          {this.state.formErrors.sizeWarning}
                        </p>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className={s.unitsubHeading}>
                      <FormattedMessage id="unitreports.selectReportTypes" defaultMessage="Select report type" />
                    </div>
                  </Col>
                </Row>
                {!this.state.search.showDataPoints && (
                  <Row>
                    <Col>
                      <ToggleSwitch
                        handleClick={this.handleClick}
                        classname={s.switch}
                        checked={this.state.showUTCDateTime}
                        labelChecked={labelChecked}
                        labelUnchecked={labelUnchecked}
                      />
                    </Col>
                  </Row>
                )}

                <Row className={s.unitReportsCheckbox}>
                  <Col>
                    <Form.Group controlId="unitComponentPositions">
                      <Checkbox
                        key={uuidv4()}
                        controlData={{ customClassName: s.unitReportsCheckbox.li }}
                        dataArray={reportTypes}
                        onSelectionChange={this.onReportTypeSelectionChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {this.state.formErrors && this.state.formErrors.reportTypes && (
                      <p role="alert" className={s.error}>
                        {this.state.formErrors.reportTypes}
                      </p>
                    )}
                    <div
                      ref={el => {
                        this.el = el;
                      }}
                    />
                  </Col>
                </Row>
                {this.state.search.showDataPoints && (
                  <div style={WidgetVisibility(this.props.userProfileWidgets, 'UNITREPORT_TELEMETRY')}>
                    <Row>
                      <Col>
                        <div className={s.unitsubHeading}>
                          <FormattedMessage id="unitreports.selectdatapoints" defaultMessage="Select data points" />
                        </div>
                      </Col>
                    </Row>

                    <Row className={s.unitReportsCheckbox}>
                      <Col>
                        <Form.Group controlId="formUserIsEnabled">
                          <Checkbox
                            key={uuidv4()}
                            dataArray={[
                              {
                                SKEY: 'SelectAll',
                                target: { type: 'checkbox' },
                                label: 'Select All',
                                isChecked: this.state.search.selectAll
                              }
                            ]}
                            onSelectionChange={this.onSelectAllChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row className={s.unitReportsCheckbox}>
                      <Col>
                        <Form.Group controlId="unitComponentPositions">
                          <Checkbox
                            key={uuidv4()}
                            controlData={{ customClassName: s.unitReportsCheckbox.li }}
                            dataArray={unitComponentPositions}
                            onSelectionChange={this.onunitComponentPartselectionChange}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                )}
                <Row>
                  <Col>
                    {this.state.formErrors && this.state.formErrors.dataPoint && (
                      <p role="alert" className={s.error}>
                        {this.state.formErrors.dataPoint}
                      </p>
                    )}
                    <div
                      ref={el => {
                        this.el = el;
                      }}
                    />
                  </Col>
                </Row>
                <Button variant="primary" className={s.margin5} onClick={this.submitForm} noValidate disabled={this.state.search.isLoading}>
                  <FormattedMessage id="unitreports.generateReport" defaultMessage="GENERATE REPORT" />
                </Button>
                <div className={s.notes}>
                  <FormattedMessage id="unitreports.notes" defaultMessage="* Telemetry report data is only available in UTC date time" />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </ContentTemplate>
    );
  }
}

UnitReports.defaultProps = {
  ...initialUnitReportsState,
  currentTimezone: moment.tz.guess()
};

UnitReports.propTypes = {
  currentTimezone: PropTypes.string.isRequired,
  unitManager: PropTypes.object.isRequired,
  userProfileWidgets: PropTypes.object,
  flowSequences: PropTypes.array.isRequired,
  alarms: PropTypes.array.isRequired,
  warnings: PropTypes.array.isRequired,
  reportTypes: PropTypes.array.isRequired
};

export default injectIntl(UnitReports);
