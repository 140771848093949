import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './CalibrationEquation.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialCalibrationEquationState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import {  Link, useParams, Navigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import EquationConstantTable from './EquationConstantTable';
import { IsWidgetAccessible } from '../../utils/widgetManager';

const CalibrationEquation = ({
  saveCalibrationEquation,
  describeCalibrationEquation,
  calibrationEquationManager,
  changeCalibrationEquation,
  cleanUpCalibrationEquation,
  setCalibrationEquationConstantList,
  changeCalibrationEquationConstant,
  setCalibrationEquationCustomError,
  showAddEditScreen,
  userProfileWidgets,
  intl
}) => {
  let IsEdit = false;
  let IsRedirect = false;
  let heading = '';
  const [localformErrors, setFormErrors] = useState({});
  const [currentEquationConstantId, setCurrentEquationConstantId] = useState(-1);
  const [currentEquationConstantIdentifier, setCurrentEquationConstantIdentifier] = useState('');
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'CALIBRATIONEQUATIONEDIT');

  let { CalibrationEquationId } = useParams();

  if (CalibrationEquationId && CalibrationEquationId > 0) {
    IsEdit = true;
  }

  let widgetCode = IsEdit ? 'CALIBRATIONEQUATIONEDIT' : 'CALIBRATIONEQUATIONCONSTADD';
  useEffect(() => {
    if (CalibrationEquationId && parseInt(CalibrationEquationId) > 0) {
      describeCalibrationEquation(CalibrationEquationId);
    }
  }, [describeCalibrationEquation, CalibrationEquationId]);

  useEffect(() => {
    return () => {
      cleanUpCalibrationEquation();
    };
  }, []);

  // Update redux store
  const setLocalCalibrationEquation = currentState => {
    changeCalibrationEquation(currentState);
  };
  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = {
      CalibrationEquationId: calibrationEquationManager.SelectedCalibrationEquation.CalibrationEquationId,
      Name: calibrationEquationManager.SelectedCalibrationEquation.Name,
      Description: calibrationEquationManager.SelectedCalibrationEquation.Description,
      Formula: calibrationEquationManager.SelectedCalibrationEquation.Formula,
      IsActive: calibrationEquationManager.SelectedCalibrationEquation.IsActive,
      CalibrationEquationConstants:
        (calibrationEquationManager.SelectedCalibrationEquation.EquationConstants &&
          calibrationEquationManager.SelectedCalibrationEquation.EquationConstants.EquationConstantList) ||
        []
    };

    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;

    if (!IsEdit) {
      saveData.CalibrationEquationId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    saveCalibrationEquation(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !calibrationEquationManager ||
      !calibrationEquationManager.SelectedCalibrationEquation ||
      !calibrationEquationManager.SelectedCalibrationEquation.Name ||
      isRequired(calibrationEquationManager.SelectedCalibrationEquation.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'calibrationEquation.nameMandatory',
        defaultMessage: 'Equation name is mandatory field'
      });
      isValid = false;
    }

    if (
      !calibrationEquationManager ||
      !calibrationEquationManager.SelectedCalibrationEquation ||
      !calibrationEquationManager.SelectedCalibrationEquation.Formula ||
      isRequired(calibrationEquationManager.SelectedCalibrationEquation.Formula, 1)
    ) {
      formErrors.Formula = intl.formatMessage({
        id: 'calibrationEquation.formulaMandatory',
        defaultMessage: 'Formula name is mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalCalibrationEquation({
        ...calibrationEquationManager,
        SelectedCalibrationEquation: { ...calibrationEquationManager.SelectedCalibrationEquation, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalCalibrationEquation({
        ...calibrationEquationManager,
        SelectedCalibrationEquation: { ...calibrationEquationManager.SelectedCalibrationEquation, [e.target.name]: e.target.value }
      });
    }
  };

  const setEditHandler = (equationConstantId, identifer) => {
    if (equationConstantId > -1) {
      setCurrentEquationConstantId(equationConstantId);
      setCurrentEquationConstantIdentifier(identifer);
      const constantList =
        (calibrationEquationManager.SelectedCalibrationEquation.EquationConstants &&
          calibrationEquationManager.SelectedCalibrationEquation.EquationConstants.EquationConstantList) ||
        [];
      let constantEquation = constantList.filter(item => {
        if (item.GuidIdentifier === identifer) return item;
      });
      if (constantEquation.length > 0) {
        changeCalibrationEquationConstant({
          SelectedEquationConstant: constantEquation[0]
        });
        showAddEditScreen(true);
      }
    } else {
      let data = initialCalibrationEquationState.calibrationEquation.SelectedCalibrationEquation.EquationConstants.SelectedEquationConstant;
      setCurrentEquationConstantId(-1);
      setCurrentEquationConstantIdentifier('');
      showAddEditScreen(false);
      changeCalibrationEquationConstant({
        SelectedEquationConstant: data
      });
    }
  };

  const setAddHandler = () => {
    let data = initialCalibrationEquationState.calibrationEquation.SelectedCalibrationEquation.EquationConstants.SelectedEquationConstant;
    data.CalibrationEquationId = parseInt(CalibrationEquationId);
    showAddEditScreen(true);
    changeCalibrationEquationConstant({
      SelectedEquationConstant: data
    });
    setCurrentEquationConstantId(0);
    setCurrentEquationConstantIdentifier('');
  };

  const disableModeHandler = status => {};

  const updateCalibrationEquationConstantConstant = status => {};

  let messageId = (calibrationEquationManager && calibrationEquationManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.calibrationequation}>
      {calibrationEquationManager.isLoading && <LoadingSpinner />}
      {calibrationEquationManager.isOpSuccessful && !calibrationEquationManager.showBanner ? (
        <Navigate to="/admin-controls/calibration-equation-list" />
      ) : (
        ''
      )}
      {IsRedirect ? <Navigate to="/admin-controls/calibration-equation-list" /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={calibrationEquationManager.showBanner}
        status={calibrationEquationManager.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="manageCalibrations" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.calibrationequationHeader}>
            <Link className={s.backLink} to={'/admin-controls/calibration-equation-list'}>
              &lt; &nbsp;
              <FormattedMessage id="calibrationEquation.backToCalibrations" defaultMessage="BACK TO CALIBRATIONS" />
            </Link>
            <h3 data-unittest="headingLabel">
              <FormattedMessage id="calibrationEquation.newCalibration" defaultMessage="New calibration equation configuration" />
            </h3>
          </div>
          <div className={s.calibrationequationContent}>
            <Form>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formCalibrationEquationName">
                    <Form.Label>
                      <FormattedMessage id="calibrationEquation.name" defaultMessage="Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={calibrationEquationManager.SelectedCalibrationEquation.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formCalibrationEquationDescription">
                    <Form.Label>
                      <FormattedMessage id="calibrationEquation.description" defaultMessage="Description" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'calibrationEquation.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={calibrationEquationManager.SelectedCalibrationEquation.Description}
                      className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formCalibrationEquationFormula">
                    <Form.Label>
                      <FormattedMessage id="calibrationEquation.formula" defaultMessage="Formula" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Formula"
                      onChange={onChange}
                      value={calibrationEquationManager.SelectedCalibrationEquation.Formula}
                      className={`${s.formControl} ${localformErrors && localformErrors.Formula ? s.formControlError : ''}`}
                    />
                    <p role="alert" className={s.info}>
                      eg: {'{m}*{x} + {c}  =  mx+c'}{' '}
                    </p>
                    <p role="alert" className={s.info}>
                      eg: {'{x}**3 + {m}*{x}**2 - {c}  =    x³ + mx² -c'}{' '}
                    </p>
                    {localformErrors && localformErrors.Formula && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Formula}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="formActiveCalibrationEquation">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsActive',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'calibrationEquation.active',
                            defaultMessage: 'Equation is active'
                          }),
                          isChecked: calibrationEquationManager.SelectedCalibrationEquation.IsActive
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div>
                    <EquationConstantTable
                      SelectedCalibrationEquationConstant={calibrationEquationManager.SelectedCalibrationEquation.EquationConstants}
                      setEditHandler={setEditHandler}
                      setCalibrationEquationCustomError={setCalibrationEquationCustomError}
                      disableMode={calibrationEquationManager.SelectedCalibrationEquation.EquationConstants.showAddEditScreen}
                      disableModeHandler={disableModeHandler}
                      selectedCalibrationEquationConstantId={currentEquationConstantId}
                      selectedEquationConstantIdentifier={currentEquationConstantIdentifier}
                      changeCalibrationEquationConstant={changeCalibrationEquationConstant}
                      setCalibrationEquationConstantList={setCalibrationEquationConstantList}
                      updateCalibrationEquationConstantConstant={updateCalibrationEquationConstantConstant}
                      userProfileWidgets={userProfileWidgets}
                      UpdateAllowed={isUpdateAllowed}
                      setAddHandler={setAddHandler}
                    />
                  </div>
                </Col>
              </Row>
              <Row className={s.breakRow}>
                <Col sm={12}>
                  <Button
                    variant="primary"
                    className={classNames(s.margin5, s.btnSaveChanges)}
                    onClick={submitForm}
                    noValidate
                    data-unittest="saveData"
                  >
                    <FormattedMessage id="calibrationEquation.saveCalibrationEquation" defaultMessage="SAVE CHANGES" />
                  </Button>
                  <Link to="/admin-controls/calibration-equation-list">
                    <Button variant="outline-secondary" className={s.btnCancel}>
                      <FormattedMessage id="calibrationEquation.cancelCalibrationEquation" defaultMessage="CANCEL" />
                    </Button>
                  </Link>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

CalibrationEquation.defaultProps = {
  calibrationEquationManager: {
    ...initialCalibrationEquationState.calibrationEquation
  }
};

CalibrationEquation.propTypes = {
  saveCalibrationEquation: PropTypes.func.isRequired,
  calibrationEquationManager: PropTypes.object.isRequired,
  describeCalibrationEquation: PropTypes.func.isRequired,
  changeCalibrationEquation: PropTypes.func.isRequired
};

export default injectIntl(CalibrationEquation);
