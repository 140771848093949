import React from 'react';
import s from './TermsAndConditions.module.scss';
import PDFViewer from './PDFViewer';
import { injectIntl } from 'react-intl';
import HelpControlsContentTemplate from '../HelpControls/HelpControlsContentTemplate';

const TermsAndConditions = ({ intl }) => {
  return (
    <div className={s.tac}>
      <HelpControlsContentTemplate selectedPage="tac">
        <div className={s.contentWrapper}>
          <PDFViewer pdfUrl={`${process.env.PUBLIC_URL + '/TermsAndConditions/software_license_agreement.pdf?#toolbar=0'}`} />
        </div>
      </HelpControlsContentTemplate>
    </div>
  );
};

export default injectIntl(TermsAndConditions);
