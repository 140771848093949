import React, { useEffect } from 'react';
import svgpanzoom from 'svg-pan-zoom';
import './EleIotGateway.css';
import classNames from 'classnames';
let schematicDiagram = null;

const const_open = 'open';
const const_close = 'close';
const const_on = 'on';
const const_off = 'off';
const const_tank_high = 'high';
const const_tank_half = 'half';
const const_tank_low = 'low';
const const_valve_pos1 = 'pos1';
const const_valve_pos2 = 'pos2';
const const_valve_pos3 = 'pos3';

const DeviceSchematicEleIotGateway = sensorData => {
  //Ball valves
  //===========
  let { ShowSensors, unitComponentParts } = sensorData;

  let clsshow_sensor = ShowSensors ? 'show-sensor' : 'hide-sensor';
  const svgElement = document.querySelector('#dv-schematics');
  var options = {
    zoomEnabled: true,
    mouseWheelZoomEnabled: sensorData.enableMouseZoom,
    controlIconsEnabled: true,

    fit: true,
    center: true,
    contain: true
  };

  if (svgElement) {
    schematicDiagram = svgpanzoom(svgElement, options);

    if (sensorData.enableMouseZoom) {
      schematicDiagram.enableMouseWheelZoom();
    } else {
      schematicDiagram.disableMouseWheelZoom();
    }
  }

  const onResize = () => {
    if (schematicDiagram) {
      // schematicDiagram.reset();
      schematicDiagram.resize();
    }
  };

  useEffect(() => {
    window.addEventListener('resize', onResize);
  }, [onResize]); // Only initialize listener on initial render

  /*
   ********************************************* SVG TO SVGR CONVERSION***************************************
    Following online SVG to SVGR Converter Tool is used 
      https://www.fetoolkit.io/widget/svg-converter  

    Step  1: Copy SVG to INPUT section
    Step  2: Disable Clean Up Ids and Remove Unknowns and Defaults from Settings
    Step  3: Select Option JSX
    Step  4: Copy the converted SVG and paste it in notepad++
    Step  5: Remove JSX code, xmlns tag and ...props attribute
    Step  6: Replace {"{# with {
    Step  7: Replace #}"} with }
    Step  8: Replace "{# with {
    Step  9: Replace #}" with }
    Step  10: Set SVG tag properties as given below
    width="100%"
    height="100%"
    viewBox="0 120 1800 780"
    id="dv-schematics"
    xmlSpace="preserve"
    xmlnsXlink="http://www.w3.org/1999/xlink"

  */

  const getStatePosValue = val => {
    const stateData = sensorData?.SensorData?.state || {};
    return stateData[val] || 'undefined';
  };

  const getSensorValue = val => {
    let uom = '';
    let returnval = '';
    const sensorDisplayData = sensorData?.SensorData || {};
    returnval = sensorDisplayData[val] || '';

    if (unitComponentParts && unitComponentParts.length > 0 && returnval) {
      let sensorInfo = unitComponentParts.find(e => e.Name.toLowerCase() === val.toLowerCase());
      uom = sensorInfo ? (sensorInfo.UoM ? sensorInfo.UoM : '') : '';
      if (uom) returnval = `${returnval} ${uom}`;
    }
    return returnval;
  };

  const getStateClass = val => {
    const stateVal = (val != 'undefined' && val.toLowerCase()) || '';
    if (stateVal == const_on || stateVal == const_open) {
      return 'sen-green_dark';
    } else if (stateVal == const_off || stateVal == const_close) {
      return '';
    } else {
      return 'sen-amber';
    }
  };

  const is_state_value_true = val => {
    val = val && val.toLowerCase();
    if (val == const_open || val == const_on) return true;
    return false;
  };

  const is_sensor_value_true = val => {
    if (val && val != '' && parseFloat(val) > 0) return true;
    return false;
  };

  const getTankFillValue = val => {
    const sensorTankVal = val || const_tank_low;

    const tankLevels = {
      [const_tank_high]: { fillValue: '85%', fillGradValue: '100%' },
      [const_tank_half]: { fillValue: '50%', fillGradValue: '65%' },
      [const_tank_low]: { fillValue: '0%', fillGradValue: '0%' }
    };

    return tankLevels[sensorTankVal.toLowerCase()] || tankLevels[const_tank_low];
  };

  let cls_main_label = '';
  let star = 'star';
  let star1 = 'star1';
  let star2 = 'star2';
  let star3 = 'star3';
  let star4 = 'star4';
  let star5 = 'star5';
  let star6 = 'star6';
  let star7 = 'star7';
  let star8 = 'star8';
  let star9 = 'star9';
  let star10 = 'star10';
  let star11 = 'star11';
  let star12 = 'star12';

  let ae01a_dotemp = '';
  let ae01a_dovalue = getSensorValue('ae01ado_dissolvedoxygen');
  let ae01a_orpph = getSensorValue('ae01aph_ph');
  let ae01a_orptemp = getSensorValue('ae01atemp_temperature');
  let ae01a_orpvalue = getSensorValue('ae01aorp_orp');
  let ae01b_dotemp = '';
  let ae01b_dovalue = getSensorValue('ae01bdo_dissolvedoxygen');
  let ae01b_orpph = getSensorValue('ae01bph_ph');
  let ae01b_orptemp = getSensorValue('ae01btemp_temperature');
  let ae01b_orpvalue = getSensorValue('ae01borp_orp');
  let b01_value = ''; // NOT Controlled
  let b02_value = getSensorValue('b02speedread_derived');
  let ft01_value = getSensorValue('ft01_flowrate');
  let p01_value = getSensorValue('p01stateread_derived');
  let p02_value = getSensorValue('p02stateread_derived');
  let p03_value = getSensorValue('p03stateread_derived');
  let p04_value = getSensorValue('p04stateread_derived');
  let p05_value = getSensorValue('p05stateread_derived');
  let p06_value = getSensorValue('p06stateread_derived');
  let p07_value = getSensorValue('p07stateread_derived');
  let p08_value = getSensorValue('p08stateread_derived');
  let p09_value = getSensorValue('p09stateread_derived');
  let mv01_value = getSensorValue('mv01positionread_derived');
  let mv02_value = getSensorValue('mv02positionread_derived');
  let mv04_value = getSensorValue('mv04positionread_derived');
  let pc01_value = '';
  let pc02_value = '';
  let sf01_value = getSensorValue('sf01stateread_derived');
  let tk01_value = '';
  let tk02_value = getSensorValue('t02_level');
  let tk04_value = getSensorValue('t04_level');
  let tk05_value = getSensorValue('t05_level');
  let tk06_value = getSensorValue('t06_level');
  let tk07_value = getSensorValue('t07_level');
  let tk09_value = getSensorValue('t09_level');

  let cls_ae01a_do_body = '';
  let cls_ae01a_orp_body = '';
  let cls_ae01b_do_body = '';
  let cls_ae01b_orp_body = '';
  let cls_b01_body = '';
  let cls_bv02_body = getStateClass(b02_value);
  let cls_tk07_overflowline = '';
  let cls_bv02_lineout = '';
  let cls_fcv01_lineout = '';
  let cls_ft01_body = '';
  let cls_hv01_body = '';
  let cls_hv02_body = '';
  let cls_hv03_body = '';
  let cls_hv04_body = 'sen-green';
  let cls_hv04_linein = '';
  let cls_hv05_body = '';
  let cls_hv06_body = 'sen-green';
  let cls_hv07_body = '';
  let cls_hv08_body = '';
  let cls_hv09_body = 'sen-green';
  let cls_hv10_body = '';
  let cls_hv12_body = '';
  let cls_hv13_body = '';
  let cls_hv14_body = '';
  let cls_hv15_body = '';
  let cls_hv15_linein = '';
  let cls_ls01_body = '';
  let cls_ls02_body = '';
  let cls_ls03_body = '';
  let cls_ls04_body = '';
  let cls_ls05_body = '';
  let cls_ls06_body = '';
  let cls_ls07_body = '';
  let cls_mv01_body = getStateClass(mv01_value);
  let cls_mv01_lineout = '';
  let cls_mv02_body = getStateClass(mv02_value);
  let cls_mv02_lineout = '';
  let cls_mv03_pos1 = '';
  let cls_mv03_pos1lineout = '';
  let cls_mv03_pos2 = '';
  let cls_mv03_body = '';
  let cls_mv04_pos1 = '';
  let cls_mv04_pos2 = '';
  let cls_mv04_body = '';
  let cls_mv03_pos2lineout = '';
  let cls_mv04_pos2lineout = '';
  let cls_mv10_lineout = '';
  let cls_p01_body = getStateClass(p01_value);
  let cls_p02_body = getStateClass(p02_value);
  let cls_p03_body = getStateClass(p03_value);
  let cls_p03_lineout = '';
  let cls_p04_body = getStateClass(p04_value);
  let cls_p04_linein = '';
  let cls_p05_body = getStateClass(p05_value);
  let cls_p05_linein = '';
  let cls_p05_lineout = '';
  let cls_p06_body = getStateClass(p06_value);
  let cls_p06_linein = '';
  let cls_p06_lineout = '';
  let cls_p07_body = getStateClass(p07_value);
  let cls_p07_linein = '';
  let cls_p07_lineout = '';
  let cls_p08_body = getStateClass(p08_value);
  let cls_p08_lineout = '';
  let cls_p09_body = getStateClass(p09_value);
  let cls_pc01_linein = '';
  let cls_pc01_lineout = '';
  let cls_pc02_lineout = '';
  let cls_pc04_linein = '';
  let cls_sandfilter_body = '';
  let cls_tk01_linein = '';
  let cls_tk01_lineout = 'line-black';
  let cls_tk02_linein = '';
  let cls_tk02_lineout = '';
  let cls_tk02_sampleline = '';
  let cls_tk05_linein = 'line-light-blue';
  let cls_tk05_lineout = '';
  let cls_tk05_sampleline = '';
  let cls_tk06_linein = '';
  let cls_tk06_lineout = '';
  let cls_tk06_sampleline = '';
  let cls_tk07_lineout = '';
  let cls_tk08_linein = '';
  let cls_water_fill_color = '#00bfff';

  let tk01fill_value = '50%';
  let tk01fill_grad_value = '60%';

  let tnk02_fillValues = getTankFillValue(tk02_value);
  let tk02fill_value = tnk02_fillValues.fillValue;
  let tk02fill_grad_value = tnk02_fillValues.fillGradValue;

  let tnk04_fillValues = getTankFillValue(tk04_value);
  let tk04fill_value = tnk04_fillValues.fillValue;
  let tk04fill_grad_value = tnk04_fillValues.fillGradValue;

  let tnk05_fillValues = getTankFillValue(tk05_value);
  let tk05fill_value = tnk05_fillValues.fillValue;
  let tk05fill_grad_value = tnk05_fillValues.fillGradValue;

  let tnk06_fillValues = getTankFillValue(tk06_value);
  let tk06fill_value = tnk06_fillValues.fillValue;
  let tk06fill_grad_value = tnk06_fillValues.fillGradValue;

  let tnk07_fillValues = getTankFillValue(tk07_value);
  let tk07fill_value = tnk07_fillValues.fillValue;
  let tk07fill_grad_value = tnk07_fillValues.fillGradValue;

  let tnk09_fillValues = getTankFillValue(tk09_value);
  let tk09fill_value = tnk09_fillValues.fillValue;
  let tk09fill_grad_value = tnk09_fillValues.fillGradValue;

  // Line Logics
  if (is_state_value_true(p01_value) || is_state_value_true(p02_value)) {
    cls_tk01_lineout = 'line-light-blue';
  }
  if (is_sensor_value_true(ft01_value)) {
    cls_fcv01_lineout = cls_tk01_lineout;
    cls_pc01_lineout = 'line-dark-red';
  }
  cls_pc01_linein = cls_fcv01_lineout;
  cls_tk02_linein = cls_pc01_linein;
  cls_tk02_sampleline = cls_tk02_linein;

  if (is_state_value_true(p03_value)) {
    cls_p03_lineout = 'line-red';
  }

  if (tk02_value.toLowerCase() == const_tank_high || tk02_value.toLowerCase() == const_tank_half) {
    cls_tk02_lineout = cls_tk02_linein;
  }

  if (tk05_value.toLowerCase() == const_tank_high || tk05_value.toLowerCase() == const_tank_half) {
    cls_tk05_sampleline = cls_tk05_linein;
    cls_tk05_lineout = cls_tk05_linein;
  }

  if (is_state_value_true(p06_value)) {
    cls_p06_linein = cls_tk05_lineout;
    cls_p06_lineout = cls_p06_linein;
    cls_tk06_linein = cls_p06_lineout;
  }

  if (tk06_value.toLowerCase() == const_tank_high || tk06_value.toLowerCase() == const_tank_half) {
    cls_tk06_sampleline = cls_tk06_linein;
    cls_tk06_lineout = cls_tk06_linein;
  }

  cls_mv03_body = 'sen-green_dark';
  cls_mv03_pos2 = cls_mv03_body;
  cls_sandfilter_body = 'sen-green_dark';
  cls_mv03_pos2lineout = cls_tk06_lineout;

  if (is_state_value_true(mv01_value)) {
    cls_mv01_lineout = cls_pc01_lineout;
    cls_p04_linein = cls_mv01_lineout;
  }

  if (is_state_value_true(mv02_value)) {
    cls_pc02_lineout = 'line-dark-red';
    cls_mv02_lineout = cls_pc01_lineout;
    cls_p04_linein = cls_mv02_lineout;
  }

  if (is_state_value_true(p04_value)) {
    cls_pc04_linein = cls_p04_linein;
  }

  if (is_state_value_true(p05_value)) {
    cls_hv15_body = 'sen-green';
    cls_hv15_linein = 'line-dark-blue';
    cls_p05_linein = cls_hv15_linein;
    cls_p05_lineout = cls_p05_linein;
    cls_hv15_linein = cls_p05_lineout;
  }

  if (is_state_value_true(p08_value)) {
    cls_p08_lineout = 'line-dark-blue';
  }

  if (mv04_value == const_valve_pos1) {
    cls_mv04_body = 'sen-green_dark';
    cls_mv04_pos1 = cls_mv03_body;
    cls_tk08_linein = cls_mv03_pos2lineout;
  } else if (mv04_value == const_valve_pos2) {
    cls_mv04_body = 'sen-green_dark';
    cls_mv04_pos2 = cls_mv03_body;
    cls_mv04_pos2lineout = cls_mv03_pos2lineout;
  } else {
    cls_mv04_body = 'sen-amber';
    cls_mv03_pos2 = cls_mv03_body;
  }

  if (tk07_value.toLowerCase() == const_tank_high || tk07_value.toLowerCase() == const_tank_half) {
    cls_tk07_lineout = cls_mv04_pos2lineout;

    if (is_state_value_true(p07_value)) {
      cls_hv12_body = 'sen-green';
      cls_p07_linein = cls_tk07_lineout;
      cls_p07_lineout = cls_p07_linein;
    }
    // if there is a full tank
    if (tk07_value.toLowerCase() == const_tank_high) {
      cls_tk07_overflowline = cls_mv04_pos2lineout;
    }
  }

  if (is_state_value_true(p09_value)) {
    cls_p08_lineout = cls_tk07_lineout;
  }

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 120 1800 780"
      id="dv-schematics"
      xmlSpace="preserve"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <style id="style1">{'path{stroke-width:1.5!important}tspan{font-size:11px!important}'}</style>
      <defs id="defs1">
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1737">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1 -3507)" id="path1737" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1739">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1889 -6897)" id="path1739" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1741">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3543 -6897)" id="path1741" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1743">
          <path d="M1 1h9919v7013H1V1" transform="translate(-6850 -6897)" id="path1743" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1745">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8503 -6897)" id="path1745" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1747">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -1116.96 828.72)" id="path1747" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1749">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -917.64 828.72)" id="path1749" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1751">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -719.16 828.72)" id="path1751" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1753">
          <path d="M1 1h9919v7013H1V1" transform="translate(-590 -1753)" id="path1753" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1755">
          <path d="M1 1h9919v7013H1V1" transform="translate(-590 -5261)" id="path1755" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1757">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -60.12 100.2)" id="path1757" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1759">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9661.5 -118)" id="path1759" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1761">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9803 -259)" id="path1761" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1763">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9803 -6755.5)" id="path1763" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1765">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9661 -6897)" id="path1765" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1767">
          <path d="M1 1h9919v7013H1V1" transform="translate(-732.5 -6897)" id="path1767" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1769">
          <path d="M1 1h9919v7013H1V1" transform="translate(-590 -6755)" id="path1769" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1771">
          <path d="M1 1h9919v7013H1V1" transform="translate(-591 -259.5)" id="path1771" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1773">
          <path d="M1 1h9919v7013H1V1" transform="translate(-732 -118)" id="path1773" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1775">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1889 -118)" id="path1775" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1777">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3543 -118)" id="path1777" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1779">
          <path d="M1 1h9919v7013H1V1" transform="translate(-6850 -118)" id="path1779" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1781">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8503 -118)" id="path1781" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1783">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -1116.96 3)" id="path1783" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1785">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9803 -1753)" id="path1785" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1787">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9803 -5261)" id="path1787" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1789">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -1178.04 100.2)" id="path1789" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1791">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5551 -6944)" id="path1791" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1793">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5551 -6944)" id="path1793" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1795">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3070 -6944)" id="path1795" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1797">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3070 -6944)" id="path1797" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1799">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5551 -70)" id="path1799" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1801">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5551 -70)" id="path1801" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1803">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3070 -70)" id="path1803" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1805">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3070 -70)" id="path1805" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1807">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9920 -3507)" id="path1807" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1809">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5196 -1)" id="path1809" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1811">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5196 -7014)" id="path1811" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1813">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1835)" id="path1813" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1815">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7905 -1694)" id="path1815" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1817">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9224 -1694)" id="path1817" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1819">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9418 -1694)" id="path1819" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1821">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1694)" id="path1821" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1823">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9716 -1694)" id="path1823" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1825">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1694)" id="path1825" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1827">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -2119)" id="path1827" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1829">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7905 -1835)" id="path1829" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1831">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9224 -1835)" id="path1831" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1833">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9418 -1835)" id="path1833" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1835">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1835)" id="path1835" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1837">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9716 -1835)" id="path1837" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1839">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1835)" id="path1839" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1841">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -2119)" id="path1841" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1843">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9499 -1598)" id="path1843" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1845">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9528 -1598)" id="path1845" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1847">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9532 -1620)" id="path1847" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1849">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9567 -1664)" id="path1849" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1851">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9556 -1664)" id="path1851" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1853">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9587 -1598)" id="path1853" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1855">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9587 -1633)" id="path1855" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1857">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9587 -1598)" id="path1857" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1859">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9618 -1642)" id="path1859" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1861">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9618 -1604)" id="path1861" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1863">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7947 -1598)" id="path1863" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1865">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7981 -1598)" id="path1865" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1867">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7981 -1633)" id="path1867" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1869">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7981 -1598)" id="path1869" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1871">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8031 -1655)" id="path1871" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1873">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8065 -1649)" id="path1873" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1875">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8076 -1598)" id="path1875" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1877">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8087 -1633)" id="path1877" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1879">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8109 -1664)" id="path1879" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1881">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8122 -1598)" id="path1881" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1883">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8162 -1664)" id="path1883" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1885">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8151 -1664)" id="path1885" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1887">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8181 -1664)" id="path1887" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1889">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8202 -1664)" id="path1889" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1891">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8229 -1598)" id="path1891" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1893">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8265 -1642)" id="path1893" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1895">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8265 -1604)" id="path1895" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1897">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7775 -1598)" id="path1897" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1899">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7786 -1633)" id="path1899" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1901">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7808 -1598)" id="path1901" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1903">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7808 -1633)" id="path1903" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1905">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7808 -1598)" id="path1905" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1907">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7833 -1664)" id="path1907" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1909">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7868 -1642)" id="path1909" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1911">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7868 -1604)" id="path1911" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1913">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9295 -1598)" id="path1913" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1915">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9309 -1633)" id="path1915" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1917">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9323 -1664)" id="path1917" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1919">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9349 -1664)" id="path1919" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1921">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9358 -1642)" id="path1921" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1923">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9358 -1604)" id="path1923" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1925">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8172 -1365)" id="path1925" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1927">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8205 -1365)" id="path1927" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1929">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8217 -1400)" id="path1929" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1931">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8247 -1431)" id="path1931" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1933">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8287 -1431)" id="path1933" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1935">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8299 -1365)" id="path1935" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1937">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8299 -1400)" id="path1937" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1939">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8299 -1365)" id="path1939" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1941">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8352 -1415)" id="path1941" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1943">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8369 -1431)" id="path1943" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1945">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8358 -1431)" id="path1945" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1947">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8390 -1409)" id="path1947" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1949">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8390 -1371)" id="path1949" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1951">
          <path d="M1 1h9919v7013H1V1" transform="translate(-814 -1346)" id="path1951" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1953">
          <path d="M1 1h9919v7013H1V1" transform="translate(-847 -1346)" id="path1953" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1955">
          <path d="M1 1h9919v7013H1V1" transform="translate(-858 -1381)" id="path1955" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1957">
          <path d="M1 1h9919v7013H1V1" transform="translate(-888 -1413)" id="path1957" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1959">
          <path d="M1 1h9919v7013H1V1" transform="translate(-928 -1413)" id="path1959" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1961">
          <path d="M1 1h9919v7013H1V1" transform="translate(-941 -1346)" id="path1961" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1963">
          <path d="M1 1h9919v7013H1V1" transform="translate(-941 -1381)" id="path1963" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1965">
          <path d="M1 1h9919v7013H1V1" transform="translate(-941 -1346)" id="path1965" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1967">
          <path d="M1 1h9919v7013H1V1" transform="translate(-993 -1397)" id="path1967" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1969">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1011 -1413)" id="path1969" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1971">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1000 -1413)" id="path1971" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1973">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1063 -1346)" id="path1973" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1975">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1094 -1346)" id="path1975" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1977">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1098 -1368)" id="path1977" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1979">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1127 -1346)" id="path1979" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1981">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1165 -1346)" id="path1981" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1983">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1165 -1381)" id="path1983" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1985">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1165 -1346)" id="path1985" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1987">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1197 -1390)" id="path1987" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1989">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1197 -1352)" id="path1989" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1991">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9224 -1552)" id="path1991" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1993">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7905 -1552)" id="path1993" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1995">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9418 -1552)" id="path1995" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1997">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7792 -1552)" id="path1997" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath1999">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9636.5 -1552)" id="path1999" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2001">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9716 -1632)" id="path2001" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2003">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9716 -1694)" id="path2003" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2005">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1694)" id="path2005" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2007">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1632.5)" id="path2007" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2009">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9703 -731.5)" id="path2009" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2011">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9702 -731)" id="path2011" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2013">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9602.5 -218)" id="path2013" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2015">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9602 -218)" id="path2015" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2017">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8934 -318.5)" id="path2017" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2019">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8934 -318)" id="path2019" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2021">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9034.5 -832)" id="path2021" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2023">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9034 -832)" id="path2023" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2025">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8867 -436.5)" id="path2025" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2027">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8867 -436)" id="path2027" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2029">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8800.5 -219)" id="path2029" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2031">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8800 -218)" id="path2031" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2033">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8100 -285.5)" id="path2033" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2035">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8099 -285)" id="path2035" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2037">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166.5 -503)" id="path2037" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2039">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166 -503)" id="path2039" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2041">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5527 -1329.5)" id="path2041" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2043">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5527 -1329)" id="path2043" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2045">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5393.5 -218)" id="path2045" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2047">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5393 -218)" id="path2047" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2049">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3924 -351.5)" id="path2049" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2051">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3923 -351)" id="path2051" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2053">
          <path d="M1 1h9919v7013H1V1" transform="translate(-4057.5 -1463)" id="path2053" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2055">
          <path d="M1 1h9919v7013H1V1" transform="translate(-4057 -1463)" id="path2055" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2056">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -673.8 103.32)" id="path2056" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2058">
          <path d="M1 1h9919v7013H1V1" transform="translate(-764 -483)" id="path2058" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2060">
          <path d="M1 1h9919v7013H1V1" transform="translate(-797 -483)" id="path2060" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2062">
          <path d="M1 1h9919v7013H1V1" transform="translate(-808 -518)" id="path2062" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2064">
          <path d="M1 1h9919v7013H1V1" transform="translate(-826 -483)" id="path2064" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2066">
          <path d="M1 1h9919v7013H1V1" transform="translate(-830 -505)" id="path2066" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2068">
          <path d="M1 1h9919v7013H1V1" transform="translate(-856 -549)" id="path2068" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2070">
          <path d="M1 1h9919v7013H1V1" transform="translate(-897 -483)" id="path2070" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2072">
          <path d="M1 1h9919v7013H1V1" transform="translate(-966 -483)" id="path2072" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2074">
          <path d="M1 1h9919v7013H1V1" transform="translate(-980 -518)" id="path2074" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2076">
          <path d="M1 1h9919v7013H1V1" transform="translate(-994 -549)" id="path2076" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2078">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1020 -549)" id="path2078" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2080">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1029 -527)" id="path2080" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2082">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1029 -489)" id="path2082" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2084">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1365.5 -219)" id="path2084" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2086">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1432 -285)" id="path2086" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2088">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1432 -514.5)" id="path2088" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2090">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1365 -581)" id="path2090" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2092">
          <path d="M1 1h9919v7013H1V1" transform="translate(-757.5 -581)" id="path2092" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2094">
          <path d="M1 1h9919v7013H1V1" transform="translate(-690 -514)" id="path2094" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2096">
          <path d="M1 1h9919v7013H1V1" transform="translate(-691 -285.5)" id="path2096" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2098">
          <path d="M1 1h9919v7013H1V1" transform="translate(-757 -218)" id="path2098" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2100">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2240 -514.5)" id="path2100" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2102">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2240 -514)" id="path2102" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2104">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2173.5 -219)" id="path2104" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2106">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2173 -218)" id="path2106" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2108">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1499 -285.5)" id="path2108" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2110">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1499 -285)" id="path2110" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2112">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1565.5 -581)" id="path2112" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2114">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1565 -581)" id="path2114" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2116">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2981.5 -219)" id="path2116" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2118">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3048 -285)" id="path2118" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2120">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3048 -514.5)" id="path2120" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2122">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2981 -581)" id="path2122" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2124">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2374.5 -581)" id="path2124" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2126">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2307 -514)" id="path2126" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2128">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2308 -285.5)" id="path2128" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2130">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2374 -218)" id="path2130" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2132">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3857 -514.5)" id="path2132" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2134">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3857 -514)" id="path2134" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2136">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3790.5 -219)" id="path2136" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2138">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3790 -218)" id="path2138" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2140">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3115 -318.5)" id="path2140" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2142">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3115 -318)" id="path2142" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2144">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3182.5 -581)" id="path2144" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2146">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3182 -581)" id="path2146" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2148">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9703 -1070.5)" id="path2148" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2150">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9702 -1070)" id="path2150" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2152">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9636.5 -876)" id="path2152" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2154">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9636 -875)" id="path2154" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2156">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8100 -942.5)" id="path2156" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2158">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8099 -942)" id="path2158" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2160">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166.5 -1137)" id="path2160" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2162">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166 -1137)" id="path2162" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2164">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9703 -1396.5)" id="path2164" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2166">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9702 -1396)" id="path2166" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2168">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9636.5 -1181)" id="path2168" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2170">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9636 -1180)" id="path2170" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2172">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8100 -1247.5)" id="path2172" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2174">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8099 -1247)" id="path2174" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2176">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166.5 -1463)" id="path2176" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2178">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166 -1463)" id="path2178" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2180">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -1977)" id="path2180" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2182">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8867 -765.5)" id="path2182" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2184">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8867 -765)" id="path2184" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2186">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8800.5 -547)" id="path2186" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2188">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8800 -546)" id="path2188" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2190">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8517 -613.5)" id="path2190" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2192">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8516 -613)" id="path2192" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2194">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8583.5 -832)" id="path2194" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2196">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8583 -832)" id="path2196" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2198">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8450 -765.5)" id="path2198" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2200">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8450 -765)" id="path2200" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2202">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8383.5 -547)" id="path2202" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2204">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8383 -546)" id="path2204" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2206">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8100 -613.5)" id="path2206" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2208">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8099 -613)" id="path2208" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2210">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166.5 -832)" id="path2210" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2212">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8166 -832)" id="path2212" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2214">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8187 -794)" id="path2214" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2216">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8214 -789)" id="path2216" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2218">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8219 -748)" id="path2218" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2220">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8223 -766)" id="path2220" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2222">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8246 -802)" id="path2222" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2224">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8268 -748)" id="path2224" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2226">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8268 -776)" id="path2226" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2228">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8268 -748)" id="path2228" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2230">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8293 -784)" id="path2230" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2232">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8293 -753)" id="path2232" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2234">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8605 -794)" id="path2234" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2236">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8614 -802)" id="path2236" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2238">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8632 -802)" id="path2238" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2240">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8614 -776)" id="path2240" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2242">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8642 -748)" id="path2242" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2244">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8642 -776)" id="path2244" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2246">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8642 -748)" id="path2246" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2248">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8666 -748)" id="path2248" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2250">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8666 -776)" id="path2250" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2252">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8666 -748)" id="path2252" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2254">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8695 -802)" id="path2254" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2256">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8686 -802)" id="path2256" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2258">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8754 -794)" id="path2258" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2260">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8763 -802)" id="path2260" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2262">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8772 -748)" id="path2262" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2264">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8772 -748)" id="path2264" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2266">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8798 -748)" id="path2266" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2268">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8798 -776)" id="path2268" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2270">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8798 -748)" id="path2270" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2272">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8824 -784)" id="path2272" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2274">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8824 -753)" id="path2274" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2276">
          <path d="M1 1h9919v7013H1V1" transform="translate(-814 -894)" id="path2276" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2278">
          <path d="M1 1h9919v7013H1V1" transform="translate(-847 -894)" id="path2278" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2280">
          <path d="M1 1h9919v7013H1V1" transform="translate(-858 -929)" id="path2280" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2282">
          <path d="M1 1h9919v7013H1V1" transform="translate(-876 -894)" id="path2282" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2284">
          <path d="M1 1h9919v7013H1V1" transform="translate(-880 -917)" id="path2284" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2286">
          <path d="M1 1h9919v7013H1V1" transform="translate(-906 -961)" id="path2286" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2288">
          <path d="M1 1h9919v7013H1V1" transform="translate(-947 -961)" id="path2288" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2290">
          <path d="M1 1h9919v7013H1V1" transform="translate(-960 -894)" id="path2290" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2292">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1017 -945)" id="path2292" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2294">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1068 -961)" id="path2294" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2296">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1057 -961)" id="path2296" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2298">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1087 -961)" id="path2298" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2300">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1106 -961)" id="path2300" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2302">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1095 -961)" id="path2302" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2304">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1125 -961)" id="path2304" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2306">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1152 -894)" id="path2306" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2308">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1152 -929)" id="path2308" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2310">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1152 -894)" id="path2310" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2312">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1184 -939)" id="path2312" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2314">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1184 -901)" id="path2314" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2316">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1594 -534)" id="path2316" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2318">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1605 -549)" id="path2318" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2320">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1627 -549)" id="path2320" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2322">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1605 -518)" id="path2322" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2324">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1640 -483)" id="path2324" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2326">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1640 -518)" id="path2326" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2328">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1640 -483)" id="path2328" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2330">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1693 -534)" id="path2330" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2332">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1704 -549)" id="path2332" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2334">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1726 -549)" id="path2334" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2336">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1712 -521)" id="path2336" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2338">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1737 -483)" id="path2338" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2340">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1737 -518)" id="path2340" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2342">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1737 -483)" id="path2342" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2344">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1767 -483)" id="path2344" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2346">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1834 -483)" id="path2346" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2348">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1849 -518)" id="path2348" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2350">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1863 -549)" id="path2350" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2352">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1888 -549)" id="path2352" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2354">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1898 -527)" id="path2354" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2356">
          <path d="M1 1h9919v7013H1V1" transform="translate(-1898 -489)" id="path2356" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2358">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2375 -483)" id="path2358" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2360">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2380 -505)" id="path2360" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2362">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2409 -483)" id="path2362" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2364">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2442 -483)" id="path2364" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2366">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2475 -483)" id="path2366" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2368">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2487 -518)" id="path2368" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2370">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2517 -549)" id="path2370" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2372">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2539 -549)" id="path2372" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2374">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2572 -483)" id="path2374" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2376">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2572 -518)" id="path2376" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2378">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2572 -483)" id="path2378" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2380">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2603 -483)" id="path2380" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2382">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2670 -483)" id="path2382" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2384">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2684 -518)" id="path2384" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2386">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2698 -549)" id="path2386" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2388">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2724 -549)" id="path2388" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2390">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2733 -527)" id="path2390" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2392">
          <path d="M1 1h9919v7013H1V1" transform="translate(-2733 -489)" id="path2392" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2394">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3188 -483)" id="path2394" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2396">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3217 -483)" id="path2396" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2398">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3222 -505)" id="path2398" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2400">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3257 -549)" id="path2400" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2402">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3246 -549)" id="path2402" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2404">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3276 -483)" id="path2404" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2406">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3276 -518)" id="path2406" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2408">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3276 -483)" id="path2408" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2410">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3308 -527)" id="path2410" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2412">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3308 -489)" id="path2412" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2414">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8172 -1038)" id="path2414" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2416">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8205 -1038)" id="path2416" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2418">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8217 -1073)" id="path2418" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2420">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8234 -1038)" id="path2420" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2422">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8239 -1061)" id="path2422" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2424">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8264 -1105)" id="path2424" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2426">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8306 -1105)" id="path2426" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2428">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8318 -1038)" id="path2428" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2430">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8376 -1089)" id="path2430" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2432">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8420 -1038)" id="path2432" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2434">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8455 -1083)" id="path2434" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2436">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8460 -1105)" id="path2436" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2438">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8477 -1083)" id="path2438" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2440">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8477 -1045)" id="path2440" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2442">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8193 -462)" id="path2442" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2444">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8204 -472)" id="path2444" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2446">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8226 -472)" id="path2446" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2448">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8204 -440)" id="path2448" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2450">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8239 -405)" id="path2450" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2452">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8239 -440)" id="path2452" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2454">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8239 -405)" id="path2454" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2456">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8269 -405)" id="path2456" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2458">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8269 -440)" id="path2458" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2460">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8269 -405)" id="path2460" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2462">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8306 -472)" id="path2462" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2464">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8295 -472)" id="path2464" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2466">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8358 -405)" id="path2466" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2468">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8393 -449)" id="path2468" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2470">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8398 -472)" id="path2470" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2472">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8415 -449)" id="path2472" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2474">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8415 -411)" id="path2474" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2476">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7898.5 -218)" id="path2476" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2478">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8032 -351)" id="path2478" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2480">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8032 -1329.5)" id="path2480" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2482">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7898 -1463)" id="path2482" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2484">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5727.5 -1463)" id="path2484" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2486">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5594 -1329)" id="path2486" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2488">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5594 -351.5)" id="path2488" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2490">
          <path d="M1 1h9919v7013H1V1" transform="translate(-5727 -218)" id="path2490" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2492">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3857 -1329.5)" id="path2492" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2494">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3857 -1329)" id="path2494" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2496">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3723.5 -1055)" id="path2496" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2498">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3723 -1055)" id="path2498" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2500">
          <path d="M1 1h9919v7013H1V1" transform="translate(-691 -1188.5)" id="path2500" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2502">
          <path d="M1 1h9919v7013H1V1" transform="translate(-690 -1188)" id="path2502" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2504">
          <path d="M1 1h9919v7013H1V1" transform="translate(-824.5 -1463)" id="path2504" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2506">
          <path d="M1 1h9919v7013H1V1" transform="translate(-824 -1463)" id="path2506" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2508">
          <path d="M1 1h9919v7013H1V1" transform="translate(-824.5 -1012)" id="path2508" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2510">
          <path d="M1 1h9919v7013H1V1" transform="translate(-690 -878)" id="path2510" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2512">
          <path d="M1 1h9919v7013H1V1" transform="translate(-691 -758.5)" id="path2512" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2514">
          <path d="M1 1h9919v7013H1V1" transform="translate(-824 -624)" id="path2514" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2516">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3723.5 -625)" id="path2516" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2518">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3857 -758)" id="path2518" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2520">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3857 -878.5)" id="path2520" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2522">
          <path d="M1 1h9919v7013H1V1" transform="translate(-3723 -1011)" id="path2522" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2524">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -674.88 68.16)" id="path2524" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2526">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9737 -1396.5)" id="path2526" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2528">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9736 -1396)" id="path2528" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2530">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9602.5 -185)" id="path2530" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2532">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9602 -184)" id="path2532" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2534">
          <path d="M1 1h9919v7013H1V1" transform="translate(-657 -285.5)" id="path2534" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2536">
          <path d="M1 1h9919v7013H1V1" transform="translate(-657 -285)" id="path2536" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2538">
          <path d="M1 1h9919v7013H1V1" transform="translate(-824.5 -1496)" id="path2538" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2540">
          <path d="M1 1h9919v7013H1V1" transform="translate(-824 -1496)" id="path2540" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2541">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.00134 0 0 -.00222 -7.805 3.227)" id="path2541" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2543">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9016 -418)" id="path2543" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2545">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9254 -372)" id="path2545" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2547">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9254 -607)" id="path2547" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2549">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9016 -561)" id="path2549" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2574">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -1009.8 34.68)" id="path2574" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2575">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -687.96 36.84)" id="path2575" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2576">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.001 0 0 -.0042 -6.908 5.21)" id="path2576" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2578">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9602.5 -5585)" id="path2578" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2580">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9736 -5718)" id="path2580" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2582">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9736 -6696.5)" id="path2582" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2584">
          <path d="M1 1h9919v7013H1V1" transform="translate(-9602 -6830)" id="path2584" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2586">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7846.5 -6830)" id="path2586" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2588">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7712 -6696)" id="path2588" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2590">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7713 -5718.5)" id="path2590" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2592">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7846 -5585)" id="path2592" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2594">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7841 -6714)" id="path2594" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2596">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7852 -6663)" id="path2596" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2598">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7852 -6698)" id="path2598" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2600">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7852 -6663)" id="path2600" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2602">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7882 -6663)" id="path2602" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2604">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7917 -6663)" id="path2604" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2606">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7917 -6698)" id="path2606" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2608">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7917 -6663)" id="path2608" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2610">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7947 -6663)" id="path2610" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2612">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7959 -6698)" id="path2612" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2614">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7976 -6663)" id="path2614" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2616">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7981 -6685)" id="path2616" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2618">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8010 -6729)" id="path2618" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2620">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8070 -6663)" id="path2620" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2622">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8113 -6729)" id="path2622" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2624">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8146 -6729)" id="path2624" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2626">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8135 -6729)" id="path2626" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2628">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8165 -6663)" id="path2628" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2630">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8165 -6698)" id="path2630" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2632">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8165 -6663)" id="path2632" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2634">
          <path d="M1 1h9919v7013H1V1" transform="translate(-8216 -6720)" id="path2634" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2636">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7822 -6599)" id="path2636" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2638">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7852 -6551)" id="path2638" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2640">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7819 -6477)" id="path2640" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2642">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7852 -6433)" id="path2642" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2644">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7820 -6375)" id="path2644" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2646">
          <path d="M1 1h9919v7013H1V1" transform="translate(-7852 -6315)" id="path2646" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2650">
          <path d="M1 1h9919v7013H1V1" transform="matrix(0 .12 .12 0 -101.4 -26.04)" id="path2650" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2652">
          <path d="M1 1h9919v7013H1V1" transform="translate(-369 -2361.5)" id="path2652" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2654">
          <path d="M1 1h9919v7013H1V1" transform="translate(-321 -2409)" id="path2654" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2656">
          <path d="M1 1h9919v7013H1V1" transform="translate(-151.5 -2409)" id="path2656" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2658">
          <path d="M1 1h9919v7013H1V1" transform="translate(-103 -2361)" id="path2658" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2660">
          <path d="M1 1h9919v7013H1V1" transform="translate(-104 -873.5)" id="path2660" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2662">
          <path d="M1 1h9919v7013H1V1" transform="translate(-151 -826)" id="path2662" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2664">
          <path d="M1 1h9919v7013H1V1" transform="translate(-321.5 -826)" id="path2664" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2666">
          <path d="M1 1h9919v7013H1V1" transform="translate(-368 -873)" id="path2666" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2667">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -674.88 54)" id="path2667" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2669">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -977.76 74.04)" id="path2669" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2671">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -1134 243.24)" id="path2671" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2675">
          <path d="M1 1h9919v7013H1V1" transform="matrix(.12 0 0 -.12 -1112.76 243.24)" id="path2675" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2677">
          <path d="M591 1500h9211v5396H591V1500" transform="translate(-2592 -5036)" id="path2677" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2679">
          <path d="M591 1500h9211v5396H591V1500" transform="translate(-2592 -5036)" id="path2679" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2681">
          <path d="M591 1500h9211v5396H591V1500" transform="translate(-2609 -5001)" id="path2681" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2683">
          <path d="M591 1500h9211v5396H591V1500" transform="translate(-2609 -5001)" id="path2683" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath2685">
          <path d="M591 1500h9211v5396H591V1500" transform="translate(-2983 -5362)" id="path2685" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5837">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.284 -3303.94)" id="path5837" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5839">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.02 -3248.75)" id="path5839" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5841">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.431 -3210.302)" id="path5841" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5843">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.858 -3159.122)" id="path5843" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5845">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.416 -3106.036)" id="path5845" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5847">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.741 -3043.292)" id="path5847" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5849">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.3 -3010.207)" id="path5849" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5851">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.02 -2966.75)" id="path5851" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5853">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3506.347 -2928.769)" id="path5853" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5855">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3554.878 -2917.858)" id="path5855" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5857">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3608.24 -2917.71)" id="path5857" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5859">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3664.155 -2918.152)" id="path5859" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5861">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3709.697 -2918.431)" id="path5861" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5863">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3761.517 -2918.005)" id="path5863" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5865">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3810.783 -2917.99)" id="path5865" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5867">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3872.783 -2917.99)" id="path5867" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5869">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3934.25 -2918.02)" id="path5869" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5871">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3973.155 -2918.152)" id="path5871" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5873">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4020.155 -2918.152)" id="path5873" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5875">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4068.189 -2918.453)" id="path5875" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5877">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4100.7 -2941.793)" id="path5877" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5879">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4101.127 -2985.612)" id="path5879" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5881">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4100.422 -3027.336)" id="path5881" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5883">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4101.142 -3075.878)" id="path5883" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5885">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4100.569 -3128.698)" id="path5885" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5887">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4100.995 -3180.517)" id="path5887" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5889">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4100.142 -3230.878)" id="path5889" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5891">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4100.569 -3283.698)" id="path5891" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5893">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4101.008 -3335.198)" id="path5893" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5895">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4059.388 -3337.127)" id="path5895" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5897">
          <path d="M0 0h9921v7015H0V0" transform="translate(-4017.845 -3336.848)" id="path5897" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5899">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3971.568 -3336.553)" id="path5899" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5901">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3930.75 -3336.98)" id="path5901" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5903">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3891.845 -3337.848)" id="path5903" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5905">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3846.217 -3337.01)" id="path5905" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5907">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3785.122 -3337.142)" id="path5907" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5909">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3731.664 -3337.422)" id="path5909" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5911">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3683.207 -3337.7)" id="path5911" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5913">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3640.122 -3337.142)" id="path5913" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5915">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3586.302 -3337.569)" id="path5915" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5917">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3534.835 -3337.538)" id="path5917" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5919">
          <path d="M0 0h9921v7015H0V0" transform="translate(-3505.741 -3337.292)" id="path5919" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5921">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.725 -3088.026)" id="path5921" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5923">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6082.563 -3043.398)" id="path5923" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5925">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6082.99 -2983.217)" id="path5925" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5927">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.431 -2922.302)" id="path5927" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5929">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.152 -2870.845)" id="path5929" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5931">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.593 -2823.93)" id="path5931" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5933">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.563 -2786.398)" id="path5933" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5935">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.3 -2727.207)" id="path5935" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5937">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.137 -2683.58)" id="path5937" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5939">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6082.873 -2625.388)" id="path5939" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5941">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6082.741 -2584.292)" id="path5941" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5943">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6082.99 -2551.217)" id="path5943" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5945">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6082.873 -2489.388)" id="path5945" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5947">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.284 -2447.94)" id="path5947" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5949">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6082.563 -2393.398)" id="path5949" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5951">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.544 -2333.636)" id="path5951" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5953">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6110.421 -2304.137)" id="path5953" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5955">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6168.612 -2303.873)" id="path5955" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5957">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6210.421 -2304.137)" id="path5957" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5959">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6268.697 -2303.431)" id="path5959" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5961">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6319.517 -2304.005)" id="path5961" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5963">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6369.783 -2303.99)" id="path5963" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5965">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6432.07 -2303.594)" id="path5965" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5967">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6468.421 -2304.137)" id="path5967" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5969">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6526.421 -2304.137)" id="path5969" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5971">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6584.708 -2303.741)" id="path5971" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5973">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6618.602 -2304.563)" id="path5973" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5975">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6678.517 -2304.005)" id="path5975" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5977">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6728.527 -2304.315)" id="path5977" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5979">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6761.431 -2304.447)" id="path5979" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5981">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6801.974 -2303.726)" id="path5981" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5983">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6846.697 -2304.431)" id="path5983" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5985">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6898.06 -2303.284)" id="path5985" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5987">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6952.421 -2304.137)" id="path5987" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5989">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7010.25 -2304.02)" id="path5989" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5991">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7049.155 -2304.152)" id="path5991" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5993">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7096.06 -2304.284)" id="path5993" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5995">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7150.25 -2304.02)" id="path5995" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5997">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7189.421 -2304.137)" id="path5997" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath5999">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7246.878 -2303.858)" id="path5999" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6001">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7300.878 -2303.858)" id="path6001" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6003">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7353.878 -2303.858)" id="path6003" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6005">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7406.963 -2303.416)" id="path6005" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6007">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7470.155 -2304.152)" id="path6007" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6009">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7517.421 -2304.137)" id="path6009" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6011">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7576.067 -2303.857)" id="path6011" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6013">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7584.98 -2333.25)" id="path6013" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6015">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.01 -2371.783)" id="path6015" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6017">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7584.716 -2433.06)" id="path6017" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6019">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.437 -2488.602)" id="path6019" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6021">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.848 -2547.155)" id="path6021" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6023">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7584.716 -2594.06)" id="path6023" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6025">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.127 -2649.612)" id="path6025" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6027">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.422 -2691.336)" id="path6027" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6029">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.29 -2739.24)" id="path6029" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6031">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.127 -2795.612)" id="path6031" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6033">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.142 -2836.878)" id="path6033" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6035">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.437 -2890.602)" id="path6035" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6037">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.01 -2950.783)" id="path6037" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6039">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.7 -3010.793)" id="path6039" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6041">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7585.29 -3054.24)" id="path6041" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6043">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7584.814 -3111.187)" id="path6043" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6045">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7556.111 -3125.833)" id="path6045" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6047">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7520.845 -3125.848)" id="path6047" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6049">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7473.398 -3125.437)" id="path6049" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6051">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7414.473 -3125.685)" id="path6051" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6053">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7382.398 -3125.437)" id="path6053" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6055">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7322.473 -3125.685)" id="path6055" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6057">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7290.303 -3125.569)" id="path6057" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6059">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7239.292 -3126.259)" id="path6059" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6061">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7205.664 -3125.422)" id="path6061" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6063">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7157.388 -3126.127)" id="path6063" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6065">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7116.122 -3126.142)" id="path6065" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6067">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7063.664 -3125.422)" id="path6067" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6069">
          <path d="M0 0h9921v7015H0V0" transform="translate(-7014.93 -3125.406)" id="path6069" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6071">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6978.845 -3125.848)" id="path6071" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6073">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6932.217 -3125.01)" id="path6073" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6075">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6870.207 -3125.7)" id="path6075" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6077">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6825.569 -3125.553)" id="path6077" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6079">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6785.398 -3125.437)" id="path6079" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6081">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6725.111 -3125.833)" id="path6081" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6083">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6689.941 -3125.716)" id="path6083" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6085">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6635.026 -3125.274)" id="path6085" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6087">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6589.93 -3125.406)" id="path6087" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6089">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6553.93 -3125.406)" id="path6089" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6091">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6517.037 -3125.584)" id="path6091" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6093">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6454.217 -3126.01)" id="path6093" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6095">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6392.845 -3125.848)" id="path6095" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6097">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6346.76 -3125.29)" id="path6097" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6099">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6290.845 -3125.848)" id="path6099" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6101">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6243.664 -3125.422)" id="path6101" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6103">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6195.217 -3126.01)" id="path6103" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6105">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6133.292 -3125.259)" id="path6105" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6107">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6099.282 -3124.95)" id="path6107" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6109">
          <path d="M0 0h9921v7015H0V0" transform="translate(-6083.593 -3125.93)" id="path6109" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6111">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.725 -4555.026)" id="path6111" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6113">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.857 -4510.122)" id="path6113" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6115">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.315 -4456.473)" id="path6115" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6117">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.563 -4424.398)" id="path6117" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6119">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.431 -4364.303)" id="path6119" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6121">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.447 -4312.569)" id="path6121" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6123">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.284 -4272.94)" id="path6123" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6125">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.99 -4217.217)" id="path6125" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6127">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.71 -4155.76)" id="path6127" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6129">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.857 -4099.122)" id="path6129" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6131">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.517 -4046.595)" id="path6131" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6133">
          <path d="M0 0h9921v7015H0V0" transform="translate(-557.25 -4015.02)" id="path6133" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6135">
          <path d="M0 0h9921v7015H0V0" transform="translate(-595.793 -4015.3)" id="path6135" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6137">
          <path d="M0 0h9921v7015H0V0" transform="translate(-638.964 -4015.416)" id="path6137" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6139">
          <path d="M0 0h9921v7015H0V0" transform="translate(-702.336 -4015.578)" id="path6139" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6141">
          <path d="M0 0h9921v7015H0V0" transform="translate(-751.07 -4015.594)" id="path6141" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6143">
          <path d="M0 0h9921v7015H0V0" transform="translate(-786.879 -4014.858)" id="path6143" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6145">
          <path d="M0 0h9921v7015H0V0" transform="translate(-840.421 -4016.137)" id="path6145" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6147">
          <path d="M0 0h9921v7015H0V0" transform="translate(-899.432 -4015.447)" id="path6147" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6149">
          <path d="M0 0h9921v7015H0V0" transform="translate(-939.25 -4016.02)" id="path6149" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6151">
          <path d="M0 0h9921v7015H0V0" transform="translate(-977.527 -4015.315)" id="path6151" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6153">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1009.421 -4015.137)" id="path6153" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6155">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1067.06 -4015.284)" id="path6155" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6157">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1122.697 -4015.431)" id="path6157" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6159">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1174.432 -4015.447)" id="path6159" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6161">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1214.421 -4015.137)" id="path6161" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6163">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1272.527 -4015.315)" id="path6163" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6165">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1303.964 -4015.416)" id="path6165" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6167">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1367.06 -4015.284)" id="path6167" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6169">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1421.964 -4015.416)" id="path6169" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6171">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1484.793 -4015.3)" id="path6171" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6173">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1529.25 -4016.02)" id="path6173" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6175">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1567.793 -4015.3)" id="path6175" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6177">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1611.335 -4015.578)" id="path6177" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6179">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1658.708 -4015.741)" id="path6179" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6181">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1692.793 -4015.3)" id="path6181" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6183">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1736.602 -4015.563)" id="path6183" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6185">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1796.25 -4016.02)" id="path6185" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6187">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1835.155 -4015.152)" id="path6187" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6189">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1881.708 -4015.741)" id="path6189" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6191">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1915.793 -4015.3)" id="path6191" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6193">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1958.432 -4015.447)" id="path6193" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6195">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1997.602 -4015.563)" id="path6195" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6197">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2057.889 -4016.167)" id="path6197" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6199">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2092.889 -4015.167)" id="path6199" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6201">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2128.315 -4016.06)" id="path6201" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6203">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2151.142 -4054.878)" id="path6203" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6205">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2151.685 -4108.527)" id="path6205" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6207">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2150.98 -4140.25)" id="path6207" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6209">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2151.29 -4178.24)" id="path6209" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6211">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2151.422 -4234.336)" id="path6211" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6213">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2151.7 -4282.793)" id="path6213" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6215">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2151.833 -4325.889)" id="path6215" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6217">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2150.863 -4361.42)" id="path6217" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6219">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2151.29 -4419.24)" id="path6219" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6221">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2150.848 -4475.155)" id="path6221" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6223">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2150.995 -4522.517)" id="path6223" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6225">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2150.703 -4572.351)" id="path6225" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6227">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2140.111 -4614.833)" id="path6227" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6229">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2104.037 -4614.584)" id="path6229" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6231">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2042.388 -4615.127)" id="path6231" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6233">
          <path d="M0 0h9921v7015H0V0" transform="translate(-2000.292 -4615.259)" id="path6233" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6235">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1966.75 -4614.98)" id="path6235" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6237">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1927.93 -4614.406)" id="path6237" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6239">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1891.388 -4615.127)" id="path6239" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6241">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1849.845 -4614.848)" id="path6241" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6243">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1802.941 -4614.716)" id="path6243" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6245">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1748.207 -4614.7)" id="path6245" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6247">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1705.217 -4615.01)" id="path6247" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6249">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1643.569 -4614.554)" id="path6249" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6251">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1604.217 -4615.01)" id="path6251" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6253">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1542.292 -4614.259)" id="path6253" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6255">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1508.484 -4614.995)" id="path6255" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6257">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1458.569 -4614.554)" id="path6257" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6259">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1418.292 -4614.259)" id="path6259" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6261">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1383.473 -4614.685)" id="path6261" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6263">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1351.302 -4614.569)" id="path6263" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6265">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1299.111 -4614.833)" id="path6265" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6267">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1264.207 -4614.7)" id="path6267" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6269">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1221.026 -4614.274)" id="path6269" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6271">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1175.76 -4615.29)" id="path6271" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6273">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1119.75 -4614.98)" id="path6273" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6275">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1080.026 -4614.274)" id="path6275" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6277">
          <path d="M0 0h9921v7015H0V0" transform="translate(-1034.941 -4614.716)" id="path6277" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6279">
          <path d="M0 0h9921v7015H0V0" transform="translate(-980.473 -4614.685)" id="path6279" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6281">
          <path d="M0 0h9921v7015H0V0" transform="translate(-949.026 -4615.274)" id="path6281" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6283">
          <path d="M0 0h9921v7015H0V0" transform="translate(-903.93 -4614.406)" id="path6283" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6285">
          <path d="M0 0h9921v7015H0V0" transform="translate(-867.111 -4614.833)" id="path6285" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6287">
          <path d="M0 0h9921v7015H0V0" transform="translate(-833.217 -4615.01)" id="path6287" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6289">
          <path d="M0 0h9921v7015H0V0" transform="translate(-772.026 -4615.274)" id="path6289" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6291">
          <path d="M0 0h9921v7015H0V0" transform="translate(-726.398 -4614.437)" id="path6291" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6293">
          <path d="M0 0h9921v7015H0V0" transform="translate(-666.111 -4614.833)" id="path6293" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6295">
          <path d="M0 0h9921v7015H0V0" transform="translate(-631.207 -4614.7)" id="path6295" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6297">
          <path d="M0 0h9921v7015H0V0" transform="translate(-587.026 -4614.274)" id="path6297" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6299">
          <path d="M0 0h9921v7015H0V0" transform="translate(-541.563 -4614.398)" id="path6299" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6300">
          <path d="M0 0h15.084v15.084H0Z" transform="translate(-1.508 -1.508)" id="path6300" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6301">
          <path d="M215.605 700.038h100.261v37.265H215.605Z" transform="matrix(1.33333 0 0 -1.33333 2265.333 1122.667)" id="path6301" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6302">
          <path d="M0 0h24.843v15.97H0Z" transform="translate(-2.04 -7.985)" id="path6302" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6303">
          <path d="M215.605 700.038h100.261v37.265H215.605Z" transform="matrix(1.33333 0 0 -1.33333 2265.333 1122.667)" id="path6303" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6304">
          <path d="M0-3.9h28.008v16.38H0Z" transform="matrix(1 0 0 -1 0 0)" id="path6304" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6305">
          <path d="M215.605 700.038h100.261v37.265H215.605Z" transform="matrix(1.33333 0 0 -1.33333 2265.333 1122.667)" id="path6305" />
        </clipPath>
        <clipPath clipPathUnits="userSpaceOnUse" id="clipPath6306">
          <path d="M215.605 700.038h100.261v37.265H215.605Z" transform="matrix(1.33333 0 0 -1.33333 2265.333 1122.667)" id="path6306" />
        </clipPath>
        <linearGradient id="tk01fill" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={cls_water_fill_color} id="stop1" />
          <stop offset={tk01fill_value} stopColor={cls_water_fill_color} id="stop2" />
          <stop offset={tk01fill_grad_value} stopColor="#fff" id="stop3" />
          <stop offset="100%" stopColor="#fff" id="stop4" />
        </linearGradient>
        <linearGradient id="tk02fill" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={cls_water_fill_color} id="stop5" />
          <stop offset={tk02fill_value} stopColor={cls_water_fill_color} id="stop6" />
          <stop offset={tk02fill_grad_value} stopColor="#fff" id="stop7" />
          <stop offset="100%" stopColor="#fff" id="stop8" />
        </linearGradient>
        <linearGradient id="tk04fill" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={cls_water_fill_color} id="stop9" />
          <stop offset={tk04fill_value} stopColor={cls_water_fill_color} id="stop10" />
          <stop offset={tk04fill_grad_value} stopColor="#fff" id="stop11" />
          <stop offset="100%" stopColor="#fff" id="stop12" />
        </linearGradient>
        <linearGradient id="tk05fill" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={cls_water_fill_color} id="stop13" />
          <stop offset={tk05fill_value} stopColor={cls_water_fill_color} id="stop14" />
          <stop offset={tk05fill_grad_value} stopColor="#fff" id="stop15" />
          <stop offset="100%" stopColor="#fff" id="stop16" />
        </linearGradient>
        <linearGradient id="tk06fill" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={cls_water_fill_color} id="stop17" />
          <stop offset={tk06fill_value} stopColor={cls_water_fill_color} id="stop18" />
          <stop offset={tk06fill_grad_value} stopColor="#fff" id="stop19" />
          <stop offset="100%" stopColor="#fff" id="stop20" />
        </linearGradient>
        <linearGradient id="tk07fill" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={cls_water_fill_color} id="stop21" />
          <stop offset={tk07fill_value} stopColor={cls_water_fill_color} id="stop22" />
          <stop offset={tk07fill_grad_value} stopColor="#fff" id="stop23" />
          <stop offset="100%" stopColor="#fff" id="stop24" />
        </linearGradient>
        <linearGradient id="tk09fill" x1="0%" y1="100%" x2="0%" y2="0%">
          <stop offset="0%" stopColor={cls_water_fill_color} id="stop25" />
          <stop offset={tk09fill_value} stopColor={cls_water_fill_color} id="stop26" />
          <stop offset={tk09fill_grad_value} stopColor="#fff" id="stop27" />
          <stop offset="100%" stopColor="#fff" id="stop28" />
        </linearGradient>
        <path id="rect2" d="M181.419 568.47h48.754v36.151h-48.754z" />
      </defs>
      <g id="layer-MC0" transform="translate(-2265.333)">
        <path
          id="path2690"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m2759.134 435.806 32.48-60m-65.12 0 32.64 60m-32.64-132.64v72.64m65.12-72.64v72.64"
        />
        <path
          id="path2741"
          d="M2929.854 355.326v-42.24"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path2742"
          d="M3241.785 459.57v-32.613"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.104924,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path2770"
          d="M3180.874 460.044v-64.543"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.22954,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text2772"
          xmlSpace="preserve"
          x={2848.247}
          y={469.18}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2848.247}
            y={469.18}
            id="tspan2775"
          >
            {'Preconditioning Tank'}
          </tspan>
        </text>
        <path
          id="path2789"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3038.042 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className={classNames(star, star1)}
        />
        <path
          id="path2789-5"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3054.353 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className={classNames(star, star2)}
        />
        <path
          id="tk03_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.152054,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3106.157 467.947v-31.501m24.017 37.12v-37.12m135.52 31.642v-31.642m0 37.12h-135.52m147.36 17.12 12-17.12m-12 17.12-11.84-17.12m-147.36 17.12 11.84-17.12m-11.84 17.12-12-17.12m183.2-37.44v37.44m-266.08 0v-37.44m82.88 37.44h-82.88"
        />
        <path
          id="tk09_body"
          fill="url(#tk09fill)"
          d="M2707.447 766.133v103.025h-190.942V766.133h190.942"
          style={{
            stroke: '#000',
            strokeWidth: 0.174775,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="tk04_body"
          d="M2464.974 641.726v53.12h169.28v-53.12h-169.28"
          fill="url(#tk04fill)"
          style={{
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text3041"
          xmlSpace="preserve"
          x={2470.414}
          y={750.766}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x="2470.4141 2476.8665 2482.738 2485.6738 2488.6096 2492.1265 2495.6431 2497.9875 2503.8591 2509.7307 2512.6665 2515.011 2520.8826"
            y={750.766}
            id="tspan3041"
          >
            {'To Irrigation'}
          </tspan>
        </text>
        <path
          id="path3075"
          d="M3152.874 460.594V352.252"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.212192,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3145"
          d="M2997.214 509.006a6 6 0 1 0 12 0 6 6 0 0 0-12 0"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text3145"
          xmlSpace="preserve"
          transform="scale(-1 1)"
          x={-3007.717}
          y={512.907}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={-3007.717}
            y={512.907}
            id="tspan3145"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="path3146"
          d="M3003.134 515.166v12.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3151"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2705.854 660.623v-9.44m-5.92 15.28a6 6 0 1 0 12 0 6 6 0 0 0-12 0m-9.92-31.28 23.84-13.76m-23.84 13.76 23.84 13.76m-23.84-13.84c0 8.792 7.128 15.92 15.92 15.92s15.92-7.128 15.92-15.92c0-8.793-7.128-15.92-15.92-15.92s-15.92 7.127-15.92 15.92"
          className={cls_p04_body}
        />
        <path
          id="path3151-1"
          d="M2989.382 682.878v-9.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3159"
          d="M3062.654 246.686a6 6 0 1 0-12 0 6 6 0 0 0 12 0"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text3150"
          xmlSpace="preserve"
          x={2657.475}
          y={681.189}
          style={{
            fontSize: '17.888px',
            strokeWidth: 1.49067
          }}
          transform="scale(1.01646 .9838)"
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.49067
            }}
            x={2657.475}
            y={681.189}
            id="tspan3150"
          >
            {'M'}
          </tspan>
        </text>
        <text
          id="text3159"
          xmlSpace="preserve"
          x={3052.083}
          y={250.571}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3052.083}
            y={250.571}
            id="tspan3159"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="path3160"
          d="M3056.574 252.686v12.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="cds01_body"
          d="M2969.217 189.409v55.194h47.305v-55.194h-47.305"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.165591,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="p03_body"
          d="M3008.814 146.046c-8.792 0-15.92 7.127-15.92 15.92 0 8.792 7.128 15.92 15.92 15.92s15.92-7.128 15.92-15.92c0-8.793-7.128-15.92-15.92-15.92"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_p03_body}
        />
        <path
          id="path3447"
          d="m3008.734 146.046-13.76 24"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3448"
          d="m3008.734 146.046 13.76 24"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="p06_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3485.563 501.848h9.44m-15.44-6.08a6 6 0 1 0 0 12 6 6 0 0 0 0-12m31.28-9.76 13.92 23.84m-13.92-23.84-13.76 23.84m13.84-24c-8.792 0-15.92 7.127-15.92 15.92 0 8.792 7.128 15.92 15.92 15.92 8.793 0 15.92-7.128 15.92-15.92 0-8.793-7.127-15.92-15.92-15.92"
          className={cls_p06_body}
        />
        <text
          id="text3457"
          xmlSpace="preserve"
          x={3474.774}
          y={505.266}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3474.774}
            y={505.266}
            id="tspan3457"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="path3467"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3577.374 463.806 32.48-60m-64.96 0 32.48 60m-32.48-132.64v72.64m64.96-72.64v72.64"
        />
        <path
          id="tk01_body"
          d="M2412.584 389.576v131.02h240.3v-131.02h-240.3"
          fill="url(#tk01fill)"
          style={{
            stroke: '#000',
            strokeWidth: 0.3,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="p01_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2430.974 468.64h9.44m-15.44-6.08a6 6 0 1 0 0 12 6 6 0 0 0 0-12m31.36-9.92c-8.792 0-15.92 7.127-15.92 15.92 0 8.792 7.128 15.92 15.92 15.92s15.92-7.128 15.92-15.92c0-8.793-7.128-15.92-15.92-15.92m-.08 0 13.92 23.84m-13.92-23.84-13.76 23.84"
          className={cls_p01_body}
        />
        <path
          style={{
            fontSize: 11,
            fontFamily: 'Arial',
            strokeWidth: 1.48578
          }}
          d="M-490.76 2335.763v-7.874h1.569l1.864 5.575q.257.779.376 1.165.134-.43.419-1.262l1.885-5.478h1.402v7.874h-1.005v-6.59l-2.288 6.59h-.94l-2.277-6.703v6.703z"
          transform="matrix(0 -.96167 1.03985 0 0 0)"
          id="text3881-3"
        />
        <path
          id="tk05_body"
          fill="url(#tk05fill)"
          d="M3369.86 436.132v80.947h51.766v-80.946h-51.765"
          style={{
            stroke: '#000',
            strokeWidth: 0.17353,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="p09_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2573.044 835.06h-9.44m15.6-6.08a6 6 0 1 0 0 12 6 6 0 0 0 0-12m-31.6-9.76-13.76 23.84m13.76-23.84 13.92 23.84m-13.84-24c-8.792 0-15.92 7.128-15.92 15.92 0 8.793 7.128 15.92 15.92 15.92s15.92-7.127 15.92-15.92c0-8.792-7.128-15.92-15.92-15.92"
          className={cls_p09_body}
        />
        <text
          id="text3873"
          xmlSpace="preserve"
          transform="matrix(0 -1.00953 -.99056 0 0 0)"
          x={-831.374}
          y={-2599.995}
          style={{
            fontSize: '18.9803px',
            strokeWidth: 1.58169
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.58169
            }}
            x={-831.374}
            y={-2599.995}
            id="tspan3873"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="tk07_body"
          fill="url(#tk07fill)"
          d="M3023.382 747.418v81.096h-108.396v-81.096h108.396"
          style={{
            stroke: '#000',
            strokeWidth: 0.184021,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="p07_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3073.75 821.911v9.44m-5.92-15.6a6 6 0 1 0 12.001 0 6 6 0 0 0-12 0m21.76 31.6-23.84-13.92m23.84 13.92-23.84 13.76m-7.84-13.84c0 8.792 7.128 15.92 15.92 15.92s15.92-7.128 15.92-15.92-7.128-15.92-15.92-15.92-15.92 7.128-15.92 15.92"
          className={cls_p07_body}
        />
        <text
          id="text4122"
          xmlSpace="preserve"
          transform="scale(-1 1)"
          x={-3078.509}
          y={819.442}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={-3078.509}
            y={819.442}
            id="tspan4122"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="screen_body"
          d="M2603.774 252.766v37.76h75.68v-37.76h-75.68"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4240"
          d="M3061.614 458.3v-62.208"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.22535,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4241"
          d="M3035.49 457.449V352.625"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.208718,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4279"
          d="M3379.134 504.349v-79.263"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16732,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="tk06_body"
          fill="url(#tk06fill)"
          d="M3623.934 382.846v80.96h44.16v-80.96h-44.16"
          style={{
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5103"
          d="M3661.374 452.659v-81.653"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.154262,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5104"
          d="M3656.094 391.326v-19.52"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5116"
          d="M2934.334 817.572v-82.006"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.154596,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5117"
          d="M2925.054 755.886v-19.913"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.162267,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="p05_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3072.094 569.326v9.44m6.08-15.6a6 6 0 1 0-12 0 6 6 0 0 0 12 0m-22.08 31.44 24-13.76m-24 13.76 24 13.92m8-14c0-8.793-7.128-15.92-15.92-15.92s-15.92 7.127-15.92 15.92c0 8.792 7.128 15.92 15.92 15.92s15.92-7.128 15.92-15.92"
          className={cls_p05_body}
        />
        <text
          id="text5121"
          xmlSpace="preserve"
          x={3067.602}
          y={566.844}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3067.602}
            y={566.844}
            id="tspan5121"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="p08_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3465.924 693.886 13.76 24m-13.76-24-13.76 24m13.84-24c-8.792 0-15.92 7.127-15.92 15.92 0 8.792 7.128 15.92 15.92 15.92s15.92-7.128 15.92-15.92c0-8.793-7.128-15.92-15.92-15.92"
          className={cls_p08_body}
        />
        <text
          id="text3021-0"
          xmlSpace="preserve"
          x={2588.353}
          y={662.903}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2588.353}
            y={662.903}
            id="tspan3021-8"
          >
            {'H'}
          </tspan>
        </text>
        <text
          id="text3021-0-6"
          xmlSpace="preserve"
          x={2919.343}
          y={353.955}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2919.343}
            y={353.955}
            id="tspan3021-8-7"
          >
            {'H'}
          </tspan>
        </text>
        <text
          id="text3021-0-4"
          xmlSpace="preserve"
          x={3262.964}
          y={413.993}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontSize: '8.34624px',
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3262.964}
            y={413.993}
            id="tspan3021-8-6"
          >
            {'H'}
          </tspan>
        </text>
        <text
          id="text3445-9"
          xmlSpace="preserve"
          x={2991.133}
          y={201.917}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2991.133}
            y={201.917}
            id="tspan3446-2"
          >
            {'HL'}
          </tspan>
        </text>
        <path
          id="cds02_body"
          d="M3426.565 737.477v55.194h47.636v-55.194h-47.636"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.166169,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3449-1"
          d="M3468.362 725.658v60.644"
          style={{
            fill: 'none',
            stroke: '#ff003f',
            strokeWidth: 0.158547,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3449-3-7"
          d="M3462.087 725.273v18.572"
          style={{
            fill: 'none',
            stroke: '#ff003f',
            strokeWidth: 0.0877389,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text3033-5"
          xmlSpace="preserve"
          x={2334.684}
          y={349.823}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2334.684}
            id="tspan3034-9"
            y={349.823}
          >
            {'Waste Water'}
          </tspan>
        </text>
        <text
          id="text2686-9"
          xmlSpace="preserve"
          x={3550.195}
          y={363.245}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3550.195}
            y={363.245}
            id="tspan5-8"
          >
            {'Secondary'}
          </tspan>
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3550.195}
            y={383.245}
            id="tspan2"
          >
            {'Clarifier'}
          </tspan>
        </text>
        <text
          id="text2772-5"
          xmlSpace="preserve"
          x={3016.395}
          y={489.259}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3016.395}
            y={489.259}
            id="tspan2775-5"
          >
            {'Algaesys Tank'}
          </tspan>
        </text>
        <path
          id="tk02_body"
          fill="url(#tk02fill)"
          d="M2950.589 342.919v114.343h-65.984V342.92h65.984"
          style={{
            stroke: '#000',
            strokeWidth: 0.170484,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text4688-6-1"
          xmlSpace="preserve"
          x={2412.122}
          y={531.846}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontSize: '8.62448px',
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2412.122}
            id="tspan4688-8-9"
            y={531.846}
          >
            {'Waste Water Tank'}
          </tspan>
        </text>
        <text
          x={2591.046}
          y={201.236}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="ppc101_value"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ft01_value}
        </text>
        <text
          id="text2772-51"
          xmlSpace="preserve"
          x={2616.281}
          y={273.985}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2616.281}
            y={273.985}
            id="tspan2775-7"
          >
            {'Screening'}
          </tspan>
        </text>
        <text
          x={2452.208}
          y={509.504}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="value_p01"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {p01_value}
        </text>
        <text
          x={2536.988}
          y={510.676}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="value_p02"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {p02_value}
        </text>
        <text
          x={2707.388}
          y={615.169}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="value_p02-8"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {p04_value}
        </text>
        <text
          x={2611.372}
          y={468.736}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="value_p03"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {tk01_value}
        </text>
        <text
          x={2918.202}
          y={424.236}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="value_p03-2"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {tk02_value}
        </text>
        <text
          x={2757.702}
          y={379.736}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="value_p03-5"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {pc01_value}
        </text>
        <path
          id="p02_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2509.583 468.75h9.44m-15.44-6.08a6 6 0 1 0 0 12 6 6 0 0 0 0-12m31.36-9.92c-8.792 0-15.92 7.128-15.92 15.92s7.128 15.92 15.92 15.92 15.92-7.128 15.92-15.92-7.128-15.92-15.92-15.92m-.08 0 13.92 23.84m-13.92-23.84-13.76 23.84"
          className={cls_p02_body}
        />
        <text
          id="text3881"
          xmlSpace="preserve"
          transform="matrix(0 -.96167 1.03985 0 0 0)"
          x={-491.706}
          y={2411.465}
          style={{
            fontSize: '17.8294px',
            strokeWidth: 1.48578
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.48578
            }}
            x={-491.706}
            y={2411.465}
            id="tspan3881"
          >
            {'M'}
          </tspan>
        </text>
        <text
          x={2535.702}
          y={687.236}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="value_p03-0"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {tk04_value}
        </text>
        <text
          id="text4277-5-1"
          xmlSpace="preserve"
          x={2684.052}
          y={865.453}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2684.052}
            y={865.453}
            id="tspan4277-0-3"
          >
            {'LL'}
          </tspan>
        </text>
        <text
          x={3053.677}
          y={325.184}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01a_orpvalue}
        </text>
        <text
          x={3172.195}
          y={320.029}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-25"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01b_orpvalue}
        </text>
        <text
          x={3172.926}
          y={335.979}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-25-7"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01b_orptemp}
        </text>
        <text
          x={3080.155}
          y={377.388}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-2"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01a_dovalue}
        </text>
        <text
          x={3078.931}
          y={392.652}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-2-3"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01a_dotemp}
        </text>
        <text
          x={3199.946}
          y={389.14}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-2-3-1"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01b_dotemp}
        </text>
        <text
          x={3054.273}
          y={339.648}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-3"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01a_orptemp}
        </text>
        <text
          id="text4277-5-1-2"
          xmlSpace="preserve"
          x={2997.161}
          y={238.453}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2997.161}
            y={238.453}
            id="tspan4277-0-3-0"
          >
            {'LL'}
          </tspan>
        </text>
        <text
          x={3040.647}
          y={183.301}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="ppc101_value-3-7-6"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {p03_value}
        </text>
        <text
          x={3054.334}
          y={354.918}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-9"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01a_orpph}
        </text>
        <text
          x={3172.654}
          y={351.383}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-25-1"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01b_orpph}
        </text>
        <text
          x={3199.713}
          y={372.504}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="left"
          id="ppc101_value-3-7-7-2-6"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {ae01b_dovalue}
        </text>
        <text
          id="text4277-5-1-4"
          xmlSpace="preserve"
          x={3375.161}
          y={513.703}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3375.161}
            y={513.703}
            id="tspan4277-0-3-1"
          >
            {'LL'}
          </tspan>
        </text>
        <text
          id="text4277-5-1-4-0"
          xmlSpace="preserve"
          x={3651.872}
          y={461.774}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3651.872}
            y={461.774}
            id="tspan4277-0-3-1-7"
          >
            {'LL'}
          </tspan>
        </text>
        <text
          x={3539.079}
          y={525.929}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="ppc101_value-3-7-6-7"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {p06_value}
        </text>
        <text
          x={3579.352}
          y={418.581}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="ppc101_value-3-7-6-7-8"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {pc02_value}
        </text>
        <text
          id="text4277-5-6"
          xmlSpace="preserve"
          x={3646.052}
          y={400.453}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3646.052}
            y={400.453}
            id="tspan4277-0-5"
          >
            {'HL'}
          </tspan>
        </text>
        <text
          x={3007.1}
          y={583.038}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="ppc101_value-3-7-43"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {b01_value}
        </text>
        <text
          id="text2686-9-1"
          xmlSpace="preserve"
          x={3721.704}
          y={541.578}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3721.704}
            y={541.578}
            id="tspan5-8-9"
          >
            {'Sand Filter'}
          </tspan>
        </text>
        <text
          id="text4277-5-1-4-07"
          xmlSpace="preserve"
          x={3453.161}
          y={789.453}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3453.161}
            y={789.453}
            id="tspan4277-0-3-1-8"
          >
            {'LL'}
          </tspan>
        </text>
        <text
          id="text4277-5-61"
          xmlSpace="preserve"
          x={3449.713}
          y={751.594}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3449.713}
            y={751.594}
            id="tspan4277-0-2"
          >
            {'HL'}
          </tspan>
        </text>
        <text
          x={3508.738}
          y={734.465}
          fill="#000"
          fontFamily="Helvetica"
          fontSize={12}
          textAnchor="middle"
          id="ppc101_value-3-7-6-7-86"
          style={{
            strokeWidth: 0.999999
          }}
          className={clsshow_sensor}
        >
          {p08_value}
        </text>
        <text
          id="text4277-5-1-4-07-3"
          xmlSpace="preserve"
          x={2930.396}
          y={825.279}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2930.396}
            y={825.279}
            id="tspan4277-0-3-1-8-9"
          >
            {'LL'}
          </tspan>
        </text>
        <text
          id="text4277-5-61-1"
          xmlSpace="preserve"
          x={2917.005}
          y={766.644}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2917.005}
            y={766.644}
            id="tspan4277-0-2-8"
          >
            {'HL'}
          </tspan>
        </text>
        <path
          id="path2789-2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3070.757 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star3"
        />
        <path
          id="path2789-2-3"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3086.925 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star4"
        />
        <path
          id="path2789-6"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3143.24 443.808 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04H3137m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star5"
        />
        <path
          id="path2789-5-7"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3159.55 443.808 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star6"
        />
        <path
          id="path2789-2-9"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3175.955 443.808 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star7"
        />
        <path
          id="path2789-2-3-9"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3192.123 443.808 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star8"
        />
        <path
          id="path2789-6-3"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3207.823 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star9"
        />
        <path
          id="path2789-5-7-5"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3224.134 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star10"
        />
        <path
          id="path2789-2-9-7"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3240.538 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star11"
        />
        <path
          id="path2789-2-3-9-6"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3256.706 443.806 3.04-5.28m-3.04 5.28 5.12-3.04m-5.12 3.04h6.08m-6.08 0 5.12 3.04m-5.12-3.04 3.04 5.28m-3.04-5.28v6.08m0-6.08-3.04 5.28m3.04-5.28-5.28 3.04m5.28-3.04h-6.24m6.24 0-5.28-3.04m5.28 3.04-3.04-5.28m3.04 5.28v-6.24"
          className="star star12"
        />
        <rect
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 1.24134,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          id="sandfilter_body"
          width={53.98}
          height={33.052}
          x={489.599}
          y={-3718.21}
          rx={14.509}
          transform="rotate(90)"
          className={cls_sandfilter_body}
        />
      </g>
      <g id="layer-MC2" transform="translate(-2265.333)">
        <path
          id="tk02_linein"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.157903,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2896.052 310.042v79.409m-104.367-79.41h104.367"
          className={cls_tk02_linein}
        />
        <path
          id="tk02_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2977.694 441.886h45.76m-45.76 0v-94.4m-27.057 0h27.057"
          className={cls_tk02_lineout}
        />
        <path
          id="mv04_pos2lineout"
          d="M2989.374 696.046v51.398"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.139433,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_mv04_pos2lineout}
        />
        <path
          id="path3036"
          d="M2328.094 333.086h68.32l13.28 12.96-13.28 13.28h-68.32v-26.24"
          style={{
            fill: 'none',
            stroke: '#00bfff',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3041"
          style={{
            fill: 'none',
            stroke: '#00bfff',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m2471.054 739.086-7.68 7.52m0 0 7.68 7.84m61.76-15.36v15.36m0-15.36h-61.76m61.76 15.36h-61.76"
        />
        <text
          id="text3136"
          xmlSpace="preserve"
          x={2695.193}
          y={604.899}
          style={{
            fontSize: 16,
            fill: '#000',
            fillOpacity: 1,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2695.193}
            y={604.899}
            id="tspan3136"
          >
            {'P04'}
          </tspan>
        </text>
        <path
          id="tk05_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3412.83 517.085v27.041m0 0h35.749"
          className={cls_tk05_lineout}
        />
        <path
          id="p09_lineout"
          style={{
            fill: 'none',
            stroke: '#00bfff',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2547.854 746.766h-15.04m15.04 71.947v-71.947"
        />
        <path
          id="fcv01_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2573.534 271.646h30.24m-30.24 12v-12"
          className={cls_fcv01_lineout}
        />
        <path
          id="tk01_linein"
          style={{
            fill: 'none',
            stroke: '#00bfff',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2445.934 346.046v60.48m-36.312-60.48h36.312"
          className={cls_tk01_linein}
        />
        <path
          id="tk01_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2456.04 432.299h117.43m-117.43 19.987v-19.987m117.406-.605V310.065m-38.67 142.044v-19.457"
          className={cls_tk01_lineout}
        />
        <path
          id="hv04_linein"
          style={{
            fill: 'none',
            stroke: '#00bfff',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3277.766 494.32h42.881m-42.881-3.588v3.589"
          className={cls_hv04_linein}
        />
        <path
          id="tk05_linein"
          d="M3346.93 494.384h22.973"
          style={{
            fill: 'none',
            stroke: '#00bfff',
            strokeWidth: 0.207952,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_tk05_linein}
        />
        <path
          id="p06_linein"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3510.543 517.755v26.341m-35.37 0h35.37"
          className={cls_p06_linein}
        />
        <path
          id="p06_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3510.654 485.886v-172.16m0 0h47.04m0 34.225v-34.225"
          className={cls_p06_lineout}
        />
        <path
          id="mv03_pos2lineout"
          d="M3702.254 465.726v23.252"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_mv03_pos2lineout}
        />
        <path
          id="tk02_sampleline"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2839.974 448.676h45.047m-45.047 87.147v-87.147"
          className={cls_tk02_sampleline}
        />
        <path
          id="tk07_overflowline"
          d="M2914.974 755.086h-20.96"
          style={{
            fill: 'none',
            stroke: '#00bfff',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_tk07_overflowline}
        />
        <path
          id="tk07_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2989.374 847.406h23.2m-23.2-18.88v18.88"
          className={cls_tk07_lineout}
        />
        <path
          id="p07_linein"
          d="M3038.974 847.406h18.88"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_p07_linein}
        />
        <path
          id="pc01_linein"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.159013,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m2702.792 355.326.044-82.642m-23.555.022h52.493m-.062 46.37v-46.449"
          className={cls_pc01_linein}
        />
        <path
          id="tk06_linein"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.20947,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3630.138 335.818v69.735m-20.3-69.735h20.3"
          className={cls_tk06_linein}
        />
        <path
          id="tk06_lineout"
          d="M3688.974 452.606h-20.837"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.168427,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_tk06_lineout}
        />
        <path
          id="tk06_sampleline"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.182158,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3614.431 456.291h9.719m-9.719 26.478V456.29"
          className={cls_tk06_sampleline}
        />
        <path
          id="tk05_sampleline"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3362.174 515.166h7.68m-7.68 26.4v-26.4"
          className={cls_tk05_sampleline}
        />
        <path
          id="p07_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3127.614 790.606v56.8m0-56.8h23.84m-61.76 56.8h61.76"
          className={cls_p07_lineout}
        />
        <path
          id="tk08_linein"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2845.374 682.926v72.16m130.72-72.16h-130.72m-145.6 72.16v11.04m167.68-11.04h-167.68"
          className={cls_tk08_linein}
        />
        <path
          id="b01_lineout"
          style={{
            fill: '#ff00f1',
            fillOpacity: 1,
            stroke: '#000',
            strokeWidth: 0.75,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: '4.49999456,4.49999456',
            strokeDashoffset: 0,
            strokeOpacity: 1
          }}
          d="m2959.907 451.534-.233 81.107m-67.887-80.755h71.913m-3.925 80.578h27.719"
        />
        <path
          id="bv02_lineout"
          style={{
            fill: '#ff00f1',
            fillOpacity: 1,
            stroke: '#d400ff',
            strokeWidth: 0.7,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: '4.19999993,4.19999993',
            strokeDashoffset: 0,
            strokeOpacity: 1
          }}
          d="m3100.63 425.593.058 41.688m33.29-40.9.193 41.534m-9.507-195.364-.248 153.42m10.1 41.426h123.418m-229.6-.09h71.912m-27.918-195.948h72.629m-43.936 154.428h34.288"
          className={cls_bv02_lineout}
        />
      </g>
      <text
        id="text2686"
        xmlSpace="preserve"
        x={474.875}
        y={328.046}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={474.875}
          y={328.046}
          id="tspan5"
        >
          {'Primary '}
        </tspan>
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={474.875}
          y={348.046}
          id="tspan1"
        >
          {'Clarifier'}
        </tspan>
      </text>
      <g id="layer-MC4" transform="translate(-2265.333)">
        <path
          id="pc01_lineout"
          d="M2759.134 435.806v75.84"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_pc01_lineout}
        />
        <path
          id="hv15_linein"
          d="M3118.334 490.686v10.56"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_hv15_linein}
        />
        <path
          id="pc02_lineout"
          d="M3577.374 463.806v75.84"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_pc02_lineout}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.181059,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2759.384 538.171v96.835"
          id="mv01_lineout"
          className={cls_mv01_lineout}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2759.03 634.536h-37.334"
          id="p04_linein"
          className={cls_p04_linein}
        />
        <path
          id="mv02_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2758.938 634.727h817.831m.243-68.584v68.426"
          className={cls_mv02_lineout}
        />
        <path
          id="pc04_linein"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.128925,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2690.045 635.028h-65.454m.039-.073v22.257"
          className={cls_pc04_linein}
        />
        <path
          id="p05_linein"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3118.337 594.626h-30.069m30.07-66.978v66.978"
          className={cls_p05_linein}
        />
        <path
          id="p05_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.160082,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2666.075 594.693V290.614m390.02 303.997h-390.111"
          className={cls_p05_lineout}
        />
        <path
          id="path5128"
          d="m2649.734 369.836-2.88 5.76"
          style={{
            fill: 'none',
            stroke: '#fff',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
      </g>
      <g id="layer-MC5" transform="translate(-2265.333)">
        <path
          id="fcv01_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2566.014 283.646h15.2l-7.68 13.28-7.52-13.28m15.2 26.4h-15.2l7.52-13.12 7.68 13.12"
        />
        <path
          id="path3045"
          d="M2564.414 301.406v-9.12"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3046"
          d="M2573.534 296.926h-9.12"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3142"
          d="M3006.654 528.126h-18.56"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="b01_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2988.094 528.126v9.76m26.56 12.16 4.8 7.36m-25.6 0h25.6m-20.8-7.36-4.8 7.36m.73-19.52a12.4 12.4 0 1 0 12.15-9.92"
          className={cls_b01_body}
        />
        <path
          id="path3144"
          d="m2988.094 537.886 6.4.16"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3156"
          d="M3053.054 265.646h18.72"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          style={{
            fill: '#000',
            fillOpacity: 1,
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3071.774 265.646v9.92"
          id="path3157"
        />
        <path
          id="bv02_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m3071.774 275.566-6.4.16m-20.16 12-4.8 7.36m25.44 0h-25.44m20.64-7.36 4.8 7.36m-12.72-29.44a12.4 12.4 0 1 0 12.15 9.92"
          className={cls_bv02_body}
        />
        <path
          id="hv15_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3118.414 510.526a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m-7.6-9.28h15.04l-7.52 13.12-7.52-13.12m15.04 26.4h-15.04l7.52-13.28 7.52 13.28"
          className={cls_hv15_body}
        />
        <path
          id="hv06_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3465.694 544.046a3.76 3.76 0 1 0-7.52 0 3.76 3.76 0 0 0 7.52 0m9.28-7.44v15.2l-13.12-7.68 13.12-7.52m-26.4 15.2v-15.2l13.28 7.52-13.28 7.68"
          className={cls_hv06_body}
        />
        <path
          id="hv03_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3161.694 270.526a3.76 3.76 0 1 0-7.52 0 3.76 3.76 0 0 0 7.52 0m9.44-7.44v15.04l-13.28-7.52 13.28-7.52m-26.4 15.04v-15.04l13.12 7.52-13.12 7.52"
          className={cls_hv03_body}
        />
        <path
          id="mv01_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2759.214 520.926a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m-7.76-9.28h15.2l-7.52 13.12-7.68-13.12m15.2 26.4h-15.2l7.68-13.28 7.52 13.28"
          className={cls_mv01_body}
        />
        <path
          id="path3673"
          d="M2743.694 518.686a6 6 0 1 0 0 12 6 6 0 0 0 0-12"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text3673"
          xmlSpace="preserve"
          x={2739.094}
          y={528.431}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2739.094}
            y={528.431}
            id="tspan3673"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="path3674"
          d="M2749.534 524.766h9.6"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="mv02_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3577.454 548.926a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m-7.6-9.28h15.04l-7.52 13.12-7.52-13.12m15.04 26.4h-15.04l7.52-13.28 7.52 13.28"
          className={cls_mv02_body}
        />
        <path
          id="path3868"
          d="M3561.934 546.686a6 6 0 1 0 0 12 6 6 0 0 0 0-12"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text3868"
          xmlSpace="preserve"
          x={3557.302}
          y={556.528}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3557.302}
            y={556.528}
            id="tspan3868"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="path3869"
          d="M3567.934 552.766h9.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="hv04_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3337.632 494.286a3.76 3.76 0 1 0-7.52 0 3.76 3.76 0 0 0 7.52 0m9.28-7.44v15.04l-13.12-7.52 13.12-7.52m-26.4 15.04v-15.04l13.28 7.52-13.28 7.52"
          className={cls_hv04_body}
        />
        <path
          id="hv02_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2839.66 545.374a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m-7.6-9.44h15.04l-7.52 13.28-7.52-13.28m15.04 26.56h-15.04l7.52-13.28 7.52 13.28"
          className={cls_hv02_body}
        />
        <path
          id="mv03_pos1"
          d="M3715.374 444.926v15.2l-13.12-7.52 13.12-7.68"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_mv03_pos1}
        />
        <path
          id="path4142"
          d="M3708.334 437.006a6 6 0 1 0-12 0 6 6 0 0 0 12 0"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text4142"
          xmlSpace="preserve"
          x={3697.674}
          y={440.479}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3697.674}
            y={440.479}
            id="tspan4142"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="path4143"
          d="M3702.254 443.006v9.6"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="mv03_pos2"
          d="m3709.774 465.726-7.52-13.12-7.68 13.12h15.2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_mv03_pos2}
        />
        <path
          id="path34"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3706.094 452.526a3.76 3.76 0 1 0-7.52 0 3.76 3.76 0 0 0 7.52 0m-17.12 7.6v-15.2l13.28 7.68-13.28 7.52"
          className={cls_mv03_body}
        />
        <path
          id="hv01_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2702.873 372.17a3.76 3.76 0 1 0 0-7.52 3.76 3.76 0 0 0 0 7.52m7.44 9.6h-15.04l7.52-13.28 7.52 13.28m-15.04-26.4h15.04l-7.52 13.12-7.52-13.12"
          className={cls_hv01_body}
        />
        <path
          id="hv13_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3160.894 790.526a3.76 3.76 0 1 0 7.52 0 3.76 3.76 0 0 0-7.52 0m-9.44-7.44v15.2l13.12-7.68-13.12-7.52m26.4 15.2v-15.2l-13.28 7.52 13.28 7.68"
          className={cls_hv13_body}
        />
        <path
          id="hv07_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3614.494 492.126a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m-7.6-9.44h15.04l-7.52 13.28-7.52-13.28m15.04 26.56h-15.04l7.52-13.28 7.52 13.28"
          className={cls_hv07_body}
        />
        <path
          id="hv05_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3362.254 551.006a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m-7.6-9.44h15.2l-7.68 13.28-7.52-13.28m15.2 26.56h-15.2l7.52-13.28 7.68 13.28"
          className={cls_hv05_body}
        />
        <path
          id="hv08_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3747.722 671.575a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m7.44-9.44h-15.04l7.52 13.28 7.52-13.28m-15.04 26.4h15.04l-7.52-13.12-7.52 13.12"
          className={cls_hv08_body}
        />
        <path
          id="hv14_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3160.894 847.326a3.76 3.76 0 1 0 7.52 0 3.76 3.76 0 0 0-7.52 0m-9.44-7.6v15.2l13.12-7.52-13.12-7.68m26.4 15.2v-15.2l-13.28 7.68 13.28 7.52"
          className={cls_hv14_body}
        />
        <path
          id="hv12_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3022.014 847.326a3.76 3.76 0 1 0 7.52 0 3.76 3.76 0 0 0-7.52 0m-9.44-7.6v15.2l13.12-7.52-13.12-7.68m26.4 15.2v-15.2l-13.28 7.68 13.28 7.52"
          className={cls_hv12_body}
        />
        <path
          id="mv04_pos1"
          d="M2976.094 690.446v-15.2l13.28 7.68-13.28 7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_mv04_pos1}
        />
        <path
          id="path5716"
          d="M2995.423 667.326a6 6 0 1 0-12 0 6 6 0 0 0 12 0"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <text
          id="text5716"
          xmlSpace="preserve"
          x={2985.059}
          y={671.064}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={2985.059}
            y={671.064}
            id="tspan5716"
          >
            {'M'}
          </tspan>
        </text>
        <path
          id="path23"
          d="m2996.894 696.046-7.52-13.12-7.52 13.12h15.04"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_mv04_pos2}
        />
        <path
          id="mv04_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2993.214 682.846a3.76 3.76 0 1 0-7.52 0 3.76 3.76 0 0 0 7.52 0m9.44-7.6v15.2l-13.28-7.52 13.28-7.68"
          className={cls_mv04_body}
        />
        <path
          id="path5817"
          d="m2874.814 756.206-1.6 1.76h2.56z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5818"
          d="M2873.214 757.966h2.56l-3.2.64zm2.56 0-3.2.64h1.92z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5819"
          d="m2874.494 758.606-3.68 2.08h-.64zm-3.68 2.08h-.64l-2.72 1.92z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5820"
          d="M2874.494 758.606h-1.92l-2.4 2.08z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5821"
          d="m2875.774 757.966-2.72 1.44h3.52z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5822"
          d="M2873.054 759.406h3.52l-4.96.8zm3.52 0-4.96.8h1.92z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5823"
          d="m2873.534 760.206-3.04 1.12h-.64zm-3.04 1.12h-.64l-2.4 1.28z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5824"
          d="M2873.534 760.206h-1.92l-1.76 1.12z"
          style={{
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5825"
          d="m2876.574 759.406-3.04.8-3.04 1.12-3.04 1.28 2.72-1.92 2.4-2.08 2.24-2.4 1.76 3.2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5826"
          d="M2891.614 748.126a1.2 1.2 0 1 0 2.4 0 1.2 1.2 0 0 0-2.4 0"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5827"
          d="M2894.014 762.606v-15.2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5828"
          d="m2894.014 747.406-26.56 15.2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5829"
          d="M2867.454 762.606v-15.2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
      </g>
      <g id="layer-MC6" transform="translate(-2265.333)">
        <path
          id="ft01_body"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2572.094 224.446h33.92m.16-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04m-17.12 47.28v-30.24"
          className={cls_ft01_body}
        />
        <path
          id="path3049"
          d="m2584.71 227.443-.96.48-.8.96-.32 1.92v.96l.32 1.92.8.96.96.48h.8l1.12-.48.64-.96.32-1.92v-.96l-.32-1.92-.64-.96-1.12-.48h-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3050"
          d="m2591.95 229.59.64-.32 1.12-1.12v7.68"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <g id="g5">
          <path
            id="path3051"
            style={{
              fill: 'none',
              stroke: '#000',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              strokeDasharray: 'none',
              strokeOpacity: 1
            }}
            d="M2582.494 222.046v-8.64h5.28"
          />
          <path
            id="path3052"
            d="M2582.494 217.566h3.2"
            style={{
              fill: 'none',
              stroke: '#000',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              strokeDasharray: 'none',
              strokeOpacity: 1
            }}
          />
        </g>
        <g id="g2" transform="translate(.088)">
          <path
            id="path3053"
            d="M2592.734 213.406v8.64"
            style={{
              fill: 'none',
              stroke: '#000',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              strokeDasharray: 'none',
              strokeOpacity: 1
            }}
          />
          <path
            id="path3054"
            d="M2589.854 213.406h5.76"
            style={{
              fill: 'none',
              stroke: '#000',
              strokeLinecap: 'butt',
              strokeLinejoin: 'miter',
              strokeMiterlimit: 10,
              strokeDasharray: 'none',
              strokeOpacity: 1
            }}
          />
        </g>
        <path
          id="ls01_body"
          d="M2946.974 296.046c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_ls01_body}
        />
        <path
          id="path3058"
          d="M2912.734 296.126h34.08"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3059"
          d="m2925.454 300.046-1.12.32-.64 1.12-.48 1.76v1.12l.48 1.76.64 1.12 1.12.32h.64l1.12-.32.8-1.12.32-1.76v-1.12l-.32-1.76-.8-1.12-1.12-.32h-.64"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3060"
          d="m2932.414 301.486.8-.32 1.12-1.12v7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3061"
          d="M2923.294 283.246v8.64h4.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3062"
          d="m2936.254 284.366-.96-.8-1.12-.32h-1.76l-1.12.32-.96.8v.96l.48.8.48.32.8.48 2.4.8.8.48.48.32.48.8v1.28l-.96.8-1.12.48h-1.76l-1.12-.48-.96-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="ls02_body"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3224.814 409.886h34.08m0-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          className={cls_ls02_body}
        />
        <path
          id="path3068"
          d="m3237.934 413.806-1.12.32-.8 1.12-.32 1.76v1.12l.32 1.76.8 1.12 1.12.32h.64l1.12-.32.64-1.12.48-1.76v-1.12l-.48-1.76-.64-1.12-1.12-.32h-.64"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3069"
          d="M3243.214 415.566v-.32l.48-.8.32-.32.64-.32h1.44l.8.32.32.32.32.8v.64l-.32.8-.64.96-3.68 3.68h5.12"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3070"
          d="M3235.374 396.846v8.8h4.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3071"
          d="m3248.334 398.126-.96-.8-1.12-.48h-1.76l-1.12.48-.96.8v.8l.48.96.48.32.8.48 2.4.8.8.32.48.48.48.8v1.28l-.96.8-1.12.48h-1.76l-1.12-.48-.96-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="ls04_body"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2574.574 613.726h34.08m0-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04m-17.12 40.4v-23.2"
          className={cls_ls04_body}
        />
        <path
          id="path3078"
          d="m2587.534 617.646-1.12.32-.8 1.12-.32 1.76v1.12l.32 1.76.8 1.12 1.12.32h.64l1.12-.32.64-1.12.48-1.76v-1.12l-.48-1.76-.64-1.12-1.12-.32h-.64"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3079"
          d="m2596.174 615.646-3.68 5.12h5.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3080"
          d="M2598.174 618.146v7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3081"
          d="M2585.134 602.846v8.64h4.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3082"
          d="m2598.094 604.126-.96-.96-1.12-.32h-1.76l-1.28.32-.8.96v.8l.48.8.32.48.96.32 2.4.8.8.48.48.32.48.96v1.12l-.96.96-1.12.32h-1.76l-1.28-.32-.8-.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="ae01b_dobody"
          d="M3198.494 378.046c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_ae01b_do_body}
        />
        <path
          id="path3087"
          d="M3164.414 378.126h33.92"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3088"
          d="m3174.494 380.046-.96.32-.8 1.12-.32 1.76v1.12l.32 1.76.8 1.12.96.32h.8l1.12-.32.64-1.12.32-1.76v-1.12l-.32-1.76-.64-1.12-1.12-.32h-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3089"
          d="m3179.614 381.486.64-.48 1.12-.96v7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3090"
          d="M3185.374 387.566v-7.52h3.2l1.12.32.32.32.32.8v.64l-.32.8-.32.32-1.12.32h-3.2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3091"
          d="m3188.574 383.566 1.12.32.32.48.32.64v1.12l-.32.64-.32.48-1.12.32h-3.2"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3092"
          d="m3174.174 375.886 3.36-8.8 3.2 8.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3093"
          d="M3175.454 373.006h4.16"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3094"
          d="M3183.294 375.886v-8.8h5.28"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3095"
          d="M3183.294 371.246h3.36"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3096"
          d="M3183.294 375.886h5.28"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3100"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3136.254 335.886h34.08m.16-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          className={cls_ae01b_orp_body}
        />
        <path
          id="path3101"
          d="m3146.494 337.806-1.12.32-.64 1.12-.48 1.76v1.12l.48 1.76.64 1.12 1.12.32h.64l1.12-.32.8-1.12.32-1.76v-1.12l-.32-1.76-.8-1.12-1.12-.32h-.64"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3102"
          d="m3151.454 339.246.8-.48 1.12-.96v7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3103"
          d="M3157.214 345.326v-7.52h3.36l.96.32.48.32.32.8v.64l-.32.8-.48.32-.96.32h-3.36"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3104"
          d="m3160.574 341.326.96.32.48.48.32.64v1.12l-.32.64-.48.48-.96.32h-3.36"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3105"
          d="m3146.014 333.646 3.36-8.8 3.36 8.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3106"
          d="M3147.294 330.766h4.16"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3107"
          d="M3155.134 333.646v-8.8h5.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3108"
          d="M3155.134 329.006h3.36"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3109"
          d="M3155.134 333.646h5.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2689.142 853.757V734.766"
          id="path22"
        />
        <path
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="m2679.67 775.725.136-40.557"
          id="path21"
        />
        <path
          id="ls07_body"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2666.814 718.606h33.92m.16-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          className={cls_ls07_body}
        />
        <path
          id="path3168"
          d="m2679.684 721.776-.96.32-.8 1.12-.32 1.76v1.12l.32 1.76.8 1.12.96.32h.8l1.12-.32.64-1.12.32-1.76v-1.12l-.32-1.76-.64-1.12-1.12-.32h-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3169"
          d="m2686.911 722.814 3.768-.093-4.08 6.928"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3170"
          d="M2677.374 707.726v8.64h4.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3171"
          d="m2690.174 708.846-.8-.8-1.28-.32h-1.6l-1.28.32-.8.8v.96l.32.8.48.32.8.48 2.56.8.8.48.32.32.48.8v1.28l-.8.8-1.28.48h-1.6l-1.28-.48-.8-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="ae01a_dobody"
          d="M3078.734 378.046c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          className={cls_ae01a_do_body}
        />
        <path
          id="path4243"
          d="M3044.654 378.126h34.08"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4244"
          d="m3054.414 380.046-.96.32-.8 1.12-.32 1.76v1.12l.32 1.76.8 1.12.96.32h.8l1.12-.32.64-1.12.32-1.76v-1.12l-.32-1.76-.64-1.12-1.12-.32h-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4245"
          d="m3059.534 381.486.64-.48 1.12-.96v7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4246"
          d="m3065.294 387.566 2.88-7.52 2.88 7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4247"
          d="M3066.414 385.006h3.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4248"
          d="m3054.414 375.886 3.36-8.8 3.2 8.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4249"
          d="M3055.694 373.006h4.16"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4250"
          d="M3063.534 375.886v-8.8h5.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4251"
          d="M3063.534 371.246h3.36"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4252"
          d="M3063.534 375.886h5.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4256"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3018.494 335.886h34.08m.16-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          className={cls_ae01a_orp_body}
        />
        <path
          id="path4257"
          d="m3028.414 337.806-1.12.32-.8 1.12-.32 1.76v1.12l.32 1.76.8 1.12 1.12.32h.64l1.12-.32.64-1.12.48-1.76v-1.12l-.48-1.76-.64-1.12-1.12-.32h-.64"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4258"
          d="m3033.374 339.246.8-.48.96-.96v7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4259"
          d="m3039.134 345.326 2.88-7.52 2.88 7.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4260"
          d="M3040.254 342.766h3.52"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4261"
          d="m3028.254 333.646 3.36-8.8 3.36 8.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4262"
          d="M3029.534 330.766h4.16"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4263"
          d="M3037.374 333.646v-8.8h5.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4264"
          d="M3037.374 329.006h3.36"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4265"
          d="M3037.374 333.646h5.44"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="ls03_body"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3362.174 408.126h34.08m0-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          className={cls_ls03_body}
        />
        <path
          id="path4282"
          d="m3375.294 411.886-1.12.48-.8.96-.32 1.92v.96l.32 1.92.8.96 1.12.48h.64l1.12-.48.64-.96.48-1.92v-.96l-.48-1.92-.64-.96-1.12-.48h-.64"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4283"
          d="M3381.054 411.886h3.84l-2.08 2.88h1.12l.64.48.32.32.48 1.12v.64l-.48 1.12-.64.64-1.12.48h-1.12l-.96-.48-.48-.32-.32-.64"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4284"
          d="M3372.734 395.086v8.8h4.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path4285"
          d="m3385.534 396.366-.8-.8-1.12-.48h-1.76l-1.28.48-.8.8v.8l.48.8.32.48.96.32 2.4.96.8.32.48.48.32.8v1.28l-.8.8-1.12.48h-1.76l-1.28-.48-.8-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="ls05_body"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3639.134 354.846h33.92m.16-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          className={cls_ls05_body}
        />
        <path
          id="path5093"
          d="m3651.934 358.766-1.12.32-.64 1.12-.32 1.76v1.12l.32 1.76.64 1.12 1.12.32h.8l.96-.32.8-1.12.32-1.76v-1.12l-.32-1.76-.8-1.12-.96-.32h-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5096"
          d="M3649.694 341.966v8.64h4.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5097"
          d="m3662.494 343.086-.8-.8-1.28-.32h-1.6l-1.28.32-.8.8v.96l.32.8.48.32.8.48 2.56.8.8.48.32.32.48.8v1.28l-.8.8-1.28.48h-1.6l-1.28-.48-.8-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="ls06_body"
          style={{
            fill: 'none',
            stroke: '#0f0',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2912.094 719.406h33.92m.16-.08c0-9.411-7.629-17.04-17.04-17.04-9.41 0-17.04 7.629-17.04 17.04 0 9.41 7.63 17.04 17.04 17.04 9.411 0 17.04-7.63 17.04-17.04"
          className={cls_ls06_body}
        />
        <path
          id="path5107"
          d="m2925.054 723.326-.96.32-.8 1.12-.32 1.76v1.12l.32 1.76.8 1.12.96.32h.8l1.12-.32.64-1.12.32-1.76v-1.12l-.32-1.76-.64-1.12-1.12-.32h-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5109"
          d="M2922.654 706.526v8.64h4.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5110"
          d="m2935.454 707.806-.8-.96-1.28-.32h-1.6l-1.28.32-.8.96v.8l.48.8.32.48.8.32 2.56.8.8.48.48.48.32.8v1.12l-.8.96-1.28.32h-1.6l-1.28-.32-.8-.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path5108-4"
          d="M3661.415 358.83h-3.68l-.32 3.2.32-.32 1.12-.32h1.12l1.12.32.64.8.32.96v.8l-.32 1.12-.64.64-1.12.32h-1.12l-1.12-.32-.32-.32-.32-.8"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3169-9"
          d="m2935.858 724.45-.32-.8-1.12-.32h-.8l-.96.32-.8 1.12-.32 1.76v1.76l.32 1.44.8.8.96.32h.48l.96-.32.8-.8.32-.96v-.48l-.32-.96-.8-.8-.96-.32h-.48l-.96.32-.8.8-.32.96"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
      </g>
      <g id="layer-MC7" transform="translate(-2265.333)">
        <text
          id="text3163"
          xmlSpace="preserve"
          x={3129.922}
          y={517.309}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontSize: '10.5605px',
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3129.922}
            id="tspan3164"
            y={517.309}
          >
            {'HV15'}
          </tspan>
        </text>
      </g>
      <text
        id="text3056"
        xmlSpace="preserve"
        x={256.379}
        y={299.71}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={256.379}
          id="tspan3057"
          y={299.71}
        >
          {'FCV01'}
        </tspan>
      </text>
      <text
        id="text3137"
        xmlSpace="preserve"
        x={478.82}
        y={366.233}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={478.82}
          y={366.233}
          id="tspan3138"
        >
          {'PC01'}
        </tspan>
      </text>
      <text
        id="text3151"
        xmlSpace="preserve"
        x={637.509}
        y={409.363}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={637.509}
          id="tspan3152"
          y={409.363}
        >
          {'TK02'}
        </tspan>
      </text>
      <text
        id="text3151-2"
        xmlSpace="preserve"
        x={910.374}
        y={489.506}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={910.374}
          id="tspan3152-1"
          y={489.506}
        >
          {'TK03'}
        </tspan>
      </text>
      <text
        id="text3160"
        xmlSpace="preserve"
        x={732.628}
        y={571.864}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={732.628}
          id="tspan3160"
          y={571.864}
        >
          {'B01'}
        </tspan>
      </text>
      <text
        id="text3179"
        xmlSpace="preserve"
        x={1185.003}
        y={570.703}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1185.003}
          id="tspan3179"
          y={570.703}
        >
          {'HV06'}
        </tspan>
      </text>
      <text
        id="text3395"
        xmlSpace="preserve"
        x={879.481}
        y={289.486}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={879.481}
          id="tspan3395"
          y={289.486}
        >
          {'HV03'}
        </tspan>
      </text>
      <g id="layer-MC9" transform="translate(-2265.333)">
        <path
          id="path3449"
          d="M3011.014 177.59v61.013"
          style={{
            fill: 'none',
            stroke: '#ff003f',
            strokeWidth: 0.15903,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="path3449-3"
          d="M3006.74 177.705V202.4"
          style={{
            fill: 'none',
            stroke: '#ff003f',
            strokeWidth: 0.101178,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
        />
        <path
          id="p03_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M2904.574 133.086v256.32m0-256.32h104.16m0 12.96v-12.96"
          className={cls_p03_lineout}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3702.223 544.24v38.332"
          id="path7"
          className={cls_mv03_pos2lineout}
        />
        <path
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.143114,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3465.87 693.753v-10.751"
          id="p08_lineout"
          className={cls_p08_lineout}
        />
        <path
          id="hv09_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.143114,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3701.88 609.097v73.95m-.112-45.294h45.878m0 24.289v-24.289m-45.878 45.421h-699.07"
          className={cls_mv03_pos2lineout}
        />
      </g>
      <text
        id="text3458"
        xmlSpace="preserve"
        x={1266.199}
        y={511.772}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          id="tspan3458"
          y={511.772}
          x={1266.199}
        >
          {'P06'}
        </tspan>
      </text>
      <text
        id="text3471"
        xmlSpace="preserve"
        x={1296.351}
        y={403.177}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1296.351}
          id="tspan3472"
          y={403.177}
        >
          {'PC02'}
        </tspan>
      </text>
      <text
        id="text3674"
        xmlSpace="preserve"
        x={506.891}
        y={535.628}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={506.891}
          id="tspan3674"
          y={535.628}
        >
          {'MV01'}
        </tspan>
      </text>
      <text
        id="text3869"
        xmlSpace="preserve"
        x={1323.492}
        y={555.496}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1323.492}
          id="tspan3869"
          y={555.496}
        >
          {'MV02'}
        </tspan>
      </text>
      <text
        id="text3874"
        xmlSpace="preserve"
        x={302.646}
        y={853.933}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={302.646}
          y={853.933}
          id="tspan3874"
        >
          {'P09'}
        </tspan>
      </text>
      <text
        id="text3882"
        xmlSpace="preserve"
        x={261.136}
        y={496.037}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={261.136}
          id="tspan3882"
          y={496.037}
        >
          {'P02'}
        </tspan>
      </text>
      <text
        id="text3896"
        xmlSpace="preserve"
        x={179.827}
        y={495.89}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={179.827}
          id="tspan3896"
          y={495.89}
        >
          {'P01'}
        </tspan>
      </text>
      <text
        id="text3911"
        xmlSpace="preserve"
        x={1117.79}
        y={478.555}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1117.79}
          id="tspan3911"
          y={478.555}
        >
          {'TK05'}
        </tspan>
      </text>
      <text
        id="text3914"
        xmlSpace="preserve"
        x={1053.398}
        y={480.714}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1053.398}
          id="tspan3914"
          y={480.714}
        >
          {'HV04'}
        </tspan>
      </text>
      <text
        id="text4110"
        xmlSpace="preserve"
        x={591.594}
        y={553.426}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={591.594}
          id="tspan4110"
          y={553.426}
        >
          {'HV02'}
        </tspan>
      </text>
      <text
        id="text4111"
        xmlSpace="preserve"
        x={698.161}
        y={789.845}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={698.161}
          id="tspan4111"
          y={789.845}
        >
          {'TK07'}
        </tspan>
      </text>
      <text
        id="text4123"
        xmlSpace="preserve"
        x={801.627}
        y={874.211}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={801.627}
          y={874.211}
          id="tspan4123"
        >
          {'P07'}
        </tspan>
      </text>
      <g id="layer-MC10" transform="translate(-2265.333)">
        <path
          id="mv03_pos1lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.199453,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3753.633 452.57v-64.302m-38.252 64.33h38.299"
          className={cls_mv03_pos1lineout}
        />
        <path
          id="mv10_lineout"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.165849,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3753.688 320.288h-43.341m43.34 41.495v-41.495m-341.662-67.677v208.31m298.322-208.31h-298.398m298.398 67.677V252.61"
          className={cls_mv10_lineout}
        />
        <path
          id="hv09_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3702.134 591.993a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m7.44-9.44h-15.04l7.52 13.28 7.52-13.28m-15.04 26.4h15.04l-7.52-13.12-7.52 13.12"
          className={cls_hv09_body}
        />
        <text
          id="text5323-9"
          xmlSpace="preserve"
          x={3718.829}
          y={598.889}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontSize: '10.5605px',
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3718.829}
            y={598.889}
            id="tspan5323-0"
          >
            {'HV09'}
          </tspan>
        </text>
        <path
          id="hv10_body"
          style={{
            fill: 'none',
            stroke: '#000',
            strokeWidth: 0.16,
            strokeLinecap: 'butt',
            strokeLinejoin: 'miter',
            strokeMiterlimit: 10,
            strokeDasharray: 'none',
            strokeOpacity: 1
          }}
          d="M3753.692 371.35a3.76 3.76 0 1 0 0 7.52 3.76 3.76 0 0 0 0-7.52m7.44-9.44h-15.04l7.52 13.28 7.52-13.28m-15.04 26.4h15.04l-7.52-13.12-7.52 13.12"
          className={cls_hv10_body}
        />
        <text
          id="text5323-93"
          xmlSpace="preserve"
          x={3768.061}
          y={378.352}
          style={{
            fontSize: 16,
            strokeWidth: 1.33333
          }}
          className={cls_main_label}
        >
          <tspan
            style={{
              fontVariant: 'normal',
              fontWeight: 400,
              fontSize: '10.5605px',
              fontFamily: 'Arial',
              writingMode: 'lr-tb',
              fill: '#000',
              fillOpacity: 1,
              fillRule: 'nonzero',
              stroke: 'none',
              strokeWidth: 1.33333
            }}
            x={3768.061}
            y={378.352}
            id="tspan5323-6"
          >
            {'HV10'}
          </tspan>
        </text>
      </g>
      <text
        id="text4238"
        xmlSpace="preserve"
        x={1423.721}
        y={423.526}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1423.721}
          id="tspan4238"
          y={423.526}
        >
          {'MV03'}
        </tspan>
      </text>
      <text
        id="text4277"
        xmlSpace="preserve"
        x={349.983}
        y={808.018}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={349.983}
          y={808.018}
          id="tspan4277"
        >
          {'TK09'}
        </tspan>
      </text>
      <text
        id="text4277-5"
        xmlSpace="preserve"
        x={406.12}
        y={785.953}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={406.12}
          y={785.953}
          id="tspan4277-0"
        >
          {'HL'}
        </tspan>
      </text>
      <text
        id="text4486"
        xmlSpace="preserve"
        x={422.683}
        y={402.542}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={422.683}
          id="tspan4486"
          y={402.542}
        >
          {'HV01'}
        </tspan>
      </text>
      <text
        id="text4680"
        xmlSpace="preserve"
        x={885.725}
        y={779.406}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={885.725}
          id="tspan4680"
          y={779.406}
        >
          {'HV13'}
        </tspan>
      </text>
      <text
        id="text4688"
        xmlSpace="preserve"
        x={251.438}
        y={880.532}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '8.62448px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={251.438}
          id="tspan4688"
          y={880.532}
        >
          {'Treated Water Buffer Tank'}
        </tspan>
      </text>
      <text
        id="text4899"
        xmlSpace="preserve"
        x={1362.381}
        y={499.686}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1362.381}
          id="tspan4899"
          y={499.686}
        >
          {'HV07'}
        </tspan>
      </text>
      <text
        id="text5090"
        xmlSpace="preserve"
        x={1083.062}
        y={587.264}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1083.062}
          id="tspan5090"
          y={587.264}
        >
          {'HV05'}
        </tspan>
      </text>
      <text
        id="text5117"
        xmlSpace="preserve"
        x={825.685}
        y={606.623}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={825.685}
          y={606.623}
          id="tspan5117"
        >
          {'P05'}
        </tspan>
      </text>
      <text
        id="text5323"
        xmlSpace="preserve"
        x={1471.39}
        y={708.176}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1471.39}
          y={708.176}
          id="tspan5323"
        >
          {'HV08'}
        </tspan>
      </text>
      <text
        id="text5522"
        xmlSpace="preserve"
        x={886.55}
        y={836.458}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={886.55}
          y={836.458}
          id="tspan5522"
        >
          {'HV14'}
        </tspan>
      </text>
      <text
        id="text5713"
        xmlSpace="preserve"
        x={746.821}
        y={865.296}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={746.821}
          y={865.296}
          id="tspan5713"
        >
          {'HV12'}
        </tspan>
      </text>
      <text
        id="text5814"
        xmlSpace="preserve"
        x={710.441}
        y={655.726}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={710.441}
          id="tspan5814"
          y={655.726}
        >
          {'MV04'}
        </tspan>
      </text>
      <text
        id="text5829"
        xmlSpace="preserve"
        x={601.161}
        y={743.886}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={601.161}
          id="tspan5829"
          y={743.886}
        >
          {'CV01'}
        </tspan>
      </text>
      <text
        id="text3898-8"
        xmlSpace="preserve"
        x={751.05}
        y={291.905}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={751.05}
          y={291.905}
          id="tspan3899-0"
        >
          {'B02'}
        </tspan>
      </text>
      <text
        id="text3452"
        xmlSpace="preserve"
        x={767.789}
        y={168.889}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={767.789}
          id="tspan3453"
          y={168.889}
        >
          {'P03'}
        </tspan>
      </text>
      <text
        id="text3452-87"
        xmlSpace="preserve"
        x={1228.073}
        y={721.269}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1228.073}
          id="tspan3453-5"
          y={721.269}
        >
          {'P08'}
        </tspan>
      </text>
      <text
        id="text3896-5"
        xmlSpace="preserve"
        x={330.47}
        y={455.799}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={330.47}
          id="tspan3896-9"
          y={455.799}
        >
          {'TK01'}
        </tspan>
      </text>
      <text
        id="text3896-5-3"
        xmlSpace="preserve"
        x={256.185}
        y={673.215}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={256.185}
          id="tspan3896-9-2"
          y={673.215}
        >
          {'TK04'}
        </tspan>
      </text>
      <text
        id="text3896-5-3-0"
        xmlSpace="preserve"
        x={1364.391}
        y={427.366}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '10.5605px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1364.391}
          id="tspan3896-9-2-9"
          y={427.366}
        >
          {'TK06'}
        </tspan>
      </text>
      <text
        id="text4688-6"
        xmlSpace="preserve"
        x={197.803}
        y={705.524}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '8.62448px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={197.803}
          id="tspan4688-8"
          y={705.524}
        >
          {'Waste Biomass Tank'}
        </tspan>
      </text>
      <text
        id="text4688-6-2"
        xmlSpace="preserve"
        x={592.759}
        y={843.789}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontSize: '8.62448px',
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={592.759}
          id="tspan4688-8-6"
          y={843.789}
        >
          {'Plant Washdown Tank'}
        </tspan>
      </text>
      <text
        x={362.369}
        y={819.486}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p03-0-4"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {tk09_value}
      </text>
      <text
        x={312.369}
        y={864.486}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p03-0-4-1"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {p09_value}
      </text>
      <text
        id="text3674-6"
        xmlSpace="preserve"
        x={710.313}
        y={258.5}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={710.313}
          id="tspan3674-5"
          y={258.5}
        >
          {'CDS01'}
        </tspan>
      </text>
      <text
        x={1129.369}
        y={489.736}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p03-0-4-8"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {tk05_value}
      </text>
      <text
        x={1381.042}
        y={438.071}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p03-0-4-8-9"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {tk06_value}
      </text>
      <text
        x={768.318}
        y={306.102}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="ppc101_value-3-7-43-8"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {b02_value}
      </text>
      <text
        id="text3674-6-2"
        xmlSpace="preserve"
        x={1167.186}
        y={805.95}
        style={{
          fontSize: 16,
          strokeWidth: 1.33333
        }}
        className={cls_main_label}
      >
        <tspan
          style={{
            fontVariant: 'normal',
            fontWeight: 400,
            fontFamily: 'Arial',
            writingMode: 'lr-tb',
            fill: '#000',
            fillOpacity: 1,
            fillRule: 'nonzero',
            stroke: 'none',
            strokeWidth: 1.33333
          }}
          x={1167.186}
          id="tspan3674-5-8"
          y={805.95}
        >
          {'CDS02'}
        </tspan>
      </text>
      <text
        x={838.452}
        y={622.387}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p02-8-9"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {p05_value}
      </text>
      <text
        x={810.801}
        y={886.116}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p02-8-9-9"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {p07_value}
      </text>
      <text
        x={709.992}
        y={802.562}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p02-8-9-0"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {tk07_value}
      </text>
      <text
        x={1477.231}
        y={557.05}
        fill="#000"
        fontFamily="Helvetica"
        fontSize={12}
        textAnchor="middle"
        id="value_p02-8-9-9-5"
        style={{
          strokeWidth: 0.999999
        }}
        className={clsshow_sensor}
      >
        {sf01_value}
      </text>
    </svg>
  );
};

export default DeviceSchematicEleIotGateway;
