import React, { useEffect, Fragment, useState, useLayoutEffect } from 'react';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import s from './AddUnit.module.scss';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues } from '../../utils';
import { v4 as uuidv4 } from 'uuid';

const SEARCH_FILTER_TYPE_SERIAL_NUMBER = 'SEARCH_FILTER_TYPE_SERIAL_NUMBER';
const SEARCH_FILTER_TYPE_POSITION = 'SEARCH_FILTER_TYPE_POSITION';

const ManagePartsList = ({ unitManagerParts, dropdownDatalist, addUpdatePartHandler, removePartHandler, intl }) => {
  const [showAddPartSection, setShowAddPartSection] = useState(false);
  const [currentEditId, setCurrentEditId] = useState(-1);
  const [currentEditData, setCurrentEditData] = useState({});
  const [addExistingPart, setAddExistingPart] = useState(false);
  const [localComponentParts, setLocalComponentParts] = useState([]);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchfilterType, setSearchFilterType] = useState(SEARCH_FILTER_TYPE_SERIAL_NUMBER);

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);
  };

  const getPartsTableList = () => {
    if (searchText && searchText.length > 0) {
      if (searchfilterType === SEARCH_FILTER_TYPE_POSITION) {
        return unitManagerParts.filter(function(item) {
          return item.ModelComponentPositionName.toLowerCase().includes(searchText.toLowerCase());
        });
      } else {
        return unitManagerParts.filter(function(item) {
          return item.SerialNumber.toLowerCase().includes(searchText.toLowerCase());
        });
      }
    }

    return unitManagerParts;
  };

  let typePlaceHolder = intl.formatMessage({ id: 'componentParts.serialNumber', defaultMessage: 'Serial Number' });

  const typeOptions = () => {
    let statusData = [];

    statusData.push({
      label: <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />,
      value: SEARCH_FILTER_TYPE_SERIAL_NUMBER
    });

    statusData.push({
      label: <FormattedMessage id="componentParts.position" defaultMessage="Position" />,
      value: SEARCH_FILTER_TYPE_POSITION
    });

    return statusData;
  };

  let newRowClass = showAddPartSection && currentEditId === 0 ? s.newRow : '';

  const addUpdateHandler = data => {
    setCurrentEditId(-1);
    setCurrentEditData({});
    setShowAddPartSection(false);
    addUpdatePartHandler(data);
  };

  const fetchDataHandler = (id, item) => {
    setCurrentEditId(id);
    setCurrentEditData(item);
    setShowAddPartSection(true);
  };

  const removeDataHandler = id => {
    removePartHandler(id);
  };

  const onTypeChange = e => {
    setSearchFilterType(e.value);
  };

  const setAddHandler = () => {
    setShowAddPartSection(true);
    setAddExistingPart(false);
    setCurrentEditId(0);
  };
  const setAddExistingHandler = () => {
    setShowAddPartSection(true);
    setAddExistingPart(true);
    setCurrentEditId(0);
  };

  const cancelHandler = () => {
    setCurrentEditId(-1);
    setAddExistingPart(false);
    setShowAddPartSection(false);
  };

  useLayoutEffect(() => {
    if (showAddPartSection) {
      const scrollPos = window.scrollY + window.innerHeight;
      window.scrollTo(0, scrollPos);
    }
  }, [showAddPartSection]);

  const editComponentPartContent = (
    <div>
      <AddNewExistingPart
        unitManagerParts={unitManagerParts}
        id={currentEditId}
        editData={currentEditData}
        modelComponentPositions={dropdownDatalist.unitComponentPositions}
        approvedComponentsList={dropdownDatalist.approvedComponents}
        partsList={dropdownDatalist.componentParts}
        cancelHandler={cancelHandler}
        addUpdatePartHandler={addUpdateHandler}
        addExistingPart={addExistingPart}
        intl={intl}
      />
    </div>
  );

  let editorMain = (
    <tr>
      <td colSpan="10">{editComponentPartContent}</td>
    </tr>
  );

  return (
    <div className={s.unit}>
      <Row className={s.topRow}>
        <Col sm={6} md={7} lg={6}>
          <SearchBar
            searchHandler={searchHandler}
            clearSearchInVisible={false}
            disabled={showAddPartSection}
            initialText={searchText}
            placeHolderTextId={
              searchfilterType === SEARCH_FILTER_TYPE_SERIAL_NUMBER ? 'componentParts.serialNumber' : 'componentParts.position'
            }
            id="txtSearchBar"
            showFilterType
            searchfilterType={searchfilterType}
            onFilterTypeChange={onTypeChange}
            filterTypeData={typeOptions()}
            filterPlaceholderText={typePlaceHolder}
            data-unittest="searchComponentParts"
          />
        </Col>
        <Col sm={6} md={5} lg={6}>
          <Button className={s.addPartbutton} onClick={setAddHandler} disabled={showAddPartSection} data-unittest="addComponentPartButton">
            + <FormattedMessage id="componentParts.addNewPart" defaultMessage="ADD NEW PART" />
          </Button>
          <Button
            className={s.addExistingPartbutton}
            onClick={setAddExistingHandler}
            disabled={showAddPartSection}
            data-unittest="addComponentPartButton"
          >
            + <FormattedMessage id="componentParts.existingNewPart" defaultMessage="ADD EXISTING PART" />
          </Button>
        </Col>
      </Row>
      <div className="">
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="componentParts.modelComponentPosition" defaultMessage="Model Component Position" />
              </th>
              <th>
                <FormattedMessage id="componentParts.approvedComponentRef" defaultMessage="Approved Component Ref" />
              </th>
              <th>
                <FormattedMessage id="componentParts.brand" defaultMessage="Brand" />
              </th>
              <th>
                <FormattedMessage id="componentParts.model" defaultMessage="Model" />
              </th>
              <th>
                <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />
              </th>
              <th>
                <FormattedMessage id="componentParts.componentType" defaultMessage="Component Type" />
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {getPartsTableList().map(n => {
              return (
                <Fragment key={n.id}>
                  <tr key={n.id} data-unittest="unitManagerParts">
                    <td>{n.ModelComponentPositionName}</td>
                    <td>{n.ApprovedComponentRef}</td>
                    <td>{n.Brand}</td>
                    <td>{n.Model}</td>
                    <td>{n.SerialNumber}</td>
                    <td>{n.ComponentTypeName}</td>
                    <td className={s.btnCol}>
                      <Button
                        variant="outline-secondary"
                        className={s.changeButton}
                        disabled={showAddPartSection}
                        onClick={fetchDataHandler.bind(this, n.id, n)}
                        data-unittest="replaceData"
                      >
                        <FormattedMessage id="unit.change" defaultMessage="CHANGE" />
                      </Button>
                      <Button
                        variant="outline-secondary"
                        className={s.changeButton}
                        disabled={showAddPartSection}
                        onClick={removeDataHandler.bind(this, n.id)}
                        data-unittest="replaceData"
                      >
                        <FormattedMessage id="unit.remove" defaultMessage="REMOVE" />
                      </Button>
                    </td>
                  </tr>
                  {showAddPartSection && currentEditId === n.id && editorMain}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
        <div className={newRowClass}>{showAddPartSection && currentEditId === 0 && editComponentPartContent}</div>
      </div>
    </div>
  );
};

export default injectIntl(ManagePartsList);

const AddNewExistingPart = ({
  unitManagerParts,
  id,
  editData,
  modelComponentPositions,
  partsList,
  approvedComponentsList,
  cancelHandler,
  addUpdatePartHandler,
  addExistingPart,
  intl
}) => {
  const [localformErrors, setFormErrors] = useState({});
  const [localModelComponentPositionId, setLocalModelComponentPositionId] = useState(editData.ModelComponentPositionId || 0);
  const [localModelComponentPositionName, setLocalModelComponentPositionName] = useState(editData.ModelComponentPositionName || '');
  const [localApprovedComponentId, setLocalApprovedComponentId] = useState(editData.ApprovedComponentId || 0);
  const [localApprovedComponentRef, setLocalApprovedComponentRef] = useState(editData.ApprovedComponentRef || '');
  const [localComponentTypeName, setLocalComponentTypeName] = useState(editData.ComponentTypeName || '');
  const [localBrand, setLocalBrand] = useState(editData.Brand || '');
  const [localModel, setLocalModel] = useState(editData.Model || '');
  const [localPartId, setLocalPartId] = useState(editData.PartId || 0);
  const [localSerialNumber, setLocalSerialNumber] = useState(editData.SerialNumber || '');

  let ddlComponentPartClass = localformErrors && localformErrors.ComponentPartId ? s.ddlError : '';
  let ddlPositionClass = localformErrors && localformErrors.ModelComponentPositionId ? s.ddlError : '';
  let ddlApprovedComponentClass = localformErrors && localformErrors.ApprovedComponentId ? s.ddlError : '';
  let IsEdit = id ? true : false;
  let selectedComponentTypeId = 0;
  let selectedComponentPosition = modelComponentPositions.filter(x => x.ModelComponentPositionId === localModelComponentPositionId);
  if (selectedComponentPosition.length > 0) {
    selectedComponentTypeId = selectedComponentPosition[0].ModelComponentTypeId;
  }

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!localModelComponentPositionId || isRequired(localModelComponentPositionId, 1)) {
      formErrors.ModelComponentPositionId = intl.formatMessage({
        id: 'unitConfigurationComponentParts.modelComponentPositionMandatory',
        defaultMessage: 'Model Component Position is mandatory'
      });
      isValid = false;
    }

    if (addExistingPart && (!localPartId || isRequired(localPartId, 1))) {
      formErrors.ComponentPartId = intl.formatMessage({
        id: 'unitConfigurationComponentParts.componentPartMandatory',
        defaultMessage: 'Component Part is mandatory'
      });
      isValid = false;
    }

    if (!addExistingPart && (!localApprovedComponentId || isRequired(localApprovedComponentId, 1))) {
      formErrors.ApprovedComponentId = intl.formatMessage({
        id: 'componentParts.approvedComponentMandatory',
        defaultMessage: 'Approved Component is mandatory'
      });
      isValid = false;
    }

    if ((!addExistingPart && !localSerialNumber) || isRequired(localSerialNumber, 1)) {
      formErrors.SerialNumber = intl.formatMessage({
        id: 'unit.serialNumberMandatory',
        defaultMessage: 'Serial number is a mandatory field'
      });
      isValid = false;
    }

    const serialNumberValidateList = unitManagerParts.filter(x => x.SerialNumber === localSerialNumber) || [];
    if (
      (!addExistingPart &&
        id !== 0 &&
        serialNumberValidateList.length > 0 &&
        serialNumberValidateList.filter(x => x.id === id).length > 1) ||
      (id === 0 && serialNumberValidateList.length > 0)
    ) {
      formErrors.SerialNumber = intl.formatMessage({
        id: 'unit.serialNumberDuplicate',
        defaultMessage: 'This serial number already exists'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const localAddHandler = () => {
    if (!validateInput()) {
      return false;
    }

    let partsData = {};
    if (!unitManagerParts.find(x => x.id === id)) {
      partsData.id = uuidv4();
    } else {
      partsData.id = id;
    }
    partsData.ModelComponentPositionId = localModelComponentPositionId;
    partsData.ModelComponentPositionName = localModelComponentPositionName;
    partsData.ApprovedComponentId = localApprovedComponentId;
    partsData.ApprovedComponentRef = localApprovedComponentRef;
    partsData.ComponentPartId = localPartId;
    partsData.SerialNumber = localSerialNumber;
    partsData.ComponentTypeName = localComponentTypeName;
    partsData.Brand = localBrand;
    partsData.Model = localModel;
    addUpdatePartHandler(partsData);
    setLocalSerialNumber('');
    setLocalModelComponentPositionId(0);
    setLocalPartId(0);
    setLocalApprovedComponentId(0);
  };

  const onPositionDropdownChange = e => {
    setLocalModelComponentPositionId(e.value);
    setLocalModelComponentPositionName(e.label);
  };

  const onComponentPartDropdownChange = e => {
    setLocalPartId(e.value);
    let partInfo = partsList.find(x => x.ComponentPartId === e.value) || null;
    if (partInfo) {
      setLocalApprovedComponentId(partInfo.ApprovedComponentId);
      setLocalComponentTypeName(partInfo.ComponentTypeName);
      setLocalSerialNumber(partInfo.SerialNumber);
      setLocalBrand(partInfo.Brand);
      setLocalModel(partInfo.Model);
      setLocalApprovedComponentRef(partInfo.ApprovedComponentRef || '');
    }
  };

  const onApprovedComponentDropdownChange = e => {
    setLocalApprovedComponentId(e.value);
    let approvedComponentInfo = approvedComponentsList.find(x => x.ApprovedComponentId === e.value) || null;
    if (approvedComponentInfo) {
      setLocalComponentTypeName(approvedComponentInfo.ModelComponentType);
      setLocalBrand(approvedComponentInfo.Brand);
      setLocalModel(approvedComponentInfo.Model);
      setLocalApprovedComponentRef(approvedComponentInfo.ApprovedComponentRef || '');
    }
  };

  const handleInput = e => {
    setLocalSerialNumber(e.target.value);
  };

  const modelComponentPositionOptions = () => {
    let modelComponentPositionData = [];
    modelComponentPositions.forEach(element => {
      if (unitManagerParts && unitManagerParts.filter(x => x.ModelComponentPositionId === element.ModelComponentPositionId).length === 1) {
        element.isDisabled = true;
      } else {
        element.isDisabled = false;
      }
      modelComponentPositionData.push({
        ...element,
        label: element.Name,
        value: element.ModelComponentPositionId
      });
    });
    return modelComponentPositionData.sort(compareValues('label'));
  };

  const partsOptions = () => {
    let partsData = [];
    let spareParts = partsList || [];
    if (selectedComponentTypeId > 0) {
      spareParts = spareParts.filter(x => x.ModelComponentTypeId === selectedComponentTypeId);
    }

    spareParts.forEach(element => {
      partsData.push({
        ...element,
        label: ` ${element.SerialNumber} - ${element.Brand} - ${element.Model}`,
        value: element.ComponentPartId
      });
    });
    return partsData.sort(compareValues('label'));
  };

  const approvedComponentOptions = () => {
    let approvedComponentsData = [];
    let approvedComponents = approvedComponentsList || [];
    if (selectedComponentTypeId > 0) {
      approvedComponents = approvedComponents.filter(x => x.ModelComponentTypeId === selectedComponentTypeId);
    }

    approvedComponents.forEach(element => {
      approvedComponentsData.push({
        ...element,
        label: `${element.Brand} - ${element.Model}`,
        value: element.ApprovedComponentId
      });
    });

    approvedComponentsData.push({
      label: <FormattedMessage id="componentParts.selectApprovedComponent" defaultMessage="Select Approved Component" />,
      value: ''
    });

    return approvedComponentsData.sort(compareValues('label'));
  };

  return (
    <div className={s.section}>
      <div className={s.topRow}>
        <Row>
          <Col lg={4}>
            <Form.Group controlId="formPositionName">
              <Form.Label>
                <FormattedMessage id="componentParts.selectModleComponentPosition" defaultMessage="Select Model Component Position" />
              </Form.Label>
              <Dropdown
                id="formPositionName"
                dataArray={modelComponentPositionOptions()}
                controlData={{
                  placeholderText: (
                    <FormattedMessage id="componentParts.selectModleComponentPosition" defaultMessage="Select Model Component Position" />
                  ),
                  customClassName: ddlPositionClass
                }}
                onDropdownChange={onPositionDropdownChange}
                selectedOption={modelComponentPositionOptions().filter(option => option.value === localModelComponentPositionId)}
                disabled={IsEdit}
                data-unittest="formModelComponentPosition"
              />
              {localformErrors && localformErrors.ModelComponentPositionId && (
                <p role="alert" className={s.error}>
                  {localformErrors.ModelComponentPositionId}
                </p>
              )}
            </Form.Group>
          </Col>
          {addExistingPart ? (
            <Col lg={4}>
              <Form.Group controlId="formComponentPart">
                <Form.Label>
                  <FormattedMessage id="unitConfigurationComponentParts.selectPart" defaultMessage="Select Part" />
                </Form.Label>
                <Dropdown
                  id="formComponentPart"
                  dataArray={partsOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="unitConfigurationComponentParts.selectPart" defaultMessage="Select Part" />,
                    customClassName: ddlComponentPartClass
                  }}
                  onDropdownChange={onComponentPartDropdownChange}
                  selectedOption={partsOptions().filter(option => option.value === localPartId)}
                  data-unittest="formComponentPart"
                />
                {localformErrors && localformErrors.ComponentPartId && (
                  <p role="alert" className={s.error}>
                    {localformErrors.ComponentPartId}
                  </p>
                )}
              </Form.Group>
            </Col>
          ) : (
            <Fragment>
              <Col lg={4}>
                <Form.Group controlId="formApprovedComponent">
                  <Form.Label>
                    <FormattedMessage id="componentParts.approvedComponent" defaultMessage="Approved Component" />
                  </Form.Label>
                  <Dropdown
                    id="formApprovedComponent"
                    dataArray={approvedComponentOptions()}
                    controlData={{
                      placeholderText: (
                        <FormattedMessage id="componentParts.selectApprovedComponent" defaultMessage="Select Approved Component" />
                      ),
                      customClassName: ddlApprovedComponentClass
                    }}
                    onDropdownChange={onApprovedComponentDropdownChange}
                    selectedOption={approvedComponentOptions().filter(option => option.value === localApprovedComponentId)}
                    data-unittest="formApprovedComponent"
                  />
                  {localformErrors && localformErrors.ApprovedComponentId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.ApprovedComponentId}
                    </p>
                  )}
                </Form.Group>
              </Col>

              <Col lg={4}>
                <Form.Label>
                  <FormattedMessage id="componentParts.serialNumber" defaultMessage="Serial Number" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="SerialNumber"
                  value={localSerialNumber}
                  onChange={handleInput}
                  className={`${localformErrors.SerialNumber ? s.formControlError : ''}`}
                />
                {localformErrors && localformErrors.SerialNumber && (
                  <p role="alert" className={s.error}>
                    {localformErrors.SerialNumber}
                  </p>
                )}
              </Col>
            </Fragment>
          )}
        </Row>
        <Row>
          <Col lg={8} xs={12}>
            <Button
              variant="primary"
              className={classNames(s.margin5, s.btnSaveChanges)}
              onClick={localAddHandler}
              noValidate
              data-unittest="saveData"
            >
              {IsEdit ? (
                <FormattedMessage id="unit.update" defaultMessage="'UPDATE'" />
              ) : (
                <FormattedMessage id="unit.add" defaultMessage="ADD" />
              )}
            </Button>
            <Button
              variant="outline-secondary"
              className={classNames(s.btnCancel)}
              onClick={cancelHandler}
              noValidate
              data-unittest="saveCancel"
            >
              <FormattedMessage id="unit.cancel" defaultMessage="CANCEL" />
            </Button>
          </Col>
          <Col lg={4}></Col>
        </Row>
      </div>
    </div>
  );
};
