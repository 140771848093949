import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './DailySummaryReport.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import moment from 'moment-timezone';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import arrowDown from '../../assets/arrow-down.svg';
import { v4 as uuid } from 'uuid' ;
import help from '../../assets/help-grey.svg';
import DateTimePicker from '../WSAControls/DateTimePicker/DateTimePicker';
import { getUserProfileWidget, getCurrentTimezone, getDailySummary } from '../../selectors/index';
import {
  getExportDataThunk,
  setDailySummaryChangeThunk,
  setExportCompleteThunk,
  setDailySummaryExportErrorThunk
} from '../../actions/dailySummary';

import { SUMMARY_TYPES } from '../../constants/index';
import { downloadCsvFile, flattenObject } from '../../utils/index';

const DailySummaryReport = ({ intl }) => {
  const dispatch = useDispatch();
  let { unitSerialNumber } = useParams();

  const dailySummaryData = useSelector(state => getDailySummary(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));

  let exportComplete = dailySummaryData.search.exportComplete || false;
  let isOpSuccessful = dailySummaryData.isOpSuccessful || false;
  let exportedResults = dailySummaryData.search.exportedResults || [];
  let disableControls = dailySummaryData.isLoading || false;

  const currentTimestamp = moment().tz(currentTimezone);

  var d = new Date();
  const currentFromTimestamp =
    dailySummaryData.search.fromDate > 0
      ? dailySummaryData.search.fromDate
      : moment()
          .subtract(1, 'days')
          .valueOf();

  const currentToTimestamp = dailySummaryData.search.toDate > 0 ? dailySummaryData.search.toDate : moment(d).valueOf();
  const [localformErrors, setFormErrors] = useState({});

  const noDataText = intl.formatMessage({ id: 'dailySummaryReport.noData', defaultMessage: 'No data is available' });

  //export check
  useEffect(() => {
    if (exportComplete && isOpSuccessful) {
      computeExportData();
    }
  }, [setExportCompleteThunk, exportComplete, isOpSuccessful]);

  const submitForm = e => {
    e.preventDefault();

    let searchData = dailySummaryData.search;
    if (searchData.fromDate === 0) {
      searchData.fromDate = currentFromTimestamp;
    }
    if (searchData.toDate === 0) {
      searchData.toDate = currentToTimestamp;
    }
    searchData.unitSerialNumber = unitSerialNumber;

    if (!validateInput(searchData)) {
      return;
    }

    //Initiating report fetch
    exportReportHandler(searchData);
  };

  const validateInput = searchData => {
    let formErrors = {};
    let isValid = true;

    if (!searchData || !searchData.dataOptions || searchData.dataOptions.length === 0) {
      formErrors.dataPoint = intl.formatMessage({
        id: 'unitreport.selectdatapoint',
        defaultMessage: 'Please select at least one data point'
      });
      // if (isValid) this.scrollToBottom();
      isValid = false;
    } else {
      formErrors.dataPoint = null;
    }

    if (searchData.fromDate > searchData.toDate) {
      formErrors.timeRange = intl.formatMessage({
        id: 'dailySummaryReport.timerangenotvalid',
        defaultMessage: 'Search time range is not valid'
      });
      // if (isValid) this.scrollToBottom();
      isValid = false;
    }

    setFormErrors(formErrors);
    return isValid;
  };

  const setLocalSearch = currentState => {
    dispatch(setDailySummaryChangeThunk(currentState));
  };

  const onFromDateChange = value => {
    setLocalSearch({
      search: { ...dailySummaryData.search, fromDate: moment(value).valueOf() }
    });
  };

  const onToDateChange = value => {
    setLocalSearch({
      search: { ...dailySummaryData.search, toDate: moment(value).valueOf() }
    });
  };

  const onSelectAllChange = (e, data) => {
    let selectAllChecked = (e && e.target && e.target.checked) || false;

    setLocalSearch({
      search: {
        ...dailySummaryData.search,
        selectAll: selectAllChecked,
        dataOptions: selectAllChecked ? Object.keys(SUMMARY_TYPES).map(key => SUMMARY_TYPES[key].value) : []
      }
    });
  };

  const onSummaryTypeChange = (e, component) => {
    let isChecked = (e && e.target && e.target.checked) || false;
    let dataArray = [];
    let exists = false;
    dataArray = dailySummaryData.search.dataOptions.map(option => {
      if (option === component.value) {
        exists = true;
      }
      return option;
    });

    //remove unchecked item
    dailySummaryData.search.dataOptions.map(option => {
      if (option === component.value && !isChecked) {
        if (dataArray.indexOf(option) > -1) {
          dataArray.splice(dataArray.indexOf(option), 1);
        }
      }
    });

    //add item if not exist
    if (!exists && isChecked) {
      dataArray.push(component.value);
    }

    setLocalSearch({
      search: {
        ...dailySummaryData.search,
        selectAll: dataArray.length === Object.keys(SUMMARY_TYPES).length,
        dataOptions: dataArray
      }
    });
  };

  const summaryTypes = () => {
    let dataArray = [];

    dataArray = Object.keys(SUMMARY_TYPES).map(key => {
      return {
        value: SUMMARY_TYPES[key].value,
        label: SUMMARY_TYPES[key].name,
        SKEY: SUMMARY_TYPES[key].value,
        isChecked: dailySummaryData.search.dataOptions.filter(selComp => selComp === SUMMARY_TYPES[key].value).length === 1
      };
    });

    return dataArray;
  };

  const disableFutureDates = current => current.isBefore(currentTimestamp);
  const disablePastDates = current =>
    current.isAfter(
      moment(dailySummaryData.search.fromDate)
        .tz(currentTimezone)
        .subtract(1, 'days')
    ) && current.isBefore(currentTimestamp);

  const jsonToCsv = items => {
    const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
    const header = Object.keys(flattenObject(items[0]));

    let csv = items.map(row => {
      let flattenedRow = flattenObject(row);
      return header.map(fieldName => JSON.stringify(flattenedRow[fieldName], replacer)).join(',');
    });
    csv.unshift(header.join(','));
    return csv.join('\r\n');
  };

  const exportReportHandler = data => {
    dispatch(getExportDataThunk(data, 'DAILY_SUMMARY_DATA'));
  };

  const computeExportData = useCallback(() => {
    const computePromise = new Promise((resolve, reject) => {
      try {
        let convertToCSV = jsonToCsv([{ NoData: noDataText }]);

        if (exportedResults.length > 0) {
          let exportFormattedData = [];
          exportedResults.map(item => {
            const { UnitSerialNumber, DeviceId, timestamp, time_range_hours, OrganisationId, ...sensors } = item;
            let formattedRow = {
              unitSerialNumber: UnitSerialNumber,
              deviceId: DeviceId,
              timestamp: moment(timestamp)
                .tz(currentTimezone)
                .format('DD-MMM-YYYY HH:mm:ss z'),
              time_range_hours: time_range_hours ? time_range_hours : '',
              component: sensors
            };
            exportFormattedData.push(formattedRow);
          });

          convertToCSV = jsonToCsv(exportFormattedData);
        }
        downloadCsvFile(convertToCSV, `summary_data_${moment().unix()}.csv`);
        resolve(dispatch(setExportCompleteThunk()));
      } catch (error) {
        reject(dispatch(setDailySummaryExportErrorThunk('dailySummaryReport.unableToExport')));
      }
    });

    return computePromise;
  }, [dispatch, exportedResults]);

  let messageId = (dailySummaryData && dailySummaryData.displayMessageCode) || 'unitreport.datafetchfailed';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <ContentTemplate
      selectedPage="dailySummary"
      userProfileWidgets={userProfileWidgets}
      widgetCode={'DAILY_SUMMARY_DATA'}
      tagoutRequired={false}
    >
      <div className={s.unitReports}>
        {(dailySummaryData.isLoading || dailySummaryData.isLoading) && <LoadingSpinner />}
        <Banner
          key={uuid()}
          failureText={messageText}
          showBanner={dailySummaryData.showBanner}
          status={dailySummaryData.isOpSuccessful}
          successText={messageText}
        />

        <div className={s.contentWrapper}>
          <div className={s.unitReportsContent}>
            <Form>
              <Row>
                <Col>
                  <div className={s.subHeading2}>
                    <img src={help} alt="icon" className={s.icon}></img>
                    <FormattedMessage
                      id="unitreports.dailySummaryDownloadSize"
                      defaultMessage="Reports with data more two days (48 hours) may not be available due to its size limitation"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={s.unitsubHeading}>
                    <FormattedMessage id="unitreports.selectedateandtimerange" defaultMessage="Select date and time range" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <div className={s.subHeading2}>
                        <FormattedMessage id="unitreports.fromdatetime" defaultMessage="From date/time" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group has-icon">
                        <span className="form-control-icon">
                          <img src={arrowDown} alt="icon"></img>
                        </span>
                        <DateTimePicker
                          className={s.datetimeInput}
                          isValidDate={disableFutureDates}
                          onChange={m => {
                            onFromDateChange(m.valueOf());
                          }}
                          timeFormat="HH:mm"
                          dateFormat="DD-MMM-YYYY"
                          value={moment(currentFromTimestamp).tz(currentTimezone)}
                          defaultValue={moment(currentFromTimestamp).tz(currentTimezone)}
                          closeOnSelect={true}
                          showClear={false}
                          disabled={disableControls}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}>
                  <Row>
                    <Col>
                      <div className={s.subHeading2}>
                        <FormattedMessage id="unitreports.todatetime" defaultMessage="To date/time" />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="form-group has-icon">
                        <span className="form-control-icon">
                          <img src={arrowDown} alt="icon"></img>
                        </span>
                        <DateTimePicker
                          className={s.datetimeInput}
                          isValidDate={disablePastDates}
                          onChange={m => onToDateChange(m.valueOf())}
                          timeFormat="HH:mm"
                          dateFormat="DD-MMM-YYYY"
                          value={moment(currentToTimestamp).tz(currentTimezone)}
                          defaultValue={moment(currentToTimestamp).tz(currentTimezone)}
                          closeOnSelect={true}
                          showClear={false}
                          disabled={disableControls}
                        />
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col lg={4}></Col>
              </Row>
              <Row>
                <Col>
                  {localformErrors && localformErrors.timeRange && (
                    <p role="alert" className={s.error}>
                      {localformErrors.timeRange}
                    </p>
                  )}
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className={s.unitsubHeading}>
                    <FormattedMessage id="unitreports.selectdatapoints" defaultMessage="Select data points" />
                  </div>
                </Col>
              </Row>

              <Row className={s.unitReportsCheckbox}>
                <Col>
                  <Form.Group controlId="formUserIsEnabled">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'SelectAll',
                          target: { type: 'checkbox' },
                          label: 'Select All',
                          isChecked: dailySummaryData.search.selectAll
                        }
                      ]}
                      onSelectionChange={onSelectAllChange}
                      disable={disableControls}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className={s.unitReportsCheckbox}>
                <Col>
                  <Form.Group controlId="summaryDataTypes">
                    <Checkbox
                      key={uuidv4()}
                      controlData={{ customClassName: s.unitReportsCheckbox.li }}
                      dataArray={summaryTypes()}
                      onSelectionChange={onSummaryTypeChange}
                      disable={disableControls}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  {localformErrors && localformErrors.dataPoint && (
                    <p role="alert" className={s.error}>
                      {localformErrors.dataPoint}
                    </p>
                  )}
                </Col>
              </Row>
              <Button variant="primary" className={s.margin5} onClick={submitForm} noValidate disabled={disableControls}>
                <FormattedMessage id="dailySummaryReport.downloadReport" defaultMessage="DOWNLOAD REPORT" />
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </ContentTemplate>
  );
};

export default injectIntl(DailySummaryReport);
