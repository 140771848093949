import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './ApprovedComponent.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import ToggleSwitch from '../ToggleSwitch/ToggleSwitch';
import InputNumber from '../WSAControls/InputNumber/InputNumber';

const ApprovedSubComponent = ({
  isEdit,
  selectedApprovedSubComponent,
  changeApprovedSubComponent,
  saveApprovedSubComponent,
  cancelHandler,
  componentSubTypeOptions,
  equationOptions,
  intl
}) => {
  const [localformErrors, setFormErrors] = useState({});
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = selectedApprovedSubComponent;
    saveData.IsEdit = isEdit;
    saveData.isChanged = true;
    saveData.isDeleted = false;

    if (!isEdit) {
      saveData.ApprovedSubComponentId = 0;
      saveData.GuidIdentifier = uuidv4();
    }
    //saving Model Version
    cancelHandler();
    saveApprovedSubComponent(saveData);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!selectedApprovedSubComponent || !selectedApprovedSubComponent.ModelSubComponentTypeId) {
      formErrors.ModelSubComponentTypeId = intl.formatMessage({
        id: 'approvedComponents.subComponentTypeRequired',
        defaultMessage: 'Approved sub component is a mandatory field'
      });
      isValid = false;
    }

    if (
      selectedApprovedSubComponent &&
      selectedApprovedSubComponent.CalibrationRequired &&
      !selectedApprovedSubComponent.CalibrationEquationId
    ) {
      formErrors.CalibrationEquationId = intl.formatMessage({
        id: 'approvedComponents.calibrationEquationRequired',
        defaultMessage: 'Calibration equation is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    changeApprovedSubComponent({
      selectedSubApprovedComponent: { ...selectedApprovedSubComponent, [e.target.name]: e.target.value }
    });
  };

  const onToggleChange = name => {
    if (name === 'CalibrationRequired') {
      changeApprovedSubComponent({
        selectedSubApprovedComponent: { [name]: !selectedApprovedSubComponent.CalibrationRequired, isChanged: true }
      });
    }

    if (name === 'Output') {
      changeApprovedSubComponent({
        selectedSubApprovedComponent: { [name]: !selectedApprovedSubComponent.Output, isChanged: true }
      });
    }
  };

  const onDropdownModelComponentTypeChange = e => {
    changeApprovedSubComponent({
      selectedSubApprovedComponent: { ModelSubComponentTypeId: e.value, ApprovedSubComponentName: e.label, isChanged: true }
    });
  };

  const onDropdownCalibrationEquationChange = e => {
    changeApprovedSubComponent({ selectedSubApprovedComponent: { CalibrationEquationId: e.value, isChanged: true } });
  };

  let ddlClassComponentSubType = localformErrors && localformErrors.ModelSubComponentTypeId ? s.ddlError : '';
  let ddlClassCalibrationEquation = localformErrors && localformErrors.CalibrationEquationId ? s.ddlError : '';
  let isCalibrationReq = selectedApprovedSubComponent.CalibrationRequired || false;
  let isOutputReq = selectedApprovedSubComponent.Output || false;

  const labelUnchecked = <FormattedMessage id="common.no" defaultMessage="No" />;
  const labelChecked = <FormattedMessage id="common.yes" defaultMessage="Yes" />;

  let saveText = <FormattedMessage id="approvedComponents.addApprovedSubComponents" defaultMessage="ADD" />;

  if (isEdit) {
    saveText = <FormattedMessage id="approvedComponents.updateApprovedSubComponents" defaultMessage="UPDATE" />;
  }

  return (
    <div className={s.approvedComponent}>
      <div className={s.approvedSubComponentContent}>
        <div className={s.topRow}>
          <Row>
            <hr />
          </Row>
          <Row>
            <Col lg={4}>
              <Form.Group controlId="formComponentType">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.approvedSubComponents" defaultMessage="Approved Sub Components" />
                </Form.Label>
                <Dropdown
                  id="ddlComponentSubType"
                  dataArray={componentSubTypeOptions}
                  controlData={{
                    placeholderText: <FormattedMessage id="approvedComponents.pleaseSelectOne" defaultMessage="Please select one" />,
                    customClassName: ddlClassComponentSubType
                  }}
                  onDropdownChange={onDropdownModelComponentTypeChange}
                  selectedOption={componentSubTypeOptions.filter(
                    option => option.value === selectedApprovedSubComponent.ModelSubComponentTypeId
                  )}
                  data-unittest="ddlComponentType"
                />
                {localformErrors && localformErrors.ModelSubComponentTypeId && (
                  <p role="alert" className={s.error}>
                    {localformErrors.ModelSubComponentTypeId}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="formApprovedComponentCalibrationRequired">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.calibrationRequired" defaultMessage="Calibration required" />
                </Form.Label>
                <ToggleSwitch
                  id="CalibrationRequired"
                  handleClick={() => {
                    onToggleChange('CalibrationRequired');
                  }}
                  classname={s.switch}
                  checked={selectedApprovedSubComponent.CalibrationRequired}
                  labelChecked={labelChecked}
                  labelUnchecked={labelUnchecked}
                  labelPosition="Right"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group>
                <Form.Label>
                  <FormattedMessage id="approvedComponents.calibrationFrequency" defaultMessage="Calibration frequency" />
                </Form.Label>
                <Form.Control
                  type="text"
                  disabled={!isCalibrationReq}
                  name="CalibrationFrequency"
                  onChange={onChange}
                  value={selectedApprovedSubComponent.CalibrationFrequency || ''}
                  className={`${s.formControl}`}
                  placeholder={intl.formatMessage({
                    id: 'approvedComponents.calibrationFrequency',
                    defaultMessage: 'Calibration frequency'
                  })}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formApprovedComponentCalibrationTolerance">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.calibrationTolerance" defaultMessage="Calibration tolerance" />
                </Form.Label>
                <Row>
                  <Col>
                    <InputNumber
                      disabled={!isCalibrationReq}
                      name="CalibrationAcceptedErrorTolerance"
                      step=".1"
                      onInputChange={onChange}
                      customClassName={s.formControl}
                      value={selectedApprovedSubComponent.CalibrationAcceptedErrorTolerance?.toString()}
                      label="%"
                      id="CalibrationTolerance"
                      placeholder={intl.formatMessage({
                        id: 'approvedComponents.calibrationTolerance',
                        defaultMessage: 'Calibration tolerance'
                      })}
                    />
                  </Col>
                  <Col className={s.verticalAlign}>
                    <div className={s.calTolerance}>%</div>
                  </Col>
                </Row>
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formCalibrationEquation">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.calibrationEquation" defaultMessage="Calibration equation" />
                </Form.Label>
                <Dropdown
                  id="ddlCalibrationEquation"
                  dataArray={equationOptions}
                  controlData={{
                    placeholderText: <FormattedMessage id="approvedComponents.pleaseSelectOne" defaultMessage="Please select one" />,
                    customClassName: ddlClassCalibrationEquation
                  }}
                  onDropdownChange={onDropdownCalibrationEquationChange}
                  selectedOption={equationOptions.filter(option => option.value === selectedApprovedSubComponent.CalibrationEquationId)}
                  data-unittest="ddlComponentType"
                  disabled={!isCalibrationReq}
                />
                {localformErrors && localformErrors.CalibrationEquationId && (
                  <p role="alert" className={s.error}>
                    {localformErrors.CalibrationEquationId}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col lg={3}>
              <Form.Group controlId="formApprovedComponentOutput">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.output" defaultMessage="Output" />
                </Form.Label>
                <ToggleSwitch
                  id="Output"
                  handleClick={() => {
                    onToggleChange('Output');
                  }}
                  classname={s.switch}
                  checked={selectedApprovedSubComponent.Output}
                  labelChecked={labelChecked}
                  labelUnchecked={labelUnchecked}
                  labelPosition="Right"
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={3}>
              <Form.Group controlId="formApprovedComponentOutputLowRange">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.outputLowRange" defaultMessage="Output low range" />
                </Form.Label>
                <InputNumber
                  disabled={!isOutputReq}
                  name="OutputRangeLow"
                  step=".1"
                  onInputChange={onChange}
                  customClassName={s.formControl}
                  value={selectedApprovedSubComponent.OutputRangeLow?.toString()}
                  id="OutputRangeLow"
                  placeholder={intl.formatMessage({
                    id: 'approvedComponents.outputLowRange',
                    defaultMessage: 'Output low range'
                  })}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formApprovedComponentOutputHighRange">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.outputHighRange" defaultMessage="Output high range" />
                </Form.Label>
                <InputNumber
                  disabled={!isOutputReq}
                  name="OutputRangeHigh"
                  step=".1"
                  onInputChange={onChange}
                  customClassName={s.formControl}
                  value={selectedApprovedSubComponent.OutputRangeHigh?.toString()}
                  id="OutputRangeHigh"
                  placeholder={intl.formatMessage({
                    id: 'approvedComponents.outputHighRange',
                    defaultMessage: 'Output high range'
                  })}
                />
              </Form.Group>
            </Col>
            <Col lg={3}>
              <Form.Group controlId="formApprovedComponentOutputUnitOfMeasure">
                <Form.Label>
                  <FormattedMessage id="approvedComponents.outputUOM" defaultMessage="Output unit of measure" />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="OutputUnitOfMeasure"
                  onChange={onChange}
                  value={selectedApprovedSubComponent.OutputUnitOfMeasure}
                  className={`${s.formControl}`}
                  placeholder={intl.formatMessage({
                    id: 'approvedComponents.outputUOM',
                    defaultMessage: 'Output unit of measure'
                  })}
                  disabled={!isOutputReq}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChangesSmall)}
                onClick={submitForm}
                noValidate
                data-unittest="saveData"
              >
                {saveText}
              </Button>
              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancelSmall)}
                onClick={cancelHandler}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="calibrationEquation.constant.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
            <Col lg={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(ApprovedSubComponent);
