import React, { useEffect, useState } from 'react';
import s from './FAQuestion.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { getFAQuestion } from '../../../selectors/index';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../../LoadingSpinner/LoadingSpinner';
import Banner from '../../Banner/Banner';
import { initialFAQuestionState } from '../../../reducers/initialState';
import { getFAQuestionThunk, saveFAQuestionThunk, describeFAQuestionThunk, setFAQuestionCleanUpThunk } from '../../../actions/faQuestions';
import FAQuestionListTable from './FAQuestionListTable';
import collapseDown from '../../../assets/collapse-down.svg';
import collapseUp from '../../../assets/collapse-up.svg';
import { WidgetVisibility, IsWidgetAccessible } from '../../../utils/widgetManager';
import SearchBar from '../../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues } from '../../../utils';
import Cookies from 'js-cookie';
import ConfirmDialog from '../../ConfirmDialog/ConfirmDialog';
import Collapse from 'react-bootstrap/Collapse';
import Dropdown from '../../WSAControls/DroprdownContainer/Dropdown';

const FAQuestionList = ({ userProfileWidgets, faqCategorySpecificQuestions, faqCategoryId, intl }) => {
  const history = useNavigate();

  let filter = '';
  let offset = 0;
  let limit = 1000;
  let initialLimit = 6;

  const dispatch = useDispatch();
  const faQuestion = useSelector(state => getFAQuestion(state));

  let initialRecords = faQuestion.faQuestions.slice(0, initialLimit);
  const [faQuestionOpen, setFAQuestionOpen] = useState(false);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    if (!faqCategorySpecificQuestions) {
      dispatch(getFAQuestionThunk('FAQ_LIST'));
    } else if (faqCategoryId) {
      dispatch(describeFAQuestionThunk(null, 'FAQ_LIST', faqCategoryId));
    }
  }, [getFAQuestionThunk, dispatch, faQuestion.isOpSuccessful, faqCategoryId]);

  useEffect(() => {
    return () => {
      dispatch(
        setFAQuestionCleanUpThunk({
          ...initialFAQuestionState.faQuestion.selectedFAQuestion
        })
      );
    };
  }, []);

  const [localFAQuestions, setLocalFAQuestions] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);

  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [active, setActive] = useState(false);
  const [faQuestionId, setFAQuestionId] = useState(0);
  const [filterFaqCategoryId, setFilterFAQCategoryId] = useState(0);
  const [searchText, setSearchText] = useState('');

  const localFAQuestionLength = localFAQuestions.length;
  const globalSettingLength = faQuestion.faQuestions.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalFAQuestions(faQuestion.faQuestions);
    }
    setShowAll(!showAll);
  };

  const searchHandler = (value, isDropDown = false) => {
    if (value === '' || value === 0) {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }

    let filteredList = faQuestion.faQuestions;

    if (isDropDown) {
      setFilterFAQCategoryId(value);
      filteredList = filteredList.filter(function(item) {
        return item.FAQCategoryId === value;
      });
      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Question.toLowerCase().includes(searchText.toLowerCase());
        });
      }
    } else {
      setSearchText(value);
      filteredList = filteredList.filter(function(item) {
        return item.Question.toLowerCase().includes(value.toLowerCase());
      });

      if (filterFaqCategoryId) {
        filteredList = filteredList.filter(function(item) {
          return item.FAQCategoryId === filterFaqCategoryId;
        });
      }
    }

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalFAQuestions(filteredList);
  };

  const filterData = () => {
    let filteredList = faQuestion.faQuestions;
    if (isFilter) {
      if (searchText !== '') {
        filteredList = filteredList.filter(function(item) {
          return item.Question.toLowerCase().includes(searchText.toLowerCase());
        });
      }
      if (filterFaqCategoryId) {
        filteredList = filteredList.filter(function(item) {
          return item.FAQCategoryId === filterFaqCategoryId;
        });
      }
    }

    return filteredList;
  };

  const getFAQuestionData = () => {
    let faQuestions = [];
    if (showAll) {
      faQuestions = filterData();
    } else {
      if (isFilter) {
        faQuestions = localFAQuestionLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        faQuestions = initialRecords;
      }
    }
    return faQuestions.sort(compareValues('FAQCategoryId'));
  };

  const onRemoveFAQuestionClick = faQuestionId => {
    setActive(true);
    setFAQuestionId(faQuestionId);
  };

  const DeleteFAQuestion = () => {
    let saveData = faQuestion.selectedFAQuestion;
    saveData.PerformDelete = true;
    saveData.FAQuestionId = faQuestionId;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    dispatch(saveFAQuestionThunk(saveData, 'FAQ_DELETE'));
  };

  const handleClose = () => {
    setActive(false);
    setFAQuestionId(0);
  };

  const handleConfirm = () => {
    DeleteFAQuestion();
    handleClose();
  };

  const onAddNewClick = () => {
    if (faqCategorySpecificQuestions) {
      history.push(`/admin-controls/faqs/question/0/${faqCategoryId}`);
    } else {
      history.push(`/admin-controls/faqs/question`);
    }
  };

  const onChangeFAQuestionClick = faQuestionId => {
    if (faqCategorySpecificQuestions) {
      history.push(`/admin-controls/faqs/question/${faQuestionId}/${faqCategoryId}`);
    } else {
      history.push(`/admin-controls/faqs/question/${faQuestionId}`);
    }
  };

  let messageId = (faQuestion && faQuestion.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  let showAllText = intl.formatMessage({ id: 'common.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'common.showLess', defaultMessage: 'SHOW LESS' });

  const isUpdateAllowed = IsWidgetAccessible(userProfileWidgets, 'FAQ_EDIT');
  const isDeleteAllowed = IsWidgetAccessible(userProfileWidgets, 'FAQ_DELETE');

  const dialogTitle = intl.formatMessage({
    id: 'faQuestions.confirmDelete',
    defaultMessage: 'Are you sure you want to delete this question?'
  });

  const faqCategoryOptions = () => {
    let faqCategorysData = [];

    const data = faQuestion.faqCategories;

    data.forEach(element => {
      faqCategorysData.push({
        label: element.Name,
        value: element.FAQCategoryId
      });
    });

    let sortedData = faqCategorysData.sort(compareValues('label'));
    return [
      {
        label: <FormattedMessage id="faQuestions.allFAQCategory" defaultMessage="All FAQ Category" />,
        value: 0
      },
      ...sortedData
    ];
  };

  const component = (
    <div className={s.innerComponent} id="model-component-type-section">
      <div className={s.component}>
        {faQuestion.isLoading && <LoadingSpinner />}
        {faQuestion.showBanner && (
          <Banner
            failureText={messageText}
            showBanner={faQuestion.showBanner}
            status={faQuestion.isOpSuccessful}
            successText={messageText}
          />
        )}
        <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
        <div className={s.contentWrapper}>
          <Row className={s.topRow}>
            {!faqCategorySpecificQuestions && (
              <Col sm={3} lg={3}>
                <Dropdown
                  id="formFAQCategory"
                  dataArray={faqCategoryOptions()}
                  controlData={{
                    placeholderText: <FormattedMessage id="faQuestions.selectFAQCategory" defaultMessage="Select FAQ Category" />
                  }}
                  name="FAQCategoryId"
                  onDropdownChange={e => searchHandler(e.value, true)}
                  selectedOption={faqCategoryOptions().filter(option => option.value === filterFaqCategoryId)}
                />
              </Col>
            )}
            <Col sm={3} lg={3}>
              <SearchBar
                searchHandler={searchHandler}
                clearSearchInVisible={false}
                disabled={faQuestion.showAddEditScreen}
                placeHolderTextId="common.search"
                data-unittest="searchFAQuestions"
              />
            </Col>
            <Col sm={!faqCategorySpecificQuestions ? 6 : 9} lg={!faqCategorySpecificQuestions ? 6 : 9}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'FAQ_ADD')}
                onClick={onAddNewClick}
                disabled={faQuestion.showAddEditScreen}
                data-unittest="addFAQuestionButton"
              >
                + <FormattedMessage id="common.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Col>
          </Row>

          <div>
            <FAQuestionListTable
              RemoveFAQuestion={onRemoveFAQuestionClick}
              FAQuestionData={getFAQuestionData()}
              ChangeFAQuestion={onChangeFAQuestionClick}
              UpdateAllowed={isUpdateAllowed}
              DeleteAllowed={isDeleteAllowed}
              faqCategorySpecificQuestions={faqCategorySpecificQuestions}
            />
          </div>
          {((!isFilter && globalSettingLength > initialLimit) || (isFilter && localFAQuestionLength > initialLimit)) && (
            <Row className={s.topRow}>
              <Col>
                <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                  {!showAll ? `${showAllText} (${!showAll && !isFilter ? globalSettingLength : localFAQuestionLength})` : showLessText}
                </Button>
              </Col>
            </Row>
          )}
        </div>
      </div>
    </div>
  );

  return (
    <div className={s.faQuestionList}>
      <div className={s.titleRows} style={WidgetVisibility(userProfileWidgets, 'FAQ_LIST')}>
        {!faqCategorySpecificQuestions && (
          <Row>
            <Col xs={12} md={6}>
              <Row>
                <img
                  src={faQuestionOpen ? collapseDown : collapseUp}
                  alt="Collapse section"
                  onClick={() => setFAQuestionOpen(!faQuestionOpen)}
                  aria-controls="model-component-type-section"
                  aria-expanded={faQuestionOpen}
                ></img>
                <h4>
                  <FormattedMessage id="faQuestions.faQuestions" defaultMessage="FAQ Questions" />
                </h4>
                <span className={s.circle}>{faQuestion.faQuestions.length}</span>
              </Row>
            </Col>
          </Row>
        )}
        {!faqCategorySpecificQuestions && <Collapse in={faQuestionOpen}>{component}</Collapse>}
        {faqCategorySpecificQuestions && <div>{component}</div>}
      </div>
    </div>
  );
};

FAQuestionList.defaultProps = {
  ...initialFAQuestionState
};

export default injectIntl(FAQuestionList);
