import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './UnitServiceDetail.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import Form from 'react-bootstrap/Form';
import { isRequired, getQuerystring, compareValues, getDateInDDMMYYYFormat } from '../../utils';
import classNames from 'classnames';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import InputNumber from '../WSAControls/InputNumber/InputNumber';

const UnitPartService = ({
  intl,
  onRemove,
  selectedPartServices,
  checklistTasks,
  componentParts,
  serviceTypes,
  isEdit,
  onSave,
  isView
}) => {
  const [isPartAddNew, setIsPartAddNew] = useState(false);
  const [selectedEditUnitPartId, setSelectedEditUnitPartId] = useState(0);
  const [selectedEditTaskId, setSelectedEditTaskId] = useState(0);
  const [selectedViewUnitPart, setSelectedViewUnitPart] = useState({ UnitPartServiceId: 0, TaskId: 0 });
  const [showAll, setShowAll] = useState(false);

  isView = isView ?? false;

  let initialLimit = 6;
  let initialPartServices = showAll ? selectedPartServices : selectedPartServices.slice(0, initialLimit);

  let showAllText = intl.formatMessage({ id: 'partService.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'partService.showLess', defaultMessage: 'SHOW LESS' });

  const onAddNewBtnClick = () => {
    setSelectedEditUnitPartId(0);
    setSelectedViewUnitPart({ UnitPartServiceId: 0, TaskId: 0 });
    setSelectedEditTaskId(0);
    setIsPartAddNew(!isPartAddNew);
  };

  const onViewBtnClick = unitPartService => {
    setSelectedViewUnitPart({ UnitPartServiceId: unitPartService.UnitPartServiceId, TaskId: unitPartService.TaskId });
    setSelectedEditUnitPartId(0);
    setSelectedEditTaskId(0);
    setIsPartAddNew(false);
  };

  const onViewLessBtnClick = () => {
    setSelectedViewUnitPart({ UnitPartServiceId: 0, TaskId: 0 });
    setSelectedEditUnitPartId(0);
    setSelectedEditTaskId(0);
    setIsPartAddNew(false);
  };

  const onPartServiceEdit = unitPartService => {
    setSelectedViewUnitPart({ UnitPartServiceId: 0, TaskId: 0 });
    setSelectedEditUnitPartId(unitPartService.UnitPartServiceId || 0);
    setSelectedEditTaskId(unitPartService.TaskId);
    setIsPartAddNew(false);
  };

  const onPartServiceEditCancel = () => {
    setSelectedViewUnitPart({ UnitPartServiceId: 0, TaskId: 0 });
    setSelectedEditUnitPartId(0);
    setSelectedEditTaskId(0);
    setIsPartAddNew(false);
  };

  const onPartServiceRemove = partService => {
    onRemove(partService);
  };

  const showAllHandler = () => {
    setShowAll(!showAll);
  };

  return (
    <div className={s.partsServiceContainer}>
      <div className={s.subHeading}>
        <Row>
          <Col>
            <h4>
              <FormattedMessage id="partService.linkedTasks" defaultMessage="Linked Tasks" />
            </h4>
            <span className={s.circle}>{selectedPartServices.length}</span>
          </Col>
          <Col className={s.colAddButton}>
            {!isView && (
              <Button
                disabled={isPartAddNew || componentParts.length === 0}
                variant="primary"
                data-unittest="addnew"
                className={s.addButton}
                onClick={() => onAddNewBtnClick()}
              >
                + <FormattedMessage id="partService.addNew" defaultMessage="ADD NEW" />
              </Button>
            )}
          </Col>
        </Row>
      </div>
      <div className={s.tableContainer}>
        <Table variant className={s.innerTable}>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="partService.task" defaultMessage="Task" />
              </th>
              <th>
                <FormattedMessage id="partService.component" defaultMessage="Component" />
              </th>
              <th className={`d-none d-md-table-cell`}>
                <FormattedMessage id="partService.serialNumber" defaultMessage="Serial Number" />
              </th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {selectedPartServices.length === 0 && !isPartAddNew && (
              <tr className={s.emptyRow} data-unittest="unitPartServiceData">
                <td colSpan="4">
                  <FormattedMessage id="partService.noData" defaultMessage="No tasks have been linked to a service record" />
                </td>
              </tr>
            )}
            {isPartAddNew && (
              <tr>
                <td colSpan={4}>
                  <UnitPartServiceDetails
                    unitPart={null}
                    selectedPartServices={initialPartServices}
                    checklistTasks={checklistTasks}
                    componentParts={componentParts}
                    isEdit={isEdit}
                    serviceTypes={serviceTypes}
                    intl={intl}
                    onSave={onSave}
                    onAddNewBtnClick={onAddNewBtnClick}
                    onPartServiceEditCancel={onPartServiceEditCancel}
                  />
                </td>
              </tr>
            )}
            {initialPartServices.map((n, index) => {
              return (
                <Fragment key={index}>
                  {selectedEditTaskId !== n.TaskId && (
                    <tr className={`${s.partsServiceRow}`} data-unittest="TaskData">
                      <td>{n.TaskName}</td>
                      <td>{n.ModelComponentPositionName}</td>
                      <td className="d-none d-md-table-cell">{n.SerialNumber}</td>
                      <td>
                        {!n.UnitPartServiceId && (
                          <Button
                            variant="outline-secondary"
                            data-unittest="remove"
                            onClick={() => onPartServiceRemove({ UnitPartServiceId: n.UnitPartServiceId, TaskId: n.TaskId })}
                            className={s.partsServiceRowButton}
                          >
                            <FormattedMessage id="checklistTaskss.remove" defaultMessage="REMOVE" />
                          </Button>
                        )}

                        {selectedViewUnitPart.UnitPartServiceId === n.UnitPartServiceId && selectedViewUnitPart.TaskId === n.TaskId ? (
                          <Button
                            onClick={() => {
                              onViewLessBtnClick(n);
                            }}
                            variant="outline-secondary"
                            data-unittest="viewLess"
                            className={s.partsServiceRowButton}
                          >
                            <FormattedMessage id="partService.viewLess" defaultMessage="VIEW LESS" />
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              onViewBtnClick(n);
                            }}
                            variant="outline-secondary"
                            data-unittest="viewMore"
                            className={s.partsServiceRowButton}
                          >
                            <FormattedMessage id="partService.viewMore" defaultMessage="VIEW MORE" />
                          </Button>
                        )}

                        {!isView && (
                          <Button
                            variant="outline-secondary"
                            data-unittest="edit"
                            onClick={() => onPartServiceEdit({ UnitPartServiceId: n.UnitPartServiceId, TaskId: n.TaskId })}
                            className={s.partsServiceRowButton}
                          >
                            <FormattedMessage id="partService.edit" defaultMessage="EDIT" />
                          </Button>
                        )}
                      </td>
                    </tr>
                  )}
                  {selectedEditUnitPartId === n.UnitPartServiceId && selectedEditTaskId === n.TaskId && (
                    <tr>
                      <td colSpan={4}>
                        <UnitPartServiceDetails
                          unitPart={n}
                          componentParts={componentParts}
                          serviceTypes={serviceTypes}
                          selectedPartServices={initialPartServices}
                          checklistTasks={checklistTasks}
                          isEdit={isEdit}
                          intl={intl}
                          onSave={onSave}
                          onAddNewBtnClick={onAddNewBtnClick}
                          onPartServiceEditCancel={onPartServiceEditCancel}
                        />
                      </td>
                    </tr>
                  )}
                  {selectedViewUnitPart.UnitPartServiceId === n.UnitPartServiceId && selectedViewUnitPart.TaskId === n.TaskId && (
                    <tr>
                      <td colSpan={4} className={s.noborder}>
                        <UnitPartServiceView unitPart={n} serviceTypes={serviceTypes} />
                      </td>
                    </tr>
                  )}
                </Fragment>
              );
            })}
          </tbody>
        </Table>
        {selectedPartServices.length > initialLimit && (
          <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
            {!showAll ? `${showAllText} (${selectedPartServices.length})` : showLessText}
          </Button>
        )}
      </div>
    </div>
  );
};

const UnitPartServiceDetails = ({
  unitPart,
  checklistTasks,
  componentParts,
  serviceTypes,
  intl,
  selectedPartServices,
  onSave,
  onPartServiceEditCancel
}) => {
  const [formUnitPart, setFormUnitPart] = useState(unitPart || { UnitPartServiceId: 0, GuidIdentifier: uuidv4() });
  const [formError, setFormError] = useState({});

  const onDropdownChange = e => {
    setFormUnitPart({
      ...formUnitPart,
      [e.fieldIdName]: e.value,
      [e.fieldName]: e.label,
      SerialNumber: e.fieldName === 'ModelComponentPositionName' ? e.SerialNumber : formUnitPart.SerialNumber
    });
  };

  const checklistTaskOptions = () => {
    let checklistTaskData = [];
    checklistTasks.forEach(element => {
      checklistTaskData.push({
        ...element,
        fieldIdName: 'TaskId',
        fieldName: 'TaskName',
        label: element.Name,
        value: element.TaskId,
        isDisabled: selectedPartServices.filter(elem => element.TaskId === elem.TaskId).length > 0
      });
    });

    let sortedData = checklistTaskData.sort(compareValues('label'));
    return [
      {
        value: 0,
        label: 'Select',
        fieldIdName: 'TaskId',
        fieldName: 'TaskName'
      },
      ...sortedData
    ];
  };

  const componentPartsOptions = () => {
    let componentPartsData = [];
    componentParts.forEach(element => {
      componentPartsData.push({
        ...element,
        fieldIdName: 'ComponentPartId',
        fieldName: 'ModelComponentPositionName',
        label: element.ModelComponentPositionName,
        value: element.ComponentPartId
      });
    });

    let sortedData = componentPartsData.sort(compareValues('label'));
    return [
      {
        value: 0,
        label: 'Select',
        fieldIdName: 'ComponentPartId',
        fieldName: 'ModelComponentPositionName'
      },
      ...sortedData
    ];
  };

  const serviceTypeOptions = () => {
    let serviceTypesData = [];
    serviceTypes.forEach(element => {
      serviceTypesData.push({
        ...element,
        fieldIdName: 'ServiceTypeId',
        fieldName: 'ServiceTypeName',
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });

    let sortedData = serviceTypesData.sort(compareValues('label'));
    return [
      {
        value: 0,
        label: 'Select',
        fieldIdName: 'ServiceTypeId',
        fieldName: 'ServiceTypeName'
      },
      ...sortedData
    ];
  };

  const onChange = e => {
    setFormUnitPart({
      ...formUnitPart,
      [e.target.name]: e.target.value
    });
  };

  const onSaveClick = () => {
    if (validate()) {
      return;
    }
    onSave(formUnitPart);
    onPartServiceEditCancel();
  };

  const validate = () => {
    let taskError = false;
    if (!formUnitPart.TaskId) {
      taskError = true;
    }

    let componentPartError = false;
    if (!formUnitPart.ComponentPartId) {
      componentPartError = true;
    }

    let serviceTypeError = false;
    if (!formUnitPart.ServiceTypeId) {
      serviceTypeError = true;
    }

    setFormError({
      taskError: taskError,
      componentPartError: componentPartError,
      serviceTypeError: serviceTypeError
    });

    return componentPartError || serviceTypeError || taskError;
  };

  const onCancelClick = () => {
    setFormError({});
    setFormUnitPart({});
    onPartServiceEditCancel();
  };

  return (
    <div className={s.unitServiceDetail}>
      <div>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group controlId="formGrpTask">
              <Form.Label>
                <FormattedMessage id="partService.task" defaultMessage="Task" />
              </Form.Label>
              <Dropdown
                id="formTask"
                customClassName={`${formError && formError.taskError ? s.formControlError : ''}`}
                dataArray={checklistTaskOptions()}
                controlData={{ placeholderText: 'Select', customClassName: formError.taskError ? s.ddlError : '' }}
                onDropdownChange={onDropdownChange}
                data-unittest="formTask"
                selectedOption={checklistTaskOptions().filter(option => option.value == formUnitPart.TaskId)}
                disabled={formUnitPart.UnitPartServiceId > 0}
              />
              {formError && formError.taskError && (
                <p role="alert" className={s.error}>
                  <FormattedMessage id="partService.selecteTask" defaultMessage="Please select task" />
                </p>
              )}
              <div className={s.warningMsg}>
                *{' '}
                <FormattedMessage
                  id="unitServiceDetail.taskAndComponentPositionWarningMsg"
                  defaultMessage="Task and Component Position can not be changed once finalized"
                />
              </div>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group controlId="formGrpComponentPosition">
              <Form.Label>
                <FormattedMessage id="partService.componentPositions" defaultMessage="Component Positions" />
              </Form.Label>
              <Dropdown
                id="formComponentPosition"
                dataArray={componentPartsOptions()}
                controlData={{ placeholderText: 'Select', customClassName: formError.componentPartError ? s.ddlError : '' }}
                onDropdownChange={onDropdownChange}
                data-unittest="formComponentPosition"
                disabled={formUnitPart.UnitPartServiceId > 0}
                selectedOption={componentPartsOptions().filter(option => option.value == formUnitPart.ComponentPartId)}
              />
              {formUnitPart.SerialNumber && (
                <span>
                  <FormattedMessage id="partService.serialNumber" defaultMessage="Serial Number" />: {formUnitPart.SerialNumber || ''}
                </span>
              )}

              {formError && formError.componentPartError && (
                <p role="alert" className={s.error}>
                  <FormattedMessage id="partService.selectCompPosition" defaultMessage="Please select component position" />
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={3}>
            <Form.Group controlId="formPartsWarrantyCost">
              <Form.Label>
                <FormattedMessage id="partService.warrantyCost" defaultMessage="Warranty Cost" />
              </Form.Label>
              <InputNumber
                className={`${s.formControl}`}
                name="WarrantyCost"
                step="1"
                onInputChange={onChange}
                placeholder={intl.formatMessage({
                  id: 'partService.warrantyCost',
                  defaultMessage: 'Warranty Cost'
                })}
                value={formUnitPart.WarrantyCost || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={6} lg={3}>
            <Form.Group controlId="formPartsWarrantyHours">
              <Form.Label>
                <FormattedMessage id="partService.warrantyHours" defaultMessage="Warranty Hours" />
              </Form.Label>
              <InputNumber
                className={`${s.formControl}`}
                name="WarrantyHours"
                step="1"
                onInputChange={onChange}
                placeholder={intl.formatMessage({
                  id: 'partService.warrantyHours',
                  defaultMessage: 'Warranty Hours'
                })}
                value={formUnitPart.WarrantyHours || ''}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={6} lg={3}>
            <Form.Group controlId="formPartsNonWarrantyCost">
              <Form.Label>
                <FormattedMessage id="partService.nonWarrantyCost" defaultMessage="Non-Warranty Cost" />
              </Form.Label>
              <InputNumber
                className={`${s.formControl}`}
                name="NonWarrantyCost"
                step="1"
                onInputChange={onChange}
                placeholder={intl.formatMessage({
                  id: 'partService.nonWarrantyCost',
                  defaultMessage: 'Non-Warranty Cost'
                })}
                value={formUnitPart.NonWarrantyCost || ''}
              />
            </Form.Group>
          </Col>
          <Col xs={6} lg={3}>
            <Form.Group controlId="formPartsNonWarrantyHours">
              <Form.Label>
                <FormattedMessage id="partService.nonWarrantyHours" defaultMessage="Non-Warranty Hours" />
              </Form.Label>
              <InputNumber
                className={`${s.formControl}`}
                name="NonWarrantyHours"
                step="1"
                onInputChange={onChange}
                placeholder={intl.formatMessage({
                  id: 'partService.nonWarrantyHours',
                  defaultMessage: 'Non-Warranty Hours'
                })}
                value={formUnitPart.NonWarrantyHours || ''}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col xs={12} lg={6}>
            <Form.Group controlId="formGrpServiceType">
              <Form.Label>
                <FormattedMessage id="partService.serviceType" defaultMessage="Service Type" />
              </Form.Label>
              <Dropdown
                id="formServiceType"
                dataArray={serviceTypeOptions()}
                controlData={{ placeholderText: 'Select', customClassName: formError.serviceTypeError ? s.ddlError : '' }}
                onDropdownChange={onDropdownChange}
                data-unittest="formServiceType"
                selectedOption={serviceTypeOptions().filter(option => option.value == formUnitPart.ServiceTypeId)}
              />
              {formError && formError.serviceTypeError && (
                <p role="alert" className={s.error}>
                  <FormattedMessage id="partService.selectServiceType" defaultMessage="Please select service type" />
                </p>
              )}
            </Form.Group>
          </Col>
        </Row>
      </div>
      <div>
        <Form>
          <Button variant="primary" data-unittest="saveData" className={classNames(s.margin5, s.btnSaveChanges)} onClick={onSaveClick}>
            <FormattedMessage id="partService.save" defaultMessage="SAVE" />
          </Button>

          <Button variant="outline-secondary" className={s.btnCancel} onClick={() => onCancelClick()}>
            <FormattedMessage id="partService.cancel" defaultMessage="Cancel" />
          </Button>
        </Form>
      </div>
    </div>
  );
};

const UnitPartServiceView = ({ unitPart, serviceTypes }) => {
  const [formUnitPart, setFormUnitPart] = useState(unitPart || { UnitPartServiceId: 0, GuidIdentifier: uuidv4() });

  const serviceTypeOptions = () => {
    let serviceTypesData = [];
    serviceTypes.forEach(element => {
      serviceTypesData.push({
        ...element,
        fieldIdName: 'ServiceTypeId',
        fieldName: 'ServiceTypeName',
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });

    let sortedData = serviceTypesData.sort(compareValues('label'));
    return [
      {
        value: 0,
        label: 'Select',
        fieldIdName: 'ServiceTypeId',
        fieldName: 'ServiceTypeName'
      },
      ...sortedData
    ];
  };

  return (
    <div className={s.unitServiceDetail}>
      <div>
        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.task" defaultMessage="Task" />
            </Form.Label>
          </Col>
          <Col> {formUnitPart.TaskName}</Col>
        </Row>

        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.componentPositions" defaultMessage="Component Positions" />
            </Form.Label>
          </Col>
          <Col> {formUnitPart.ModelComponentPositionName}</Col>
        </Row>

        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.serialNumber" defaultMessage="Serial Number" />
            </Form.Label>
          </Col>
          <Col> {formUnitPart.SerialNumber}</Col>
        </Row>
        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.warrantyCost" defaultMessage="Warranty Cost" />
            </Form.Label>
          </Col>
          <Col> {formUnitPart.WarrantyCost || ''}</Col>
        </Row>

        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.warrantyHours" defaultMessage="Warranty Hours" />
            </Form.Label>
          </Col>
          <Col> {formUnitPart.WarrantyHours || ''}</Col>
        </Row>
        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.nonWarrantyCost" defaultMessage="Non-Warranty Cost" />
            </Form.Label>
          </Col>
          <Col> {formUnitPart.NonWarrantyCost || ''}</Col>
        </Row>
        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.nonWarrantyHours" defaultMessage="Non-Warranty Hours" />
            </Form.Label>
          </Col>
          <Col> {formUnitPart.NonWarrantyHours || ''}</Col>
        </Row>
        <Row className={s.viewRow}>
          <Col xs={12} lg={3}>
            <Form.Label>
              <FormattedMessage id="partService.serviceType" defaultMessage="Service Type" />
            </Form.Label>
          </Col>
          <Col>
            {' '}
            {serviceTypeOptions().find(option => option.value == formUnitPart.ServiceTypeId)
              ? serviceTypeOptions().find(option => option.value == formUnitPart.ServiceTypeId).label
              : ''}
          </Col>
        </Row>
      </div>
    </div>
  );
};

UnitPartService.defaultProps = {
  selectedPartServices: [],
  checklistTasks: [],
  isEdit: false,
  userProfileWidgets: [],
  componentParts: []
};

export default injectIntl(UnitPartService);
