import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import s from './ModelTelemetryLink.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { initialModelTelemetryLinkState } from '../../reducers/initialState';
import ModelTelemetryLinkListTable from './ModelTelemetryLinkListTable';
import { WidgetVisibility, IsWidgetAccessible } from '../../utils/widgetManager';
import { Link } from 'react-router-dom';
import { getTelemetryLinks, postTelemetryLink } from '../../actions/telemetryLinks';
import { getModelTelemetryLinks } from '../../selectors/index';
import Cookies from 'js-cookie';
import SearchBar from '../SearchBar/SearchBar';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { compareValues, logEntry } from '../../utils';
import { TELEMETRY_LINKS_MANAGEMENT, UPDATE_TELEMETRY_LINK } from '../../constants/logs';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import Banner from '../Banner/Banner';
import { v4 as uuid } from 'uuid' ;

const ModelTelemetryLinkList = ({ intl, ModelId, userProfileWidgets }) => {
  const dispatch = useDispatch();
  const telemetryLinkManager = useSelector(state => getModelTelemetryLinks(state));
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  useEffect(() => {
    dispatch(getTelemetryLinks(ModelId, 'MODEL_TELEMETRY_LINKS'));
  }, [getTelemetryLinks, ModelId]);

  let initialLimit = 6;
  let initialRecords = telemetryLinkManager && telemetryLinkManager.TelemetryLinks.slice(0, initialLimit);

  let messageId = (telemetryLinkManager && telemetryLinkManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });
  useEffect(() => {
    if (messageId === 'refresh') dispatch(getTelemetryLinks(ModelId, 'MODEL_TELEMETRY_LINKS'));
  }, [getTelemetryLinks, messageId]);

  const [localSettings, setLocalSettings] = useState([]);
  const [initialFilteredRecords, setInitialFilteredRecords] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [active, setActive] = useState(false);
  const [telemetryLinkId, setTelemetryLinkId] = useState(0);

  const localSettingLength = localSettings.length;
  const telemetryLinksLength = telemetryLinkManager && telemetryLinkManager.TelemetryLinks.length;

  const showAllHandler = () => {
    if (!isFilter) {
      setLocalSettings(telemetryLinkManager.TelemetryLinks);
    }
    setShowAll(!showAll);
  };

  const deleteTelemetry = telemetryLinkId => {
    let saveData = {
      TelemetryLinkId: telemetryLinkId,
      PerformDelete: true,
      UpdatedBy: Cookies.get(`userid-${envName}`) || 0
    };

    let actionName = UPDATE_TELEMETRY_LINK;
    let log = logEntry(TELEMETRY_LINKS_MANAGEMENT, actionName, saveData);
    dispatch(postTelemetryLink(saveData, log, 'MODEL_TELEMETRY_LINKS_REMOVE'));
  };

  const searchHandler = value => {
    if (value === '') {
      setIsFilter(false);
    } else {
      setIsFilter(true);
    }
    setSearchText(value);

    let filteredList = telemetryLinkManager.TelemetryLinks.filter(function(item) {
      return item.TargetComponentPositionName.toLowerCase().includes(value.toLowerCase());
    });

    if (filteredList.length > initialLimit) {
      setInitialFilteredRecords(filteredList.slice(0, 6));
    }
    setLocalSettings(filteredList);
  };

  const filterData = () => {
    let filteredList = telemetryLinkManager.TelemetryLinks;
    if (isFilter) {
      filteredList = telemetryLinkManager.TelemetryLinks.filter(function(item) {
        return item.TargetComponentPositionName.toLowerCase().includes(searchText.toLowerCase());
      });
    }

    return filteredList;
  };

  const getTelemetryLinksData = () => {
    let telemetryLinks = [];
    if (showAll) {
      telemetryLinks = filterData();
    } else {
      if (isFilter) {
        telemetryLinks = localSettingLength > initialLimit ? initialFilteredRecords : filterData();
      } else {
        telemetryLinks = initialRecords;
      }
    }
    return telemetryLinks && telemetryLinks.sort(compareValues('TargetComponentPositionName'));
  };

  const handleConfirm = () => {
    deleteTelemetry(telemetryLinkId);
    handleClose();
  };

  const handleClose = () => setActive(false);

  const handleRemove = telemetryLinkId => {
    setActive(true);
    setTelemetryLinkId(telemetryLinkId);
  };

  const dialogTitle = intl.formatMessage({
    id: 'model.telemetryLinks.areYouSureYouWantToRemoveTheTelemetryLink',
    defaultMessage: 'Are you sure you want to remove the Telemetry link?'
  });

  let showAllText = intl.formatMessage({ id: 'modelVersion.showAll', defaultMessage: 'SHOW ALL' });
  let showLessText = intl.formatMessage({ id: 'modelVersion.showLess', defaultMessage: 'SHOW LESS' });
  const IsChangeAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_TELEMETRY_LINKS_EDIT');
  const IsRemoveAllowed = IsWidgetAccessible(userProfileWidgets, 'MODEL_TELEMETRY_LINKS_REMOVE');

  return (
    <div className={s.telemetryLink}>
      {telemetryLinkManager && telemetryLinkManager.isLoading && <LoadingSpinner />}
      <ConfirmDialog title={dialogTitle} onConfirm={handleConfirm} onClose={handleClose} showDialog={active} />
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={telemetryLinkManager.showBanner}
        status={telemetryLinkManager.isOpSuccessful}
        successText={messageText}
      />
      <div className={s.contentWrapper}>
        <Row className={s.topRow}>
          <Col sm={6} lg={4}>
            <SearchBar
              searchHandler={searchHandler}
              clearSearchInVisible={false}
              // initialText={filter}
              placeHolderTextId="model.telemetryLinks.position"
              data-unittest="searchTelemetryLink"
            />
          </Col>
          <Col sm={6} lg={8}>
            <Link to={`/admin-controls/model-configuration/${ModelId}/telemetry-link`}>
              <Button
                className={s.addbutton}
                style={WidgetVisibility(userProfileWidgets, 'MODEL_TELEMETRY_LINKS_ADD')}
                data-unittest="addTelemetryLink"
              >
                + <FormattedMessage id="model.telemetryLinks.addNew" defaultMessage="ADD NEW" />
              </Button>
            </Link>
          </Col>
        </Row>

        <div>
          <ModelTelemetryLinkListTable
            telemetryLinkManager={telemetryLinkManager}
            telemetryLinksData={getTelemetryLinksData()}
            IsChangeAllowed={IsChangeAllowed}
            IsRemoveAllowed={IsRemoveAllowed}
            ModelId={ModelId}
            onRemoveClick={handleRemove}
          />
        </div>
        {((!isFilter && telemetryLinksLength > initialLimit) || (isFilter && localSettingLength > initialLimit)) && (
          <Row className={s.topRow}>
            <Col>
              <Button variant="outline-secondary" className="w-100" onClick={showAllHandler} data-unittest="showButton">
                {!showAll ? `${showAllText} (${!showAll && !isFilter ? telemetryLinksLength : localSettingLength})` : showLessText}
              </Button>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

ModelTelemetryLinkList.defaultProps = {
  ...initialModelTelemetryLinkState
};

ModelTelemetryLinkList.propTypes = {
  ModelId: PropTypes.string.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(ModelTelemetryLinkList);
