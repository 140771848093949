import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import s from './ModelVersion.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialModelVersionState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Navigate, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Checkbox from '../WSAControls/CheckboxContainer/Checkbox';
import AdminControlsContentTemplate from '../AdminControls/AdminControlsContentTemplate';
import classNames from 'classnames';
import { isRequired } from '../../utils';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import { compareValues } from '../../utils/';

const ModelVersion = ({
  saveModelVersion,
  describeModel,
  modelManager,
  describeModelVersion,
  modelVersionManager,
  changeModelVersion,
  sequenceVersionListing,
  userProfileWidgets,
  intl
}) => {
  let IsEdit = false;
  let IsDuplicate = false;
  let IsRedirect = false;
  let heading = '';
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  let { ModelVersionId, Duplicate, ModelId } = useParams();

  if (ModelVersionId) {
    IsEdit = true;
    if (Duplicate) {
      IsDuplicate = true;
      heading = intl.formatMessage({ id: 'modelVersion.duplicateModelVersion', defaultMessage: 'Duplicate' });
    } else {
      heading = intl.formatMessage({ id: 'modelVersion.editModelVersion', defaultMessage: 'Edit' });
    }
  }

  let widgetCode = ModelVersionId ? (Duplicate ? 'MODELVERSIONDUPLICATE' : 'MODELVERSIONUPDATE') : 'MODELVERSIONADD';

  useEffect(() => {
    if (ModelId && !ModelVersionId) {
      describeModel(ModelId, widgetCode);
    }
  }, [describeModel, ModelId, ModelVersionId, widgetCode]);

  useEffect(() => {
    if (ModelVersionId) {
      if (Duplicate) {
        describeModelVersion(ModelVersionId, Duplicate, widgetCode);
      } else {
        describeModelVersion(ModelVersionId, null, widgetCode);
      }
    }
  }, [describeModelVersion, ModelVersionId, Duplicate, widgetCode]);

  useEffect(() => {
    if (ModelVersionId) {
      if (Duplicate) {
        sequenceVersionListing(ModelVersionId, widgetCode);
      }
    }
  }, [sequenceVersionListing, ModelVersionId, Duplicate, widgetCode]);

  //clean up
  useEffect(() => {
    return () => {
      changeModelVersion({
        ...modelVersionManager,
        selectedModelVersion: { ...initialModelVersionState.modelVersionManager.selectedModelVersion }
      });
    };
  }, []);

  // Update redux store
  const setLocalModelVersion = currentState => {
    changeModelVersion(currentState);
  };
  //Saving
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = modelVersionManager.selectedModelVersion;
    saveData.IsEdit = IsEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.OrganisationId = IsDuplicate ? saveData.OrganisationId : Cookies.get(`selectedorganisationid-${envName}`) || 1;

    if (!IsEdit) {
      saveData.ModelId = ModelId;
      saveData.ModelVersionId = 0;
      saveData.GuidIdentifier = uuidv4();
    }

    if (IsDuplicate) {
      saveData.RefModelVersionId = ModelVersionId;
      saveData.RefModelId = ModelId;
      saveData.IsDuplicate = IsDuplicate;
      saveData.GuidIdentifier = uuidv4();
    }

    //saving Model Version
    saveModelVersion(saveData, widgetCode);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !modelVersionManager ||
      !modelVersionManager.selectedModelVersion ||
      !modelVersionManager.selectedModelVersion.Name ||
      isRequired(modelVersionManager.selectedModelVersion.Name, 1)
    ) {
      formErrors.Name = intl.formatMessage({
        id: 'modelVersion.nameMandatory',
        defaultMessage: 'Version name is a mandatory field'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    if (e.target && e.target.type && e.target.type === 'checkbox') {
      setLocalModelVersion({
        ...modelVersionManager,
        selectedModelVersion: { ...modelVersionManager.selectedModelVersion, [e.target.name]: e.target.checked }
      });
    } else {
      setLocalModelVersion({
        ...modelVersionManager,
        selectedModelVersion: { ...modelVersionManager.selectedModelVersion, [e.target.name]: e.target.value }
      });
    }
  };

  const organisationOptions = () => {
    let organisationData = [];

    modelVersionManager.selectedModelVersion.OrganisationHirarchies &&
      modelVersionManager.selectedModelVersion.OrganisationHirarchies.length > 0 &&
      modelVersionManager.selectedModelVersion.OrganisationHirarchies.forEach(element => {
        organisationData.push({
          label: element.Name,
          value: element.OrganisationId
        });
      });
    return organisationData;
  };

  const onOrganisationOptionsChange = e => {
    setLocalModelVersion({
      ...modelVersionManager,
      selectedModelVersion: { ...modelVersionManager.selectedModelVersion, OrganisationId: parseInt(e.value) }
    });
  };

  const onSequenceOptionsChange = e => {
    setLocalModelVersion({
      ...modelVersionManager,
      selectedModelVersion: { ...modelVersionManager.selectedModelVersion, SequenceVersionId: parseInt(e.value) }
    });
  };

  const sequenceOptions = () => {
    let sequenceVersionListingData = modelVersionManager.selectedModelVersion.SequenceVersions || [];
    let dataOption = [];

    dataOption.push({
      label: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />,
      value: 0
    });

    sequenceVersionListingData.forEach(element => {
      dataOption.push({
        label: element.Name,
        value: element.SequenceVersionId
      });
    });

    return dataOption.sort(compareValues('label'));
  };

  let messageId = (modelVersionManager && modelVersionManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  const modelName = (modelManager.selectedModel ? modelManager.selectedModel.Name : '') || '';

  const returnUrl = `/admin-controls/model-configuration/${ModelId}/`;

  return (
    <div className={s.modelversion}>
      {modelManager.isLoading || (modelVersionManager.isLoading && <LoadingSpinner />)}
      {modelVersionManager.isOpSuccessful && !modelVersionManager.showBanner ? <Navigate to={returnUrl} /> : ''}
      {IsRedirect ? <Navigate to={returnUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={modelVersionManager.showBanner}
        status={modelVersionManager.isOpSuccessful}
        successText={messageText}
      />

      <AdminControlsContentTemplate selectedPage="manageModels" userProfileWidgets={userProfileWidgets}>
        <div className={s.contentWrapper}>
          <div className={s.modelversionHeader}>
            <Link className={s.backLink} to={returnUrl}>
              &lt; &nbsp;
              <FormattedMessage id="modelVersion.backToModelConfiguration" defaultMessage="BACK TO MODEL CONFIGURATION" />
            </Link>
            <h3 data-unittest="headingLabel">
              {heading} {modelName} : {modelVersionManager.selectedModelVersion.Name}
            </h3>
          </div>
          <div className={s.modelversionContent}>
            <Form>
              {IsDuplicate && (
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formModelVersionName">
                      <Form.Label>
                        <FormattedMessage id="modelVersionManagement.Organisation" defaultMessage="Organisation" />
                      </Form.Label>

                      <Dropdown
                        id="drpOrgId"
                        dataArray={organisationOptions()}
                        controlData={{
                          placeholderText: (
                            <FormattedMessage id="modelVersionManagement.selectOrganisation" defaultMessage="Select Organisation" />
                          ),
                          customClassName: ''
                        }}
                        onDropdownChange={e => onOrganisationOptionsChange(e)}
                        selectedOption={organisationOptions().filter(
                          option => option.value === modelVersionManager.selectedModelVersion.OrganisationId
                        )}
                        disabled={false}
                      />
                      {localformErrors && localformErrors.OrganisationId && (
                        <p role="alert" className={s.error}>
                          {localformErrors.OrganisationId}
                        </p>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formModelVersionName">
                    <Form.Label>
                      <FormattedMessage id="modelVersionManagement.versionName" defaultMessage="Version Name" />
                    </Form.Label>

                    <Form.Control
                      type="text"
                      name="Name"
                      onChange={onChange}
                      value={modelVersionManager.selectedModelVersion.Name}
                      className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                    />
                    {localformErrors && localformErrors.Name && (
                      <p role="alert" className={s.error}>
                        {localformErrors.Name}
                      </p>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <Form.Group controlId="formModelVersionDescription">
                    <Form.Label>
                      <FormattedMessage id="modelVersionManagement.description" defaultMessage="Version Description" />
                    </Form.Label>

                    <Form.Control
                      as="textarea"
                      rows="3"
                      name="Description"
                      onChange={onChange}
                      placeholder={intl.formatMessage({
                        id: 'modelVersionManagement.enterDescription',
                        defaultMessage: 'Enter description'
                      })}
                      value={modelVersionManager.selectedModelVersion.Description}
                      className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                    />
                  </Form.Group>
                </Col>
              </Row>
              {IsDuplicate && (
                <Row>
                  <Col lg={6}>
                    <Form.Group controlId="formModelVersionName">
                      <Form.Label>
                        <FormattedMessage id="modelVersionManagement.sequenceVersion" defaultMessage="Sequence Version" />
                      </Form.Label>
                      <Dropdown
                        id="ddlSequenceVersions"
                        dataArray={sequenceOptions()}
                        controlData={{
                          placeholderText: <FormattedMessage id="common.selectOne" defaultMessage="Please select one" />
                        }}
                        onDropdownChange={e => onSequenceOptionsChange(e)}
                        selectedOption={sequenceOptions().filter(
                          option => option.value === modelVersionManager.selectedModelVersion.SequenceVersionId
                        )}
                        disabled={!IsDuplicate}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              )}
              <Row>
                <Col>
                  <Form.Group controlId="formActiveModelVersion">
                    <Checkbox
                      key={uuidv4()}
                      dataArray={[
                        {
                          SKEY: 'IsActive',
                          target: { type: 'checkbox' },
                          label: intl.formatMessage({
                            id: 'modelVersionManagement.active',
                            defaultMessage: 'Version is active'
                          }),
                          isChecked: modelVersionManager.selectedModelVersion.IsActive
                        }
                      ]}
                      onSelectionChange={onChange}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div>
                <Button
                  variant="primary"
                  className={classNames(s.margin5, s.btnSaveChanges)}
                  onClick={submitForm}
                  noValidate
                  data-unittest="saveData"
                >
                  <FormattedMessage id="modelVersionManagement.saveModelVersion" defaultMessage="SAVE CHANGES" />
                </Button>
                <Link to={returnUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="modelVersionManagement.cancelModelVersion" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </div>
            </Form>
          </div>
        </div>
      </AdminControlsContentTemplate>
    </div>
  );
};

ModelVersion.defaultProps = {
  modelVersionManager: {
    ...initialModelVersionState.modelVersionManager
  }
};

ModelVersion.propTypes = {
  saveModelVersion: PropTypes.func.isRequired,
  modelVersionManager: PropTypes.object.isRequired,
  describeModelVersion: PropTypes.func.isRequired,
  changeModelVersion: PropTypes.func.isRequired,
  userProfileWidgets: PropTypes.object.isRequired
};

export default injectIntl(ModelVersion);
