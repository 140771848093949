import React from 'react';
import PropTypes from 'prop-types';
import routes from './routes';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import languageObject from './translations/messages';
import { BrowserRouter  } from 'react-router-dom';

import '@formatjs/intl-pluralrules/polyfill';
import '@formatjs/intl-pluralrules/locale-data/en'; // And any other locales you need

const App = props => {
  const { locale } = props.locale;
  return (
    <IntlProvider locale={locale} messages={languageObject[locale]}>
      <BrowserRouter history={props.history}>{routes}</BrowserRouter>
    </IntlProvider>
  );
};

App.propTypes = {
  history: PropTypes.object,
  locale: PropTypes.object
};

const mapStateToProps = state => ({ locale: state.locale });

export default connect(mapStateToProps)(App);
