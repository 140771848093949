import React, { useState } from 'react';
import logoBlack from '../../assets/logo-black.svg';
import collapse from '../../assets/collapse.svg';
import expand from '../../assets/expand.svg';
import logoutIcon from '../../assets/logout-grey.svg';
import arrowDown from '../../assets/arrow-down-grey.svg';
import cross from '../../assets/cross.svg';
import defaultUser from '../../images/default-user.png';
import { matchPath } from 'react-router';
import PropTypes from 'prop-types';
import s from './Sidebar.module.scss';
import classNames from 'classnames';
import Image from 'react-bootstrap/Image';
import Cookies from 'js-cookie';
import { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import ProfileSwitch from '../ProfileSwitch/ProfileSwitch';
import OrganisationSwitch from '../OrganisationSwitch/OrganisationSwitch';
import { Navigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
// import { getUnitStatisticsSettingsThunk } from '../../actions/unitStatistics';
import { getUnitStatistics } from '../../selectors/index';
import { TEST_ENV, DEV_ENV, STAGING_ENV, DEMO_ENV, DOMAIN_NAME, IS_LOCALHOST } from '../../constants/index';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import { DEV_ENV, TEST_ENV, STAGING_ENV, IS_LOCALHOST } from '../../constants/index';
//import Cookies from 'js-cookie';
import { postUserThunk } from '../../actions/userManager';
import { setAllAlarmList } from '../../actions/devices';
import { redirectTo } from '../../actions/sidebar';
import ModalPDF from '../Help/TermsAndConditions/SignTermsAndConditions';



const envName = process.env.REACT_APP_ENV_NAME;
let envPrefix = '';
if (envName === DEV_ENV) {
  envPrefix = 'dev.';
} else if (envName === TEST_ENV) {
  envPrefix = 'test.';
} else if (envName === STAGING_ENV) {
  envPrefix = 'staging.';
} else if (envName === DEMO_ENV) {
  envPrefix = 'demo.';
}
const NAVIGATION_ITEMS = [
  {
    name: '',
    label: <FormattedMessage id="sidebar.organisationHierarchy" defaultMessage="Organisation Hierarchy" />,
    activeIcon: require('../../assets/hierarchy_blue.svg'),
    nonActiveIcon: require('../../assets/hierarchy_grey.svg'),
    link: '/organisation-hierarchy',
    WidgetName: 'NAV_ORGANISATIONHIERARCHY'
  },
  {
    name: 'home',
    label: <FormattedMessage id="sidebar.home" defaultMessage="Home" />,
    activeIcon: require('../../assets/home-blue.svg'),
    nonActiveIcon: require('../../assets/home-grey.svg'),
    link: '/dashboard',
    WidgetName: 'NAV_HOME'
  },
  {
    name: 'units',
    label: <FormattedMessage id="sidebar.units" defaultMessage="Units" />,
    activeIcon: require('../../assets/units-blue.svg'),
    nonActiveIcon: require('../../assets/units-grey.svg'),
    link: '/unit',
    WidgetName: 'NAV_UNITS'
  },
  {
    name: 'tasksChecklists',
    label: <FormattedMessage id="sidebar.tasksChecklists" defaultMessage="Tasks" />,
    activeIcon: require('../../assets/tasks-blue.svg'),
    nonActiveIcon: require('../../assets/tasks-grey.svg'),
    link: '/tasks-and-checklists',
    WidgetName: 'NAV_TASKS'
  },
  // {
  //   name: 'parts',
  //   label: <FormattedMessage id="sidebar.parts" defaultMessage="Parts" />,
  //   activeIcon: require('../../assets/parts-blue.svg'),
  //   nonActiveIcon: require('../../assets/parts-grey.svg'),
  //   link: '/parts',
  //   WidgetName: 'NAV_PARTS'
  // },
  {
    name: 'alarms',
    label: <FormattedMessage id="alarms.alarms" defaultMessage="Alarms" />,
    activeIcon: require('../../assets/alarm-light-blue.svg'),
    nonActiveIcon: require('../../assets/alarm-light-grey.svg'),
    link: '/alarms',
    WidgetName: 'NAV_ALARMS'
  },
  {
    name: 'maintenance',
    label: <FormattedMessage id="maintenance.maintenance" defaultMessage="Maintenance" />,
    activeIcon: require('../../assets/maintenance-blue.svg'),
    nonActiveIcon: require('../../assets/maintenance-grey.svg'),
    link: '/maintenance',
    WidgetName: 'NAV_MAINTAINENCE'
  },
  {
    name: 'adminControls',
    label: <FormattedMessage id="sidebar.adminControls" defaultMessage="Admin Controls" />,
    activeIcon: require('../../assets/admin-blue.svg'),
    nonActiveIcon: require('../../assets/admin-grey.svg'),
    link: '/admin-controls',
    WidgetName: 'ADMINCONTROLS'
  },
  {
    name: 'qualityApp',
    label: <FormattedMessage id="sidebar.safetyPlan" defaultMessage="Safety Plan" />,
    activeIcon: require('../../assets/units-blue.svg'),
    nonActiveIcon: require('../../assets/units-grey.svg'),
    link: `https://${envPrefix}wqsp.watersourceaustralia.com.au/dashboard`,
    WidgetName: 'NAV_SAFETY_PLAN'
  },
  {
    name: 'accountSettings',
    label: <FormattedMessage id="sidebar.accountSettings" defaultMessage="Account Settings" />,
    activeIcon: require('../../assets/settings-blue.svg'),
    nonActiveIcon: require('../../assets/settings-grey.svg'),
    link: '/account-settings',
    WidgetName: 'NAV_SETTINGS'
  },
  {
    name: 'releasenotes',
    label: <FormattedMessage id="sidebar.releasenotes" defaultMessage="Release Notes" />,
    activeIcon: require('../../assets/releasenoteblue.svg'),
    nonActiveIcon: require('../../assets/releasenotegrey.svg'),
    link: '/release-notes',
    WidgetName: 'NAV_RELEASENOTE'
  },
  {
    name: 'help',
    label: <FormattedMessage id="sidebar.help" defaultMessage="Help" />,
    activeIcon: require('../../assets/help-blue.svg'),
    nonActiveIcon: require('../../assets/help-grey.svg'),
    link: '/help',
    WidgetName: 'NAV_HELP'
  }
];

const Sidebar = ({
  logout,
  handleViewSidebar,
  currentSidebarOpen,
  location,
  getSelectedUserProfileWidgets,
  userProfileWidgets,
  getGlobalSettings,
  globalSettingsDataPresent,
  userProfileWidgetsPresent
}) => {
  const envName = process.env.REACT_APP_ENV_NAME;
  const envNameShort = process.env.REACT_APP_ENV_NAME_SHORT;
  const currentYear = new Date().getFullYear();

  const dispatch = useDispatch();
  const unitStatistics = useSelector(state => getUnitStatistics(state));

  const arrowOrientation = currentSidebarOpen ? collapse : expand;
  const sidebarClass = currentSidebarOpen ? '' : s.active;
  const sidebarTopClass = currentSidebarOpen ? s.sidebarTop : s.sidebarTopClose;
  const width = window.innerWidth;
  const name = Cookies.get(`name-${envNameShort}`);
  //const profiles = Cookies.get(`profiles-${envNameShort}`) ? JSON.parse(Cookies.get(`profiles-${envNameShort}`)) : {};
  const profiles = localStorage.getItem(`profiles-${envNameShort}`) ? JSON.parse(localStorage.getItem(`profiles-${envNameShort}`)) : {};

  //const organisations = Cookies.get(`organisations-${envNameShort}`) ? JSON.parse(Cookies.get(`organisations-${envNameShort}`)) : [];
  const organisations =
    (localStorage.getItem(`organisations-${envNameShort}`) && JSON.parse(localStorage.getItem(`organisations-${envNameShort}`))) || [];
  let selectedProfile = Cookies.get(`selectedprofile-${envNameShort}`) || '';
  let selectedProfileid = Cookies.get(`selectedprofileid-${envNameShort}`) || 0;
  let selectedOrganisationId = Cookies.get(`selectedorganisationid-${envNameShort}`) || 0;

  let userName = Cookies.get(`username-${envNameShort}`) || '';
  let selectedOrganisation = organisations.filter(x => x.OrganisationId === parseInt(selectedOrganisationId));
  let selectedOrganisationName = selectedOrganisation.length > 0 ? selectedOrganisation[0].Name : '';
  let organisationLogos = localStorage.getItem(`organisationslogos-${envNameShort}`) || [];
  let organisationLogosJSONData = [];
  try {
    organisationLogosJSONData = JSON.parse(organisationLogos);
  } catch (e) {
    organisationLogosJSONData = [];
  }

  let selectedOrganisationLogoData = organisationLogosJSONData.filter(item => item.OrganisationId == parseInt(selectedOrganisationId));
  let selectedOrganisationLogo = logoBlack;
  if (selectedOrganisationLogoData.length > 0 && selectedOrganisationLogoData[0].OrganisationLogo) {
    selectedOrganisationLogo = process.env.REACT_APP_FILEUPLOAD_URL + '/' + selectedOrganisationLogoData[0].OrganisationLogo;
  }

  const [showPopup, setShowPopup] = useState(false);
  const [showOrganisationPopup, setShowOrganisationPopup] = useState(false);

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  const handleOrganisationClick = () => {
    setShowOrganisationPopup(!showOrganisationPopup);
  };

  const [redirect, setredirect] = useState(false);

  useEffect(() => {
    if (!userProfileWidgetsPresent) {
      getSelectedUserProfileWidgets(selectedProfileid, userName);
    }
  }, [getSelectedUserProfileWidgets, selectedProfileid, userName]);

  useEffect(() => {
    if (!globalSettingsDataPresent) {
      getGlobalSettings();
    }
  }, []);

  // useEffect(() => {
  //   if (IsWidgetAccessible(userProfileWidgets, 'UNIT_STATISTICS_VIEW')) {
  //     if (!unitStatistics.isLoading && (!unitStatistics || !unitStatistics.statisticTypes || unitStatistics.statisticTypes.length === 0)) {
  //       dispatch(getUnitStatisticsSettingsThunk());
  //     }
  //   }
  // }, []);

  let onUserProfileChange = prof => {
    setredirect(true);
    Cookies.set(`selectedprofile-${envNameShort}`, prof.Name, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    Cookies.set(`selectedprofileid-${envNameShort}`, prof.ProfileId, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    selectedProfile = prof.Name;
    selectedProfileid = prof.ProfileId;
    getSelectedUserProfileWidgets(selectedProfileid, userName);
    handleClick();
  };

  let onOrganisationChange = org => {
    Cookies.set(`selectedorganisationid-${envNameShort}`, org.OrganisationId, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
    selectedOrganisationName = org.Name;
    selectedOrganisationId = org.OrganisationId;
    getSelectedUserProfileWidgets(null, userName);
    handleOrganisationClick();
    const userId = Cookies.get(`userid-${envNameShort}`) || 0;
    dispatch(setAllAlarmList('', []));
    dispatch(postUserThunk({ CurrentOrganisationId: org.OrganisationId, UserId: userId }, 'GENERALACCESS', true));
    dispatch(redirectTo());
  };
  return (
    <>
      <nav className={classNames(s.sidebar, sidebarClass)}>
        {redirect ? <Navigate to="/dashboard" /> : ''}
        <div className={sidebarTopClass}>
          <div className={s.dismiss}>
            <img src={cross} alt="close" onClick={() => handleViewSidebar(true)} />
          </div>

          <div className={s.sidebarHeader}>
            <Link to="/" id="logo" className={s.logo}>
              <img src={selectedOrganisationLogo} alt="Logo" className={s.selectedLogoImg} />
            </Link>
            <img
              src={arrowOrientation}
              alt="Collapse"
              className={classNames(`${s.arrowCollapse} float-right`)}
              onClick={() => handleViewSidebar(!currentSidebarOpen)}
            />
          </div>
          <ul className="list-unstyled components">
            {NAVIGATION_ITEMS &&
              NAVIGATION_ITEMS.map((sidebarItem, index) =>
                IsWidgetAccessible(userProfileWidgets, sidebarItem.WidgetName) ? (
                  <li className={matchPath(location.pathname, { path: sidebarItem.link }) ? s.activeLink : ''} key={index}>
                    {sidebarItem.name === 'qualityApp' ? (
                      <a
                        target="_blank"
                        href={sidebarItem.link}
                        onClick={
                          width <= 768
                            ? () => handleViewSidebar(true)
                            : () => {
                                return false;
                              }
                        }
                      >
                        <img
                          src={
                            matchPath(location.pathname, { path: sidebarItem.link }) ? sidebarItem.activeIcon : sidebarItem.nonActiveIcon
                          }
                          alt=""
                          width="20"
                          height="20"
                          className="mr-2"
                        ></img>
                        <span>{sidebarItem.label}</span>
                      </a>
                    ) : (
                      <Link
                        to={sidebarItem.link}
                        onClick={
                          width <= 768
                            ? () => handleViewSidebar(true)
                            : () => {
                                return false;
                              }
                        }
                      >
                        <img
                          src={
                            matchPath(location.pathname, { path: sidebarItem.link }) ? sidebarItem.activeIcon : sidebarItem.nonActiveIcon
                          }
                          alt=""
                          width="20"
                          height="20"
                          className="mr-2"
                        ></img>
                        <span>{sidebarItem.label}</span>
                      </Link>
                    )}
                  </li>
                ) : (
                  <span key={index} />
                )
              )}
            <li key="logout">
              <a href="#" onClick={logout}>
                <img src={logoutIcon} alt="" width="20" height="20" className="mr-2"></img>
                <span>
                  <FormattedMessage id="sidebar.logout" defaultMessage="Log out" />
                </span>
              </a>
            </li>
          </ul>
        </div>

        <Row className={s.sidebarFooter}>
          <Col md={3}>
            <Image src={defaultUser} width="55px" height="55px" roundedCircle />
          </Col>
          <Col className={s.footerText} md={9}>
            <span className={s.userName}>{name ? name : ''}</span>
            <br />
            <span className={s.profileName}>
              {selectedProfile}
              <img src={arrowDown} alt="icon" onClick={handleClick}></img>
            </span>{' '}
            <br />
            <span className={s.profileName}>
              {selectedOrganisationName}
              <img src={arrowDown} alt="icon" onClick={handleOrganisationClick}></img>
            </span>
            {/* <span className={s.versionNo}>version : v1.1.0 </span> */}
            {profiles &&
              profiles.length > 0 &&
              profiles.map(prof => {
                let profName = prof.Name;
                if (!selectedProfile || !selectedProfileid) {
                  selectedProfile = profName;
                  selectedProfileid = prof.ProfileId;
                  Cookies.set(`selectedprofile-${envNameShort}`, profName, { expires: 7, domain: DOMAIN_NAME, secure: !IS_LOCALHOST });
                  Cookies.set(`selectedprofileid-${envNameShort}`, selectedProfileid, {
                    expires: 7,
                    domain: DOMAIN_NAME,
                    secure: !IS_LOCALHOST
                  });
                }
              })}
            {showPopup && (
              <ProfileSwitch
                selectedProfile={selectedProfile}
                profiles={profiles}
                onUserProfileChange={onUserProfileChange}
                closePopup={handleClick}
              ></ProfileSwitch>
            )}
            {organisations &&
              organisations.length > 0 &&
              organisations.map(org => {
                selectedOrganisation = org.Name;
                if (!selectedOrganisationId) {
                  selectedOrganisationId = org.OrganisationId;
                  Cookies.set(`selectedorganisationid-${envNameShort}`, selectedOrganisationId, {
                    expires: 7,
                    domain: DOMAIN_NAME,
                    secure: !IS_LOCALHOST
                  });
                }
              })}
            {showOrganisationPopup && (
              <OrganisationSwitch
                onOrganisationChange={onOrganisationChange}
                closePopup={handleOrganisationClick}
                selectedOrganisationId={selectedOrganisationId}
                organisations={organisations}
              ></OrganisationSwitch>
            )}
          </Col>
        </Row>
        <Row className={s.copyRight}>
          <Col className={s.copyRightText}>© Copyright {currentYear} Water Source</Col>
        </Row>
      </nav>
      <ModalPDF />
    </>
  );
};

Sidebar.propTypes = {
  handleViewSidebar: PropTypes.func,
  currentSidebarOpen: PropTypes.bool,
  logout: PropTypes.func,
  userProfileWidgets: PropTypes.object,
  getSelectedUserProfileWidgets: PropTypes.func,
  getGlobalSettings: PropTypes.func,
  globalSettingsDataPresent: PropTypes.bool.isRequired,
  userProfileWidgetsPresent: PropTypes.bool
};

Sidebar.defaultProps = {
  userProfileWidgets: {},
  globalSettingsDataPresent: false
};

export default Sidebar;
