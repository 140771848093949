import React, { useRef, useEffect, useState } from 'react';

const PDFViewer = ({ pdfUrl }) => {
  const getRandomNumber = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };
  const [height, setHeight] = useState('100vh');
  const [rndomizer, setRandomizer] = useState(1);
  const iframeRef = useRef(null);

  useEffect(() => {
    setRandomizer(getRandomNumber(1, 100));
    const handleMessage = event => {
      if (event.data.type === 'pdfHeight') {
        setHeight(`${event.data.height}px`);
      }
    };

    window.addEventListener('message', handleMessage);
    return () => window.removeEventListener('message', handleMessage);
  }, []);

  return (
    <iframe ref={iframeRef} src={pdfUrl + '?rnd=' + rndomizer + '#toolbar=0&scrollbar=0'} width="100%" style={{ height, border: 'none' }} />
  );
};

export default PDFViewer;
