import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import s from './Scheduler.module.scss';
import { FormattedMessage, injectIntl } from 'react-intl';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { initialCommandSchedulerState } from '../../reducers/initialState';
import { v4 as uuidv4 } from 'uuid';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import Banner from '../Banner/Banner';
import { Navigate, Link, useParams } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContentTemplate from '../ContentTemplate/ContentTemplate';
import Dropdown from '../WSAControls/DroprdownContainer/Dropdown';
import classNames from 'classnames';
import { isRequired, compareValues, logEntry } from '../../utils';
import { v4 as uuid } from 'uuid' ;
import Cookies from 'js-cookie';
import DateTime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import moment from 'moment-timezone';
import { IsWidgetAccessible } from '../../utils/widgetManager';
import { SCHEDULE_FREQUENCY, SCHEDULE_DURATION_IN_WEEKS, MINIMUM_START_TIME_IN_MINUTES } from '../../constants/index';
import GoBack from '../WSAControls/GoBack/GoBack';
import SchedulerListTable from '../SchedulerList/SchedulerListTable';

import {
  getCommandScheduler,
  getUserProfileWidget,
  getCurrentTimezone,
  getFlowSequenceGlobalSettings,
  getSelectedDeviceDefault,
  getUnitManager
} from '../../selectors/index';
import { SCHEDULE_MANAGEMENT, CREATE_SCHEDULE, END_SCHEDULE } from '../../constants/logs';
import { setCommandSchedulerChange, saveCommandScheduler } from '../../actions/commandScheduler';

const Scheduler = ({ intl }) => {
  const dispatch = useDispatch();
  const schedulerManager = useSelector(state => getCommandScheduler(state));
  const userProfileWidgets = useSelector(state => getUserProfileWidget(state));
  const currentTimezone = useSelector(state => getCurrentTimezone(state));
  const unitManager = useSelector(state => getUnitManager(state));
  const flowSequences = useSelector(state => getFlowSequenceGlobalSettings(state));
  const iotCommands = flowSequences && flowSequences.filter(x => x.ShowCommand);
  const isUnitTaggedOut = (unitManager.selectedUnit && unitManager.selectedUnit.IsTaggedOut) || 0;

  let { unitSerialNumber, unitId } = useParams();
  const [localformErrors, setFormErrors] = useState({});
  const [localScheduleData, setLocalScheduleData] = useState([]);

  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let userName = Cookies.get(`name-${envName}`) || '';
  let widgetCode = '';
  var d = new Date();

  const currentStartTimestamp =
    schedulerManager.selectedCommandSchedule.StartDateTime > 0
      ? schedulerManager.selectedCommandSchedule.StartDateTime
      : moment(d)
          .add(MINIMUM_START_TIME_IN_MINUTES, 'm')
          .unix();

  const currentEndTimestamp =
    schedulerManager.selectedCommandSchedule.EndDateTime > 0
      ? schedulerManager.selectedCommandSchedule.EndDateTime
      : moment(d)
          .add(MINIMUM_START_TIME_IN_MINUTES, 'm')
          .unix();

  useEffect(() => {
    return () => {
      dispatch(
        setCommandSchedulerChange({
          ...schedulerManager,
          selectedCommandSchedule: { ...initialCommandSchedulerState.commandScheduler.selectedCommandSchedule }
        })
      );
    };
  }, []);

  const setLocalScheduler = currentState => {
    dispatch(setCommandSchedulerChange(currentState));
  };

  const onCommandDropdownChange = e => {
    setLocalScheduler({
      selectedCommandSchedule: { ...schedulerManager.selectedCommandSchedule, GeneralGlobalSettingId: e.value }
    });

    //update local schedule data if present
    if (localScheduleData.length > 0) {
      const commandName = iotCommands.filter(x => x.GeneralGlobalSettingId == e.value) || [];

      let scheduleData = [];
      let localData = localScheduleData[0];
      localData.CommandName = commandName.length > 0 ? commandName[0].Name : '';

      scheduleData.push(localData);
      setLocalScheduleData(scheduleData);
    }
  };

  const onScheduleFrequencyDropdownChange = e => {
    let endTimestamp = moment(d)
      .add(MINIMUM_START_TIME_IN_MINUTES, 'm')
      .add(SCHEDULE_DURATION_IN_WEEKS, 'w')
      .unix();

    setLocalScheduler({
      selectedCommandSchedule: {
        ...schedulerManager.selectedCommandSchedule,
        ScheduleFrequency: e.value,
        StartDateTime: currentStartTimestamp,
        EndDateTime: endTimestamp
      }
    });

    //update local schedule data if present
    if (localScheduleData.length > 0) {
      let scheduleData = [];
      let localData = localScheduleData[0];
      localData.ScheduleFrequency = e.value;

      scheduleData.push(localData);
      setLocalScheduleData(scheduleData);
    }
  };

  const onStartDateTimeChange = value => {
    setLocalScheduler({
      selectedCommandSchedule: { ...schedulerManager.selectedCommandSchedule, StartDateTime: moment(value).unix() }
    });

    //update local schedule data if present
    if (localScheduleData.length > 0) {
      let scheduleData = [];
      let localData = localScheduleData[0];
      localData.StartDateTime = moment(value).unix();

      scheduleData.push(localData);
      setLocalScheduleData(scheduleData);
    }
  };

  const onEndDateChange = value => {
    setLocalScheduler({
      selectedCommandSchedule: { ...schedulerManager.selectedCommandSchedule, EndDateTime: moment(value).unix() }
    });

    //update local schedule data if present
    if (localScheduleData.length > 0) {
      let scheduleData = [];
      let localData = localScheduleData[0];
      localData.EndDateTime = moment(value).unix();

      scheduleData.push(localData);
      setLocalScheduleData(scheduleData);
    }
  };

  const localSaveForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    const saveData = schedulerManager.selectedCommandSchedule;
    const commandName = iotCommands.filter(x => x.GeneralGlobalSettingId == saveData.GeneralGlobalSettingId) || [];
    let scheduleData = [];
    scheduleData.push({
      CommandName: commandName.length > 0 ? commandName[0].Name : '',
      StartDateTime: saveData.StartDateTime,
      EndDateTime: saveData.EndDateTime,
      ScheduleFrequency: saveData.ScheduleFrequency,
      FullName: userName
    });
    setLocalScheduleData(scheduleData);
  };

  const submitForm = e => {
    if (!validateInput()) {
      return;
    }

    let saveData = schedulerManager.selectedCommandSchedule;
    saveData.UnitId = parseInt(unitId);
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.Username = Cookies.get(`username-${envName}`) || '';
    saveData.CommandSchedulerId = 0;
    saveData.GuidIdentifier = uuidv4();
    widgetCode = 'SCHEDULE_ADD';

    let actionName = saveData.CommandSchedulerId === 0 ? CREATE_SCHEDULE : END_SCHEDULE;
    let log = logEntry(SCHEDULE_MANAGEMENT, actionName, saveData);
    dispatch(saveCommandScheduler(saveData, log, unitSerialNumber, widgetCode));
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (
      !schedulerManager ||
      !schedulerManager.selectedCommandSchedule ||
      !schedulerManager.selectedCommandSchedule.GeneralGlobalSettingId ||
      isRequired(schedulerManager.selectedCommandSchedule.GeneralGlobalSettingId, 1)
    ) {
      formErrors.GeneralGlobalSettingId = intl.formatMessage({
        id: 'schedule.commandMandatory',
        defaultMessage: 'Command is a mandatory field'
      });
      isValid = false;
    }

    if (
      !schedulerManager ||
      !schedulerManager.selectedCommandSchedule ||
      parseInt(schedulerManager.selectedCommandSchedule.ScheduleFrequency) < 0
    ) {
      formErrors.ScheduleFrequency = intl.formatMessage({
        id: 'schedule.frequencyMandatory',
        defaultMessage: 'Command Frequency is a mandatory field'
      });
      isValid = false;
    }

    //if start date and end date are selected, validate other conditions
    if (
      schedulerManager &&
      schedulerManager.selectedCommandSchedule &&
      !isRequired(schedulerManager.selectedCommandSchedule.EndDateTime, 1) &&
      !isRequired(schedulerManager.selectedCommandSchedule.StartDateTime, 1)
    ) {
      let endDate = moment.unix(schedulerManager.selectedCommandSchedule.EndDateTime);
      let startDate = moment.unix(schedulerManager.selectedCommandSchedule.StartDateTime);
      var d = new Date();
      const allowedStartTime = moment(d)
        .add(MINIMUM_START_TIME_IN_MINUTES - 1, 'm')
        .unix();

      if (startDate.isBefore(moment.unix(allowedStartTime))) {
        formErrors.PastStartTime = intl.formatMessage({
          id: 'schedule.pastStartTime',
          defaultMessage: 'Start Time can be selected from next 15 minutes'
        });
        isValid = false;
      }

      if (endDate.isBefore(moment.unix(allowedStartTime))) {
        formErrors.PastEndTime = intl.formatMessage({
          id: 'schedule.pastEndTime',
          defaultMessage: 'End Time can be selected from next 15 minutes'
        });
        isValid = false;
      }

      if (endDate.isBefore(startDate)) {
        formErrors.EndDateGreater = intl.formatMessage({
          id: 'schedule.endDateGreater',
          defaultMessage: 'Do-not-send-command after should be greater than Start-sending-command-at'
        });
        isValid = false;
      }

      let diff = moment.duration(endDate.diff(startDate)).asDays();
      if (diff > SCHEDULE_DURATION_IN_WEEKS * 7) {
        formErrors.EndDateLimit = intl.formatMessage({
          id: 'schedule.endDateLimit',
          defaultMessage: 'Do-not-send-command after can only be selected up to 12 weeks from the Start-sending-command-at'
        });
        isValid = false;
      }
    }

    setFormErrors(formErrors);

    return isValid;
  };

  const unitCommandOptions = () => {
    let unitCommandData = [];

    iotCommands.forEach(element => {
      if (!IsWidgetAccessible(userProfileWidgets, `IOTALPHA2COMMANDBUTTON_${element.SequenceCode}`)) {
        element.isDisabled = true;
      }
      unitCommandData.push({
        ...element,
        label: element.Name,
        value: element.GeneralGlobalSettingId
      });
    });
    return unitCommandData.sort(compareValues('Name'));
  };

  const scheduleFrequencyOptions = () => {
    let scheduleFrequencyData = [];

    Object.keys(SCHEDULE_FREQUENCY).map(element => {
      scheduleFrequencyData.push({
        label: SCHEDULE_FREQUENCY[element].name,
        value: parseInt(element)
      });
    });

    return scheduleFrequencyData.sort(compareValues('label'));
  };

  const disablePastDates = current =>
    current.isAfter(
      moment()
        .tz(currentTimezone)
        .subtract(1, 'day')
    );

  let ddlCommandClass = localformErrors && localformErrors.GeneralGlobalSettingId ? s.ddlError : '';
  let ddlFrequencyClass = localformErrors && localformErrors.ScheduleFrequency ? s.ddlError : '';
  const returnUrl = `/unit/schedule/${unitSerialNumber}`;
  let messageId = (schedulerManager && schedulerManager.displayMessageCode) || 'none';
  const messageText = intl.formatMessage({ id: messageId, defaultMessage: messageId });

  return (
    <div className={s.scheduler}>
      {isUnitTaggedOut && isUnitTaggedOut === 1 ? <Navigate to="/unauth" /> : ''}
      {schedulerManager.isLoading && <LoadingSpinner />}
      {schedulerManager.isOpSuccessful && !schedulerManager.showBanner ? <Navigate to={returnUrl} /> : ''}
      <Banner
        key={uuid()}
        failureText={messageText}
        showBanner={schedulerManager.showBanner || false}
        status={schedulerManager.isOpSuccessful}
        successText={messageText}
      />
      <ContentTemplate
        selectedPage="unitSchedule"
        userProfileWidgets={userProfileWidgets}
        widgetCode={'SCHEDULE_LIST'}
        tagoutRequired={true}
      >
        <div className={s.contentWrapper}>
          <div className={s.schedulerHeader}>
            <GoBack className={s.backLink}>
              &lt; &nbsp;
              <FormattedMessage id="schedule.back" defaultMessage="BACK" />
            </GoBack>
            <h3 data-unittest="headingLabel">
              <FormattedMessage id="schedule.newSchedule" defaultMessage="New Schedule" />
            </h3>
          </div>
          <div className={s.schedulerContent}>
            <Row className={s.breakrow}>
              <Col sm={3} xs={6}>
                <Form.Group controlId="frmCommand">
                  <Form.Label>
                    <FormattedMessage id="schedule.command" defaultMessage="Command" />
                  </Form.Label>
                  <Dropdown
                    id="drpCommand"
                    dataArray={unitCommandOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="schedule.select" defaultMessage="Select" />,
                      customClassName: ddlCommandClass
                    }}
                    onDropdownChange={onCommandDropdownChange}
                    selectedOption={unitCommandOptions().filter(
                      option => option.value === schedulerManager.selectedCommandSchedule.GeneralGlobalSettingId
                    )}
                    data-unittest="formGlobalSetting"
                  />
                  {localformErrors && localformErrors.GeneralGlobalSettingId && (
                    <p role="alert" className={s.error}>
                      {localformErrors.GeneralGlobalSettingId}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3} xs={6}>
                <Form.Group controlId="frmScheduleFrequencyId">
                  <Form.Label>
                    <FormattedMessage id="schedule.scheduleFrequency" defaultMessage="Command Frequency" />
                  </Form.Label>
                  <Dropdown
                    id="drpScheduleFrequency"
                    dataArray={scheduleFrequencyOptions()}
                    controlData={{
                      placeholderText: <FormattedMessage id="schedule.select" defaultMessage="Select" />,
                      customClassName: ddlFrequencyClass
                    }}
                    onDropdownChange={onScheduleFrequencyDropdownChange}
                    selectedOption={scheduleFrequencyOptions().filter(
                      option => option.value === schedulerManager.selectedCommandSchedule.ScheduleFrequency
                    )}
                    data-unittest="formGlobalSetting"
                  />
                  {localformErrors && localformErrors.ScheduleFrequency && (
                    <p role="alert" className={s.error}>
                      {localformErrors.ScheduleFrequency}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3} xs={6}>
                <Form.Group controlId="frmStartDatetime">
                  <Form.Label>
                    <FormattedMessage id="schedule.startDateTime" defaultMessage="Start sending command at" />
                  </Form.Label>
                  <DateTime
                    isValidDate={disablePastDates}
                    onChange={m => onStartDateTimeChange(m.valueOf())}
                    timeFormat="HH:mm"
                    dateFormat="DD-MMM-YYYY"
                    value={moment.unix(currentStartTimestamp).tz(currentTimezone)}
                    defaultValue={moment(currentStartTimestamp).tz(currentTimezone)}
                    closeOnSelect
                  />
                  {localformErrors && localformErrors.PastStartTime && (
                    <p role="alert" className={s.error}>
                      {localformErrors.PastStartTime}
                    </p>
                  )}
                </Form.Group>
              </Col>
              <Col sm={3} xs={6}>
                <Form.Group controlId="frmEndDate">
                  <Form.Label>
                    <FormattedMessage id="schedule.endDate" defaultMessage="Do not send command after" />
                  </Form.Label>
                  <DateTime
                    isValidDate={disablePastDates}
                    onChange={m => onEndDateChange(m.valueOf())}
                    timeFormat="HH:mm"
                    dateFormat="DD-MMM-YYYY"
                    value={moment.unix(currentEndTimestamp).tz(currentTimezone)}
                    defaultValue={moment(currentEndTimestamp).tz(currentTimezone)}
                    closeOnSelect
                  />
                  {localformErrors && localformErrors.PastEndTime && (
                    <p role="alert" className={s.error}>
                      {localformErrors.PastEndTime}
                    </p>
                  )}
                  {localformErrors && localformErrors.EndDateGreater && (
                    <p role="alert" className={s.error}>
                      {localformErrors.EndDateGreater}
                    </p>
                  )}
                  {localformErrors && localformErrors.EndDateLimit && (
                    <p role="alert" className={s.error}>
                      {localformErrors.EndDateLimit}
                    </p>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row className={s.breakRow}>
              <Col sm={12}>
                <Button className={classNames(s.btnSaveChanges)} onClick={localSaveForm} noValidate data-unittest="saveData">
                  <FormattedMessage id="schedule.save" defaultMessage="SAVE" />
                </Button>
                <Link to={returnUrl}>
                  <Button variant="outline-secondary" className={s.btnCancel}>
                    <FormattedMessage id="schedule.cancel" defaultMessage="CANCEL" />
                  </Button>
                </Link>
              </Col>
            </Row>
          </div>
        </div>

        {localScheduleData && localScheduleData.length > 0 && (
          <Row className={s.breakRow}>
            <Col sm={12}>
              <SchedulerListTable
                paginatedData={localScheduleData}
                userProfileWidgets={userProfileWidgets}
                currentTimezone={currentTimezone}
                isSummary={true}
              />

              <Button className={classNames(s.btnSaveChanges)} onClick={submitForm} noValidate data-unittest="finalizeData">
                <FormattedMessage id="schedule.finalize" defaultMessage="Finalize" />
              </Button>
            </Col>
          </Row>
        )}
      </ContentTemplate>
    </div>
  );
};

Scheduler.defaultProps = {
  schedulerManager: {
    ...initialCommandSchedulerState.commandScheduler
  }
};

export default injectIntl(Scheduler);
