import React, { useEffect, useState } from 'react';
import s from './ReleaseNoteView.module.scss';
import { FormattedMessage } from 'react-intl';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { useDispatch, useSelector } from 'react-redux';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { getReleaseNoteViewThunk } from '../../actions/releaseNotes';
import { getReleaseNote } from '../../selectors/index';
import { CONST_PLATFORM_NAME_CONTROL_SYSTEM, CONST_PLATFORM_NAME_ELEIOT } from '../../constants/index';
import Collapse from 'react-bootstrap/Collapse';
import collapseDown from '../../assets/collapse-down.svg';
import collapseUp from '../../assets/collapse-up.svg';

const ReleaseNoteView = ({ intl }) => {
  const dispatch = useDispatch();
  const widgetCode = 'GENERALACCESS';
  const releaseNote = useSelector(state => getReleaseNote(state));
  const releaseNoteViewData = releaseNote.viewReleaseNotes || [];
  const rnControlSystemViewData = releaseNoteViewData.filter(item => item.PlatformName == CONST_PLATFORM_NAME_CONTROL_SYSTEM);
  const rnEleiotViewData = releaseNoteViewData.filter(item => item.PlatformName == CONST_PLATFORM_NAME_ELEIOT);
  const [eleiotOpen, setEleiotOpen] = useState(false);
  const [controlSystemtOpen, setControlSystemOpen] = useState(false);
  useEffect(() => {
    dispatch(getReleaseNoteViewThunk(widgetCode));
  }, [getReleaseNoteViewThunk, dispatch, widgetCode]);

  return (
    <div className={s.releaseNotes}>
      {releaseNote.isLoading && <LoadingSpinner />}
      <div className={s.fullWidth}>
        <div className={s.fullWidthWrapper}>
          <h1 className={s.tabHeader}>{<FormattedMessage id="releaseNotes.releaseNotes" defaultMessage="Release Notes" />}</h1>
        </div>
      </div>
      <div className={s.pageContent}>
        <Row>
          <Col>
            <Row>
              <img
                src={eleiotOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setEleiotOpen(!eleiotOpen)}
                aria-controls="eleiot-section"
                aria-expanded={eleiotOpen}
                className={s.collapsesection}
              ></img>
              <h3>
                <FormattedMessage id="releaseNotes.eleiot" defaultMessage="Eleiot" />
              </h3>
            </Row>
          </Col>
        </Row>
        <Collapse in={eleiotOpen}>
          <div className={s.innerComponent} id="eleiot-section">
            {rnEleiotViewData.length > 0 &&
              rnEleiotViewData.map(item => {
                return <ReleaseNoteItem key={item.ReleaseNoteId} item={item} />;
              })}
          </div>
        </Collapse>
      </div>
      <div className={s.pageContent}>
        <Row>
          <Col>
            <Row>
              <img
                src={controlSystemtOpen ? collapseDown : collapseUp}
                alt="Collapse section"
                onClick={() => setControlSystemOpen(!controlSystemtOpen)}
                aria-controls="control-system-section"
                aria-expanded={controlSystemtOpen}
                className={s.collapsesection}
              ></img>
              <h3>
                <FormattedMessage id="releaseNotes.controlSystem" defaultMessage="Control System" />
              </h3>
            </Row>
          </Col>
        </Row>
        <Collapse in={controlSystemtOpen}>
          <div className={s.innerComponent} id="control-system-section">
            {rnControlSystemViewData.length > 0 &&
              rnControlSystemViewData.map(item => {
                return <ReleaseNoteItem key={item.ReleaseNoteId} item={item} />;
              })}
          </div>
        </Collapse>
      </div>
    </div>
  );
};

ReleaseNoteView.defaultProps = {};

ReleaseNoteView.propTypes = {};

export default ReleaseNoteView;

const ReleaseNoteItem = ({ item }) => {
  return (
    <div className={s.contentWrapper}>
      <Row>
        <Col>
          <h2>{item.Name}</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <h3>
            <FormattedMessage id="releaseNotes.versionNo" defaultMessage="Version No" /> : {item.VersionNo}
          </h3>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormattedMessage id="releaseNotes.dependencyVersionName" defaultMessage="Dependency Version Name" /> :{' '}
          {item.DependencyVersionName || ''}
        </Col>
      </Row>
      <Row>
        <Col>
          <FormattedMessage id="releaseNotes.dependencyVersionNo" defaultMessage="Dependency Version No" /> :{' '}
          {item.DependencyVersionNo || ''}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={s.notes} dangerouslySetInnerHTML={{ __html: item.Notes }} />
        </Col>
      </Row>
    </div>
  );
};
