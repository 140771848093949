import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { FormattedMessage, injectIntl } from 'react-intl';
import s from './CalibrationEquation.module.scss';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classNames from 'classnames';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import { isRequired } from '../../utils';
import Cookies from 'js-cookie';

const EquationConstant = ({
  isEdit,
  selectedEquationConstant,
  changeCalibrationEquationConstant,
  saveEquationConstant,
  cancelEquationConstantHandler,
  intl
}) => {
  const [localformErrors, setFormErrors] = useState({});
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;
  const submitForm = e => {
    e.preventDefault();

    if (!validateInput()) {
      return;
    }

    let saveData = selectedEquationConstant;
    saveData.IsEdit = isEdit;
    saveData.UpdatedBy = Cookies.get(`userid-${envName}`) || 0;
    saveData.isChanged = true;
    saveData.isDeleted = false;
    saveData.OrderNumber = 1;

    if (!isEdit) {
      saveData.CalibrationEquationConstantId = 0;
      saveData.GuidIdentifier = uuidv4();
      saveData.IsDeletable = 1;
    }
    //saving Model Version
    cancelEquationConstantHandler();
    saveEquationConstant(saveData);
  };

  const validateInput = () => {
    let formErrors = {};
    let isValid = true;

    if (!selectedEquationConstant || !selectedEquationConstant.Name || isRequired(selectedEquationConstant.Name, 1)) {
      formErrors.Name = intl.formatMessage({
        id: 'calibrationEquation.constant.nameMandatory',
        defaultMessage: 'Constant name is required'
      });
      isValid = false;
    }

    setFormErrors(formErrors);

    return isValid;
  };

  //on control value change
  const onChange = e => {
    changeCalibrationEquationConstant({
      SelectedEquationConstant: { ...selectedEquationConstant, [e.target.name]: e.target.value }
    });
  };

  let saveText = <FormattedMessage id="calibrationEquation.constant.add" defaultMessage="ADD" />;

  if (isEdit) {
    saveText = <FormattedMessage id="calibrationEquation.constant.update" defaultMessage="UPDATE" />;
  }

  return (
    <div className={s.calibrationequation}>
      <div className={s.calibrationequationContent}>
        <div className={s.topRow}>
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formEquationConstantName">
                <Form.Label>
                  <FormattedMessage id="calibrationEquation.constant.name" defaultMessage="Name" />
                </Form.Label>

                <Form.Control
                  type="text"
                  name="Name"
                  onChange={onChange}
                  value={selectedEquationConstant.Name}
                  className={`${s.formControl} ${localformErrors && localformErrors.Name ? s.formControlError : ''}`}
                />
                {localformErrors && localformErrors.Name && (
                  <p role="alert" className={s.error}>
                    {localformErrors.Name}
                  </p>
                )}
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={6}>
              <Form.Group controlId="formEquationConstantDescription">
                <Form.Label>
                  <FormattedMessage id="calibrationEquation.constant.description" defaultMessage="Description" />
                </Form.Label>

                <Form.Control
                  as="textarea"
                  rows="3"
                  name="Description"
                  onChange={onChange}
                  placeholder={intl.formatMessage({
                    id: 'calibrationEquation.constant.calibrationEquationConstant',
                    defaultMessage: 'Enter description'
                  })}
                  value={selectedEquationConstant.Description}
                  className={`${s.textArea} ${localformErrors && localformErrors.Description ? s.formControlError : ''}`}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <Button
                variant="primary"
                className={classNames(s.margin5, s.btnSaveChangesSmall)}
                onClick={submitForm}
                noValidate
                data-unittest="saveData"
              >
                {saveText}
              </Button>
              <Button
                variant="outline-secondary"
                className={classNames(s.btnCancelSmall)}
                onClick={cancelEquationConstantHandler}
                noValidate
                data-unittest="saveCancel"
              >
                <FormattedMessage id="calibrationEquation.constant.cancel" defaultMessage="CANCEL" />
              </Button>
            </Col>
            <Col lg={8}></Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(EquationConstant);
