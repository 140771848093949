import React, { Fragment, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DeviceHeader from '../DeviceHeader/DeviceHeaderContainer';
import TabNavigation from '../TabNavigation/TabNavigation';
import Alert from 'react-bootstrap/Alert';
import { FormattedMessage } from 'react-intl';
import s from './ContentTemplate.module.scss';
import alert from '../../assets/alert.svg';
import { setSelectedUnit, setActiveDeviceTimezone } from '../../actions/devices';
import { describeUnitThunk } from '../../actions/unitManager';
import { changeTimezone } from '../../actions/timezone';
import Cookies from 'js-cookie';
import moment from 'moment-timezone';
import { getUnitManager, getSelectedDeviceDefault } from '../../selectors/index';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const ContentTemplate = ({
  backLink,
  children,
  selectedPage,
  tabNavigation,
  userProfileWidgets,
  isNewAlarm,
  widgetCode,
  tagoutRequired
}) => {
  const dispatch = useDispatch();
  const { unitSerialNumber } = useParams();
  const history = useNavigate();
  const envName = process.env.REACT_APP_ENV_NAME_SHORT;

  let defaultTimezone = Cookies.get(`defaultTimezone-${envName}`);
  if (defaultTimezone === undefined) {
    defaultTimezone = moment.tz.guess();
  }

  const unitManager = useSelector(state => getUnitManager(state));
  const selectedDevice = useSelector(state => getSelectedDeviceDefault(state));

  const devicesObj = Object.values(selectedDevice);

  if (unitManager.isDataRendered && (!selectedDevice || !selectedDevice.unitSerialNumber)) {
    history.push('/unauth');
  }

  useEffect(() => {
    if (
      (!unitManager.isDataRendered || unitManager.selectedUnit.UnitSerialNumber !== unitSerialNumber) &&
      unitSerialNumber &&
      unitSerialNumber.length > 0 &&
      widgetCode
    ) {
      dispatch(setSelectedUnit(unitSerialNumber));
      dispatch(changeTimezone(defaultTimezone));
      dispatch(setActiveDeviceTimezone(''));
      dispatch(describeUnitThunk(unitSerialNumber, widgetCode, tagoutRequired));
    }
  }, [setSelectedUnit, changeTimezone, setActiveDeviceTimezone, describeUnitThunk, unitSerialNumber, widgetCode, tagoutRequired]);

  return (
    devicesObj.length > 0 &&
    unitManager.isDataRendered && (
      <Fragment>
        {unitManager.isLoading && <LoadingSpinner />}
        <div className={s.fullWidth}>
          <div className={s.fullWidthWrapper}>
            <DeviceHeader {...selectedDevice} backLink={backLink} />
            {isNewAlarm && (
              <Alert variant="light" className={`d-flex flex-row align-items-center ${s.alert}`}>
                <img src={alert} alt="icon" />
                <span className={s.alarmMessage}>
                  <FormattedMessage id="contentTemplate.newAlarm" defaultMessage="New alarm" />
                </span>
              </Alert>
            )}
            {tabNavigation && (
              <TabNavigation
                unitSerialNumber={selectedDevice.unitSerialNumber}
                selectedPage={selectedPage}
                userProfileWidgets={userProfileWidgets}
              />
            )}
          </div>
        </div>

        <div className={s.pageContent}>{children}</div>
      </Fragment>
    )
  );
};

ContentTemplate.defaultProps = {
  backLink: '/dashboard',
  selectedPage: '',
  tabNavigation: true,
  isNewAlarm: false,
  selectedDevice: {
    unitSerialNumber: ''
  }
};

ContentTemplate.propTypes = {
  backLink: PropTypes.string,
  children: PropTypes.node.isRequired,
  selectedDevice: PropTypes.object.isRequired,
  selectedPage: PropTypes.string,
  tabNavigation: PropTypes.bool,
  isNewAlarm: PropTypes.bool
};

export default ContentTemplate;
